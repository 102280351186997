import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cv-interview',
  templateUrl: './cv-interview.component.html',
  styleUrls: ['./cv-interview.component.scss']
})
export class CVInterviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
