import { Injectable } from "@angular/core";
import  {THEMES} from "src/assets/json/themes-list"
import { InMemoryDbService } from "angular-in-memory-web-api";
import { THEMES2 } from "src/assets/json/themes-list2";


@Injectable({
    providedIn: "root",
})
export class ThemeDataService implements InMemoryDbService {
    createDb() {
        let themes = THEMES;
        let themes2 = THEMES2;
        return {themes,themes2 }
    }

    createDb2() {
        let themes3 = THEMES2;
        let themes4 = THEMES2;
        return {themes3,themes4 }
    }

}
