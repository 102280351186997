<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Mon Tableau de bord</li>
            </ul>
            <h2>Mon Tableau de bord</h2>
        </div>
    </div>
</div>


<!--
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            title
        </mat-expansion-panel-header>
        content
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            title2
        </mat-expansion-panel-header>
        content2
    </mat-expansion-panel>
</mat-accordion>
-->
<div class="my-dashboard-area ptb-100">
    <div class="container">

        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/cvs"><i class='bx bx-id-card'></i> Mes CV</a></li>
                <li><a routerLink="/cvs/cover_letters"><i class='bx bx-file'></i> Mes lettres de motivation</a></li>
                <li><a routerLink="/cvs/information"><i class='bx bx-user'></i>Mes informations</a></li>
                <li><a routerLink="/cvs/subscription" class="active"><i class='bx bx-file'></i>Mes abonnements</a></li>



            </ul>
        </div>
        <div class="myDashboard-content">



            <div class="col-lg-12 col-md-12">

                <div class="row">
                    <div class="cart-area ptb-100">
                        <div class="container">

                            <div class="cart-table table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr style="font-size: 14px;">
                                            <th scope="col">REF</th>
                                            <th scope="col">MOYEN</th>
                                            <th scope="col">PRIX UNITAIRE</th>
                                            <th scope="col">NBRE DE JOURS</th>
                                            <th scope="col">TOTAL</th>
                                            <th scope="col">DEVISE</th>
                                            <th scope="col">DEBUT</th>
                                            <th scope="col">FIN</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="font-size: 14px;">
                                            <td> -</td>
                                            <td> - </td>
                                            <td>- </td>
                                            <td>- </td>
                                            <td> -</td>
                                            <td>- </td>
                                            <td>-</td>
                                            <td>-</td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>



                        </div>
                    </div>
                </div>



            </div>

        </div>
    </div>
</div>