

<div id="cvt" class="container-fluid" style="height: 100%; width: 100%;">
    <div class="row">


        <!--   CV TEMPLATE -->

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">



            <form [formGroup]="cvFormGroup" style="z-index : -1">
                <div id="cvt2" >
                    <div id="cvt10">

                        <!--  START PAGE 1-->
                        <div id="content1" class="resume-box">

                            <div id="left_side1" class="left-section" [style.background]="firstcolor">
                                <div class="profile">
                                    <img *ngIf="!imageToShow && imgURL" [src]="imgURL" class="profile-img">
                                    <img *ngIf="imageToShow" [src]="imageToShow" class="profile-img">
                                    <div class="blue-box" [style.background]="secondcolor"></div>
                                </div>

                                <h2 class="name" [style.color]="pagecolor">{{this.cvFormGroup.get("firstname").value}}
                                    <br>{{this.cvFormGroup.get("lastname").value}}
                                </h2>
                                <p class="n-p"> {{this.cvFormGroup.get("profession").value}}</p>

                                <div *ngIf="cvFormGroup.get('telephone').value.trim() || cvFormGroup.get('email').value.trim() ||
                                cvFormGroup.get('address').value.trim() || cvFormGroup.get('linkedin').value.trim() ||
                                 cvFormGroup.get('birth').value.trim() || cvFormGroup.get('webSite').value.trim() ||
                                 cvFormGroup.get('marital').value.trim() ||
                                  cvFormGroup.get('city').value.trim()" class="info"  >
                                    <p class="heading">{{'details' | translate}}</p>
                                    <p *ngIf="cvFormGroup.get('telephone').value" class="p1">
                                        <span class="span1"><img src="assets/img/phone8.png" width="10"
                                                height="10"></span>{{'phone' | translate}} :<span><br>
                                            <span *ngIf="this.cvFormGroup.get('telephone').value.includes('U2FsdG')">
                                                {{cvFormGroup.get('telephone').value | decrypted}}</span>
                                            <span *ngIf="!this.cvFormGroup.get('telephone').value.includes('U2FsdG')">
                                                {{cvFormGroup.get('telephone').value | encrypted | decrypted}}</span>
                                        </span>
                                    </p>
                                    <p *ngIf="cvFormGroup.get('email').value" class="p1"><span class="span1">
                                            <img src="assets/img/env2.png" width="12" height="12"></span>Email : <span>
                                            <br>{{cvFormGroup.get('email').value}}</span></p>
                                    <p *ngIf="cvFormGroup.get('linkedin').value.trim()" class="p1"><span class="span1">
                                            <img src="assets/img/linkedin4.png" width="16" height="16"></span>Linkedin:
                                        <span> <br>{{cvFormGroup.get('linkedin').value}}</span>
                                    </p>
                                    <p *ngIf="cvFormGroup.get('webSite').value.trim()" class="p1"><span class="span1">
                                            <img src="assets/img/globe2.png" width="15" height="15"></span>Site web :
                                        <span> <br>{{cvFormGroup.get('webSite').value}}</span>
                                    </p>
                                    <p *ngIf="cvFormGroup.get('address').value.trim() || cvFormGroup.get('city').value.trim()"
                                        class="p1"><span class="span1">
                                            <img src="assets/img/map2.png" width="17" height="17"></span><span
                                            *ngIf="this.cvFormGroup.get('address').value.includes('U2FsdG')">
                                            {{cvFormGroup.get('address').value | decrypted}}</span>
                                        <span *ngIf="!this.cvFormGroup.get('address').value.includes('U2FsdG')">
                                            {{cvFormGroup.get('address').value | encrypted | decrypted}}</span>
                                        <span> {{","+cvFormGroup.get('city').value}}</span>
                                    </p>


                                    <p *ngIf="cvFormGroup.get('birth').value.trim()" class="p1"><span class="span1"><img
                                                src="assets/img/birth2.png" width="17" height="17">
                                        </span>Né(e) le : <span> <br>{{cvFormGroup.get('birth').value}}</span></p>
                                    <p *ngIf="cvFormGroup.get('marital').value.trim()" class="p1"><span
                                            class="span1"><img src="assets/img/marital2.png" width="17" height="17">
                                        </span>Etat civil : <span> <br>{{cvFormGroup.get('marital').value}}</span></p>


                                </div>

                                <div *ngIf="cvFormGroup.get('skills').value.length" formArrayName="skills" class="info">
                                    <p class="heading">{{'skills' | translate}}</p>
                                    <div>
                                        <div *ngFor="let skill of cvSkills().controls; let skillIndex=index"
                                            [formGroupName]="skillIndex" style="margin:15px; margin-bottom: -3px;margin-top: 12px">
                                            <p style="margin-bottom: 0.3px; color: aliceblue; font-size: 15px;">
                                                {{cvSkills().at(skillIndex).get('item').value}}</p>
                                            <div style="height: 7px; background: #000; ">
                                                <div style="height: 100%; background: #1273bb;"
                                                    [style.background]="pagecolor"
                                                    [style.width]="cvSkills().at(skillIndex).get('level').value"> </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div *ngIf="cvFormGroup.get('languages').value.length" formArrayName="languages"
                                    class="info">
                                    <p class="heading">{{'languages' | translate}}</p>
                                    <div>
                                        <div *ngFor="let lang of cvLanguages().controls; let langIndex=index"
                                            [formGroupName]="langIndex" style="margin:15px; margin-bottom: -3px;margin-top: 12px">
                                            <p style="margin-bottom: 0.3px; color: aliceblue; font-size: 15px;">
                                                {{cvLanguages().at(langIndex).get('item').value}}</p>
                                            <div style="height: 7px; background: #000; ">
                                                <div style="height: 100%; background: #1273bb;"
                                                    [style.background]="pagecolor"
                                                    [style.width]="cvLanguages().at(langIndex).get('level').value">
                                                </div>
                                            </div>
                                        </div>



                                    </div>

                                </div>




                            </div>

                            <div class="right-section" id="right_side1">
                                <div *ngIf="this.cvFormGroup.get('profile').value.trim()" class="right-heading">

                                    <p class="p2">{{'profile' | translate}}</p>
                                </div>
                                <p *ngIf="this.cvFormGroup.get('profile').value.trim()" class="p3">
                                    {{this.cvFormGroup.get("profile").value}}</p>

                                <div class="clearfix"></div>
                                <br>

                                <!-- EDUCATION BEGINNER-->

                                <div *ngIf="cvFormGroup.get('educations').value.length && isBeginner" formArrayName="educations">
                                    <div class="right-heading" >

                                        <p class="p2">Education</p>
                                    </div>

                                    <div class="clearfix"></div>
                                    <div *ngFor="let education of cvEducations().controls; let educationIndex=index"
                                        [formGroupName]="educationIndex" class="lr-box">
                                        <div class="left">
                                            <p class="p4" style="width: 140px;">{{cvEducations().at(educationIndex).get('period').value}}</p>
                                            <p class="p5" style="width: 140px;">
                                                {{cvEducations().at(educationIndex).get('city').value}}</p>
                                        </div>

                                        <div class="right">
                                            <p class="p4" style="margin-left: 30px;">
                                                {{cvEducations().at(educationIndex).get('degree').value}}
                                            <p class="p5" style="margin-left: 30px;">
                                                {{cvEducations().at(educationIndex).get('institute').value}}</p>

                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <br>

                                </div>

                                <!-- EXPERIENCE PROFESSIONAL-->
                                <div *ngIf="cvFormGroup.get('experiences').value.length && isProfessional" formArrayName="experiences">
                                    <div class="right-heading">

                                        <p class="p2">{{'experiences' | translate}}</p>
                                    </div>
                                    <div class="clearfix"></div>

                                    <div *ngFor="let experience of cvExperiences().controls; let experienceIndex=index"
                                        [formGroupName]="experienceIndex" class="lr-box">
                                        <div class="left" >
                                            <p class="p4" style="width: 140px; font-size: 13px; ">
                                                {{cvExperiences().at(experienceIndex).get('period').value}}</p>
                                            <p class="p5" style="width: 140px; font-size: 13px; ">
                                                {{cvExperiences().at(experienceIndex).get('city').value}}</p>
                                        </div>

                                        <div class="right" >
                                            <p class="p4" style="margin-left: 30px;">
                                                {{cvExperiences().at(experienceIndex).get('function').value}}</p>
                                            <p class="p5" style="margin-left: 30px;"><strong
                                                    style=" text-transform: uppercase;">
                                                    {{cvExperiences().at(experienceIndex).get('company').value}}</strong>
                                            </p>
                                            <p class="p5" style="font-size: 12px; word-wrap: break-word; margin-left : 30px"
                                                [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">
                                            </p>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <br>
                                </div>


                                <div *ngIf="cvFormGroup.get('certifications').value.length"
                                    formArrayName="certifications" style="margin-top: -21px;">
                                    <div class="right-heading">

                                        <p class="p2">{{'certificates' | translate}}</p>
                                    </div>

                                    <div class="clearfix"></div>
                                    <div *ngFor="let certif of cvCertifications().controls; let certifIndex=index"
                                        [formGroupName]="certifIndex" class="lr-box">
                                        <div class="left">
                                            <p class="p4" style="width: 140px;">
                                                {{cvCertifications().at(certifIndex).get('date').value}}</p>

                                        </div>

                                        <div class="right">
                                            <p class="p4" style="margin-left: 30px;">
                                                {{cvCertifications().at(certifIndex).get('certificat').value}}</p>
                                            <p class="p5" style="margin-left: 30px;">
                                                {{cvCertifications().at(certifIndex).get('institute').value}}</p>

                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <br>
                                </div>

                                <div *ngIf="cvFormGroup.get('formations').value.length" formArrayName="formations" style="margin-top: -21px;">

                                    <div class="right-heading">

                                        <p class="p2">Formations</p>
                                    </div>

                                    <div class="clearfix"></div>
                                    <div *ngFor="let formation of cvFormations().controls; let formationIndex=index"
                                        [formGroupName]="formationIndex" class="lr-box">
                                        <div class="left">
                                            <p class="p4" tyle="width: 140px;">
                                                {{cvFormations().at(formationIndex).get('period').value}} </p>
                                            <p style="margin-left: 30px;" class="p5"></p>
                                        </div>

                                        <div class="right">
                                            <p class="p4" style="margin-left: 30px;"> {{cvFormations().at(formationIndex).get('title').value}}</p>
                                            <p class="p5" style="margin-left: 30px;"> {{cvFormations().at(formationIndex).get('former').value}}</p>
                                            <p class="p5" style="font-size: 12px; word-wrap: break-word; margin-left: 30px; "
                                                [innerHTML]="cvFormations().at(formationIndex).get('description').value">
                                            </p>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <br>
                                </div>

                                <!-- EDUCATION PROFESSIONAL-->

                                <div *ngIf="cvFormGroup.get('educations').value.length && isProfessional" formArrayName="educations">
                                    <div class="right-heading" style="margin-top: -21px;">

                                        <p class="p2">Education</p>
                                    </div>

                                    <div class="clearfix"></div>
                                    <div *ngFor="let education of cvEducations().controls; let educationIndex=index"
                                        [formGroupName]="educationIndex" class="lr-box">
                                        <div class="left">
                                            <p class="p4" style="width: 140px;">{{cvEducations().at(educationIndex).get('period').value}}</p>
                                            <p class="p5" style="width: 140px;">
                                                {{cvEducations().at(educationIndex).get('city').value}}</p>
                                        </div>

                                        <div class="right">
                                            <p class="p4" style="margin-left: 30px;">
                                                {{cvEducations().at(educationIndex).get('degree').value}}
                                            <p class="p5" style="margin-left: 30px;">
                                                {{cvEducations().at(educationIndex).get('institute').value}}</p>

                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <br>

                                </div>
                                <!-- EXPERIENCE BEGINNER-->
                                <div *ngIf="cvFormGroup.get('experiences').value.length && isBeginner" formArrayName="experiences">
                                    <div class="right-heading" style="margin-top: -21px;">

                                        <p class="p2">{{'experiences' | translate}}</p>
                                    </div>
                                    <div class="clearfix"></div>

                                    <div *ngFor="let experience of cvExperiences().controls; let experienceIndex=index"
                                        [formGroupName]="experienceIndex" class="lr-box">
                                        <div class="left" >
                                            <p class="p4" style="width: 140px; font-size: 13px; ">
                                                {{cvExperiences().at(experienceIndex).get('period').value}}</p>
                                            <p class="p5" style="width: 140px; font-size: 13px; ">
                                                {{cvExperiences().at(experienceIndex).get('city').value}}</p>
                                        </div>

                                        <div class="right" >
                                            <p class="p4" style="margin-left: 30px;">
                                                {{cvExperiences().at(experienceIndex).get('function').value}}</p>
                                            <p class="p5" style="margin-left: 30px;"><strong
                                                    style=" text-transform: uppercase;">
                                                    {{cvExperiences().at(experienceIndex).get('company').value}}</strong>
                                            </p>
                                            <p class="p5" style="font-size: 12px; word-wrap: break-word; margin-left : 30px"
                                                [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">
                                            </p>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <br>
                                </div>


                                <!-- RUBRIQUE PERSONNALISEE -->

                                <div *ngIf="cvFormGroup.get('customs').value.length" formArrayName="customs" style="margin-top: -21px;">
                                    <div *ngFor="let custom of cvCustoms().controls; let customIndex=index"
                                        [formGroupName]="customIndex">
                                        <div class="right-heading">

                                            <p class="p2">{{cvCustoms().at(customIndex).get('title').value}}</p>
                                        </div>

                                        <div class="clearfix"></div>
                                        <div class="lr-box">


                                            <div class="right">
                                                <p style="font-size: 12px; word-wrap: break-word;" [innerHTML]="cvCustoms().at(customIndex).get('description').value">
                                                </p>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>

                                    </div>
                                    <br>
                                </div>


                                <div *ngIf="cvFormGroup.get('quality1').value.trim() || cvFormGroup.get('quality2').value.trim() ||
                                cvFormGroup.get('quality3').value.trim() || cvFormGroup.get('quality4').value.trim()" style="margin-top: -21px;">
                                    <div class="right-heading">

                                        <p class="p2">{{'qualities' | translate}}</p>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="lr-box">
                                        <ul style="display: grid;
                                    grid-template-columns: repeat(4, 1fr); margin-left: 7px;">
                                            <li style="list-style: none;">
                                                {{cvFormGroup.get('quality1').value}}
                                            </li>
                                            <li style="list-style: none;">
                                                {{cvFormGroup.get('quality2').value}}
                                            </li>
                                            <li style="list-style: none;">
                                                {{cvFormGroup.get('quality3').value}}
                                            </li>
                                            <li style="list-style: none;">
                                                {{cvFormGroup.get('quality4').value}}
                                            </li>

                                        </ul>
                                    </div>
                                    <br>
                                </div>


                                <div  style="margin-top: -21px;" *ngIf="cvFormGroup.get('hobby1').value.trim() || cvFormGroup.get('hobby2').value.trim() ||
                                cvFormGroup.get('hobby3').value.trim() || cvFormGroup.get('hobby4').value.trim()">
                                    <div class="right-heading">

                                        <p class="p2">{{'hobbies' | translate}}</p>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="lr-box">
                                        <ul style="display: grid;
                                    grid-template-columns: repeat(4, 1fr); margin-left: 7px;">
                                            <li style="list-style: none;">
                                                {{cvFormGroup.get('hobby1').value}}
                                            </li>
                                            <li style="list-style: none;">
                                                {{cvFormGroup.get('hobby2').value}}
                                            </li>
                                            <li style="list-style: none;">
                                                {{cvFormGroup.get('hobby3').value}}
                                            </li>
                                            <li style="list-style: none;">
                                                {{cvFormGroup.get('hobby4').value}}
                                            </li>

                                        </ul>
                                    </div>
                                    <br />
                                </div>

                                <div *ngIf="cvFormGroup.get('references').value.length" formArrayName="references">
                                    <div class="right-heading" style="margin-top: -21px;">

                                        <p class="p2">{{'references' | translate}}</p>
                                    </div>

                                    <div class="clearfix"></div>
                                    <div *ngFor="let ref of cvReferences().controls; let refIndex=index"
                                        [formGroupName]="refIndex" class="lr-box">

                                        <div class="right">
                                            <p class="p4">{{cvReferences().at(refIndex).get('fullname').value}}</p>
                                            <p class="p5">
                                                <strong>{{cvReferences().at(refIndex).get('organisation').value}}
                                                </strong>
                                            </p>
                                            <p class="p5"> <strong
                                                    *ngIf="cvReferences().at(refIndex).get('telephone').value.trim()">Tel
                                                    :</strong>
                                                <span>{{cvReferences().at(refIndex).get('telephone').value}}</span> --
                                                <strong
                                                    *ngIf="cvReferences().at(refIndex).get('telephone').value.trim()">Email
                                                    :</strong>
                                                <span>{{cvReferences().at(refIndex).get('email').value}}</span>
                                            </p>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>

                            </div>

                            <div class="clearfix"></div>




                        </div>



                    </div>

                </div>
            </form>
        </div>

    </div>
</div>

<!--   FORM ARRAY         -->
