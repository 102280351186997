<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Mon compte</li>
            </ul>
            <h2>Mon compte</h2>
        </div>
    </div>
</div>

<div class="my-dashboard-area ptb-100">
    <div class="container">

        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/my-dashboard" class="active"><i class='bx bxs-dashboard'></i> Mes CV</a></li>
                <li><a routerLink="/orders"><i class='bx bx-cart'></i> Orders</a></li>
                <li><a routerLink="/downloads"><i class='bx bx-download'></i> Downloads</a></li>
                <li><a routerLink="/edit-address"><i class='bx bx-home-alt'></i> Addresses</a></li>
                <li><a routerLink="/edit-account"><i class='bx bx-edit'></i> Account Details</a></li>
                <li><a routerLink="/"><i class='bx bx-log-out'></i> Logout</a></li>
            </ul>
        </div>
        <div class="myDashboard-content">
            <div class="shop-area ptb-100">




                <div class="row">

                    <div *ngIf="listCV.length<6" class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box mb-30">
                            <div class="product-image">
                                <a routerLink="/cv1/ajout">
                                    <img src="assets/img/add1.jpg" alt="image">
                                    <img src="assets/img/add1.jpg" alt="image">
                                </a>
                                <a routerLink="/cv1/ajout" class="add-to-cart-btn">Ajouter un CV <i class='bx bx-file'></i></a>
                            </div>

                        </div>
                    </div>




                    <div *ngFor="let cv of listCV" class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box mb-30" style="padding: 0;">

                            <div class="product-image" style="padding: 0;">
                                <a routerLink="/my-dashboard/cv/+{{cv.id}}" style="padding: 0;">
                                    <div class="imgDiv">
                                        <modele1 [cv]="cv"></modele1>
                                    </div>
                                    <!--
                                    <img src="assets/img/shop/shop6.jpg" alt="image">
                                    <img src="assets/img/shop/shop6-1.jpg" alt="image">
                                    -->
                                </a>
                                <a (click)="showCv(cv)" href="javascript:" class="add-to-cart-btn">Modifier CV <i class='bx bx-edit'></i></a>
                            </div>
                            <div class="product-content" style="max-height: 60px;">
                                <a (click)="showCv(cv)" routerLink="/single-products">{{cv.firstname+" "+cv.lastname}}</a>

                            </div>
                        </div>

                    </div>


                </div>

            </div>

        </div>
    </div>
</div>

<!--
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            title
        </mat-expansion-panel-header>
        content
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            title2
        </mat-expansion-panel-header>
        content2
    </mat-expansion-panel>
</mat-accordion>
-->