<section class="features-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                <div class="single-features-box">
                    <div class="inner-content">
                        <h3> Créer un CV à partir de zéro</h3>
                        <p>Créer un CV en quelques minutes à partir de zéro en choisissant un modèle</p>
                        <a routerLink="/modeles" class="default-btn"><i class='bx bx-log-in-circle icon-arrow before'></i><span class="label">Commencer</span><i class="bx bx-log-in-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                <div class="single-features-box">
                    <div class="inner-content">
                        <h3>Personnaliser un CV existant</h3>
                        <p>Créer votre CV en moins de 5 minutes en choisissant un exemple de CV parmi nos exemples classés par métier, puis  personnaliser par rapport son expérience propre.</p>
                        <a routerLink="/cvtheque" class="default-btn"><i class='bx bx-log-in-circle icon-arrow before'></i><span class="label"> Commencer</span><i class="bx bx-log-in-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                <div class="single-features-box">
                    <div class="inner-content">
                        <h3> Créer une lettre de motivation à partir de zéro</h3>
                        <p>Créer une lettre de motivation à partir de zéro en choisissant un modèle</p>
                        <a routerLink="/cover-modeles" class="default-btn"><i class='bx bx-log-in-circle icon-arrow before'></i><span class="label">Commencer</span><i class="bx bx-log-in-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                <div class="single-features-box">
                    <div class="inner-content">
                        <h3>Personnaliser une lettre de motivation existante</h3>
                        <p>Créer votre lettre de motivation en moins de 5 minutes en choisissant un exemple de lettre de motivation parmi nos exemples puis personnaliser un exemple par rapport à son propre métier ou similaire .</p>
                        <a routerLink="/cover-letter/examples" class="default-btn"><i class='bx bx-log-in-circle icon-arrow before'></i><span class="label">Commencer</span><i class="bx bx-log-in-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="slideshow-box">
        <ul class='slideshow-slides'>
            <owl-carousel-o [options]="slideShowSlides">
                <ng-template carouselSlide>
                    <li><span class="bg3"></span></li>
                </ng-template>
                <ng-template carouselSlide>
                    <li><span class="bg2"></span></li>
                </ng-template>
                <ng-template carouselSlide>
                    <li><span class="bg1"></span></li>
                </ng-template>
                <ng-template carouselSlide>
                    <li><span class="bg4"></span></li>
                </ng-template>
                <ng-template carouselSlide>
                    <li><span class="bg5"></span></li>
                </ng-template>
            </owl-carousel-o>
        </ul>
    </div>
</section>
