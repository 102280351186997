

<div id="cvt" class="container-fluid" style="height: 100%; width: 100%;">
    <div class="row">



        <!--   CV TEMPLATE -->

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">



            <form [formGroup]="cvFormGroup" style="z-index : -1">
                <div id="cvt2">
                    <div id="cvt10">

                        <!--  START PAGE 1-->

                        <div id="content1" class="resume_wrapper">
                            <div id="left_side1" class="resume_left" [style.background]="firstcolor">
                                <div class="resume_image">
                                    <div class="resume_item resume_namerole2">
                                        <div style="font-size: 22px; color: #71aac9;; margin : 7px;margin-left: 23px; text-transform: uppercase;" [style.color]="secondcolor">
                                            {{this.cvFormGroup.get("firstname").value}} {{this.cvFormGroup.get("lastname").value}}</div>
                                        <div style="color: #d4cfcf; margin-left: 23px; font-size: 15px;">{{this.cvFormGroup.get("profession").value}}</div>
                                    </div>
                                    <img style="height: 250px;" *ngIf="!imageToShow && imgURL" [src]="imgURL" alt="Resume_image">
                                    <img style="height: 250px;" *ngIf="imageToShow" [src]="imageToShow" alt="Resume_image">
                                </div>
                                <div class="resume_bottom">



                                    <div *ngIf="this.cvFormGroup.get('profile').value.trim()" class="resume_item resume_profile">
                                        <div class="resume_title" [style.color]="pagecolor"> {{'profile' | translate}}</div>
                                        <div class="resume_info" style="text-align: left; font-size: 12px;">
                                            {{this.cvFormGroup.get("profile").value}}
                                        </div>


                                    </div>

                                    <div class="resume_item resume_contact" *ngIf="cvFormGroup.get('telephone').value.trim() || cvFormGroup.get('email').value.trim() ||
                                    cvFormGroup.get('address').value.trim() || cvFormGroup.get('linkedin').value.trim() ||
                                     cvFormGroup.get('birth').value.trim() || cvFormGroup.get('webSite').value.trim() ||
                                     cvFormGroup.get('marital').value.trim() ||
                                      cvFormGroup.get('city').value.trim()">
                                        <div class="resume_title" [style.color]="pagecolor"> {{'details' | translate}}</div>
                                        <div class="resume_info">

                                            <div *ngIf="cvFormGroup.get('telephone').value.trim()" class="resume_subinfo"> <strong style="color: aliceblue;">{{'phone' | translate}} :

                                            </strong>

                                                <span style="font-size:13px ;" *ngIf="this.cvFormGroup.get('telephone').value.includes('U2FsdG')"> {{cvFormGroup.get('telephone').value | decrypted}}</span>
                                                <span style="font-size:13px ;" *ngIf="!this.cvFormGroup.get('telephone').value.includes('U2FsdG')"> {{cvFormGroup.get('telephone').value | encrypted | decrypted}}</span>

                                            </div>
                                            <div *ngIf="cvFormGroup.get('email').value.trim()" class="resume_subinfo"> <strong style="color: aliceblue;">Email :</strong> {{cvFormGroup.get('email').value}}</div>
                                            <div *ngIf="cvFormGroup.get('webSite').value.trim()" class="resume_subinfo"> <strong style="color: aliceblue;"> {{'website' | translate}} :</strong> {{cvFormGroup.get('webSite').value}} </div>
                                            <div *ngIf="cvFormGroup.get('linkedin').value.trim()" class="resume_subinfo"> <strong style="color: aliceblue;">Linkedin :</strong> {{cvFormGroup.get('linkedin').value}} </div>
                                            <div *ngIf="cvFormGroup.get('address').value.trim() || cvFormGroup.get('city').value.trim()" class="resume_subinfo"> <strong style="color: aliceblue;">{{'address' | translate}} :</strong>
                                                <span style="font-size:12px ;" *ngIf="this.cvFormGroup.get('address').value.includes('U2FsdG')"> {{cvFormGroup.get('address').value | decrypted}}</span>
                                                <span style="font-size:12px ;" *ngIf="!this.cvFormGroup.get('address').value.includes('U2FsdG')"> {{cvFormGroup.get('address').value | encrypted | decrypted}}</span>
                                                <span *ngIf="cvFormGroup.get('city').value.trim()" style="font-size: 12px; color: #cac6c6;">{{" ,"+cvFormGroup.get('city').value}}
                                                </span>
                                            </div>
                                            <div *ngIf="cvFormGroup.get('birth').value.trim()" class="resume_subinfo"> <strong style="color: aliceblue;"> {{'birth' | translate}}:</strong>{{cvFormGroup.get('birth').value}}</div>
                                            <div *ngIf="cvFormGroup.get('marital').value.trim()" class="resume_subinfo"> <strong style="color: aliceblue;"></strong>{{cvFormGroup.get('marital').value}}</div>
                                        </div>

                                    </div>

                                    <div *ngIf="cvFormGroup.get('skills').value.length" formArrayName="skills" class="resume_item resume_skills">
                                        <div class="resume_title" [style.color]="pagecolor"> {{'skills' | translate}}</div>
                                        <div class="resume_info">
                                            <div *ngFor="let skill of cvSkills().controls; let skillIndex=index" [formGroupName]="skillIndex" class="skills_list">
                                                <div class="skills_left">{{cvSkills().at(skillIndex).get('item').value}}</div>
                                                <div class="skills_bar">
                                                    <p>
                                                        <span [style.width]="cvSkills().at(skillIndex).get('level').value" [style.background]="secondcolor"></span>
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('languages').value.length" formArrayName="languages" class="resume_item resume_skills">
                                        <div class="resume_title" [style.color]="pagecolor"> {{'languages' | translate}}</div>
                                        <div class="resume_info">
                                            <div *ngFor="let lang of cvLanguages().controls; let langIndex=index" [formGroupName]="langIndex" class="skills_list">
                                                <div class="skills_left">{{cvLanguages().at(langIndex).get('item').value}}</div>
                                                <div class="skills_bar">
                                                    <p>
                                                        <span [style.width]="cvLanguages().at(langIndex).get('level').value" [style.background]="secondcolor"></span>
                                                    </p>
                                                </div>
                                            </div>


                                        </div>
                                    </div>


                                    <div *ngIf="cvFormGroup.get('references').value.length" formArrayName="references" class="resume_item resume_contact">
                                        <div class="resume_title" [style.color]="pagecolor"> {{'references' | translate}}</div>
                                        <div *ngFor="let ref of cvReferences().controls; let refIndex=index" [formGroupName]="refIndex" class="resume_info">
                                            <div class="resume_subtitle">{{cvReferences().at(refIndex).get('fullname').value}}</div>
                                            <div class="resume_subinfo">{{cvReferences().at(refIndex).get('organisation').value}}</div>
                                            <div *ngIf="cvReferences().at(refIndex).get('telephone').value.trim()" class="resume_subinfo">
                                                <strong style="color: aliceblue;">Tel :</strong> {{cvReferences().at(refIndex).get('telephone').value}}</div>
                                            <div *ngIf="cvReferences().at(refIndex).get('email').value.trim()" class="resume_subinfo"> <strong style="color: aliceblue;">Email :</strong> {{cvReferences().at(refIndex).get('email').value}}</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="resume_right" id="right_side1">

                                <!-- EDUCATION BEGINNER -->
                                <div *ngIf="cvFormGroup.get('educations').value.length && isBeginner" formArrayName="educations" class="resume_item resume_education">
                                    <div class="resume_title">Education</div>
                                    <div class="resume_info">
                                        <div *ngFor="let education of cvEducations().controls; let educationIndex=index" [formGroupName]="educationIndex" class="resume_data">
                                            <div class="year">{{cvEducations().at(educationIndex).get('period').value}}
                                                <p style="font-size: 12px;">{{cvEducations().at(educationIndex).get('city').value}}</p>
                                            </div>
                                            <div class="content">
                                                <p style="font-size: 16px; font-weight: 600;"  [style.color]="firstcolor">{{cvEducations().at(educationIndex).get('degree').value}}
                                                </p>
                                                <p style="font-size: 14px; font-weight: 700; text-transform: uppercase;">{{cvEducations().at(educationIndex).get('institute').value}} </p>

                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <!-- EDUCATION PROFESSIONAL -->
                                <div *ngIf="cvFormGroup.get('experiences').value.length && isProfessional" formArrayName="experiences" class="resume_item resume_experience">
                                    <div class="resume_title"> {{'experiences' | translate}}</div>
                                    <div class="resume_info">
                                        <div *ngFor="let experience of cvExperiences().controls; let experienceIndex=index" [formGroupName]="experienceIndex"
                                         class="resume_data">
                                            <div class="year">{{cvExperiences().at(experienceIndex).get('period').value}}
                                                <p style="font-size: 12px;">{{cvExperiences().at(experienceIndex).get('city').value}}</p>
                                            </div>
                                            <div class="content">
                                                <p style="font-size: 14px; font-weight: 600;"  [style.color]="firstcolor">  {{cvExperiences().at(experienceIndex).get('function').value}} </p>
                                                <p style="font-size: 14px; font-weight: 700;text-transform: uppercase;">

                                                    {{cvExperiences().at(experienceIndex).get('company').value}}
                                                   </p>
                                                <p style="font-size: 12px; word-wrap: break-word;" [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div *ngIf="cvFormGroup.get('certifications').value.length" formArrayName="certifications" class="resume_item resume_education">
                                    <div class="resume_title"> {{'certificates' | translate}}</div>
                                    <div class="resume_info">
                                        <div *ngFor="let certif of cvCertifications().controls; let certifIndex=index" [formGroupName]="certifIndex" class="resume_data">
                                            <div class="year">{{cvCertifications().at(certifIndex).get('date').value}}
                                                <p></p>
                                            </div>
                                            <div class="content">
                                                <p style="font-size: 14px; font-weight: 600;"  [style.color]="firstcolor">{{cvCertifications().at(certifIndex).get('certificat').value}} </p>
                                                <p style="font-size: 14px; font-weight: 700; text-transform: uppercase;">{{cvCertifications().at(certifIndex).get('institute').value}}</p>

                                            </div>
                                        </div>


                                    </div>
                                </div>


                                <div *ngIf="cvFormGroup.get('formations').value.length" formArrayName="formations" class="resume_item resume_experience">
                                    <div class="resume_title">Formations</div>
                                    <div class="resume_info">
                                        <div *ngFor="let formation of cvFormations().controls; let formationIndex=index" [formGroupName]="formationIndex" class="resume_data">
                                            <div class="year">{{cvFormations().at(formationIndex).get('period').value}}
                                                <p></p>
                                            </div>
                                            <div class="content">
                                                <p style="font-size: 14px; font-weight: 600;"  [style.color]="firstcolor">{{cvFormations().at(formationIndex).get('title').value}} </p>
                                                <p style="font-size: 14px; font-weight: 700; text-transform: uppercase;">{{cvFormations().at(formationIndex).get('former').value}}</p>
                                                <p style="font-size: 12px; word-wrap: break-word;" [innerHTML]="cvFormations().at(formationIndex).get('description').value"></p>
                                            </div>
                                        </div>



                                    </div>
                                </div>

                                <!-- EDUCATION PROFESSIONAL -->
                                <div *ngIf="cvFormGroup.get('educations').value.length && isProfessional" formArrayName="educations" class="resume_item resume_education">
                                    <div class="resume_title">Education</div>
                                    <div class="resume_info">
                                        <div *ngFor="let education of cvEducations().controls; let educationIndex=index" [formGroupName]="educationIndex" class="resume_data">
                                            <div class="year">{{cvEducations().at(educationIndex).get('period').value}}
                                                <p style="font-size: 12px;">{{cvEducations().at(educationIndex).get('city').value}}</p>
                                            </div>
                                            <div class="content">
                                                <p style="font-size: 16px; font-weight: 600;"  [style.color]="firstcolor">{{cvEducations().at(educationIndex).get('degree').value}}
                                                </p>
                                                <p style="font-size: 14px; font-weight: 700; text-transform: uppercase;">{{cvEducations().at(educationIndex).get('institute').value}} </p>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- EXPERIENCE BEGINNER -->
                                <div *ngIf="cvFormGroup.get('experiences').value.length && isBeginner" formArrayName="experiences" class="resume_item resume_experience">
                                    <div class="resume_title"> {{'experiences' | translate}}</div>
                                    <div class="resume_info">
                                        <div *ngFor="let experience of cvExperiences().controls; let experienceIndex=index" [formGroupName]="experienceIndex"
                                         class="resume_data">
                                            <div class="year">{{cvExperiences().at(experienceIndex).get('period').value}}
                                                <p style="font-size: 12px;">{{cvExperiences().at(experienceIndex).get('city').value}}</p>
                                            </div>
                                            <div class="content">
                                                <p style="font-size: 14px; font-weight: 600;"  [style.color]="firstcolor">  {{cvExperiences().at(experienceIndex).get('function').value}} </p>
                                                <p style="font-size: 14px; font-weight: 700;text-transform: uppercase;">

                                                    {{cvExperiences().at(experienceIndex).get('company').value}}
                                                   </p>
                                                <p style="font-size: 12px; word-wrap: break-word;" [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>



                                <div *ngIf="cvFormGroup.get('customs').value.length" formArrayName="customs">
                                    <div *ngFor="let custom of cvCustoms().controls; let customIndex=index" [formGroupName]="customIndex" class="resume_item resume_education">
                                        <div class="resume_title">{{cvCustoms().at(customIndex).get('title').value}}</div>
                                        <div class="resume_info">
                                            <div class="resume_data">

                                                <div class="content">

                                                    <p style="font-size: 12px; word-wrap: break-word;" [innerHTML]="cvCustoms().at(customIndex).get('description').value">
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>



                                <div *ngIf="cvFormGroup.get('quality1').value.trim() || cvFormGroup.get('quality2').value.trim() ||
                                cvFormGroup.get('quality3').value.trim() || cvFormGroup.get('quality4').value.trim()" class="resume_item resmue_interests">
                                    <div class="resume_title"> {{'qualities' | translate}}</div>
                                    <div class="resume_info">
                                        <div class="interests">

                                            <div class="int_data"> {{cvFormGroup.get('quality1').value}}</div>
                                        </div>
                                        <div class="interests">

                                            <div class="int_data"> {{cvFormGroup.get('quality2').value}}</div>
                                        </div>
                                        <div class="interests">

                                            <div class="int_data"> {{cvFormGroup.get('quality3').value}}</div>
                                        </div>
                                        <div class="interests">

                                            <div class="int_data"> {{cvFormGroup.get('quality4').value}}</div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="cvFormGroup.get('hobby1').value.trim() || cvFormGroup.get('hobby2').value.trim() ||
                                cvFormGroup.get('hobby3').value.trim() || cvFormGroup.get('hobby4').value.trim()" class="resume_item resmue_interests">
                                    <div class="resume_title"> {{'hobbies' | translate}}</div>
                                    <div class="resume_info">
                                        <div class="interests">

                                            <div class="int_data"> {{cvFormGroup.get('hobby1').value}}</div>
                                        </div>
                                        <div class="interests">

                                            <div class="int_data"> {{cvFormGroup.get('hobby2').value}}</div>
                                        </div>
                                        <div class="interests">

                                            <div class="int_data"> {{cvFormGroup.get('hobby3').value}}</div>
                                        </div>
                                        <div class="interests">

                                            <div class="int_data"> {{cvFormGroup.get('hobby4').value}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>





                    </div>

                </div>
            </form>
        </div>

    </div>
</div>

<!--   FORM ARRAY         -->
