<loading *ngIf="isLoading && cv">{{ isLoading }}</loading>


<div id="cvt" class="container-fluid" style="height: 100%; width: 100%;" *ngIf="this.cv">
    <div class="row">


        <div id="cvt1" class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="height: 100%;  ">


            <cv-notice2> </cv-notice2>

        </div>

        <!--   CV TEMPLATE -->

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

            <div class="container">
                <div class="user-actions"
                    style="background: #47494b; padding-top : 7px; padding-bottom: 7px; height : 50px">


                    <div id="content_2" class="row">


                        <div style="visibility: hidden; max-height: 3px;" class="col-lg-1 col-md-1 col-sm-1 icon1">
                            <div #iconselect2 id="my-icon-select2" style="margin: 0;"></div>

                            <input #selectedText2 type="text" id="selected-text2" name="selected-text2"
                                style="width:65px; height:2px; visibility: hidden;">
                        </div>


                        <div style="visibility: hidden; max-height: 3px;" class="col-lg-1 col-md-1 col-sm-1 icon1">
                            <div #iconselect (click)="onChangeTheme()" id="my-icon-select" style="margin: 0;"></div>
                            <label style="margin-left:3px ; color: #fff; font-weight: 400;">
                                {{'themes' |
                                translate}}
                            </label>
                            <input #selectedText type="text" id="selected-text" name="selected-text"
                                style="width:65px; height: 3px; visibility: hidden;">
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2"></div>


                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <button *ngIf="isOpen" type="button" class=" form-control btn btn-sm btn-secondary"
                                (click)="useExample(cv)"
                                style="padding: 7px; font-size: 14px; color: #fff; background: #003147; height: 33px;">
                                <i style="color: #fff; font-size: 15px;" class="bx bxs-duplicate"></i>
                                {{'usethiscv' | translate}}
                            </button>
                            <button *ngIf="!isOpen" type="button" class=" form-control btn "
                                style="padding: 7px; font-size: 12px;  height: 30px;width: 30px;">
                                <span role="status" style="color: #003147;"
                                    class="spinner-border spinner-danger spinner-border-sm"></span>
                            </button>
                        </div>

                        <div class="col-lg-2 col-md-2 col-sm-2"></div>

                        <div class="col-lg-2 col-md-2 col-sm-2"></div>


                    </div>


                </div>


            </div>

            <form [formGroup]="cvFormGroup" style="z-index : -1">
                <div id="cvt2" class="div_body" [ngClass]="family1">
                    <div class="marge cvt20">

                        <!--  START PAGE 1-->

                        <div class="container2" id="content1" #content1>

                            <div class="left_side" id="left_side1" #left_side1 [style.background]="firstcolor"
                                [style.opacity]="1">

                                <div class="profileText" style=" margin : -21px; margin-right: -21px; padding-top: 21px"
                                    [style.background]="secondcolor">
                                    <div *ngIf="!imageToShow && imgURL" class="imgBx">

                                        <img class="" [src]="imgURL" alt="" />

                                    </div>
                                    <div *ngIf="imageToShow" class="imgBx">
                                        <img *ngIf="imageToShow" class="img_class" [src]="imageToShow">
                                    </div>
                                    <h3 style="color: #000;">{{this.cvFormGroup.get("firstname").value+"
                                        "+this.cvFormGroup.get("lastname").value}}
                                        <br /><span> {{this.cvFormGroup.get("profession").value}} </span>
                                    </h3>


                                </div>

                                <div *ngIf="cvFormGroup.get('profile').value.trim()" class="contactInfo education">
                                    <h5 class="title"><img *ngIf="!isIcon" src="assets/img/personal1.png"
                                            style="margin-right: 7px;" width="25" height="25">{{'profile' | translate}}
                                    </h5>

                                    <p style="font-size: 12px; word-wrap: break-word;">

                                        {{this.cvFormGroup.get("profile").value}}
                                    </p>
                                </div>

                                <div *ngIf="cvFormGroup.get('telephone').value.trim() || cvFormGroup.get('email').value.trim() ||
                                cvFormGroup.get('address').value.trim() || cvFormGroup.get('linkedin').value.trim() ||
                                 cvFormGroup.get('birth').value.trim() || cvFormGroup.get('webSite').value.trim() ||
                                 cvFormGroup.get('marital').value.trim() ||
                                  cvFormGroup.get('city').value.trim() "
                                    class="contactInfo">

                                    <h5 class="title"><img *ngIf="!isIcon" src="assets/img/coord.png"
                                            style="margin-right: 7px;" width="25" height="25"> {{'details' | translate}}
                                    </h5>
                                    <ul>
                                        <li *ngIf="cvFormGroup.get('telephone').value" class="liTitle">
                                            <a href="" style="color: #fff;">
                                                <img src="assets/img/phone7.png" width="17" height="17">
                                            </a>

                                            <span *ngIf="this.cvFormGroup.get('telephone').value.includes('U2FsdG')"
                                                class="liText"> <span>{{'phone' | translate}} : </span><br />
                                                {{cvFormGroup.get('telephone').value | decrypted}}
                                            </span>
                                            <span *ngIf="!this.cvFormGroup.get('telephone').value.includes('U2FsdG')"
                                                class="liText"> <span>{{'phone' | translate}} : </span><br />
                                                {{cvFormGroup.get('telephone').value | encrypted | decrypted}}
                                            </span>
                                        </li>
                                        <li *ngIf="cvFormGroup.get('email').value" class="liTitle">
                                            <img id="ic1" src="assets/img/env.png" width="17" height="17">
                                            <span class="liText"><span>Email:
                                                </span><br />{{cvFormGroup.get('email').value}}</span>
                                        </li>
                                        <li id="ic3" *ngIf="cvFormGroup.get('webSite').value.trim()" class="liTitle">
                                            <img src="assets/img/globe.png" width="15" height="15">
                                            <span class="liText"> <span>S{{'website' | translate}}:
                                                </span><br />{{cvFormGroup.get('webSite').value}}</span>

                                        </li>

                                        <li id="ic4" *ngIf="cvFormGroup.get('linkedin').value.trim()" class="liTitle">
                                            <img src="assets/img/linkedin3.png" width="16" height="16">
                                            <span class="liText"><span>Linkedin:
                                                </span><br />{{cvFormGroup.get('linkedin').value}}</span>
                                        </li>

                                        <li *ngIf="cvFormGroup.get('address').value.trim() || cvFormGroup.get('city').value.trim()" class="liTitle">
                                            <img id="ic2" src="assets/img/map.png" width="17" height="17">
                                            <span *ngIf="this.cvFormGroup.get('telephone').value.includes('U2FsdG')"
                                                class="liText">
                                                {{cvFormGroup.get('address').value | decrypted }}
                                                <br>
                                                <span class>
                                                    {{cvFormGroup.get('city').value}}
                                                </span>
                                            </span>
                                            <span *ngIf="!this.cvFormGroup.get('telephone').value.includes('U2FsdG')"
                                                class="liText">
                                                {{cvFormGroup.get('address').value | encrypted | decrypted }}

                                                <br>
                                                <span class>
                                                    {{cvFormGroup.get('city').value}}
                                                </span>
                                            </span>


                                        </li>

                                        <li *ngIf="cvFormGroup.get('birth').value.trim()" class="liTitle">
                                            <a href="" style="color: #fff;">
                                                <img src="assets/img/birth.png" width="17" height="17">
                                            </a>

                                            <span class="liText"> <span>Né(e) le : </span><br /><span
                                                    [innerText]="cvFormGroup.get('birth').value"></span></span>
                                        </li>

                                        <li *ngIf="cvFormGroup.get('marital').value.trim()!==''" class="liTitle">
                                            <a href="" style="color: #fff;">
                                                <img src="assets/img/marital.png" width="17" height="17">
                                            </a>

                                            <span class="liText"> <span>{{'marital' | translate}} : </span><br />
                                                {{cvFormGroup.get('marital').value}}
                                            </span>
                                        </li>

                                        <li>
                                            <span class="icon"><i class=""></i></span>
                                            <span class="text"></span>
                                        </li>

                                    </ul>
                                </div>



                                <div *ngIf="cvFormGroup.get('skills').value.length" formArrayName="skills"
                                    class="contactInfo skills1" style="margin-top: -15px;">
                                    <h5 class="title"><img *ngIf="!isIcon" src="assets/img/skills3.png"
                                            style="margin-right: 7px;" width="25" height="25">{{'skills' | translate}}
                                    </h5>
                                    <div>
                                        <div>
                                            <ul>
                                                <li *ngFor="let skill of cvSkills().controls; let skillIndex=index"
                                                    [formGroupName]="skillIndex" style="margin-bottom: 7px;">
                                                    <span
                                                        class="text">{{cvSkills().at(skillIndex).get('item').value}}</span>
                                                    <span class="percent">
                                                        <div [style.width]="cvSkills().at(skillIndex).get('level').value"
                                                            [style.background]="secondcolor"></div>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>




                            </div>
                            <div class="right_side" id="right_side1" #right_side1 style="padding-bottom: 12px;"
                                [style.background]="pagecolor">



                                <!-- EDUCATION BEGINNER -->


                                <div *ngIf="cvFormGroup.get('educations').value.length && isBeginner" formArrayName="educations"
                                    class="about">
                                    <h2 class="title2">
                                        <img *ngIf="!isIcon" src="assets/img/education1.png" width="33" height="33">
                                        Education
                                    </h2>
                                    <div>
                                        <div>


                                            <div *ngFor="let education of cvEducations().controls; let educationIndex=index"
                                                class="box" [formGroupName]="educationIndex">
                                                <div class="year_company">
                                                    <h5>
                                                        {{cvEducations().at(educationIndex).get('period').value}}
                                                    </h5>


                                                </div>
                                                <div class="text">
                                                    <h4 [style.color]="firstcolor">
                                                        <strong>
                                                            {{cvEducations().at(educationIndex).get('degree').value}}
                                                        </strong>
                                                    </h4>


                                                    <p style="font-size: 14px; word-wrap: break-word;">
                                                        <strong>
                                                            {{cvEducations().at(educationIndex).get('institute').value}}
                                                        </strong>
                                                        <span
                                                            *ngIf="cvEducations().at(educationIndex).get('city').value.trim()">
                                                            {{" ,
                                                            "+cvEducations().at(educationIndex).get('city').value}}

                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <!-- EXPERIENCE PROFESSIONAL -->


                                <div *ngIf="cvFormGroup.get('experiences').value.length && isProfessional" formArrayName="experiences"
                                    class="about">


                                    <h2 class="title2">
                                        <img *ngIf="!isIcon" [src]="imageToShow2" width="33" height="33">
                                        {{'experiences' | translate}}
                                    </h2>
                                    <div>



                                        <div class="box" style="margin-bottom: -12px;"
                                            *ngFor="let experience of cvExperiences().controls; let experienceIndex=index"
                                            [formGroupName]="experienceIndex">
                                            <div class="year_company">
                                                <h5>
                                                    {{cvExperiences().at(experienceIndex).get('period').value}}
                                                </h5>
                                                <h5 class="company">
                                                    <strong>
                                                        {{cvExperiences().at(experienceIndex).get('company').value}}
                                                    </strong>
                                                </h5>
                                                <span>
                                                    {{cvExperiences().at(experienceIndex).get('city').value}}
                                                </span>


                                            </div>
                                            <div class="text">
                                                <h4 [style.color]="firstcolor">
                                                    <strong>{{cvExperiences().at(experienceIndex).get('function').value}}</strong>
                                                </h4>


                                                <p style="font-size: 12px; word-wrap: break-word; "
                                                    [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">


                                                </p>
                                            </div>
                                        </div>


                                    </div>

                                </div>


                                <!-- CERTIFICATION -->

                                <div *ngIf="cvFormGroup.get('certifications').value.length"
                                    formArrayName="certifications" class="about">
                                    <h2 class="title2"><img *ngIf="!isIcon" src="assets/img/certif.png" width="33"
                                            height="33"> {{'certificates' | translate}}</h2>
                                    <div>


                                        <div *ngFor="let certif of cvCertifications().controls; let certifIndex=index"
                                            class="box" [formGroupName]="certifIndex">
                                            <div class="year_company">
                                                <h5> {{cvCertifications().at(certifIndex).get('date').value}} </h5>
                                                <h5 class="company">
                                                    <strong>
                                                        {{cvCertifications().at(certifIndex).get('institute').value}}
                                                    </strong>
                                                </h5>
                                            </div>
                                            <div class="text">
                                                <h4 [style.color]="firstcolor">
                                                    <strong>
                                                    {{cvCertifications().at(certifIndex).get('certificat').value}}
                                                </strong>
                                                </h4>


                                            </div>

                                        </div>
                                    </div>

                                </div>


                                <!-- FORMATION -->

                                <div *ngIf="cvFormGroup.get('formations').value.length" formArrayName="formations"
                                    class="about">
                                    <h2 class="title2"><img *ngIf="!isIcon" src="assets/img/formation.png" width="31"
                                            height="31"> Formation </h2>
                                    <div>
                                        <div>

                                            <div *ngFor="let formation of cvFormations().controls; let formationIndex=index"
                                                class="box" [formGroupName]="formationIndex">
                                                <div class="year_company">
                                                    <h5> {{cvFormations().at(formationIndex).get('period').value}} </h5>
                                                    <h5 class="company">
                                                        <strong>
                                                            {{cvFormations().at(formationIndex).get('former').value}}
                                                        </strong>
                                                    </h5>
                                                </div>
                                                <div class="text">
                                                    <h4 [style.color]="firstcolor">
                                                        <strong>
                                                        {{cvFormations().at(formationIndex).get('title').value}}
                                                    </strong>
                                                    </h4>


                                                    <p style="font-size: 12px; word-wrap: break-word;"
                                                        [innerHTML]="cvFormations().at(formationIndex).get('description').value">

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <!-- EDUCATION PROFESSIONAL -->


                                <div *ngIf="cvFormGroup.get('educations').value.length && isProfessional" formArrayName="educations"
                                    class="about">
                                    <h2 class="title2">
                                        <img *ngIf="!isIcon" src="assets/img/education1.png" width="33" height="33">
                                        Education
                                    </h2>
                                    <div>
                                        <div>


                                            <div *ngFor="let education of cvEducations().controls; let educationIndex=index"
                                                class="box" [formGroupName]="educationIndex">
                                                <div class="year_company">
                                                    <h5>
                                                        {{cvEducations().at(educationIndex).get('period').value}}
                                                    </h5>


                                                </div>
                                                <div class="text">
                                                    <h4 [style.color]="firstcolor">
                                                        <strong>
                                                            {{cvEducations().at(educationIndex).get('degree').value}}
                                                        </strong>
                                                    </h4>


                                                    <p style="font-size: 14px; word-wrap: break-word;">
                                                        <strong>
                                                            {{cvEducations().at(educationIndex).get('institute').value}}
                                                        </strong>
                                                        <span
                                                            *ngIf="cvEducations().at(educationIndex).get('city').value.trim()">
                                                            {{" ,
                                                            "+cvEducations().at(educationIndex).get('city').value}}

                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <!-- EXPERIENCE BEGINNER -->


                                <div *ngIf="cvFormGroup.get('experiences').value.length && isBeginner" formArrayName="experiences"
                                    class="about">


                                    <h2 class="title2">
                                        <img *ngIf="!isIcon" [src]="imageToShow2" width="33" height="33">
                                        {{'experiences' | translate}}
                                    </h2>
                                    <div>



                                        <div class="box" style="margin-bottom: -12px;"
                                            *ngFor="let experience of cvExperiences().controls; let experienceIndex=index"
                                            [formGroupName]="experienceIndex">
                                            <div class="year_company">
                                                <h5>
                                                    {{cvExperiences().at(experienceIndex).get('period').value}}
                                                </h5>
                                                <h5 class="company">
                                                    <strong>
                                                        {{cvExperiences().at(experienceIndex).get('company').value}}
                                                    </strong>
                                                </h5>
                                                <span>
                                                    {{cvExperiences().at(experienceIndex).get('city').value}}
                                                </span>


                                            </div>
                                            <div class="text">
                                                <h4 [style.color]="firstcolor">
                                                    <strong>{{cvExperiences().at(experienceIndex).get('function').value}}</strong>
                                                </h4>


                                                <p style="font-size: 12px; word-wrap: break-word; "
                                                    [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">


                                                </p>
                                            </div>
                                        </div>


                                    </div>

                                </div>



                                <!-- LANGUES -->

                                <div *ngIf="cvFormGroup.get('languages').value.length" formArrayName="languages"
                                    class="about language">
                                    <h2 class="title2"> <img *ngIf="!isIcon" src="assets/img/language1.png"
                                            style="margin-right: 7px;" width="24" height="24">{{'languages' |
                                        translate}}</h2>
                                    <div>


                                        <div *ngFor="let lang of cvLanguages().controls; let langIndex=index"
                                            class="box" [formGroupName]="langIndex">
                                            <h4>{{cvLanguages().at(langIndex).get('item').value}}</h4>
                                            <div class="percent">
                                                <div [style.width]="cvLanguages().at(langIndex).get('level').value"
                                                    [style.background]="secondcolor"></div>
                                            </div>

                                        </div>


                                    </div>

                                </div>

                                <!--

                                <div class="about language" formArrayName="skills">
                                    <h2 class="title2">Professional skills</h2>
                                    <div *ngFor="let skill of cvSkills().controls; let skillIndex=index">

                                        <div [formGroupName]="skillIndex">


                                            <div class="box">
                                                <h4 formControlName="skill">{{cvSkills().at(skillIndex).get('skill').value}} </h4>
                                                <div class="percent">
                                                    <div [style.width]="cvSkills().at(skillIndex).get('level').value"></div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                             -->
                                <!-- RUBRIQUE PERSONNALISEE -->
                                <div *ngIf="cvFormGroup.get('customs').value.length" formArrayName="customs">
                                    <div *ngFor="let custom of cvCustoms().controls; let customIndex=index"
                                        [formGroupName]="customIndex" class="about interest">
                                        <h2 class="title2"> <img *ngIf="!isIcon" src="assets/img/custom.png"
                                                style="margin-right: 7px;" width="27"
                                                height="27">{{cvCustoms().at(customIndex).get('title').value}}</h2>
                                        <p style="font-size: 12px; word-wrap: break-word;"
                                            [innerHTML]="cvCustoms().at(customIndex).get('description').value">

                                        </p>
                                    </div>
                                    <p></p>
                                </div>

                                <!-- QUALITY -->


                                <div *ngIf="cvFormGroup.get('quality1').value.trim() || cvFormGroup.get('quality2').value.trim()
                                 ||
                                cvFormGroup.get('quality3').value.trim() || cvFormGroup.get('quality4').value.trim()"
                                   class="about interest">
                                   <h2 class="title2">
                                       <img *ngIf="!isIcon" src="assets/img/quality2.png" style="margin-right: 7px;"
                                           width="27" height="27">
                                           {{'qualities' | translate}}
                                   </h2>
                                   <ul>
                                       <li>{{cvFormGroup.get('quality1').value}}</li>
                                       <li>{{cvFormGroup.get('quality2').value}}</li>
                                       <li>{{cvFormGroup.get('quality3').value}}</li>
                                       <li>{{cvFormGroup.get('quality4').value}}</li>
                                   </ul>
                               </div>


                                <!-- CENTRES D'INTERET -->
                                <div *ngIf="cvFormGroup.get('hobby1').value.trim() || cvFormGroup.get('hobby2').value.trim() ||
                                 cvFormGroup.get('hobby3').value.trim() || cvFormGroup.get('hobby4').value.trim()"
                                    class="about interest">
                                    <h2 class="title2"> <img *ngIf="!isIcon" src="assets/img/interest2.png"
                                            style="margin-right: 7px;" width="27" height="27">{{'hobbies' | translate}}
                                    </h2>
                                    <ul>
                                        <li> {{cvFormGroup.get('hobby1').value}}</li>
                                        <li> {{cvFormGroup.get('hobby2').value}}</li>
                                        <li>{{cvFormGroup.get('hobby3').value}}</li>
                                        <li> {{cvFormGroup.get('hobby4').value}}</li>
                                    </ul>
                                </div>


                                <!-- REFERENCES -->

                                <div *ngIf="cvFormGroup.get('references').value.length" formArrayName="references"
                                    class="about">
                                    <h2 class="title2"><img *ngIf="!isIcon" src="assets/img/ref3.png" width="30"
                                            height="30"> {{'references' | translate}}</h2>
                                    <div>
                                        <div>

                                            <div *ngFor="let certif of cvReferences().controls; let refIndex=index"
                                                [formGroupName]="refIndex" class="box">
                                                <div class="refname">
                                                    <h5> {{cvReferences().at(refIndex).get('fullname').value}} </h5>
                                                    <h5 class="company">
                                                        <strong>
                                                            {{cvReferences().at(refIndex).get('organisation').value}}
                                                        </strong>
                                                    </h5>
                                                </div>
                                                <div class="text2">
                                                    <h4>{{cvReferences().at(refIndex).get('telephone').value}}
                                                    </h4>
                                                    <h4>{{cvReferences().at(refIndex).get('email').value}}
                                                    </h4>


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <!--  END PAGE 1-->

                        <!--  START PAGE 2-->

                        <div data-ng-controll class="container2" id="content2" #content2 style="margin-top: 2px;">
                            <div class="left_side" id="left_side2" #left_side2 style="padding : 0; margin-top: 0px "
                                [style.background]="firstcolor">





                            </div>
                            <div class="right_side" id="right_side2" #right_side2 style="padding : 0; margin-top: 0px "
                                [style.background]="pagecolor">





                            </div>
                        </div>


                        <!--  END PAGE 2-->

                        <!--  START PAGE 3-->

                        <div data-ng-controll class="container2" id="content3" #content3 style="margin-top: 0px;">
                            <div class="left_side" id="left_side3" #left_side3 style="padding : 0; margin-top: 0px "
                                [style.background]="firstcolor">



                            </div>
                            <div class="right_side" id="right_side3" #right_side3 style="padding : 0; margin-top: 0px "
                                [style.background]="pagecolor">

                            </div>
                        </div>


                        <!--  END PAGE 3-->



                    </div>

                </div>
            </form>
        </div>

    </div>
</div>

<!--   FORM ARRAY         -->
