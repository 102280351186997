import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../pages/cv-login-page/auth-signin.service';



@Component({
  selector: 'cv-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class CvFeedbackFormComponent implements OnInit {

  contactFormGroup: FormGroup;

  constructor(private fb :FormBuilder,
    private authService : AuthService,
    private router: Router) { }

  ngOnInit(): void {
    this.initContactForm();
  }

  initContactForm() : void{
    this.contactFormGroup  =  this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        message: ['', Validators.required],
        subject: ['', Validators.required],
        name: ['', Validators.required],
        localisation: ['', Validators.required],

    });
  }

  get formControls() {
    return this.contactFormGroup.controls;
 }


 message : string="";
 spin1 : boolean=false;
 isSuccess: boolean=false;

   sendmessage(): void {
    this.spin1=true;
     this.authService.sendmessage(this.contactFormGroup.value)
       .subscribe(
         (data) => {
          this.spin1=true;
         this.isSuccess=false;
         },
         (error) => {

         this.message=error.error.message
         },
         () => {
          this.contactFormGroup.reset();
          this.message='Message envoyé avec succès'
          this.isSuccess=true;
          this.spin1=false;

         }
       );

   }

}
