<loading *ngIf="isLoading && cv">{{ isLoading }}</loading>


<div id="cvt"  class="container-fluid" style="height: 100%; width: 100%;" *ngIf="this.cv">
    <div class="row" >


        <div id="cvt1" class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="height: 100%;  ">


            <cv-notice2> </cv-notice2>

        </div>

        <!--   CV TEMPLATE -->

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

            <div class="container">
                <div class="user-actions" style="background: #47494b; padding-top : 7px; padding-bottom: 7px; height : 50px">


                    <div id="content_2" class="row">


                        <div style="visibility: hidden; max-height: 3px;"  class="col-lg-1 col-md-1 col-sm-1 icon1">
                            <div #iconselect2  id="my-icon-select2" style="margin: 0;"></div>

                            <input #selectedText2 type="text" id="selected-text2" name="selected-text2"
                                style="width:65px; height:2px; visibility: hidden;">
                        </div>


                        <div style="visibility: hidden; max-height: 3px;"  class="col-lg-1 col-md-1 col-sm-1 icon1" >
                            <div #iconselect (click)="onChangeTheme()" id="my-icon-select" style="margin: 0;"></div>
                            <label style="margin-left:3px ; color: #fff; font-weight: 400;">
                                {{'themes' |
                                translate}}
                            </label>
                            <input #selectedText type="text" id="selected-text" name="selected-text"
                                style="width:65px; height: 3px; visibility: hidden;">
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2"></div>


                        <div class="col-lg-4 col-md-4 col-sm-4"  >
                            <button *ngIf="isOpen" type="button" class=" form-control btn btn-sm btn-secondary"
                            (click)="useExample(cv)"
                            style="padding: 7px; font-size: 14px; color: #fff; background: #003147; height: 33px;">
                            <i style="color: #fff; font-size: 15px;" class="bx bxs-duplicate"></i>
                            {{'usethiscv' | translate}}
                        </button>
                        <button *ngIf="!isOpen" type="button" class=" form-control btn "
                        style="padding: 7px; font-size: 12px;  height: 30px;width: 30px;">
                        <span role="status" style="color: #003147;"
                            class="spinner-border spinner-danger spinner-border-sm"></span>
                       </button>
                        </div>

                        <div class="col-lg-2 col-md-2 col-sm-2"></div>

                        <div class="col-lg-2 col-md-2 col-sm-2"></div>


                    </div>


                </div>


            </div>

            <form [formGroup]="cvFormGroup" style="z-index : -1">
                <div id="cvt2" class="div_body"  [ngClass]="family1">
                    <div class="marge cvt20">

                        <!--  START PAGE 1-->




                        <div class="container2" id="content1" #content1>

                            <div class="left_side" id="left_side1" #left_side1 [style.background]="'#ddd6d6'"
                                [style.opacity]="1">


                                <div class="profileText" style=" margin : -21px; margin-right: -21px; padding-top: 21px"
                                    [style.background]="firstcolor">
                                    <div *ngIf="!imageToShow && imgURL" class="imgBx">

                                        <img class="img_class" [src]="imgURL" alt="" />

                                    </div>
                                    <div *ngIf="imageToShow" class="imgBx">
                                        <img *ngIf="imageToShow" class="img_class" [src]="imageToShow">
                                    </div>



                                </div>



                                <div *ngIf="cvFormGroup.get('telephone').value.trim() || cvFormGroup.get('email').value.trim() ||
                                cvFormGroup.get('address').value.trim() || cvFormGroup.get('linkedin').value.trim() ||
                                 cvFormGroup.get('birth').value.trim() || cvFormGroup.get('webSite').value.trim() ||
                                 cvFormGroup.get('marital').value.trim() ||
                                  cvFormGroup.get('city').value.trim()" class="contactInfo" style="margin-top: -27px;" >


                                  <ul>
                                    <li *ngIf="cvFormGroup.get('telephone').value" class="liTitle">
                                        <a href="">
                                            <img src="assets/img/phone8.png" width="17" height="17">
                                        </a>

                                        <span *ngIf="this.cvFormGroup.get('telephone').value.includes('U2FsdG')"
                                            class="liText"> <span style="font-weight: bold;"> {{'phone' |
                                                translate}} : </span><br /> {{cvFormGroup.get('telephone').value |
                                            decrypted}}
                                        </span>
                                        <span *ngIf="!this.cvFormGroup.get('telephone').value.includes('U2FsdG')"
                                            class="liText"> <span>{{'phone' | translate}} : </span><br />
                                            {{cvFormGroup.get('telephone').value | encrypted | decrypted}}
                                        </span>
                                    </li>
                                    <li *ngIf="cvFormGroup.get('email').value" class="liTitle">
                                        <img id="ic1" src="assets/img/env2.png" width="17" height="17">
                                        <span class="liText"><span>Email:
                                            </span><br />{{cvFormGroup.get('email').value}}</span>
                                    </li>
                                    <li id="ic3" *ngIf="cvFormGroup.get('webSite').value.trim()" class="liTitle">
                                        <img src="assets/img/globe2.png" width="15" height="15">
                                        <span class="liText"> <span>S{{'website' | translate}}:
                                            </span><br />{{cvFormGroup.get('webSite').value}}</span>

                                    </li>

                                    <li id="ic4" *ngIf="cvFormGroup.get('linkedin').value.trim()" class="liTitle">
                                        <img src="assets/img/linkedin4.png" width="16" height="16">
                                        <span class="liText"><span>Linkedin:
                                            </span><br />{{cvFormGroup.get('linkedin').value}}</span>
                                    </li>

                                    <li *ngIf="cvFormGroup.get('address').value.trim() || cvFormGroup.get('city').value.trim()" class="liTitle">
                                        <img id="ic2" src="assets/img/map2.png" width="17" height="17">
                                        <span *ngIf="this.cvFormGroup.get('address').value.includes('U2FsdG')"
                                            class="liText">{{cvFormGroup.get('address').value | decrypted}}
                                            <br> <span class="">
                                                {{cvFormGroup.get('city').value}}</span>
                                        </span>
                                        <span *ngIf="!this.cvFormGroup.get('address').value.includes('U2FsdG')"
                                            class="liText">{{cvFormGroup.get('address').value | encrypted |
                                            decrypted}}
                                            <br> <span class="">
                                                {{cvFormGroup.get('city').value}}</span>
                                        </span>

                                    </li>


                                    <li *ngIf="cvFormGroup.get('birth').value.trim()" class="liTitle">
                                        <a href="" style="color: #fff;">
                                            <img src="assets/img/birth2.png" width="17" height="17">
                                        </a>

                                        <span class="liText"> <span>Né(e) le : </span><br /><span
                                                [innerText]="cvFormGroup.get('birth').value"></span></span>
                                    </li>

                                    <li *ngIf="cvFormGroup.get('marital').value.trim()!==''" class="liTitle">
                                        <a href="" style="color: #fff;">
                                            <img src="assets/img/marital2.png" width="17" height="17">
                                        </a>

                                        <span class="liText"> <span>{{'marital' | translate}} : </span><br />
                                            {{cvFormGroup.get('marital').value}}
                                        </span>
                                    </li>

                                    <li>
                                        <span class="icon"><i class=""></i></span>
                                        <span class="text"></span>
                                    </li>

                                </ul>
                                </div>



                                <div *ngIf="cvFormGroup.get('skills').value.length" formArrayName="skills"
                                    class="contactInfo skills1" style="margin-top: -15px;">

                                    <div class="col-12 title11">

                                        <div class="row">
                                            <div class=" col-2 title44" [style.background]="firstcolor">
                                                <img *ngIf="!isIcon" src="assets/img/skills3.png" style="margin-right: 7px;" width="29" height="29">
                                            </div>
                                            <div class="col-10 title33" >{{'skills' | translate}}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <ul>
                                                <li *ngFor="let skill of cvSkills().controls; let skillIndex=index"
                                                    [formGroupName]="skillIndex" style="margin-bottom: 7px;">
                                                    <span
                                                        class="text">{{cvSkills().at(skillIndex).get('item').value}}</span>
                                                    <span class="percent">
                                                        <div [style.width]="cvSkills().at(skillIndex).get('level').value"
                                                            [style.background]="secondcolor"></div>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>




                            <div *ngIf="cvFormGroup.get('languages').value.length" formArrayName="languages"
                            class="contactInfo skills1" style="margin-top: -15px;">

                            <div class="col-12 title11">

                                <div class="row">
                                    <div class=" col-2 title44" [style.background]="firstcolor">
                                        <img *ngIf="!isIcon"  src="assets/img/language2.png" style="margin-right: 7px;" width="25" height="25" style="margin: 0 0 0 2px">
                                    </div>
                                    <div class="col-10 title33" >{{'languages' |
                                        translate}}</div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <ul>
                                        <li *ngFor="let lang of cvLanguages().controls; let langIndex=index"
                                         [formGroupName]="langIndex" style="margin-bottom: 7px;">
                                            <span
                                                class="text">{{cvLanguages().at(langIndex).get('item').value}}</span>
                                            <span class="percent">
                                                <div [style.width]="cvLanguages().at(langIndex).get('level').value"
                                                    [style.background]="secondcolor"></div>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                          </div>


                          <!-- CENTRES D'INTERET -->
                          <div *ngIf="cvFormGroup.get('hobby1').value.trim() || cvFormGroup.get('hobby2').value.trim()
                           || cvFormGroup.get('hobby3').value.trim() || cvFormGroup.get('hobby4').value.trim()"
                          class="contactInfo skills1" style="margin-top: -15px;">

                          <div class="col-12 title11">

                              <div class="row">
                                  <div class=" col-2 title44" [style.background]="firstcolor">
                                      <img *ngIf="!isIcon"  src="assets/img/interest.png" style="margin: 0 0 0 2px" width="25" height="25">
                                  </div>
                                  <div class="col-10 title33" >{{'hobbies' |
                                      translate}}</div>
                              </div>
                          </div>
                          <div>
                              <div style="margin-left: 7px; color: #000;">
                                <ul style="list-style-type : disc;">
                                 <li *ngIf="cvFormGroup.get('hobby1').value.trim()" ><strong style="font-size: 20px; margin-right: 7px;">-</strong> {{cvFormGroup.get('hobby1').value}}</li>
                                 <li *ngIf="cvFormGroup.get('hobby2').value.trim()"><strong style="font-size: 20px; margin-right: 7px;">-</strong>  {{cvFormGroup.get('hobby2').value}}</li>
                                 <li *ngIf="cvFormGroup.get('hobby3').value.trim()"><strong style="font-size: 20px; margin-right: 7px;">-</strong>  {{cvFormGroup.get('hobby3').value}}</li>
                                 <li *ngIf="cvFormGroup.get('hobby4').value.trim()"><strong style="font-size: 20px; margin-right: 7px;">-</strong>  {{cvFormGroup.get('hobby4').value}}</li>
                                </ul>
                              </div>
                          </div>

                      </div>







                            </div>
                            <div class="right_side" id="right_side1" #right_side1 style="padding-bottom: 12px;"
                                [style.background]="pagecolor">

                                <div class="about" style="margin-bottom: 12px;">
                                    <div class="">

                                        <span
                                            style="font-size: 37px; color: #0e2e5e; text-transform: uppercase; margin-top: 0px; font-weight: 600;"
                                            > <span [style.color]="secondcolor"> {{this.cvFormGroup.get("firstname").value}}</span>
                                           <span [style.color]="firstcolor"> {{this.cvFormGroup.get("lastname").value}}</span>
                                        </span>
                                        <p
                                            style="font-size: 15px; margin-top:-12px;  color: #3d4246; text-transform: uppercase;">
                                           <strong> {{this.cvFormGroup.get("profession").value}} </strong></p>
                                    </div>
                                    <div  *ngIf="cvFormGroup.get('profile').value" style="height: 7px;"></div>
                                    <p style="font-size: 12px; word-wrap: break-word; ">

                                        {{this.cvFormGroup.get("profile").value}}
                                    </p>
                                </div>


                                <!-- EDUCATION BEGINNER -->

                                <div *ngIf="cvFormGroup.get('educations').value.length && isBeginner" formArrayName="educations"
                                    class="about">


                                    <div class="col-12 title1">

                                        <div class="row">
                                            <div class=" col-1 title4" [style.background]="firstcolor"> <img *ngIf="!isIcon"
                                                    src="assets/img/education2.png" width="30" height="30"  style="margin: -2px 0 0 0"></div>
                                            <div class="col-11 title3" >Education</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>

                                            <div *ngFor="let education of cvEducations().controls; let educationIndex=index"
                                                class="box" [formGroupName]="educationIndex">

                                                <div class="year_company">
                                                    <h5 class="company" style="color: #000;" > <strong  style="color: #5b5c5c;">
                                                        {{cvEducations().at(educationIndex).get('period').value}}</strong> <strong style="margin-left: 7px;"> | </strong>
                                                        <strong [style.color]="firstcolor" style="margin-left: 7px;">
                                                            {{cvEducations().at(educationIndex).get('degree').value}}
                                                        </strong>
                                                         </h5>



                                                </div>

                                                <div class="text">


                                                    <p style="font-size: 14px; word-wrap: break-word;">
                                                        <strong>
                                                            {{cvEducations().at(educationIndex).get('institute').value}}
                                                        </strong> <span
                                                            *ngIf="cvEducations().at(educationIndex).get('city').value.trim()">
                                                            {{" ,
                                                            "+cvEducations().at(educationIndex).get('city').value}}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <!-- EDUCATION PROFESSIONAL -->
                                <div *ngIf="cvFormGroup.get('experiences').value.length && isProfessional" formArrayName="experiences"
                                    class="about">



                                            <div class="col-12 title1">

                                                <div class="row">
                                                    <div class=" col-1 title4" [style.background]="firstcolor">
                                                        <img *ngIf="!isIcon" [src]="imageToShow2" width="33" height="33"  style="margin: -2px 0 0 0">
                                                    </div>
                                                    <div class="col-11 title3" >{{'experiences' | translate}}</div>
                                                </div>
                                            </div>

                                    <div>


                                        <div class="box"
                                            *ngFor="let experience of cvExperiences().controls; let experienceIndex=index"
                                            [formGroupName]="experienceIndex">


                                            <div class="year_company">
                                                <h5 class="company" style="color: #000;" > <strong  style="color: #5b5c5c;"> {{cvExperiences().at(experienceIndex).get('company').value}}</strong> <strong style="margin-left: 7px;"> | </strong>
                                                    <strong [style.color]="firstcolor" style="margin-left: 7px;">
                                                        {{cvExperiences().at(experienceIndex).get('function').value}}
                                                    </strong>
                                                     </h5>



                                            </div>

                                            <div class="year_company" >
                                                <h5 style="color: #000;">{{cvExperiences().at(experienceIndex).get('period').value}} <strong style="margin-left: 7px;"> | </strong>
                                                    <span style="margin-left: 7px;">
                                                        {{cvExperiences().at(experienceIndex).get('city').value}}
                                                    </span> </h5>


                                            </div>
                                            <div class="text">

                                                <p style="font-size: 12px; word-wrap: break-word;"
                                                    [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">

                                                </p>
                                            </div>
                                        </div>

                                    </div>

                                </div>


                                <!-- CERTIFICATION -->

                                <div *ngIf="cvFormGroup.get('certifications').value.length"
                                    formArrayName="certifications" class="about">

                                            <div class="col-12 title1">

                                                <div class="row">
                                                    <div class=" col-1 title4" [style.background]="firstcolor"> <img *ngIf="!isIcon"
                                                            src="assets/img/certif2.png" width="30" height="30"  style="margin: -2px 0 0 0"></div>
                                                    <div class="col-11 title3" >{{'certificates' | translate}}</div>
                                                </div>
                                            </div>

                                    <div>


                                        <div *ngFor="let certif of cvCertifications().controls; let certifIndex=index"
                                            class="box" [formGroupName]="certifIndex">

                                            <div class="year_company">
                                                <h5 class="company" style="color: #000;" > <strong  style="color: #5b5c5c;">
                                                    {{cvCertifications().at(certifIndex).get('institute').value}}</strong>
                                                     <strong style="margin-left: 7px;"> | </strong>
                                                    <strong [style.color]="firstcolor" style="margin-left: 7px;">
                                                        {{cvCertifications().at(certifIndex).get('certificat').value}}
                                                    </strong>
                                                     </h5>



                                            </div>

                                            <div class="year_company">
                                                <h5> {{cvCertifications().at(certifIndex).get('date').value}} </h5>

                                            </div>


                                        </div>
                                    </div>

                                </div>


                                <!-- FORMATION -->

                                <div *ngIf="cvFormGroup.get('formations').value.length" formArrayName="formations"
                                    class="about">

                                    <div class="col-12 title1">

                                        <div class="row">
                                            <div class=" col-1 title4" [style.background]="firstcolor"> <img *ngIf="!isIcon"
                                                    src="assets/img/formation2.png" width="27" height="27" style="margin: -2px 0 0 2px"></div>
                                            <div class="col-11 title3" >Formation</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>

                                            <div *ngFor="let formation of cvFormations().controls; let formationIndex=index"
                                                class="box" [formGroupName]="formationIndex">
                                                <div class="year_company">
                                                    <h5 class="company" style="color: #000;" > <strong  style="color: #5b5c5c;">
                                                        {{cvFormations().at(formationIndex).get('former').value}}</strong> <strong style="margin-left: 7px;"> | </strong>
                                                        <strong [style.color]="firstcolor" style="margin-left: 7px;">
                                                            {{cvFormations().at(formationIndex).get('title').value}}
                                                        </strong>
                                                         </h5>



                                                </div>

                                                <div class="year_company" >
                                                    <h5 style="color: #000;">
                                                        {{cvFormations().at(formationIndex).get('period').value}} </h5>


                                                </div>
                                                <div class="text">

                                                    <p style="font-size: 12px; word-wrap: break-word;"
                                                        [innerHTML]="cvFormations().at(formationIndex).get('description').value">

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <!-- EDUCATION PROFESSIONAL -->

                                <div *ngIf="cvFormGroup.get('educations').value.length && isProfessional" formArrayName="educations"
                                    class="about">


                                    <div class="col-12 title1">

                                        <div class="row">
                                            <div class=" col-1 title4" [style.background]="firstcolor"> <img *ngIf="!isIcon"
                                                    src="assets/img/education2.png" width="30" height="30"  style="margin: -2px 0 0 0"></div>
                                            <div class="col-11 title3" >Education</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>

                                            <div *ngFor="let education of cvEducations().controls; let educationIndex=index"
                                                class="box" [formGroupName]="educationIndex">

                                                <div class="year_company">
                                                    <h5 class="company" style="color: #000;" > <strong  style="color: #5b5c5c;">
                                                        {{cvEducations().at(educationIndex).get('period').value}}</strong> <strong style="margin-left: 7px;"> | </strong>
                                                        <strong [style.color]="firstcolor" style="margin-left: 7px;">
                                                            {{cvEducations().at(educationIndex).get('degree').value}}
                                                        </strong>
                                                         </h5>



                                                </div>

                                                <div class="text">


                                                    <p style="font-size: 14px; word-wrap: break-word;">
                                                        <strong>
                                                            {{cvEducations().at(educationIndex).get('institute').value}}
                                                        </strong> <span
                                                            *ngIf="cvEducations().at(educationIndex).get('city').value.trim()">
                                                            {{" ,
                                                            "+cvEducations().at(educationIndex).get('city').value}}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <!-- EDUCATION BEGINNER -->
                                <div *ngIf="cvFormGroup.get('experiences').value.length && isBeginner" formArrayName="experiences"
                                    class="about">



                                            <div class="col-12 title1">

                                                <div class="row">
                                                    <div class=" col-1 title4" [style.background]="firstcolor">
                                                        <img *ngIf="!isIcon" [src]="imageToShow2" width="33" height="33"  style="margin: -2px 0 0 0">
                                                    </div>
                                                    <div class="col-11 title3" >{{'experiences' | translate}}</div>
                                                </div>
                                            </div>

                                    <div>


                                        <div class="box"
                                            *ngFor="let experience of cvExperiences().controls; let experienceIndex=index"
                                            [formGroupName]="experienceIndex">


                                            <div class="year_company">
                                                <h5 class="company" style="color: #000;" > <strong  style="color: #5b5c5c;"> {{cvExperiences().at(experienceIndex).get('company').value}}</strong> <strong style="margin-left: 7px;"> | </strong>
                                                    <strong [style.color]="firstcolor" style="margin-left: 7px;">
                                                        {{cvExperiences().at(experienceIndex).get('function').value}}
                                                    </strong>
                                                     </h5>



                                            </div>

                                            <div class="year_company" >
                                                <h5 style="color: #000;">{{cvExperiences().at(experienceIndex).get('period').value}} <strong style="margin-left: 7px;"> | </strong>
                                                    <span style="margin-left: 7px;">
                                                        {{cvExperiences().at(experienceIndex).get('city').value}}
                                                    </span> </h5>


                                            </div>
                                            <div class="text">

                                                <p style="font-size: 12px; word-wrap: break-word;"
                                                    [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">

                                                </p>
                                            </div>
                                        </div>

                                    </div>

                                </div>







                                <!-- RUBRIQUE PERSONNALISEE -->
                                <div *ngIf="cvFormGroup.get('customs').value.length" formArrayName="customs">
                                    <div *ngFor="let custom of cvCustoms().controls; let customIndex=index"
                                        [formGroupName]="customIndex" class="about interest">


                                                <div class="col-12 title1">

                                                    <div class="row">
                                                        <div class=" col-1 title4" [style.background]="firstcolor" > <img *ngIf="!isIcon"
                                                                src="assets/img/custom2.png" width="27" height="27"></div>
                                                        <div class="col-11 title3"  >{{cvCustoms().at(customIndex).get('title').value}}</div>
                                                    </div>
                                                </div>

                                        <p style="font-size: 12px; word-wrap: break-word;"
                                            [innerHTML]="cvCustoms().at(customIndex).get('description').value">

                                        </p>
                                    </div>
                                    <p></p>
                                </div>

                                <!-- QUALITY -->


                                <div *ngIf="cvFormGroup.get('quality1').value.trim() || cvFormGroup.get('quality2').value.trim()
                                 ||
                                cvFormGroup.get('quality3').value.trim() || cvFormGroup.get('quality4').value.trim()"
                                    class="about interest">

                                    <div class="col-12 title1">

                                        <div class="row">
                                            <div class=" col-1 title4" [style.background]="firstcolor" > <img *ngIf="!isIcon"
                                                    src="assets/img/quality.png" width="29" height="29" style="margin: -2px 0 0 1px;"></div>
                                            <div class="col-11 title3"  >  {{'qualities' | translate}}</div>
                                        </div>
                                    </div>
                                    <ul>
                                        <li>{{cvFormGroup.get('quality1').value}}</li>
                                        <li>{{cvFormGroup.get('quality2').value}}</li>
                                        <li>{{cvFormGroup.get('quality3').value}}</li>
                                        <li>{{cvFormGroup.get('quality4').value}}</li>
                                    </ul>
                                </div>





                                <!-- REFERENCES -->

                                <div *ngIf="cvFormGroup.get('references').value.length" formArrayName="references"
                                    class="about">


                                            <div class="col-12 title1">

                                                <div class="row">
                                                    <div class=" col-1 title4"  [style.background]="firstcolor"> <img *ngIf="!isIcon"
                                                            src="assets/img/ref2.png" width="27" height="27"  style="margin: -1px 0 0 2px"></div>
                                                    <div class="col-11 title3">  {{'references' | translate}}</div>
                                                </div>
                                            </div>

                                    <div>
                                        <div>

                                            <div *ngFor="let certif of cvReferences().controls; let refIndex=index"
                                                [formGroupName]="refIndex" class="box">
                                                <div class="refname">
                                                    <h5> {{cvReferences().at(refIndex).get('fullname').value}} <strong style="margin-left: 7px;"> | </strong>
                                                        <strong [style.color]="firstcolor" style="margin-left: 7px;">
                                                            {{cvReferences().at(refIndex).get('organisation').value}}</strong>
                                                        </h5>

                                                </div>
                                                <div class="text2">
                                                    <h4>{{cvReferences().at(refIndex).get('telephone').value}}
                                                        <strong style="margin-left: 7px; "> | </strong>
                                                        <strong  style="margin-left: 7px;">
                                                        {{cvReferences().at(refIndex).get('email').value}}
                                                    </strong>
                                                    </h4>



                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>


                        <!--  END PAGE 1-->

                        <!--  START PAGE 2-->

                        <div data-ng-controll class="container2" id="content2" #content2 style="margin-top: 2px;">
                            <div class="left_side" id="left_side2" #left_side2 style="padding : 0; margin-top: 0px "
                                [style.background]="firstcolor">





                            </div>
                            <div class="right_side" id="right_side2" #right_side2 style="padding : 0; margin-top: 0px "
                                [style.background]="pagecolor">





                            </div>
                        </div>


                        <!--  END PAGE 2-->

                        <!--  START PAGE 3-->

                        <div data-ng-controll class="container2" id="content3" #content3 style="margin-top: 0px;">
                            <div class="left_side" id="left_side3" #left_side3 style="padding : 0; margin-top: 0px "
                                [style.background]="firstcolor">



                            </div>
                            <div class="right_side" id="right_side3" #right_side3 style="padding : 0; margin-top: 0px "
                                [style.background]="pagecolor">

                            </div>
                        </div>


                        <!--  END PAGE 3-->



                    </div>

                </div>
            </form>
        </div>

    </div>
</div>

<!--   FORM ARRAY         -->
