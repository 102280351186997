import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cv-faq',
  templateUrl: './cv-faq.component.html',
  styleUrls: ['./cv-faq.component.scss']
})
export class CvFaqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
