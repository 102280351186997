<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Page</li>
            </ul>
            <h2>Le nombre de pages dans un CV</h2>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/zcv.jpg" class="shadow" alt="image">

                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">About Us !</span> -->
                    <h2>Combien de pages doit avoir un CV parfait ?</h2>

                    <div class="features-text">
                        <p>La longueur du CV peut être un facteur crucial pour déterminer si vous obtenez ou non le poste. Pensez-y : si un recruteur a 100 CV à parcourir en une journée, il y a une chance qu'à un moment donné, il perde sa concentration.
                            L'astuce consiste donc à attirer leur attention, et, surtout, à faire en sorte que votre CV se démarque de tous les autres candidats.</p>
                        <p> En fait, il n'y a pas de limitations formelles quant au nombre de pages qu'un CV doit contenir. Deux théories s'affrontent : l'une dit que c'est toujours la meilleure option de limiter son CV à une page, tandis que l'autre prône
                            l'idée de se démarquer en augmentant le nombre de pages à deux. Si des dizaines ou des centaines de personnes répondent à une offre d'emploi, un recruteur n'a pas la patience à lire chacune des candidatures. Ils consacrent
                            plusieurs secondes à chaque CV. Ce n'est que lorsqu'ils trouvent un curriculum vitae donné intéressant que vous pouvez vous attendre à une plus grande attention. Cependant, trop d'informations non pertinentes décourageront
                            un recruteur</p>
                    </div>
                </div>
            </div>
        </div>


        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-10 col-md-12">
                    <div class="story-content">
                        <h3>CV en une page</h3>
                        <p>1. Un CV d'une page est un clin d'œil aux recruteurs. Il permet une analyse rapide du contenu. Une page promet au recruteur un tour rapide qui lui fera gagner du temps et attire son attention.
                        </p>
                        <p>
                            2. Il a une fonction de "dépliant publicitaire" - il informe de manière synthétique sur le profil professionnel d'un candidat.</p>
                        <p> 3. Il est particulièrement utile pour les diplômés et les candidats ayant quelques années d'expérience.</p>
                        <h3>CV en deux pages ou plus</h3>
                        <p>1. Il est particulièrement utile pour les candidats ayant plus de 10 ans d'expérience professionnelle.</p>
                        <p>
                            2. Il permet une description précise et détaillée des compétences et de l'expérience.
                        </p>
                        <p>
                            3. Lorsque vous écrivez des CV plus longs, vous devez vous rappeler de le profiler.
                        </p>
                        <p>N'écrivez pas sur l'expérience et les compétences qui ne sont pas mentionnées dans une offre d'emploi. Important! Si vous devez écrire plus d'une page, continuez sur une autre feuille de papier (pas au verso de la première !)</p>
                        <h3>Comment « résumer » votre CV ?</h3>
                        <p>
                            Il est préférable de préparer une version complète du CV contenant toutes les informations, puis de supprimer les données qui sont moins pertinentes du point de vue du recruteur. De cette manière, vous arriverez à un CV d'une longueur optimale allant
                            de 1 à 2 pages.

                        </p>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<!--
<app-our-mission></app-our-mission>


<div class="story-area ptb-100">
    <app-our-story></app-our-story>
</div>

<div class="funfacts-area">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div>

<div class="values-area ptb-100">
    <app-our-values></app-our-values>
</div>

<div class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-two></app-instructors-style-two>
    </div>
</div>

<div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>
-->

<!--
<div class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>
-->
