<loading *ngIf="isLoading">
    {{ isLoading }}</loading>
<app-header-style-one></app-header-style-one>
<div class="register-area">
    <div class="row m-0">
           <!--
        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-image">
                <img src="assets/img/register-bg.jpg" alt="image">
            </div>
        </div>
      -->
        <div class="col-lg-12 col-md-12 p-0">
            <div class="register-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="register-form">
                              <!--
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/black-logo.png" alt="image"></a>
                            </div>
                            -->
                            <h3>Enregistrez-vous </h3>
                            <p>Déjà enregistré(e)? <a routerLink="/se-connecter">Se connecter</a></p>
                            <form [formGroup]="registerFormGroup" >

                                <div class="form-group">
                                    <input class="form-control" type="text" placeholder="Prénom *" minlength="2"
                                        formControlName="firstname" [ngClass]="{ 'is-invalid': registerFormGroup.controls.firstname.errors && !registerFormGroup.controls.firstname.valid &&
                                     (registerFormGroup.controls.firstname.dirty || registerFormGroup.controls.firstname.touched )}">
                                    <div class="text text-danger" *ngIf="registerFormGroup.controls.firstname.invalid && registerFormGroup.controls.firstname.touched" >Le prénom est invalide !</div>

                                </div>

                                <div class="form-group">
                                    <input class="form-control" type="text" placeholder="Nom *" minlength="2"
                                        formControlName="lastname" required
                                        [ngClass]="{ 'is-invalid': registerFormGroup.controls.lastname.errors && !registerFormGroup.controls.lastname.valid &&
                                   (registerFormGroup.controls.lastname.dirty || registerFormGroup.controls.lastname.touched )}"
                                         >
                                    <div class="text text-danger " *ngIf="registerFormGroup.controls.lastname.invalid &&
                                    registerFormGroup.controls.lastname.touched">Le nom est invalide !</div>

                                </div>

                                <div class="form-group">
                                    <input class="form-control" type="email" placeholder="Email *" required
                                        formControlName="email" [ngClass]="{ 'is-invalid': registerFormGroup.controls.email.errors && !registerFormGroup.controls.email.valid &&
                                        (registerFormGroup.controls.email.dirty || registerFormGroup.controls.email.touched )}">
                                    <div class="text text-danger "
                                     *ngIf="registerFormGroup.controls.email.invalid && registerFormGroup.controls.email.touched" >L'email est invalide !</div>

                                </div>
                                <div class="form-group">
                                    <input class="form-control"  type="password" placeholder="Créer un mot de passe *"
                                    required minlength="6" formControlName="password" [ngClass]="{ 'is-invalid':
                                     registerFormGroup.controls.password.errors && !registerFormGroup.controls.password.valid &&
                                    (registerFormGroup.controls.password.dirty || registerFormGroup.controls.password.touched )}"
                                   >

                                   <div class="text text-danger " *ngIf="!registerFormGroup.controls.password.valid && registerFormGroup.controls.password.touched" >
                                        Le mot de passe est invalide, minimum 6 caractères !
                                    </div>
                                </div>

                                <div class="text text-danger" *ngIf="isLoginFailed" >
                                   {{errorMessage}}
                                </div>
                                <button type="button" [disabled]="registerFormGroup.invalid" class="btn-disable"
                                    (click)="register()">S'enregistrer</button>

                                    <div class="connect-with-social">
                                        <span>Ou</span>
                                        <a href="https://userapi.icv-pro.com/user/auth/google/callback">
                                        <button type="button"   class="google"><i class='bx bxl-google' ></i>S'enregistrer avec Google</button>
                                        </a>

                                        <a href="https://userapi.icv-pro.com/user/auth/linkedin/callback" style="margin-bottom: 12px;">
                                            <button type="button"   class="twitter"><i class='bx bxl-linkedin' ></i>S'enregistrer avec Linkedin</button>
                                            </a>
                                           <p></p>
                                            <a href="https://userapi.icv-pro.com/user/auth/facebook/callback" style="margin-top: 3px;">
                                                <button type="button"   class="facebook"><i class='bx bxl-facebook' ></i>S'enregistrer avec Facebook</button>
                                                </a>
                                    </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
