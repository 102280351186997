<app-header-style-one></app-header-style-one>

<cv-homethree-main-banner></cv-homethree-main-banner>

<div class="feedback-form-area">
    <div class="container">
        <cv-feedback-form></cv-feedback-form>
    </div>
</div>


<!-- 

<cv-homethree-about></cv-homethree-about>


<div class="courses-categories-area bg-image pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Courses Categories</span>
            <h2>Browse Trending Categories</h2>
            <a routerLink="/courses-category-style-2" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">View All</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <app-categories-style-two></app-categories-style-two>
    </div>
</div>


<app-offer></app-offer>

<div class="partner-area pt-100">
    <div class="container">
        <app-partner-style-two></app-partner-style-two>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-one></app-instructors-style-one>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Explore News</span>
            <h2>Our Latest Insights</h2>
            <a routerLink="/blog-style-1" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">Read All</span><i class="bx bx-book-reader icon-arrow after"></i></a>
        </div>
        <app-blog></app-blog>
    </div>
</div>
-->