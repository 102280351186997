export const THEMES  =[
    {
        "id": 1,
        "name": "darkblue",
        "firstcolor": "#19323d",
        "secondcolor": "#03a9f4",
        "thirdcolor": "#fff",
        "pagecolor": "#fff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "1"
    },
    {
        "id": 2,
        "name": "darkblue2",
        "firstcolor": "#003147",
        "secondcolor": "#4185d8",
        "thirdcolor": "#fff",
        "pagecolor": "#d4e2f7",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "2"
    },
    {
        "id": 3,
        "name": "red",
        "firstcolor": "#871919",
        "secondcolor": "#d57474",
        "thirdcolor": "#fff",
        "pagecolor": "#fff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "3"
    },
    {
        "id": 4,
        "name": "red2",
        "firstcolor": "#871919",
        "secondcolor": "#03a9f4",
        "thirdcolor": "#fff",
        "pagecolor": "#f1e4e4",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "4"
    },
    {
        "id": 5,
        "name": "gray",
        "firstcolor": "#333536",
        "secondcolor": "#42a4d5",
        "thirdcolor": "#fff",
        "pagecolor": "#fff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "5"
    },
    {
        "id": 6,
        "name": "gray2",
        "firstcolor": "#333536",
        "secondcolor": "#8f98a9",
        "thirdcolor": "#fff",
        "pagecolor": "#d7e1e7",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "6"
    },
    {
        "id": 7,
        "name": "green",
        "firstcolor": "#0c3b0f",
        "secondcolor": "#70ab90",
        "thirdcolor": "#fff",
        "pagecolor": "#fff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "7"
    },
    {
        "id": 8,
        "name": "green2",
        "firstcolor": "#0c3b0f",
        "secondcolor": "#56e4a3",
        "thirdcolor": "#fff",
        "pagecolor": "#e2efe9",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "8"
    },
    {
        "id": 9,
        "name": "blue",
        "firstcolor": "#0c3d87",
        "secondcolor": "#93baf5",
        "thirdcolor": "#fff",
        "pagecolor": "#fff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "9"
    },
    {
        "id": 10,
        "name": "yellow",
        "firstcolor": "#6e550a",
        "secondcolor": "#ffc107",
        "thirdcolor": "#fff",
        "pagecolor": "#fff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "10"
    },
    {
        "id": 11,
        "name": "yellow2",
        "firstcolor": "#333129",
        "secondcolor": "#ffc107",
        "thirdcolor": "#fff",
        "pagecolor": "#e0dbcf",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "11"
    },
    {
        "id": 12,
        "name": "cyan",
        "firstcolor": "#063b3a",
        "secondcolor": "#71c1d1",
        "thirdcolor": "#fff",
        "pagecolor": "#fff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "12"
    },
    {
        "id": 13,
        "name": "cyan2",
        "firstcolor": "#1f4a52",
        "secondcolor": "#71c1d1",
        "thirdcolor": "#fff",
        "pagecolor": "#e2f3f3",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "13"
    },
    {
        "id": 14,
        "name": "orange",
        "firstcolor": "#bb3712",
        "secondcolor": "#df907a",
        "thirdcolor": "#fff",
        "pagecolor": "#fff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "14"
    },
    {
        "id": 15,
        "name": "orange2",
        "firstcolor": "#bb3712",
        "secondcolor": "#37aebe",
        "thirdcolor": "#fff",
        "pagecolor": "#f7edea",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "15"
    },
    {
        "id": 16,
        "name": "red3",
        "firstcolor": "#9d0b0b",
        "secondcolor": "#94979b",
        "thirdcolor": "#fff",
        "pagecolor": "#fff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "16"
    },
    {
        "id": 17,
        "name": "red4",
        "firstcolor": "#4b0b24",
        "secondcolor": "#428ce7",
        "thirdcolor": "#fff",
        "pagecolor": "#fff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "17"
    },
    {
        "id": 18,
        "name": "black",
        "firstcolor": "#212529",
        "secondcolor": "#94979b",
        "thirdcolor": "#fff",
        "pagecolor": "#fff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "18"
    },
    {
        "id": 19,
        "name": "Blue3",
        "firstcolor": "#2c5489",
        "secondcolor": "#94979b",
        "thirdcolor": "#fff",
        "pagecolor": "#d8e6f3",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "19"
    },
    {
        "id": 20,
        "name": "black3",
        "firstcolor": "#212529",
        "secondcolor": "#e05858",
        "thirdcolor": "#fff",
        "pagecolor": "#fff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "20"
    },
    {
        "id": 21,
        "name": "black4",
        "firstcolor": "#212529",
        "secondcolor": "#5eaae2",
        "thirdcolor": "#fff",
        "pagecolor": "#fff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "21"
    },
    {
        "id": 22,
        "name": "purple",
        "firstcolor": "#4c0356",
        "secondcolor": "#c39ede",
        "thirdcolor": "#fff",
        "pagecolor": "#fff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "22"
    },
    {
        "id": 23,
        "name": "purple2",
        "firstcolor": "#292b3b",
        "secondcolor": "#7c82a7",
        "thirdcolor": "#fff",
        "pagecolor": "#d2d9e9",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "1",
        "id2": "23"
    },
    {
        
        "id": 24,
        "name": "gray3",
        "firstcolor": "#343f52",
        "secondcolor": "#4dbad6",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "24"
    },
    {
        "id": 25,
        "name": "cv5_1",
        "firstcolor": "#2e3133",
        "secondcolor": "#7e0905",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "1"
    },
    {
        "id": 26,
        "name": "CV5_2",
        "firstcolor": "#182839",
        "secondcolor": "#24601f",
        "thirdcolor": "",
        "pagecolor": "#cbefac",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "2"
    },
    {
        "id": 27,
        "name": "CV5",
        "firstcolor": "#0e3752",
        "secondcolor": "#740d17",
        "thirdcolor": "",
        "pagecolor": "#c4eef7",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "3"
    },
    {
        "id": 28,
        "name": "CV5",
        "firstcolor": "#0e3752",
        "secondcolor": "#4a5050",
        "thirdcolor": "",
        "pagecolor": "#efc693",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "4"
    },
    {
        "id": 29,
        "name": "CV5",
        "firstcolor": "#0e3752",
        "secondcolor": "#78621f",
        "thirdcolor": "",
        "pagecolor": "#efc693",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "5"
    },
    {
        "id": 30,
        "name": "CV5",
        "firstcolor": "#7c0303",
        "secondcolor": "#113d6a",
        "thirdcolor": "",
        "pagecolor": "#9fd3e9",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "6"
    },
    {
        "id": 31,
        "name": "CV5",
        "firstcolor": "#7c0303",
        "secondcolor": "#434544",
        "thirdcolor": "",
        "pagecolor": "#b1adad",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "7"
    },
    {
        "id": 32,
        "name": "CV5",
        "firstcolor": "#68083f",
        "secondcolor": "#1b345e",
        "thirdcolor": "",
        "pagecolor": "#a4caf3",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "8"
    },
    {
        "id": 33,
        "name": "CV5",
        "firstcolor": "#173313",
        "secondcolor": "#918f06",
        "thirdcolor": "",
        "pagecolor": "#ef9494",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "9"
    },
    {
        "id": 34,
        "name": "CV5",
        "firstcolor": "#0f3672",
        "secondcolor": "#128f34",
        "thirdcolor": "",
        "pagecolor": "#cdced0",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "10"
    },
    {
        "id": 35,
        "name": "CV5",
        "firstcolor": "#1554b6",
        "secondcolor": "#1a2637",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "11"
    },
    {
        "id": 36,
        "name": "CV5",
        "firstcolor": "#19323d",
        "secondcolor": "#4d6d7c",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "12"
    },
    {
        "id": 37,
        "name": "CV5",
        "firstcolor": "#224d60",
        "secondcolor": "#831919",
        "thirdcolor": "",
        "pagecolor": "#9ed4e7",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "13"
    },
    {
        "id": 38,
        "name": "CV5",
        "firstcolor": "#003147",
        "secondcolor": "#2f98a1",
        "thirdcolor": "",
        "pagecolor": "#afcdfb",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "14"
    },
    {
        "id": 39,
        "name": "CV5",
        "firstcolor": "#a12828",
        "secondcolor": "#370707",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "15"
    },
    {
        "id": 40,
        "name": "CV5",
        "firstcolor": "#333129",
        "secondcolor": "#c89703",
        "thirdcolor": "",
        "pagecolor": "#e0dbcf",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "16"
    },
    {
        "id": 41,
        "name": "CV5",
        "firstcolor": "#292b3b",
        "secondcolor": "#7c82a7",
        "thirdcolor": "",
        "pagecolor": "#d2d9e9",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "17"
    },
    {
        "id": 42,
        "name": "CV5",
        "firstcolor": "#2a2d2f",
        "secondcolor": "#78797a",
        "thirdcolor": "",
        "pagecolor": "#eb8d8d",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "18"
    },
    {
        "id": 43,
        "name": "CV5",
        "firstcolor": "#212529",
        "secondcolor": "#7a7d81",
        "thirdcolor": "",
        "pagecolor": "#e7dcdc",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "19"
    },
    {
        "id": 44,
        "name": "CV5",
        "firstcolor": "#080356",
        "secondcolor": "#696f8f",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "20"
    },
    {
        "id": 45,
        "name": "CV5",
        "firstcolor": "#292b3b",
        "secondcolor": "#872727",
        "thirdcolor": "",
        "pagecolor": "#bcb6b6",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "21"
    },
    {
        "id": 46,
        "name": "CV5",
        "firstcolor": "#0e245a",
        "secondcolor": "#3a3891",
        "thirdcolor": "",
        "pagecolor": "#d8cfcf",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "22"
    },
    {
        "id": 47,
        "name": "CV5",
        "firstcolor": "#0a2e47",
        "secondcolor": "#3994a1",
        "thirdcolor": "",
        "pagecolor": "#eaefaf",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "23"
    },
    {
        "id": 48,
        "name": "CV5",
        "firstcolor": "#0a2947",
        "secondcolor": "#206f89",
        "thirdcolor": "",
        "pagecolor": "#afdcef",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "24"
    },
    {
        "id": 49,
        "name": "CV9",
        "firstcolor": "#003147",
        "secondcolor": "rgba(17, 19, 29, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "1"
    },
    {
        "id": 50,
        "name": "CV9",
        "firstcolor": "#47000d",
        "secondcolor": "rgba(17, 19, 29, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#f1b4b4",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "2"
    },
    {
        "id": 51,
        "name": "CV9",
        "firstcolor": "#2b2b1f",
        "secondcolor": "rgba(17, 19, 29, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#dff1b4",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "3"
    },
    {
        "id": 52,
        "name": "CV9",
        "firstcolor": "#17411c",
        "secondcolor": "rgba(17, 19, 29, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "4"
    },
    {
        "id": 53,
        "name": "CV9",
        "firstcolor": "#173741",
        "secondcolor": "rgba(17, 19, 29, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#70c4e9",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "5"
    },
    {
        "id": 54,
        "name": "CV9",
        "firstcolor": "#27292b",
        "secondcolor": "rgba(17, 19, 29, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#35b6ef",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "6"
    },
    {
        "id": 55,
        "name": "CV9",
        "firstcolor": "#620808",
        "secondcolor": "rgba(122, 72, 68, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#e8eced",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "7"
    },
    {
        "id": 56,
        "name": "CV9",
        "firstcolor": "#3f4143",
        "secondcolor": "rgba(68, 104, 122, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "8"
    },
    {
        "id": 57,
        "name": "CV9",
        "firstcolor": "#273d2e",
        "secondcolor": "rgba(68, 104, 122, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#90f3c5",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "9"
    },
    {
        "id": 58,
        "name": "CV9",
        "firstcolor": "#312231",
        "secondcolor": "rgba(106, 68, 122, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#d3ecef",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "10"
    },
    {
        "id": 59,
        "name": "cv9",
        "firstcolor": "#493007",
        "secondcolor": "rgba(100, 82, 22, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "11"
    },
    {
        "id": 60,
        "name": "cv9",
        "firstcolor": "#7a101a",
        "secondcolor": "rgba(40, 117, 147, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#f7f7f7",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "12"
    },
    {
        "id": 61,
        "name": "cv9",
        "firstcolor": "#10336e",
        "secondcolor": "rgba(204, 20, 20, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "13"
    },
    {
        "id": 62,
        "name": "CV9",
        "firstcolor": "#314b5e",
        "secondcolor": "rgba(157, 136, 136, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "14"
    },
    {
        "id": 63,
        "name": "cv9",
        "firstcolor": "#852408",
        "secondcolor": "rgba(223, 144, 122, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "15"
    },
    {
        "id": 64,
        "name": "CV9",
        "firstcolor": "#621e0b",
        "secondcolor": "rgba(122, 219, 223, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#e3e7e5",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "16"
    },
    {
        "id": 65,
        "name": "CV9",
        "firstcolor": "#52144a",
        "secondcolor": "rgba(17, 121, 126, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "17"
    },
    {
        "id": 66,
        "name": "CV9",
        "firstcolor": "#0c3f72",
        "secondcolor": "rgba(228, 211, 211, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#ed0c0c",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "18"
    },
    {
        "id": 67,
        "name": "cv9",
        "firstcolor": "#303033",
        "secondcolor": "rgba(17, 52, 126, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "19"
    },
    {
        "id": 68,
        "name": "cv9",
        "firstcolor": "#877119",
        "secondcolor": "rgba(49, 126, 17, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "20"
    },
    {
        "id": 69,
        "name": "cv9",
        "firstcolor": "#877119",
        "secondcolor": "rgba(17, 71, 126, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "21"
    },
    {
        "id": 70,
        "name": "cv9",
        "firstcolor": "#2c8719",
        "secondcolor": "rgba(17, 71, 126, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#e2ef82",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "22"
    },
    {
        "id": 71,
        "name": "cv9",
        "firstcolor": "#293727",
        "secondcolor": "rgba(17, 71, 126, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#e2ef82",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "23"
    },
    {
        "id": 72,
        "name": "cv9",
        "firstcolor": "#1c3743",
        "secondcolor": "rgba(97, 108, 165, 0.4)",
        "thirdcolor": "",
        "pagecolor": "#3ba0ed",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "24"
    },
    {
        "id": 73,
        "name": "CV11",
        "firstcolor": "#093556",
        "secondcolor": "#0c5285",
        "thirdcolor": "",
        "pagecolor": "#1970b1",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "1"
    },
    {
        "id": 74,
        "name": "CV11",
        "firstcolor": "#292929",
        "secondcolor": "#57585c",
        "thirdcolor": "",
        "pagecolor": "#7b8387",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "2"
    },
    {
        "id": 75,
        "name": "CV11",
        "firstcolor": "#073328",
        "secondcolor": "#034b3a",
        "thirdcolor": "",
        "pagecolor": "#077e61",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "3"
    },
    {
        "id": 76,
        "name": "CV11",
        "firstcolor": "#5a0309",
        "secondcolor": "#801717",
        "thirdcolor": "",
        "pagecolor": "#974242",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "4"
    },
    {
        "id": 77,
        "name": "CV11",
        "firstcolor": "#352b15",
        "secondcolor": "#504527",
        "thirdcolor": "",
        "pagecolor": "#78603c",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "5"
    },
    {
        "id": 78,
        "name": "CV11",
        "firstcolor": "#153534",
        "secondcolor": "#274f50",
        "thirdcolor": "",
        "pagecolor": "#3c7873",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "6"
    },
    {
        "id": 79,
        "name": "CV11",
        "firstcolor": "#151d35",
        "secondcolor": "#273250",
        "thirdcolor": "",
        "pagecolor": "#3c4c78",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "7"
    },
    {
        "id": 80,
        "name": "CV11",
        "firstcolor": "#152c5a",
        "secondcolor": "#1648a3",
        "thirdcolor": "",
        "pagecolor": "#a13434",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "8"
    },
    {
        "id": 81,
        "name": "CV11",
        "firstcolor": "#0e2643",
        "secondcolor": "#134a7a",
        "thirdcolor": "",
        "pagecolor": "#504f4f",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "9"
    },
    {
        "id": 82,
        "name": "CV11",
        "firstcolor": "#740101",
        "secondcolor": "#9d3636",
        "thirdcolor": "",
        "pagecolor": "#565252",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "10"
    },
    {
        "id": 83,
        "name": "CV11",
        "firstcolor": "#3b044b",
        "secondcolor": "#5a3062",
        "thirdcolor": "",
        "pagecolor": "#565252",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "11"
    },
    {
        "id": 84,
        "name": "CV11",
        "firstcolor": "#4b0427",
        "secondcolor": "#762a4b",
        "thirdcolor": "",
        "pagecolor": "#565252",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "12"
    },
    {
        "id": 85,
        "name": "CV11",
        "firstcolor": "#044b3b",
        "secondcolor": "#2a7650",
        "thirdcolor": "",
        "pagecolor": "#758516",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "13"
    },
    {
        "id": 86,
        "name": "CV11",
        "firstcolor": "#07493a",
        "secondcolor": "#919123",
        "thirdcolor": "",
        "pagecolor": "#365238",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "14"
    },
    {
        "id": 87,
        "name": "CV11",
        "firstcolor": "#073c6c",
        "secondcolor": "#067fb6",
        "thirdcolor": "",
        "pagecolor": "#274170",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "15"
    },
    {
        "id": 88,
        "name": "CV11",
        "firstcolor": "#563e1f",
        "secondcolor": "#978e2c",
        "thirdcolor": "",
        "pagecolor": "#4b3e23",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "16"
    },
    {
        "id": 89,
        "name": "CV11",
        "firstcolor": "#143919",
        "secondcolor": "#797e1c",
        "thirdcolor": "",
        "pagecolor": "#872121",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "17"
    },
    {
        "id": 90,
        "name": "CV11",
        "firstcolor": "#14431a",
        "secondcolor": "#14385a",
        "thirdcolor": "",
        "pagecolor": "#877821",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "18"
    },
    {
        "id": 91,
        "name": "CV11",
        "firstcolor": "#0e2a5c",
        "secondcolor": "#75787c",
        "thirdcolor": "",
        "pagecolor": "#a11212",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "19"
    },
    {
        "id": 92,
        "name": "CV11",
        "firstcolor": "#7f8185",
        "secondcolor": "#275c4b",
        "thirdcolor": "",
        "pagecolor": "#1d412a",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "20"
    },
    {
        "id": 93,
        "name": "CV11",
        "firstcolor": "#64666c",
        "secondcolor": "#304972",
        "thirdcolor": "",
        "pagecolor": "#0a2e4e",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "21"
    },
    {
        "id": 94,
        "name": "CV11",
        "firstcolor": "#7f8185",
        "secondcolor": "#3d4243",
        "thirdcolor": "",
        "pagecolor": "#720505",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "22"
    },
    {
        "id": 95,
        "name": "CV11",
        "firstcolor": "#7f8185",
        "secondcolor": "#3d4243",
        "thirdcolor": "",
        "pagecolor": "#054472",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "23"
    },
    {
        "id": 96,
        "name": "CV11",
        "firstcolor": "#6c6e70",
        "secondcolor": "#3d4043",
        "thirdcolor": "",
        "pagecolor": "#1f1f1e",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "24"
    },
    {
        "id": 97,
        "name": "cv12",
        "firstcolor": "#0e3958",
        "secondcolor": "#0e3341",
        "thirdcolor": "",
        "pagecolor": "#cedbeb",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "1"
    },
    {
        "id": 98,
        "name": "cv12",
        "firstcolor": "#9d0505",
        "secondcolor": "#4b1212",
        "thirdcolor": "",
        "pagecolor": "#ebcece",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "2"
    },
    {
        "id": 99,
        "name": "cv12",
        "firstcolor": "#3d2b19",
        "secondcolor": "#a96736",
        "thirdcolor": "",
        "pagecolor": "#ebddce",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "3"
    },
    {
        "id": 100,
        "name": "cv12",
        "firstcolor": "#3b3d19",
        "secondcolor": "#707216",
        "thirdcolor": "",
        "pagecolor": "#ebe9ce",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "4"
    },
    {
        "id": 101,
        "name": "cv12",
        "firstcolor": "#273d19",
        "secondcolor": "#417216",
        "thirdcolor": "",
        "pagecolor": "#daebce",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "5"
    },
    {
        "id": 102,
        "name": "cv12",
        "firstcolor": "#193d24",
        "secondcolor": "#488f70",
        "thirdcolor": "",
        "pagecolor": "#ceebdc",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "6"
    },
    {
        "id": 103,
        "name": "cv12",
        "firstcolor": "#19393d",
        "secondcolor": "#307e7d",
        "thirdcolor": "",
        "pagecolor": "#ceebea",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "7"
    },
    {
        "id": 104,
        "name": "cv12",
        "firstcolor": "#0f304d",
        "secondcolor": "#265297",
        "thirdcolor": "",
        "pagecolor": "#c4d8fb",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "8"
    },
    {
        "id": 105,
        "name": "cv12",
        "firstcolor": "#0e385c",
        "secondcolor": "#972626",
        "thirdcolor": "",
        "pagecolor": "#fdfdfd",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "9"
    },
    {
        "id": 106,
        "name": "cv12",
        "firstcolor": "#992e10",
        "secondcolor": "#1273bb",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "10"
    },
    {
        "id": 107,
        "name": "cv12",
        "firstcolor": "#292b3b",
        "secondcolor": "#616580",
        "thirdcolor": "",
        "pagecolor": "#d2d9e9",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "11"
    },
    {
        "id": 108,
        "name": "cv12",
        "firstcolor": "#222d41",
        "secondcolor": "#2a619f",
        "thirdcolor": "",
        "pagecolor": "#a1d5f5",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "12"
    },
    {
        "id": 109,
        "name": "cv12",
        "firstcolor": "#434445",
        "secondcolor": "#8f0505",
        "thirdcolor": "",
        "pagecolor": "#e1e5e9",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "13"
    },
    {
        "id": 110,
        "name": "cv12",
        "firstcolor": "#434445",
        "secondcolor": "#786f0a",
        "thirdcolor": "",
        "pagecolor": "#e1e5e9",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "14"
    },
    {
        "id": 111,
        "name": "cv12",
        "firstcolor": "#434445",
        "secondcolor": "#0a781b",
        "thirdcolor": "",
        "pagecolor": "#a7edc6",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "15"
    },
    {
        "id": 112,
        "name": "cv12",
        "firstcolor": "#586068",
        "secondcolor": "#780a46",
        "thirdcolor": "",
        "pagecolor": "#a7bded",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "16"
    },
    {
        "id": 113,
        "name": "cv12",
        "firstcolor": "#586068",
        "secondcolor": "#191819",
        "thirdcolor": "",
        "pagecolor": "#c9cbd4",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "17"
    },
    {
        "id": 114,
        "name": "cv12",
        "firstcolor": "#284966",
        "secondcolor": "#191819",
        "thirdcolor": "",
        "pagecolor": "#ccd0e4",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "18"
    },
    {
        "id": 115,
        "name": "cv12",
        "firstcolor": "#87200e",
        "secondcolor": "#272527",
        "thirdcolor": "",
        "pagecolor": "#f3d0d0",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "19"
    },
    {
        "id": 116,
        "name": "cv12",
        "firstcolor": "#28648f",
        "secondcolor": "#272527",
        "thirdcolor": "",
        "pagecolor": "#81cff7",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "20"
    },
    {
        "id": 117,
        "name": "cv12",
        "firstcolor": "#8f2862",
        "secondcolor": "#521e38",
        "thirdcolor": "",
        "pagecolor": "#eba2f9",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "21"
    },
    {
        "id": 118,
        "name": "cv12",
        "firstcolor": "#8f6a28",
        "secondcolor": "#232b0c",
        "thirdcolor": "",
        "pagecolor": "#e6cdcd",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "22"
    },
    {
        "id": 119,
        "name": "cv12",
        "firstcolor": "#272626",
        "secondcolor": "#605757",
        "thirdcolor": "",
        "pagecolor": "#c4b2b2",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "23"
    },
    {
        "id": 120,
        "name": "CV12",
        "firstcolor": "#003147",
        "secondcolor": "#8d8888",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "24"
    },
    {
        "id": 121,
        "name": "CV15",
        "firstcolor": "#173f49",
        "secondcolor": "#e7f393",
        "thirdcolor": "",
        "pagecolor": "#f1f5ee",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "1"
    },
    {
        "id": 122,
        "name": "CV15",
        "firstcolor": "#303135",
        "secondcolor": "#93d1f3",
        "thirdcolor": "",
        "pagecolor": "#f1f5ee",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "2"
    },
    {
        "id": 123,
        "name": "CV15",
        "firstcolor": "#202037",
        "secondcolor": "#7fd0fd",
        "thirdcolor": "",
        "pagecolor": "#b5e0e6",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "3"
    },
    {
        "id": 124,
        "name": "CV15",
        "firstcolor": "#970505",
        "secondcolor": "#3be9f3",
        "thirdcolor": "",
        "pagecolor": "#eaf5ee",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "4"
    },
    {
        "id": 125,
        "name": "CV15",
        "firstcolor": "#333536",
        "secondcolor": "#d59042",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "5"
    },
    {
        "id": 126,
        "name": "CV15",
        "firstcolor": "#373f37",
        "secondcolor": "#82e2d3",
        "thirdcolor": "",
        "pagecolor": "#acde9f",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "6"
    },
    {
        "id": 127,
        "name": "CV15",
        "firstcolor": "#393120",
        "secondcolor": "#f5de8a",
        "thirdcolor": "",
        "pagecolor": "#e3e7c7",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "7"
    },
    {
        "id": 128,
        "name": "CV15",
        "firstcolor": "#203939",
        "secondcolor": "#f5de8a",
        "thirdcolor": "",
        "pagecolor": "#e3e7c7",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "8"
    },
    {
        "id": 129,
        "name": "CV15",
        "firstcolor": "#343f52",
        "secondcolor": "#4dbad6",
        "thirdcolor": "#ffffff",
        "pagecolor": "",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "9"
    },
    {
        "id": 130,
        "name": "CV15",
        "firstcolor": "#292b3b",
        "secondcolor": "#7c82a7",
        "thirdcolor": "",
        "pagecolor": "#d2d9e9",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "10"
    },
    {
        "id": 131,
        "name": "CV15",
        "firstcolor": "#3e0356",
        "secondcolor": "#eadef3",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "11"
    },
    {
        "id": 132,
        "name": "CV15",
        "firstcolor": "#130356",
        "secondcolor": "#dee7f3",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "12"
    },
    {
        "id": 133,
        "name": "CV15",
        "firstcolor": "#003147",
        "secondcolor": "#f7a1a1",
        "thirdcolor": "",
        "pagecolor": "#0db4ed",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "13"
    },
    {
        "id": 134,
        "name": "CV15",
        "firstcolor": "#332735",
        "secondcolor": "#b35fa5",
        "thirdcolor": "",
        "pagecolor": "#dfa3f3",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "14"
    },
    {
        "id": 135,
        "name": "CV15",
        "firstcolor": "#323b45",
        "secondcolor": "#6be25e",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "15"
    },
    {
        "id": 136,
        "name": "CV15",
        "firstcolor": "#2a4743",
        "secondcolor": "#dce25e",
        "thirdcolor": "",
        "pagecolor": "#f37474",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "16"
    },
    {
        "id": 137,
        "name": "CV15",
        "firstcolor": "#254534",
        "secondcolor": "#f2f5b2",
        "thirdcolor": "",
        "pagecolor": "#5a8cd4",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "17"
    },
    {
        "id": 138,
        "name": "CV15",
        "firstcolor": "#254145",
        "secondcolor": "#caf5b2",
        "thirdcolor": "",
        "pagecolor": "#c6c882",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "18"
    },
    {
        "id": 139,
        "name": "CV15",
        "firstcolor": "#3b4147",
        "secondcolor": "#e05858",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "19"
    },
    {
        "id": 140,
        "name": "cv15",
        "firstcolor": "#4d76af",
        "secondcolor": "#49cdff",
        "thirdcolor": "",
        "pagecolor": "#090a0a",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "20"
    },
    {
        "id": 141,
        "name": "CV15",
        "firstcolor": "#1f4a52",
        "secondcolor": "#5bfcff",
        "thirdcolor": "",
        "pagecolor": "#ebf9b1",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "21"
    },
    {
        "id": 142,
        "name": "CV15",
        "firstcolor": "#3e3e47",
        "secondcolor": "#ff8787",
        "thirdcolor": "",
        "pagecolor": "#f7f7f7",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "22"
    },
    {
        "id": 143,
        "name": "CV15",
        "firstcolor": "#2c3e50",
        "secondcolor": "#af9769",
        "thirdcolor": "",
        "pagecolor": "#ffffff",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "23"
    },
    {
        "id": 144,
        "name": "CV15",
        "firstcolor": "#660d61",
        "secondcolor": "#ffffff",
        "thirdcolor": "",
        "pagecolor": "#080000",
        "fontfamily": "",
        "flag": "1",
        "modeleId": "NULL",
        "id2": "24"
    }
]