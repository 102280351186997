<loading *ngIf="isLoading && cv">{{ isLoading }}</loading>


<div id="cvt"  class="container-fluid" style="height: 100%; width: 100%;" *ngIf="this.cv">
    <div class="row" >


        <div id="cvt1" class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="height: 100%;  ">


            <cv-notice2> </cv-notice2>


        </div>

        <!--   CV TEMPLATE -->

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

            <div class="container">
                <div class="user-actions" style="background: #47494b; padding-top : 7px; padding-bottom: 7px; height : 50px">


                    <div id="content_2" class="row">


                        <div style="visibility: hidden; max-height: 3px;"  class="col-lg-1 col-md-1 col-sm-1 icon1">
                            <div #iconselect2  id="my-icon-select2" style="margin: 0;"></div>

                            <input #selectedText2 type="text" id="selected-text2" name="selected-text2"
                                style="width:65px; height:2px; visibility: hidden;">
                        </div>


                        <div style="visibility: hidden; max-height: 3px;"  class="col-lg-1 col-md-1 col-sm-1 icon1" >
                            <div #iconselect (click)="onChangeTheme()" id="my-icon-select" style="margin: 0;"></div>
                            <label style="margin-left:3px ; color: #fff; font-weight: 400;">
                                {{'themes' |
                                translate}}
                            </label>
                            <input #selectedText type="text" id="selected-text" name="selected-text"
                                style="width:65px; height: 3px; visibility: hidden;">
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2"></div>


                        <div class="col-lg-4 col-md-4 col-sm-4"  >
                            <button *ngIf="isOpen" type="button" class=" form-control btn btn-sm btn-secondary"
                            (click)="useExample(cv)"
                            style="padding: 7px; font-size: 14px; color: #fff; background: #003147; height: 33px;">
                            <i style="color: #fff; font-size: 15px;" class="bx bxs-duplicate"></i>
                            {{'usethiscv' | translate}}
                        </button>
                        <button *ngIf="!isOpen" type="button" class=" form-control btn "
                        style="padding: 7px; font-size: 12px;  height: 30px;width: 30px;">
                        <span role="status" style="color: #003147;"
                            class="spinner-border spinner-danger spinner-border-sm"></span>
                    </button>
                        </div>

                        <div class="col-lg-2 col-md-2 col-sm-2"></div>

                        <div class="col-lg-2 col-md-2 col-sm-2"></div>


                    </div>


                </div>


            </div>

            <form [formGroup]="cvFormGroup" style="z-index : -1">
                <div id="cvt2" class="div_body" [ngClass]="family1">
                    <div class="marge cvt20">

                        <!--  START PAGE 1-->
                        <div id="content1" class="resume_wrapper">



                            <div class="resume_right" id="right_side1">

                                <div *ngIf="this.cvFormGroup.get('profile').value.trim()" class="resume_item resume_profile">
                                    <div class="resume_title" > {{'profile' | translate}}</div>
                                    <div class="resume_info" style="text-align: left; font-size: 12px;">
                                        {{this.cvFormGroup.get("profile").value}}
                                    </div>


                                </div>

                                <!-- EDUCATION BEGINNER -->
                                <div *ngIf="cvFormGroup.get('educations').value.length && isBeginner" formArrayName="educations" class="resume_item resume_education">
                                    <div class="resume_title">Education</div>
                                    <div class="resume_info">
                                        <div *ngFor="let education of cvEducations().controls; let educationIndex=index" [formGroupName]="educationIndex" class="resume_data">
                                            <div class="year">{{cvEducations().at(educationIndex).get('period').value}}
                                                <p style="font-size: 12px;">{{cvEducations().at(educationIndex).get('city').value}}</p>
                                            </div>
                                            <div class="content">
                                                <p style="font-size: 16px; font-weight: 600;"  [style.color]="firstcolor">{{cvEducations().at(educationIndex).get('degree').value}}
                                                </p>
                                                <p style="font-size: 14px; font-weight: 700; text-transform: uppercase;">{{cvEducations().at(educationIndex).get('institute').value}} </p>

                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <!-- EDUCATION PROFESSIONAL -->
                                <div *ngIf="cvFormGroup.get('experiences').value.length && isProfessional" formArrayName="experiences" class="resume_item resume_experience">
                                    <div class="resume_title"> {{'experiences' | translate}}</div>
                                    <div class="resume_info">
                                        <div *ngFor="let experience of cvExperiences().controls; let experienceIndex=index" [formGroupName]="experienceIndex"
                                         class="resume_data">
                                            <div class="year">{{cvExperiences().at(experienceIndex).get('period').value}}
                                                <p style="font-size: 12px;">{{cvExperiences().at(experienceIndex).get('city').value}}</p>
                                            </div>
                                            <div class="content">
                                                <p style="font-size: 14px; font-weight: 600;"  [style.color]="firstcolor">  {{cvExperiences().at(experienceIndex).get('function').value}} </p>
                                                <p style="font-size: 14px; font-weight: 700;text-transform: uppercase;">

                                                    {{cvExperiences().at(experienceIndex).get('company').value}}
                                                   </p>
                                                <p style="font-size: 12px; word-wrap: break-word;" [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div *ngIf="cvFormGroup.get('certifications').value.length" formArrayName="certifications" class="resume_item resume_education">
                                    <div class="resume_title"> {{'certificates' | translate}}</div>
                                    <div class="resume_info">
                                        <div *ngFor="let certif of cvCertifications().controls; let certifIndex=index" [formGroupName]="certifIndex" class="resume_data">
                                            <div class="year">{{cvCertifications().at(certifIndex).get('date').value}}
                                                <p></p>
                                            </div>
                                            <div class="content">
                                                <p style="font-size: 14px; font-weight: 600;"  [style.color]="firstcolor">{{cvCertifications().at(certifIndex).get('certificat').value}} </p>
                                                <p style="font-size: 14px; font-weight: 700; text-transform: uppercase;">{{cvCertifications().at(certifIndex).get('institute').value}}</p>

                                            </div>
                                        </div>


                                    </div>
                                </div>


                                <div *ngIf="cvFormGroup.get('formations').value.length" formArrayName="formations" class="resume_item resume_experience">
                                    <div class="resume_title">Formations</div>
                                    <div class="resume_info">
                                        <div *ngFor="let formation of cvFormations().controls; let formationIndex=index" [formGroupName]="formationIndex" class="resume_data">
                                            <div class="year">{{cvFormations().at(formationIndex).get('period').value}}
                                                <p></p>
                                            </div>
                                            <div class="content">
                                                <p style="font-size: 14px; font-weight: 600;"  [style.color]="firstcolor">{{cvFormations().at(formationIndex).get('title').value}} </p>
                                                <p style="font-size: 14px; font-weight: 700; text-transform: uppercase;">{{cvFormations().at(formationIndex).get('former').value}}</p>
                                                <p style="font-size: 12px; word-wrap: break-word;" [innerHTML]="cvFormations().at(formationIndex).get('description').value"></p>
                                            </div>
                                        </div>



                                    </div>
                                </div>

                                <!-- EDUCATION PROFESSIONAL -->
                                <div *ngIf="cvFormGroup.get('educations').value.length && isProfessional" formArrayName="educations" class="resume_item resume_education">
                                    <div class="resume_title">Education</div>
                                    <div class="resume_info">
                                        <div *ngFor="let education of cvEducations().controls; let educationIndex=index" [formGroupName]="educationIndex" class="resume_data">
                                            <div class="year">{{cvEducations().at(educationIndex).get('period').value}}
                                                <p style="font-size: 12px;">{{cvEducations().at(educationIndex).get('city').value}}</p>
                                            </div>
                                            <div class="content">
                                                <p style="font-size: 16px; font-weight: 600;"  [style.color]="firstcolor">{{cvEducations().at(educationIndex).get('degree').value}}
                                                </p>
                                                <p style="font-size: 14px; font-weight: 700; text-transform: uppercase;">{{cvEducations().at(educationIndex).get('institute').value}} </p>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- EXPERIENCE BEGINNER -->
                                <div *ngIf="cvFormGroup.get('experiences').value.length && isBeginner" formArrayName="experiences" class="resume_item resume_experience">
                                    <div class="resume_title"> {{'experiences' | translate}}</div>
                                    <div class="resume_info">
                                        <div *ngFor="let experience of cvExperiences().controls; let experienceIndex=index" [formGroupName]="experienceIndex"
                                         class="resume_data">
                                            <div class="year">{{cvExperiences().at(experienceIndex).get('period').value}}
                                                <p style="font-size: 12px;">{{cvExperiences().at(experienceIndex).get('city').value}}</p>
                                            </div>
                                            <div class="content">
                                                <p style="font-size: 14px; font-weight: 600;"  [style.color]="firstcolor">  {{cvExperiences().at(experienceIndex).get('function').value}} </p>
                                                <p style="font-size: 14px; font-weight: 700;text-transform: uppercase;">

                                                    {{cvExperiences().at(experienceIndex).get('company').value}}
                                                   </p>
                                                <p style="font-size: 12px; word-wrap: break-word;" [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>







                                <div *ngIf="cvFormGroup.get('customs').value.length" formArrayName="customs">
                                    <div *ngFor="let custom of cvCustoms().controls; let customIndex=index" [formGroupName]="customIndex" class="resume_item resume_education">
                                        <div class="resume_title">{{cvCustoms().at(customIndex).get('title').value}}</div>
                                        <div class="resume_info">
                                            <div class="resume_data">

                                                <div class="content">

                                                    <p style="font-size: 12px;" [innerHTML]="cvCustoms().at(customIndex).get('description').value">
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>



                                <div *ngIf="cvFormGroup.get('quality1').value.trim() || cvFormGroup.get('quality2').value.trim() ||
                                cvFormGroup.get('quality3').value.trim() || cvFormGroup.get('quality4').value.trim()" class="resume_item resmue_interests">
                                    <div class="resume_title"> {{'qualities' | translate}}</div>
                                    <div class="resume_info">
                                        <div class="interests">

                                            <div class="int_data"> {{cvFormGroup.get('quality1').value}}</div>
                                        </div>
                                        <div class="interests">

                                            <div class="int_data"> {{cvFormGroup.get('quality2').value}}</div>
                                        </div>
                                        <div class="interests">

                                            <div class="int_data"> {{cvFormGroup.get('quality3').value}}</div>
                                        </div>
                                        <div class="interests">

                                            <div class="int_data"> {{cvFormGroup.get('quality4').value}}</div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="cvFormGroup.get('hobby1').value.trim() || cvFormGroup.get('hobby2').value.trim() ||
                                cvFormGroup.get('hobby3').value.trim() || cvFormGroup.get('hobby4').value.trim()" class="resume_item resmue_interests">
                                    <div class="resume_title"> {{'hobbies' | translate}}</div>
                                    <div class="resume_info">
                                        <div class="interests">

                                            <div class="int_data"> {{cvFormGroup.get('hobby1').value}}</div>
                                        </div>
                                        <div class="interests">

                                            <div class="int_data"> {{cvFormGroup.get('hobby2').value}}</div>
                                        </div>
                                        <div class="interests">

                                            <div class="int_data"> {{cvFormGroup.get('hobby3').value}}</div>
                                        </div>
                                        <div class="interests">

                                            <div class="int_data"> {{cvFormGroup.get('hobby4').value}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="left_side1" class="resume_left" >
                                <div class="resume_image">

                                    <img  *ngIf="!imageToShow && imgURL" [src]="imgURL" alt="Resume_image">
                                    <img  *ngIf="imageToShow" [src]="imageToShow" alt="Resume_image">
                                    <div class="text2" id="text2"
                                    style="font-weight: 700;">
                                    <span >{{this.cvFormGroup.get("firstname").value+"
                                        "+this.cvFormGroup.get("lastname").value}}</span>
                                    <p style="color: #fff; font-size: 13px;">
                                        {{this.cvFormGroup.get("profession").value}}</p>

                                     </div>
                                </div>
                                <div *ngIf="cvFormGroup.get('telephone').value.trim()"
                                    style="background-color: #07223a; font-size: 14px; color: #fff; width: 280px; text-align: left;  padding: 9px;"
                                    [style.background]="firstcolor">
                                    <span style="font-weight: 500;"> {{'phone' | translate}} :</span> <span
                                        *ngIf="this.cvFormGroup.get('telephone').value.includes('U2FsdG')">
                                        {{cvFormGroup.get('telephone').value | decrypted}}</span>
                                    <span *ngIf="!this.cvFormGroup.get('telephone').value.includes('U2FsdG')">
                                        {{cvFormGroup.get('telephone').value | encrypted | decrypted}}</span>
                                </div>
                                <div *ngIf="cvFormGroup.get('email').value.trim()"
                                    style="background-color: #0e2e5e; font-size: 14px; color: #fff; width: 280px; text-align: left;  padding: 9px;"
                                    [style.background]="secondcolor">
                                    <span style="font-weight: 500;"> Email :</span> {{cvFormGroup.get('email').value}}

                                </div>
                                <div *ngIf="cvFormGroup.get('address').value.trim() || cvFormGroup.get('city').value.trim()"
                                    style="background-color: #154d7e; font-size: 13px; color: #fff; width: 280px; text-align: left;  padding: 9px;"
                                    [style.background]="pagecolor">
                                    <span *ngIf="this.cvFormGroup.get('address').value.includes('U2FsdG')">
                                        {{cvFormGroup.get('address').value | decrypted}}</span>
                                    <span *ngIf="!this.cvFormGroup.get('address').value.includes('U2FsdG')">
                                        {{cvFormGroup.get('address').value | encrypted | decrypted}}</span>
                                        {{","+cvFormGroup.get('city').value}}
                                </div>
                                <div
                                    style="background-color: #656868; font-size: 15px; color: #fff; width: 280px; text-align: left;  padding: 9px;">
                                    <p style="color: #fff;" *ngIf="cvFormGroup.get('linkedin').value.trim()">
                                        <strong>Linkedin : </strong><span> {{cvFormGroup.get('linkedin').value}}</span>
                                    </p>
                                    <p style="color: #fff;" *ngIf="cvFormGroup.get('webSite').value.trim()">
                                        <strong>Site web : </strong><span> {{cvFormGroup.get('webSite').value}}</span>
                                    </p>
                                    <p style="color: #fff;" *ngIf="cvFormGroup.get('birth').value.trim()"> <span>Né(e)
                                            le {{cvFormGroup.get('birth').value}} </span> </p>
                                    <p style="color: #fff;" *ngIf="cvFormGroup.get('marital').value.trim()"> <span>
                                            {{cvFormGroup.get('marital').value}} </span> </p>
                                </div>

                                <div class="resume_bottom">







                                    <div *ngIf="cvFormGroup.get('skills').value.length" formArrayName="skills" class="resume_item resume_skills">
                                        <div class="resume_title" > {{'skills' | translate}}</div>
                                        <div class="resume_info">
                                            <div *ngFor="let skill of cvSkills().controls; let skillIndex=index" [formGroupName]="skillIndex" class="skills_list">
                                                <div class="skills_left">{{cvSkills().at(skillIndex).get('item').value}}</div>
                                                <div class="skills_bar">
                                                    <p>
                                                        <span [style.width]="cvSkills().at(skillIndex).get('level').value" [style.background]="secondcolor"></span>
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('languages').value.length" formArrayName="languages" class="resume_item resume_skills">
                                        <div class="resume_title" > {{'languages' | translate}}</div>
                                        <div class="resume_info">
                                            <div *ngFor="let lang of cvLanguages().controls; let langIndex=index" [formGroupName]="langIndex" class="skills_list">
                                                <div class="skills_left">{{cvLanguages().at(langIndex).get('item').value}}</div>
                                                <div class="skills_bar">
                                                    <p>
                                                        <span [style.width]="cvLanguages().at(langIndex).get('level').value" [style.background]="secondcolor"></span>
                                                    </p>
                                                </div>
                                            </div>


                                        </div>
                                    </div>


                                    <div *ngIf="cvFormGroup.get('references').value.length" formArrayName="references" class="resume_item resume_contact">
                                        <div class="resume_title" > {{'references' | translate}}</div>
                                        <div *ngFor="let ref of cvReferences().controls; let refIndex=index" [formGroupName]="refIndex" class="resume_info">
                                            <div class="resume_subtitle">{{cvReferences().at(refIndex).get('fullname').value}}</div>
                                            <div class="resume_subinfo">{{cvReferences().at(refIndex).get('organisation').value}}</div>
                                            <div *ngIf="cvReferences().at(refIndex).get('telephone').value.trim()" class="resume_subinfo">
                                                <strong style="color: #1e1f1f;">Tel :</strong> {{cvReferences().at(refIndex).get('telephone').value}}</div>
                                            <div *ngIf="cvReferences().at(refIndex).get('email').value.trim()" class="resume_subinfo"> <strong style="color: #1e1f1f;">Email :</strong> {{cvReferences().at(refIndex).get('email').value}}</div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>



                        <!--  START PAGE 2-->


                        <div class="resume_wrapper" id="content2">

                            <div id="right_side2" class="resume_right" style="padding: 0 ;margin: 0; ">

                            </div>
                            <div id="left_side2" class="resume_left" style="padding: 0; margin: 0;" [style.background]="firstcolor">



                            </div>
                        </div>

                        <!--  END PAGE 2-->

                        <!--  START PAGE 3-->


                        <div class="resume_wrapper" id="content3">
                            <div id="right_side3" class="resume_right" #right_side2 style="padding : 0;">

                            </div>
                            <div id="left_side3" class="resume_left" #left_side2 [style.background]="firstcolor">



                            </div>
                        </div>


                        <!--  END PAGE 3-->



                    </div>

                </div>
            </form>
        </div>

    </div>
</div>

<!--   FORM ARRAY         -->
