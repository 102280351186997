<app-header-style-one></app-header-style-one>

<app-homeone-main-banner></app-homeone-main-banner>

<div class="about-area ptb-100">

    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content left-content">
                    <span class="sub-title">Réussir son entrétien</span>
                    <h2>Préparer son entretien d'embauche </h2>
                    <h6>Quels sont les éléments décisifs qui vous aideront à réussir votre entretien?</h6>
                    <p>Le déroulement de l'entretien est souvent un élément décisif dans le processus de recrutement. Voici quelques éléments qui vous aideront à faire bonne impression.</p>

                    <div class="signature">
                        <img src="assets/img/signature.png" alt="image">
                    </div>

                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-right-image">
                    <img src="assets/img/about/about4.jpg" alt="image">
                    <img src="assets/img/about/about3.jpg" alt="image">

                    <div class="text-box">
                        <div class="inner">
                            Croyez en
                            <span>vous</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    
    <div class="container">


        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Vérifier les informations sur l'entreprise</h3>
                        <p> C'est un élément très important, non seulement parce que la question « Que savez-vous de notre entreprise ? » est une pratique très courante. Développer ses connaissances sur les succès et les défis d'une entreprise ainsi que sur
                            sa concurrence nous permet de mieux nous adapter à sa situation actuelle. Pour répondre aux questions d'un recruteur, il est bon de se référer à la situation factuelle et aux bonnes pratiques du concours.</p>
                        <!--
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Activate Listening</li>
                            <li><i class='bx bx-check'></i> Brilliant minds</li>
                            <li><i class='bx bx-check'></i> Better. Best. Wow!</li>
                            <li><i class='bx bx-check'></i> Branding it better!</li>
                        </ul>
                        -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Comprendre les contours de l'offre</h3>
                        <p>Les annonces de recrutement constituent une sorte de « recueil de connaissances » pour un candidat. C'est parce qu'une publicité bien conçue comprend des informations sur :</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> L'entreprise</li>
                            <li><i class='bx bx-check'></i> Les conditions</li>
                            <li><i class='bx bx-check'></i> L'étendue de la fonction</li>

                        </ul>
                        <p></p>
                        <p>Ce sont les éléments clés d'un poste pour lequel vous postulez. Grâce aux annonces, vous pouvez non seulement en savoir plus sur vos objectifs et vos tâches à un poste donné, mais également sur le type de connaissances et de compétences
                            qui seront prises en compte et vérifiées lors d'un entretien.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>S'adapter avec le profil demandé</h3>
                        <p>Prendre du temps à se préparer et d'être à l'aise avec le profil du poste annoncé. C'est sous cet angle que vous tiendrez une conversation avec un recruteur. Il est d'une importance cruciale que vous puissiez vous référer aux exigences
                            d'un employeur concernant un poste particulier. Une journée de travail avec un employeur sera différente d'une journée de travail avec un autre. En effet, lors d'un entretien, un employeur vérifie si un candidat est capable
                            de se rapporter à la culture et à la structure organisationnelle d'une entreprise.</p>

                    </div>
                </div>
            </div>
        </div>


        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Préparer les réponses aux questions standards</h3>
                        <p> Un grand nombre de questions diverses peuvent apparaître lors d'un entretien. Certains d'entre eux, cependant, apparaissent très fréquemment. C'est pourquoi il vaut la peine d'utiliser ces connaissances et de vous préparer à répondre
                            aux questions.</p>
                        <p>Ils peuvent être les suivants :
                        </p>

                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Parlez moi de vous</li>
                            <li><i class='bx bx-check'></i> Points forts et points faibles</li>
                            <li><i class='bx bx-check'></i> Attentes financières</li>
                            <li><i class='bx bx-check'></i> Parlez-moi de votre expérience professionnelle </li>
                            <li><i class='bx bx-check'></i> Pourquoi voulez-vous changer de travail ?</li>
                            <li><i class='bx bx-check'></i> Pourquoi vous et pas un autre ...</li>
                        </ul>
                        <p>Avant un entretien, planifiez soigneusement votre trajet jusqu'au lieu de la réunion. Les 5 premières minutes détermineront souvent le cours de la conversation ultérieure. La ponctualité indique qu'un candidat sait organiser son
                            temps.
                        </p>

                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Faire le bilan de votre parcours</h3>
                        <p>
                            Rappelez-vous vos réalisations professionnelles. Les questions posées lors d'un entretien de recrutement feront souvent référence à des faits et expériences spécifiques d'un candidat. C'est parce que, de l'avis des experts, les événements et comportements
                            passés sont le meilleur prédicteur des comportements futurs d'un candidat au travail. Par conséquent, c'est une bonne idée de passer du temps à réfléchir sur vos expériences professionnelles.</p>

                        <p> Analysez votre parcours professionnel. Les employeurs accordent beaucoup d'attention au développement d'un candidat. Pour cette raison, avant une réunion, posez-vous deux questions fondamentales : En quoi le travail pour cette
                            organisation est-il cohérent avec votre cheminement de carrière ? Comment pouvez-vous contribuer à l'organisation?</p>


                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Votre apparence compte</h3>
                        <p>Qu'on le veuille ou non, votre apparence sera jugée comme une expression de qui vous êtes et de votre approche de votre travail. Et avant de déchanter et de penser que les entretiens ne sont qu'un défilé de mode ; ils ne le sont
                            pas. Cependant, la réalité est que vos vêtements, vos cheveux et vos chaussures seront tous considérés comme des indicateurs de votre statut, de votre confiance en vous, de la façon dont vous prenez soin de vous et de votre
                            estime de soi. Obtenez la bonne image et vous vous ferez remarquer. Votre employeur potentiel sentira simplement que vous « avez l'air bien » et qu'il peut vous voir au travail. Cependant, si vous vous trompez, vous pourriez
                            avoir du mal à surmonter les idées préconçues négatives. Assurez-vous d'être habillé correctement.
                        </p>
                        <p> Une tenue correcte lors d'un entretien est fondamentale. Vous et votre approche du processus de recrutement êtes immédiatement jugés par la manière dont vous êtes habillé. La tenue professionnelle est l'option la plus sûre pour
                            une première impression.
                        </p>

                    </div>
                </div>
            </div>
        </div>


    </div>
</div>

<!--

<div class="courses-categories-area bg-image pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Courses Categories</span>
            <h2>Browse Trending Categories</h2>
            <a routerLink="/courses-category-style-1" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">View All</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <app-categories-style-one></app-categories-style-one>
    </div>
</div>

<div class="funfacts-area pt-100">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div>

<app-homeone-courses></app-homeone-courses>

<div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-one></app-instructors-style-one>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>

<div class="feedback-area ptb-100">
    <div class="container">
        <app-feedback-style-one></app-feedback-style-one>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Explore News</span>
            <h2>Our Latest Insights</h2>
            <a routerLink="/blog-style-1" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">Read All</span><i class="bx bx-book-reader icon-arrow after"></i></a>
        </div>
        <app-blog></app-blog>
    </div>
</div>
-->
