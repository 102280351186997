
<app-header-style-one></app-header-style-one>
<div class="login-area">
    <div class="row m-0">
         <!--
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="assets/img/login-bg.jpg" alt="image">
            </div>
        </div>
        -->
        <div class="col-lg-12 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <!--
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/black-logo.png" alt="image"></a>
                            </div>
                            -->
                            <h3>Bienvenue! </h3>
                            <p>Nouveau sur iCV?
                                <a routerLink="/enregistrer"> S'enregistrer</a>
                            </p>
                            <p *ngIf="!isLoginFailed && !isLoggedIn">{{loginMessage}}</p>
                            <div class="alert alert-success" *ngIf="isLoggedIn">
                                <i class="bx bx-check"></i> {{loginMessage}}</div>
                            <form [formGroup]="loginFormGroup" >
                                <div class="form-group">
                                    <input class="form-control" type="email" placeholder="Email" formControlName="email">
                                    <div class="alert alert-danger" *ngIf="isSubmitted && formControls.email.errors &&
                                    formControls.email.errors.required">L'email est requis</div>
                                </div>
                                <div class="form-group inner-addon right-addon">
                                    <input  type="password" class="form-control pwd-control"
                                    id="password-field" required placeholder="Mot de passe" formControlName="password" >
                                    <!--
                                    <div
                                    (mousedown)="showPasswordOnPress = !showPasswordOnPress"
                                    (mouseup)="showPasswordOnPress = !showPasswordOnPress">
                                      <i alt="show" class="fas fa-eye eye-show"

                                      [class.hide]="showPasswordOnPress"></i>
                                      <i alt="hide" class="fas fa-eye-slash eye-hide"

                                      [class.hide]="!showPasswordOnPress"></i>
                                    </div>
                                    -->
                                    <div class="alert alert-danger" *ngIf="isSubmitted && formControls.password.errors
                                     && formControls.password.errors.required">
                                        Le mot de passe est requis</div>

                                </div>
                                <p *ngIf="isLoginFailed" class="text text-danger">{{errorMessage}}</p>
                                <button *ngIf="!isClick" type="button"  class="btn-disable" (click)="login()">Se connecter</button>
                                <button *ngIf="isClick" type="button" >
                                    <span role="status" style="color: #f9f6f6;"
                                        class="spinner-border spinner-danger spinner-border-sm"></span>
                                     </button>



                                <div class="forgot-password">
                                    <a routerLink="/send-email">Mot de passe oublié?</a>
                                </div>
                                <div class="connect-with-social">
                                    <span>Ou</span>
                                    <a href="https://userapi.icv-pro.com/user/auth/google/callback">
                                    <button type="button"   class="google"><i class='bx bxl-google' ></i>Se connecter avec Google</button>
                                    </a>

                                    <a href="https://userapi.icv-pro.com/user/auth/linkedin/callback" style="margin-bottom: 12px;">
                                        <button type="button"   class="twitter"><i class='bx bxl-linkedin' ></i>Se connecter avec Linkedin</button>
                                        </a>
                                       <p></p>
                                        <a href="https://userapi.icv-pro.com/user/auth/facebook/callback" style="margin-top: 3px;">
                                            <button type="button"   class="facebook"><i class='bx bxl-facebook' ></i>Se connecter avec Facebook</button>
                                            </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
