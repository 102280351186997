import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageOneComponent } from './components/pages/home-page-one/home-page-one.component';
import { HomePageTwoComponent } from './components/pages/home-page-two/home-page-two.component';
import { HomePageThreeComponent } from './components/pages/home-page-three/home-page-three.component';
import { HomePageFourComponent } from './components/pages/home-page-four/home-page-four.component';
import { HomePageFiveComponent } from './components/pages/home-page-five/home-page-five.component';
import { HomePageSixComponent } from './components/pages/home-page-six/home-page-six.component';
import { HomePageSevenComponent } from './components/pages/home-page-seven/home-page-seven.component';
import { AboutPageOneComponent } from './components/pages/about-page-one/about-page-one.component';
import { AboutPageTwoComponent } from './components/pages/about-page-two/about-page-two.component';
import { InstructorsPageOneComponent } from './components/pages/instructors-page-one/instructors-page-one.component';
import { InstructorsPageTwoComponent } from './components/pages/instructors-page-two/instructors-page-two.component';
import { InstructorsPageThreeComponent } from './components/pages/instructors-page-three/instructors-page-three.component';
import { InstructorsDetailsPageComponent } from './components/pages/instructors-details-page/instructors-details-page.component';
import { EventsPageComponent } from './components/pages/events-page/events-page.component';
import { EventsDetailsPageComponent } from './components/pages/events-details-page/events-details-page.component';
import { GalleryPageComponent } from './components/pages/gallery-page/gallery-page.component';
import { PricingPageComponent } from './components/pages/pricing-page/pricing-page.component';
import { FeedbackPageComponent } from './components/pages/feedback-page/feedback-page.component';
import { PartnerPageComponent } from './components/pages/partner-page/partner-page.component';
import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { BlogGridPageComponent } from './components/pages/blog-grid-page/blog-grid-page.component';
import { BlogRightSidebarPageComponent } from './components/pages/blog-right-sidebar-page/blog-right-sidebar-page.component';
import { BlogFullWidthPageComponent } from './components/pages/blog-full-width-page/blog-full-width-page.component';
import { BlogDetailsPageComponent } from './components/pages/blog-details-page/blog-details-page.component';
import { ShopGridPageComponent } from './components/pages/shop-grid-page/shop-grid-page.component';
import { ShopFullWidthPageComponent } from './components/pages/shop-full-width-page/shop-full-width-page.component';
import { ProductsDetailsPageComponent } from './components/pages/products-details-page/products-details-page.component';
import { CartPageComponent } from './components/pages/cart-page/cart-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { CategoryPageOneComponent } from './components/pages/category-page-one/category-page-one.component';
import { CategoryPageTwoComponent } from './components/pages/category-page-two/category-page-two.component';
import { CategoryPageThreeComponent } from './components/pages/category-page-three/category-page-three.component';
import { CoursesListPageComponent } from './components/pages/courses-list-page/courses-list-page.component';
import { CoursesGridStyleOneColumnsTwoPageComponent } from './components/pages/courses-grid-style-one-columns-two-page/courses-grid-style-one-columns-two-page.component';
import { CoursesGridStyleOneColumnsThreePageComponent } from './components/pages/courses-grid-style-one-columns-three-page/courses-grid-style-one-columns-three-page.component';
import { CoursesGridStyleOneColumnsFourPageComponent } from './components/pages/courses-grid-style-one-columns-four-page/courses-grid-style-one-columns-four-page.component';
import { CoursesGridStyleTwoColumnsTwoPageComponent } from './components/pages/courses-grid-style-two-columns-two-page/courses-grid-style-two-columns-two-page.component';
import { CoursesGridStyleTwoColumnsThreePageComponent } from './components/pages/courses-grid-style-two-columns-three-page/courses-grid-style-two-columns-three-page.component';
import { CoursesGridStyleTwoColumnsFourPageComponent } from './components/pages/courses-grid-style-two-columns-four-page/courses-grid-style-two-columns-four-page.component';
import { CoursesGridStyleThreeColumnsFourPageComponent } from './components/pages/courses-grid-style-three-columns-four-page/courses-grid-style-three-columns-four-page.component';
import { CoursesGridStyleThreeColumnsThreePageComponent } from './components/pages/courses-grid-style-three-columns-three-page/courses-grid-style-three-columns-three-page.component';
import { CoursesGridStyleThreeColumnsTwoPageComponent } from './components/pages/courses-grid-style-three-columns-two-page/courses-grid-style-three-columns-two-page.component';
import { CoursesDetailsPageComponent } from './components/pages/courses-details-page/courses-details-page.component';
import { MyDashboardPageComponent } from './components/pages/my-dashboard-page/my-dashboard-page.component';
import { OrdersPageComponent } from './components/pages/orders-page/orders-page.component';
import { DownloadsPageComponent } from './components/pages/downloads-page/downloads-page.component';
import { EditAddressPageComponent } from './components/pages/edit-address-page/edit-address-page.component';
import { EditAccountPageComponent } from './components/pages/edit-account-page/edit-account-page.component';
import { EditBillingAddressPageComponent } from './components/pages/edit-billing-address-page/edit-billing-address-page.component';
import { EditShippingAddressPageComponent } from './components/pages/edit-shipping-address-page/edit-shipping-address-page.component';
import { RegisterPageComponent } from './components/pages/register-page/register-page.component';
import { CvModeleComponent } from './components/pages/cv-modele/cv-modele.component';
import { CvThemeComponent } from './components/pages/cv-theme/cv-theme.component';
import { CVInterviewComponent } from './components/pages/cv-interview/cv-interview.component';
import { CvHomeComponent } from './components/pages/cv-home/cv-home.component';
import { M1EditComponent } from './components/pages/cv-modele/modeles/m1-edit/m1-edit.component';
import { CvDashboardComponent } from './components/pages/cv-dashboard/cv-dashboard.component';
import { M2EditComponent } from './components/pages/cv-modele/modeles/m2-edit/m2-edit.component';
import { M3EditComponent } from './components/pages/cv-modele/modeles/m3-edit/m3-edit.component';
import { M4EditComponent } from './components/pages/cv-modele/modeles/m4-edit/m4-edit.component';
import { M5EditComponent } from './components/pages/cv-modele/modeles/m5-edit/m5-edit.component';
import { M6EditComponent } from './components/pages/cv-modele/modeles/m6-edit/m6-edit.component';
import { M7EditComponent } from './components/pages/cv-modele/modeles/m7-edit/m7-edit.component';
import { M8EditComponent } from './components/pages/cv-modele/modeles/m8-edit/m8-edit.component';
import { M9EditComponent } from './components/pages/cv-modele/modeles/m9-edit/m9-edit.component';
import { M10EditComponent } from './components/pages/cv-modele/modeles/m10-edit/m10-edit.component';
import { M11EditComponent } from './components/pages/cv-modele/modeles/m11-edit/m11-edit.component';
import { M12EditComponent } from './components/pages/cv-modele/modeles/m12-edit/m12-edit.component';
import { M13EditComponent } from './components/pages/cv-modele/modeles/m13-edit/m13-edit.component';
import { M14EditComponent } from './components/pages/cv-modele/modeles/m14-edit/m14-edit.component';
import { M15EditComponent } from './components/pages/cv-modele/modeles/m15-edit/m15-edit.component';
import { M16EditComponent } from './components/pages/cv-modele/modeles/m16-edit/m16-edit.component';
import { CVRegisterPageComponent } from './components/pages/cv-register-page/cv-register-page.component';
import { CVLoginPageComponent } from './components/pages/cv-login-page/cv-login-page.component';
import { CvTipsComponent } from './components/pages/cv-tips/cv-tips.component';
import { CvPageComponent } from './components/pages/cv-page/cv-page.component';
import { CvSectionsComponent } from './components/pages/cv-sections/cv-sections.component';
import { M1AddComponent } from './components/pages/cv-modele/modeles/m1-add/m1-add.component';
import { M2AddComponent } from './components/pages/cv-modele/modeles/m2-add/m2-add.component';
import { M3AddComponent } from './components/pages/cv-modele/modeles/m3-add/m3-add.component';
import { M4AddComponent } from './components/pages/cv-modele/modeles/m4-add/m4-add.component';
import { M5AddComponent } from './components/pages/cv-modele/modeles/m5-add/m5-add.component';
import { M6AddComponent } from './components/pages/cv-modele/modeles/m6-add/m6-add.component';
import { M7AddComponent } from './components/pages/cv-modele/modeles/m7-add/m7-add.component';
import { M8AddComponent } from './components/pages/cv-modele/modeles/m8-add/m8-add.component';
import { M9AddComponent } from './components/pages/cv-modele/modeles/m9-add/m9-add.component';
import { M10AddComponent } from './components/pages/cv-modele/modeles/m10-add/m10-add.component';
import { M11AddComponent } from './components/pages/cv-modele/modeles/m11-add/m11-add.component';
import { M12AddComponent } from './components/pages/cv-modele/modeles/m12-add/m12-add.component';
import { M13AddComponent } from './components/pages/cv-modele/modeles/m13-add/m13-add.component';
import { M14AddComponent } from './components/pages/cv-modele/modeles/m14-add/m14-add.component';
import { M15AddComponent } from './components/pages/cv-modele/modeles/m15-add/m15-add.component';
import { M16AddComponent } from './components/pages/cv-modele/modeles/m16-add/m16-add.component';
import { CvPrivacyComponent } from './components/pages/cv-privacy/cv-privacy.component';
import { CvFaqComponent } from './components/pages/cv-faq/cv-faq.component';
import { CvConditionComponent } from './components/pages/cv-condition/cv-condition.component';
import { CvAboutComponent } from './components/pages/cv-about/cv-about.component';
import { M17EditComponent } from './components/pages/cv-modele/modeles/m17-edit/m17-edit.component';
import { M18EditComponent } from './components/pages/cv-modele/modeles/m18-edit/m18-edit.component';
import { M19EditComponent } from './components/pages/cv-modele/modeles/m19-edit/m19-edit.component';
import { M20EditComponent } from './components/pages/cv-modele/modeles/m20-edit/m20-edit.component';
import { M21EditComponent } from './components/pages/cv-modele/modeles/m21-edit/m21-edit.component';
import { M22EditComponent } from './components/pages/cv-modele/modeles/m22-edit/m22-edit.component';
import { M23EditComponent } from './components/pages/cv-modele/modeles/m23-edit/m23-edit.component';
import { M24EditComponent } from './components/pages/cv-modele/modeles/m24-edit/m24-edit.component';
import { M17AddComponent } from './components/pages/cv-modele/modeles/m17-add/m17-add.component';
import { M18AddComponent } from './components/pages/cv-modele/modeles/m18-add/m18-add.component';
import { M19AddComponent } from './components/pages/cv-modele/modeles/m19-add/m19-add.component';
import { M20AddComponent } from './components/pages/cv-modele/modeles/m20-add/m20-add.component';
import { M21AddComponent } from './components/pages/cv-modele/modeles/m21-add/m21-add.component';
import { M22AddComponent } from './components/pages/cv-modele/modeles/m22-add/m22-add.component';
import { M23AddComponent } from './components/pages/cv-modele/modeles/m23-add/m23-add.component';
import { M24AddComponent } from './components/pages/cv-modele/modeles/m24-add/m24-add.component';
import { CVGetCodeComponent } from './components/pages/cv-login-page/cv-get-code/cv-get-code.component';
import { CVResetPasswordComponent } from './components/pages/cv-login-page/cv-reset-password/cv-reset-password.component';
import { CvThequeComponent } from './components/pages/cv-cvtheque/cv-cvtheque.component';
import { CvTarifComponent } from './components/pages/cv-tarif/cv-tarif.component';
import { CvTutoComponent } from './components/pages/cv-tuto/cv-tuto.component';
import { CvContactComponent } from './components/pages/cv-contact/cv-contact';
import { CVCheckoutComponent } from './components/pages/cv-checkout/cv-checkout.component';
import { CVLoginSocialMediaPageComponent } from './components/pages/cv-login-page/cv-login-social-media.component';
import { CoverDashboardComponent } from './components/pages/cover-modele/cover-dashboard.component';


import { M2AddCoverComponent } from './components/pages/cover-modele/modeles/m2-add-cover/m2-add-cover.component';
import { M1EditCoverComponent } from './components/pages/cover-modele/modeles/m1-edit-cover/m1-edit-cover.component';
import { M3AddCoverComponent } from './components/pages/cover-modele/modeles/m3-add-cover/m3-add-cover.component';
import { M4AddCoverComponent } from './components/pages/cover-modele/modeles/m4-add-cover/m4-add-cover.component';
import { M5AddCoverComponent } from './components/pages/cover-modele/modeles/m5-add-cover/m5-add-cover.component';
import { M6AddCoverComponent } from './components/pages/cover-modele/modeles/m6-add-cover/m6-add-cover.component';
import { M7AddCoverComponent } from './components/pages/cover-modele/modeles/m7-add-cover/m7-add-cover.component';
import { M8AddCoverComponent } from './components/pages/cover-modele/modeles/m8-add-cover/m8-add-cover.component';
import { M1AddCoverComponent } from './components/pages/cover-modele/modeles/m1-add-cover/m1-add-cover.component';
import { M2EditCoverComponent } from './components/pages/cover-modele/modeles/m2-edit-cover/m2-edit-cover.component';
import { M3EditCoverComponent } from './components/pages/cover-modele/modeles/m3-edit-cover/m3-edit-cover.component';
import { M4EditCoverComponent } from './components/pages/cover-modele/modeles/m4-edit-cover/m4-edit-cover.component';
import { M5EditCoverComponent } from './components/pages/cover-modele/modeles/m5-edit-cover/m5-edit-cover.component';
import { M6EditCoverComponent } from './components/pages/cover-modele/modeles/m6-edit-cover/m6-edit-cover.component';
import { M7EditCoverComponent } from './components/pages/cover-modele/modeles/m7-edit-cover/m7-edit-cover.component';
import { M8EditCoverComponent } from './components/pages/cover-modele/modeles/m8-edit-cover/m8-edit-cover.component';
import { CoverModeleComponent } from './components/pages/cover-modele/modeles/cover-modele.component';
import { CoverThequeComponent } from './components/pages/cover-theque/cover-theque.component';

import { M1Edit2Component } from "./components/pages/cv-cvtheque/modeles/m1-edit/m1-edit.component";
import { M2Edit2Component } from "./components/pages/cv-cvtheque/modeles/m2-edit/m2-edit.component";
import { M3Edit2Component } from "./components/pages/cv-cvtheque/modeles/m3-edit/m3-edit.component";
import { M4Edit2Component } from "./components/pages/cv-cvtheque/modeles/m4-edit/m4-edit.component";
import { M5Edit2Component } from "./components/pages/cv-cvtheque/modeles/m5-edit/m5-edit.component";
import { M6Edit2Component } from "./components/pages/cv-cvtheque/modeles/m6-edit/m6-edit.component";
import { M7Edit2Component } from "./components/pages/cv-cvtheque/modeles/m7-edit/m7-edit.component";
import { M8Edit2Component } from "./components/pages/cv-cvtheque/modeles/m8-edit/m8-edit.component";
import { M9Edit2Component } from "./components/pages/cv-cvtheque/modeles/m9-edit/m9-edit.component";
import { M10Edit2Component } from "./components/pages/cv-cvtheque/modeles/m10-edit/m10-edit.component";
import { M11Edit2Component } from "./components/pages/cv-cvtheque/modeles/m11-edit/m11-edit.component";
import { M12Edit2Component } from "./components/pages/cv-cvtheque/modeles/m12-edit/m12-edit.component";
import { M13Edit2Component } from "./components/pages/cv-cvtheque/modeles/m13-edit/m13-edit.component";
import { M14Edit2Component } from "./components/pages/cv-cvtheque/modeles/m14-edit/m14-edit.component";
import { M15Edit2Component } from "./components/pages/cv-cvtheque/modeles/m15-edit/m15-edit.component";
import { M16Edit2Component } from "./components/pages/cv-cvtheque/modeles/m16-edit/m16-edit.component";
import { M17Edit2Component } from "./components/pages/cv-cvtheque/modeles/m17-edit/m17-edit.component";
import { M18Edit2Component } from "./components/pages/cv-cvtheque/modeles/m18-edit/m18-edit.component";
import { M19Edit2Component } from "./components/pages/cv-cvtheque/modeles/m19-edit/m19-edit.component";
import { M20Edit2Component } from "./components/pages/cv-cvtheque/modeles/m20-edit/m20-edit.component";
import { M21Edit2Component } from "./components/pages/cv-cvtheque/modeles/m21-edit/m21-edit.component";
import { M22Edit2Component } from "./components/pages/cv-cvtheque/modeles/m22-edit/m22-edit.component";
import { M23Edit2Component } from "./components/pages/cv-cvtheque/modeles/m23-edit/m23-edit.component";
import { M24Edit2Component } from "./components/pages/cv-cvtheque/modeles/m24-edit/m24-edit.component";
import { M4EditCover2Component } from './components/pages/cover-theque/modeles/m4-edit-cover/m4-edit-cover.component';
import { M1EditCover2Component } from './components/pages/cover-theque/modeles/m1-edit-cover/m1-edit-cover.component';
import { M2EditCover2Component } from './components/pages/cover-theque/modeles/m2-edit-cover/m2-edit-cover.component';
import { M3EditCover2Component } from './components/pages/cover-theque/modeles/m3-edit-cover/m3-edit-cover.component';
import { M5EditCover2Component } from './components/pages/cover-theque/modeles/m5-edit-cover/m5-edit-cover.component';
import { M6EditCover2Component } from './components/pages/cover-theque/modeles/m6-edit-cover/m6-edit-cover.component';
import { M7EditCover2Component } from './components/pages/cover-theque/modeles/m7-edit-cover/m7-edit-cover.component';
import { M8EditCover2Component } from './components/pages/cover-theque/modeles/m8-edit-cover/m8-edit-cover.component';
import { CvThequeEnvironnementComponent } from './components/pages/cv-cvtheque/theque-environnement/cv-cvtheque.component';
import { CvThequeInformatiqueComponent } from './components/pages/cv-cvtheque/theque-informatique/cv-cvtheque.component';
import { CvThequeBankFinanceComponent } from './components/pages/cv-cvtheque/theque-bank-finance/cv-cvtheque.component';
import { CvThequeIndustryComponent } from './components/pages/cv-cvtheque/theque-industry/cv-cvtheque.component';
import { CvThequeMarketingComponent } from './components/pages/cv-cvtheque/theque-marketing/cv-cvtheque.component';
import { CvThequeBTPComponent } from './components/pages/cv-cvtheque/theque-btp/cv-cvtheque.component';
import { CvThequeCareComponent } from './components/pages/cv-cvtheque/theque-care/cv-cvtheque.component';
import { CvThequeHumanComponent } from './components/pages/cv-cvtheque/theque-human/cv-cvtheque.component';
import { CvThequeOthersComponent } from './components/pages/cv-cvtheque/theque-others/cv-cvtheque.component';
import { CoverThequeEnvironnementComponent } from './components/pages/cover-theque/theque-environnement/cover-theque.component';
import { CoverThequeInformatiqueComponent } from './components/pages/cover-theque/theque-informatique/cover-theque.component';
import { CoverThequeBankFinanceComponent } from './components/pages/cover-theque/theque-bank-finance/cover-theque.component';
import { CoverThequeIndustryComponent } from './components/pages/cover-theque/theque-industry/cover-theque.component';
import { CoverThequeBtpComponent } from './components/pages/cover-theque/theque-btp/cover-theque.component';
import { CoverThequeCareComponent } from './components/pages/cover-theque/theque-care/cover-theque.component';
import { CoverThequeHumanComponent } from './components/pages/cover-theque/theque-human/cover-theque.component';
import { CoverThequeOthersComponent } from './components/pages/cover-theque/theque-other/cover-theque.component';
import { CoverThequeMarketingComponent } from './components/pages/cover-theque/theque-marketing/cover-theque.component';
import { CvsCoverDashboarComponent } from './components/pages/cv-dashboard/cover-dashboard.component';
import { CvsInfoDashboardComponent } from './components/pages/cv-dashboard/info-dashboard.component';
import { CvsSubscriptionInfoDashboardComponent } from './components/pages/cv-dashboard/subscription-dashboard.component';
import { ApproveComponent } from './components/pages/cv-checkout/approve/approve.component';
import { DeclineComponent } from './components/pages/cv-checkout/decline/decline.component';



const routes: Routes = [

    {path: '', component: CvHomeComponent},
    {path: 'faq', component: CvFaqComponent},
    {path: 'privacy', component: CvPrivacyComponent},
    {path: 'conseils', component: CvTipsComponent},
    {path: 'about', component: CvAboutComponent},
    {path: 'page', component: CvPageComponent},
    {path: 'conditions', component: CvConditionComponent},
    {path: 'rubriques', component: CvSectionsComponent},
    {path: 'cvs', component: CvDashboardComponent},
    {path: 'cvs/m1-edit/:id', component: M1EditComponent},
    {path: 'cvs/m2-edit/:id',  component: M2EditComponent},
    {path: 'cvs/m3-edit/:id',  component: M3EditComponent},
    {path: 'cvs/m4-edit/:id',  component: M4EditComponent},
    {path: 'cvs/m5-edit/:id',  component: M5EditComponent},
    {path: 'cvs/m6-edit/:id',  component: M6EditComponent},
    {path: 'cvs/m7-edit/:id',  component: M7EditComponent},
    {path: 'cvs/m8-edit/:id',  component: M8EditComponent},
    {path: 'cvs/m9-edit/:id',  component: M9EditComponent},
    {path: 'cvs/m10-edit/:id',  component: M10EditComponent},
    {path: 'cvs/m11-edit/:id',  component: M11EditComponent},
    {path: 'cvs/m12-edit/:id',  component: M12EditComponent},
    {path: 'cvs/m13-edit/:id',  component: M13EditComponent},
    {path: 'cvs/m14-edit/:id',  component: M14EditComponent},
    {path: 'cvs/m15-edit/:id',  component: M15EditComponent},
    {path: 'cvs/m16-edit/:id',  component: M16EditComponent},
    {path: 'cvs/m17-edit/:id',  component: M17EditComponent},
    {path: 'cvs/m18-edit/:id',  component: M18EditComponent},
    {path: 'cvs/m19-edit/:id',  component: M19EditComponent},
    {path: 'cvs/m20-edit/:id',  component: M20EditComponent},
    {path: 'cvs/m21-edit/:id',  component: M21EditComponent},
    {path: 'cvs/m22-edit/:id',  component: M22EditComponent},
    {path: 'cvs/m23-edit/:id',  component: M23EditComponent},
    {path: 'cvs/m24-edit/:id',  component: M24EditComponent},
    {path: 'modeles', component: CvModeleComponent},
    {path: 'modeles/m1-add', component: M1AddComponent},
    {path: 'modeles/m2-add', component: M2AddComponent},
    {path: 'modeles/m3-add', component: M3AddComponent},
    {path: 'modeles/m4-add', component: M4AddComponent},
    {path: 'modeles/m5-add', component: M5AddComponent},
    {path: 'modeles/m6-add', component: M6AddComponent},
    {path: 'modeles/m7-add', component: M7AddComponent},
    {path: 'modeles/m8-add', component: M8AddComponent},
    {path: 'modeles/m9-add', component: M9AddComponent},
    {path: 'modeles/m10-add', component: M10AddComponent},
    {path: 'modeles/m11-add', component: M11AddComponent},
    {path: 'modeles/m12-add', component: M12AddComponent},
    {path: 'modeles/m13-add', component: M13AddComponent},
    {path: 'modeles/m14-add', component: M14AddComponent},
    {path: 'modeles/m15-add', component: M15AddComponent},
    {path: 'modeles/m16-add', component: M16AddComponent},
    {path: 'modeles/m17-add', component: M17AddComponent},
    {path: 'modeles/m18-add', component: M18AddComponent},
    {path: 'modeles/m19-add', component: M19AddComponent},
    {path: 'modeles/m20-add', component: M20AddComponent},
    {path: 'modeles/m21-add', component: M21AddComponent},
    {path: 'modeles/m22-add', component: M22AddComponent},
    {path: 'modeles/m23-add', component: M23AddComponent},
    {path: 'modeles/m24-add', component: M24AddComponent},
    {path: 'cover-modeles/m1-add-cover', component: M1AddCoverComponent},

    {path: 'cover-modeles/m2-add-cover', component: M2AddCoverComponent},
    {path: 'cover-modeles/m3-add-cover', component: M3AddCoverComponent},
    {path: 'cover-modeles/m4-add-cover', component: M4AddCoverComponent},
    {path: 'cover-modeles/m5-add-cover', component: M5AddCoverComponent},
    {path: 'cover-modeles/m6-add-cover', component: M6AddCoverComponent},
    {path: 'cover-modeles/m7-add-cover', component: M7AddCoverComponent},
    {path: 'cover-modeles/m8-add-cover', component: M8AddCoverComponent},

    {path: 'cvs/m1-edit-cover/:id', component: M1EditCoverComponent},
    {path: 'cvs/m2-edit-cover/:id', component: M2EditCoverComponent},
    {path: 'cvs/m3-edit-cover/:id', component: M3EditCoverComponent},
    {path: 'cvs/m4-edit-cover/:id', component: M4EditCoverComponent},
    {path: 'cvs/m5-edit-cover/:id', component: M5EditCoverComponent},
    {path: 'cvs/m6-edit-cover/:id', component: M6EditCoverComponent},
    {path: 'cvs/m7-edit-cover/:id', component: M7EditCoverComponent},
    {path: 'cvs/m8-edit-cover/:id', component: M8EditCoverComponent},


    {path: 'cvtheque/m1-edit/:id', component: M1Edit2Component},
    {path: 'cvtheque/m2-edit/:id',  component: M2Edit2Component},
    {path: 'cvtheque/m3-edit/:id',  component: M3Edit2Component},
    {path: 'cvtheque/m4-edit/:id',  component: M4Edit2Component},
    {path: 'cvtheque/m5-edit/:id',  component: M5Edit2Component},
    {path: 'cvtheque/m6-edit/:id',  component: M6Edit2Component},
    {path: 'cvtheque/m7-edit/:id',  component: M7Edit2Component},
    {path: 'cvtheque/m8-edit/:id',  component: M8Edit2Component},
    {path: 'cvtheque/m9-edit/:id',  component: M9Edit2Component},
    {path: 'cvtheque/m10-edit/:id',  component: M10Edit2Component},
    {path: 'cvtheque/m11-edit/:id',  component: M11Edit2Component},
    {path: 'cvtheque/m12-edit/:id',  component: M12Edit2Component},
    {path: 'cvtheque/m13-edit/:id',  component: M13Edit2Component},
    {path: 'cvtheque/m14-edit/:id',  component: M14Edit2Component},
    {path: 'cvtheque/m15-edit/:id',  component: M15Edit2Component},
    {path: 'cvtheque/m16-edit/:id',  component: M16Edit2Component},
    {path: 'cvtheque/m17-edit/:id',  component: M17Edit2Component},
    {path: 'cvtheque/m18-edit/:id',  component: M18Edit2Component},
    {path: 'cvtheque/m19-edit/:id',  component: M19Edit2Component},
    {path: 'cvtheque/m20-edit/:id',  component: M20Edit2Component},
    {path: 'cvtheque/m21-edit/:id',  component: M21Edit2Component},
    {path: 'cvtheque/m22-edit/:id',  component: M22Edit2Component},
    {path: 'cvtheque/m23-edit/:id',  component: M23Edit2Component},
    {path: 'cvtheque/m24-edit/:id',  component: M24Edit2Component},

    {path: 'cvtheque/environnement',  component: CvThequeEnvironnementComponent},
    {path: 'cvtheque/informatique',  component: CvThequeInformatiqueComponent},
    {path: 'cvtheque/bank-finance',  component: CvThequeBankFinanceComponent},
    {path: 'cvtheque/industry',  component: CvThequeIndustryComponent},
    {path: 'cvtheque/marketing',  component: CvThequeMarketingComponent},
    {path: 'cvtheque/btp',  component: CvThequeBTPComponent},
    {path: 'cvtheque/health',  component: CvThequeCareComponent},
    {path: 'cvtheque/human',  component: CvThequeHumanComponent},
    {path: 'cvtheque/others',  component: CvThequeOthersComponent},

    {path: 'cover-letter/examples/environnement',  component: CoverThequeEnvironnementComponent},
    {path: 'cover-letter/examples/informatique',  component: CoverThequeInformatiqueComponent},
    {path: 'cover-letter/examples/bank-finance',  component: CoverThequeBankFinanceComponent},
    {path: 'cover-letter/examples/industry',  component: CoverThequeIndustryComponent},
    {path: 'cover-letter/examples/marketing',  component: CoverThequeMarketingComponent},
    {path: 'cover-letter/examples/btp',  component: CoverThequeBtpComponent},
    {path: 'cover-letter/examples/health',  component: CoverThequeCareComponent},
    {path: 'cover-letter/examples/human',  component: CoverThequeHumanComponent},
    {path: 'cover-letter/examples/others',  component: CoverThequeOthersComponent},




    {path: 'entretien', component: CVInterviewComponent},
    {path: 'tarification', component: CvTarifComponent},
    {path: 'cvtheque', component: CvThequeComponent},
    {path: 'tutoriel', component: CvTutoComponent},
    {path: 'contact', component: CvContactComponent},
    {path: 's-abonner', component: CVCheckoutComponent},
    {path: 'approve', component: ApproveComponent},
    {path: 'decline', component: DeclineComponent},
    {path: 'cover-letter', component: CoverDashboardComponent},
    {path: 'cvs/cover_letters', component: CvsCoverDashboarComponent},
    {path: 'cvs/information', component: CvsInfoDashboardComponent},
    {path: 'cvs/subscription', component: CvsSubscriptionInfoDashboardComponent},


    {path: 'cover-modeles', component: CoverModeleComponent},
    {path: 'cover-letter/examples', component:  CoverThequeComponent},

    {path: 'cover-letter/examples/m1-edit-cover/:id', component:  M1EditCover2Component},
    {path: 'cover-letter/examples/m2-edit-cover/:id', component:  M2EditCover2Component},
    {path: 'cover-letter/examples/m3-edit-cover/:id', component:  M3EditCover2Component},
    {path: 'cover-letter/examples/m4-edit-cover/:id', component:  M4EditCover2Component},
    {path: 'cover-letter/examples/m5-edit-cover/:id', component:  M5EditCover2Component},
    {path: 'cover-letter/examples/m6-edit-cover/:id', component:  M6EditCover2Component},
    {path: 'cover-letter/examples/m7-edit-cover/:id', component:  M7EditCover2Component},
    {path: 'cover-letter/examples/m8-edit-cover/:id', component:  M8EditCover2Component},



   // {path: 'themes/:idModele', component: CvThemeComponent},
    //{path: 'register', component: RegisterPageComponent},
    {path: 'enregistrer', component: CVRegisterPageComponent},
   // {path: 'login', component: LoginPageComponent},
    {path: 'se-connecter', component: CVLoginPageComponent},
    //{path: 'login-social-media', component: CVLoginSocialMediaPageComponent},
    {path: 'login-social-media/:token', component: CVLoginSocialMediaPageComponent},
    {path: 'send-email', component: CVGetCodeComponent},
    {path: 'reset-password', component: CVResetPasswordComponent},



   // {path: 'events', component: EventsPageComponent},
   //{path: 'single-events', component: EventsDetailsPageComponent},
   // {path: 'pricing', component: PricingPageComponent},
   // {path: 'feedback', component: FeedbackPageComponent},
   // {path: 'partner', component: PartnerPageComponent},
   // {path: 'faqs', component: FaqPageComponent},
  {path: 'error-404', component: ErrorPageComponent},
  //  {path: 'coming-soon', component: ComingSoonPageComponent},

    { path: '',   redirectTo: '/', pathMatch: 'full' },
    { path: '**', pathMatch: 'full',
    component: ErrorPageComponent },
    { path: '**',   redirectTo: '/error-404', pathMatch: 'full' },

];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
