import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cover-notice2',
  templateUrl: './cover-notice2.component.html'

})
export class CoverNotice2Component implements OnInit {

  constructor() { }



  ngOnInit(): void {



  }




}
