import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from "@angular/core";
import jsPDF from "jspdf";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";


import { from, Observable, Observer } from "rxjs";
import { map } from "rxjs/operators";
import {
    DataUrl,
    DOC_ORIENTATION,
    NgxImageCompressService,
    UploadResponse,
} from "ngx-image-compress";
import { Cv } from "./cv";
import * as $ from "jquery";
import html2canvas from "html2canvas";
import { ActivatedRoute, Route } from "@angular/router";
import { Modele1Service } from "./modele1.service";

@Component({
    selector: "modele0",
    templateUrl: "./modele1.component.html",
    styleUrls: ["./modele1.component.scss"],
})
export class Modele1Component implements OnInit, AfterViewInit {
    cvFormGroup: FormGroup;

    isIcon: boolean = false;
    listSpinner: boolean = false;
    isLoading: boolean = false;

    @Input()
    cv : Cv;

    constructor(
        private modele1Service: Modele1Service,
        private imageCompress: NgxImageCompressService,
        private fb: FormBuilder,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.listSpinner = true;
        this.isIcon = false;
        this.findOne(this.cv.id);

    }

    ngAfterViewInit(): void {}


    cvs = [];
    skills: Array<any> = [];
    spin1: boolean = false;
    spin2: boolean = false;
    isSave: boolean = true;
    isOpen: boolean = true;




    initCV2(cv: Cv): void {
        this.cvFormGroup = this.fb.group({
            id: [cv.id],
            photo: [cv.photo],
            firstname: [cv.firstname],
            lastname: [cv.lastname],
            profession: [cv.profession],
            profile: [cv.profile],
            telephone: [cv.telephone],
            email: [cv.email],
            linkedin: [cv.linkedin],
            webSite: [cv.webSite],
            address: [cv.address],
            city: [cv.city],
            country: [cv.country],

            hobby1: [cv.hobby1],
            hobby2: [cv.hobby2],
            hobby3: [cv.hobby3],
            hobby4: [cv.hobby4],
            skills: this.fb.array([]),
            experiences: this.fb.array([]),
            educations: this.fb.array([]),
            languages: this.fb.array([]),
            flag: [cv.flag],
        });
    }

    fileFormGroup: FormGroup;
    initFile(): void {
        this.fileFormGroup = this.fb.group({
            file: [""],
        });
    }

    cvSkills(): FormArray {
        return this.cvFormGroup.get("skills") as FormArray;
    }

    cvExperiences(): FormArray {
        return this.cvFormGroup.get("experiences") as FormArray;
    }

    cvEducations(): FormArray {

        return this.cvFormGroup.get("educations") as FormArray;
    }

    cvLanguages(): FormArray {
        return this.cvFormGroup.get("languages") as FormArray;
    }

    initCvSkill(): FormGroup {
        return this.fb.group({
            id: [null],
            item: [""],
            level: ["50%"],
        });
    }

    initCvSkill2(skill): FormGroup {
        return this.fb.group({
            id: [skill.id],
            item: [skill.item],
            level: [skill.level],
        });
    }

    initCvExperience(): FormGroup {
        return this.fb.group({
            id: [null],
            index: [0],
            period: [""],
            company: [""],
            city: [""],
            function: [""],
            description: [""],
        });
    }

    initCvExperience2(experience): FormGroup {
        return this.fb.group({
            id: [experience.id],
            index: [experience.index],
            period: [experience.period],
            company: [experience.company],
            city: [experience.city],
            function: [experience.function],
            description: [experience.description],
        });
    }

    initCvEducation(): FormGroup {
        return this.fb.group({
            id: [null],
            index: [0],
            period: [""],
            institute: [""],
            city: [""],
            country: [""],
            degree: [""],
            domain: [""],
        });
    }
    initCvEducation2(education): FormGroup {
        return this.fb.group({
            id: [education.id],
            index: [education.index],
            period: [education.period],
            institute: [education.institute],
            city: [education.city],
            country: [education.country],
            degree: [education.degree],
            domain: [education.domain],
        });
    }

    initCvLanguage(): FormGroup {
        return this.fb.group({
            id: [null],
            item: [""],
            level: ["50%"],
        });
    }

    initCvLanguage2(lang): FormGroup {
        return this.fb.group({
            id: [lang.id],
            item: [lang.item],
            level: [lang.level],
        });
    }



    selectedFiles: FileList;
    currentFileUpload: File;
    files: any = [];
    myFiles: FileList;
    public imagePath;
    imgURL: any;
    url: any;
    @ViewChild("photo", { static: false }) photo!: ElementRef;

    isDeleteFile: boolean = false;
    isSetFile: boolean = false;
    file: any;
    localUrl: any;
    localCompressedURl: any;
    sizeOfOriginalImage: number;
    sizeOFCompressedImage: number;
    mimeType: any;
    imgResultBeforeCompress;
    imgResultAfterCompress;
    src: any;

    //



    isSuccess: boolean = false;




    sortBy(FieldName: string , myItems) {

        myItems.setValue(myItems.value.sort((a, b) => a[FieldName] - b[FieldName]))
      }

    findOne(id) {
        this.modele1Service.findOne(id).subscribe(
            (data) => {
                this.cv = data;

                this.initCV2(data);
                data.skills.forEach((el) => {
                    this.cvSkills().push(this.initCvSkill2(el));
                });

                data.experiences.forEach((el) => {
                    this.cvExperiences().push(this.initCvExperience2(el));
                });

                data.educations.forEach((el) => {
                    this.cvEducations().push(this.initCvEducation2(el));
                });

                data.languages.forEach((el) => {
                    this.cvLanguages().push(this.initCvLanguage2(el));
                });
            },
            (error) => {
                alert("Informations invalides");
            },
            () => {
                this.sortBy('index', this.cvEducations());
                this.sortBy('index', this.cvExperiences());
                if (this.cv.photo) {
                    this.showImage(this.cv.photo);
                }

                this.showImage2('assets/img/professional1.png');

            }
        );
    }

    imageToShow: any = null;

    showImage(photoname: string) : any{
        this.modele1Service
            .fetchImageById(photoname)

            .subscribe(
                (image) => {
                    this.createImage(image);
                },
                (err) => {
                    this.handleImageRetrievalError(err);
                },
                () => {

                }
            );


    }




    private handleImageRetrievalError(err: Error) {
        console.error(err);

        //alert("Problem retrieving profile photo.");
    }

    createImage(image: Blob) {
        if (image && image.size > 0) {
            let reader = new FileReader();
            reader.addEventListener(
                "load",
                () => {
                    this.imageToShow = reader.result;
                    // this.showSpinner = false;
                },
                false
            );

            reader.readAsDataURL(image);
        } else {
            //this.showSpinner = false;
        }
    }

    imageToShow2 :any;
    showImage2(photoname: string) {
        this.modele1Service
            .fetchImageById2(photoname)

            .subscribe(
                (image) => {
                    this.createImage2(image);
                },
                (err) => {
                    this.handleImageRetrievalError(err);
                },
                () => {

                }
            );
    }

    createImage2(image: Blob) {
        if (image && image.size > 0) {
            let reader = new FileReader();
            reader.addEventListener(
                "load",
                () => {
                    this.imageToShow2 = reader.result;
                    // this.showSpinner = false;

                },
                false
            );

            reader.readAsDataURL(image);

        } else {
            //this.showSpinner = false;
        }
    }




}
