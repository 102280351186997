<loading *ngIf="isLoading && cv">{{ isLoading }}</loading>


<div id="cvt"  class="container-fluid" style="height: 100%; width: 100%;" *ngIf="this.cv">
    <div class="row" >


        <div id="cvt1" class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="height: 100%;  ">

            <cv-notice2> </cv-notice2>

        </div>
        <!--   CV TEMPLATE -->

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

            <div class="container">
                <div class="user-actions" style="background: #47494b; padding-top : 7px; padding-bottom: 7px; height : 50px">


                    <div id="content_2" class="row">


                        <div style="visibility: hidden; max-height: 3px;"  class="col-lg-1 col-md-1 col-sm-1 icon1">
                            <div #iconselect2  id="my-icon-select2" style="margin: 0;"></div>

                            <input #selectedText2 type="text" id="selected-text2" name="selected-text2"
                                style="width:65px; height:2px; visibility: hidden;">
                        </div>


                        <div style="visibility: hidden; max-height: 3px;"  class="col-lg-1 col-md-1 col-sm-1 icon1" >
                            <div #iconselect (click)="onChangeTheme()" id="my-icon-select" style="margin: 0;"></div>
                            <label style="margin-left:3px ; color: #fff; font-weight: 400;">
                                {{'themes' |
                                translate}}
                            </label>
                            <input #selectedText type="text" id="selected-text" name="selected-text"
                                style="width:65px; height: 3px; visibility: hidden;">
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2"></div>


                        <div class="col-lg-4 col-md-4 col-sm-4"  >
                            <button *ngIf="isOpen" type="button" class=" form-control btn btn-sm btn-secondary"
                            (click)="useExample(cv)"
                            style="padding: 7px; font-size: 14px; color: #fff; background: #003147; height: 33px;">
                            <i style="color: #fff; font-size: 15px;" class="bx bxs-duplicate"></i>
                            {{'usethiscv' | translate}}
                        </button>
                        <button *ngIf="!isOpen" type="button" class=" form-control btn "
                        style="padding: 7px; font-size: 12px;  height: 30px;width: 30px;">
                        <span role="status" style="color: #003147;"
                            class="spinner-border spinner-danger spinner-border-sm"></span>
                    </button>
                        </div>

                        <div class="col-lg-2 col-md-2 col-sm-2"></div>

                        <div class="col-lg-2 col-md-2 col-sm-2"></div>


                    </div>


                </div>


            </div>

            <!-- CV RESUME-->

            <form [formGroup]="cvFormGroup">

                <div id="cvt2" class="div_body"  [ngClass]="family1">

                    <div class="marge cvt20">


                        <div class="wrapper">
                            <!--  START PAGE 1-->
                            <div class="resume_design" id="content1">
                                <div class="resume_left" id="left_side1">
                                    <div *ngIf="cvFormGroup.get('profile').value.trim()" class="about_sec">
                                        <div class="button" [style.color]="secondcolor"
                                            [style.border]="'solid 2px '+secondcolor">{{'profile' | translate}}</div>
                                        <p style="font-size: 12px; word-wrap: break-word;">

                                            {{this.cvFormGroup.get("profile").value}}
                                        </p>

                                    </div>


                                    <!-- EDUCATION BEGINNER -->

                                    <div *ngIf="cvFormGroup.get('educations').value.length && isBeginner" formArrayName="educations"
                                        class="edu_sec">
                                        <div class="button" [style.color]="secondcolor"
                                            [style.border]="'solid 2px '+secondcolor">Education</div>
                                        <ul *ngFor="let education of cvEducations().controls; let educationIndex=index">
                                            <li [formGroupName]="educationIndex">
                                                <div class="item">
                                                    <div class="item_grp">
                                                        <p class="title" style="font-size: 14px;">
                                                            {{cvEducations().at(educationIndex).get('degree').value}}
                                                        </p>
                                                        <p class="sub_title"><strong>
                                                                {{cvEducations().at(educationIndex).get('institute').value}}

                                                            </strong> <span>{{" ,
                                                                "+cvEducations().at(educationIndex).get('city').value}}</span>
                                                        </p>
                                                        <span class="timeline"
                                                            [style.color]="secondcolor">{{cvEducations().at(educationIndex).get('period').value}}</span>
                                                    </div>

                                                </div>
                                                <br />
                                            </li>

                                        </ul>
                                    </div>

                                    <!-- EXPERIENCE PROFESSIONAL -->
                                    <div *ngIf="cvFormGroup.get('experiences').value.length && isProfessional" formArrayName="experiences"
                                        class="exp_sec">
                                        <div class="button" [style.color]="secondcolor"
                                            [style.border]="'solid 2px '+secondcolor">{{'experiences' | translate}}
                                        </div>
                                        <ul
                                            *ngFor="let experience of cvExperiences().controls; let experienceIndex=index">
                                            <li [formGroupName]="experienceIndex">
                                                <div class="item">
                                                    <div class="item_grp">
                                                        <p class="title" style="font-size: 14px;">
                                                            {{cvExperiences().at(experienceIndex).get('function').value}}
                                                        </p>
                                                        <p class="sub_title"> <span
                                                                style="text-transform: uppercase; font-weight: 600; font-size: 12px;">
                                                                {{cvExperiences().at(experienceIndex).get('company').value}}
                                                            </span> <span
                                                                *ngIf="cvExperiences().at(experienceIndex).get('city').value.trim()">;
                                                                {{cvExperiences().at(experienceIndex).get('city').value}}
                                                            </span></p>
                                                        <span class="timeline"
                                                            [style.color]="secondcolor">{{cvExperiences().at(experienceIndex).get('period').value}}</span>
                                                    </div>
                                                    <p class="content" style="color: #2d3032; font-size: 12px;"
                                                        [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">
                                                    </p>

                                                </div>
                                                <br />
                                            </li>

                                        </ul>
                                    </div>


                                    <div *ngIf="cvFormGroup.get('certifications').value.length"
                                        formArrayName="certifications" class="edu_sec">
                                        <div class="button" [style.color]="secondcolor"
                                            [style.border]="'solid 2px '+secondcolor">{{'certificates' | translate}}
                                        </div>
                                        <ul *ngFor="let certif of cvCertifications().controls; let certifIndex=index">
                                            <li [formGroupName]="certifIndex">
                                                <div class="item">
                                                    <div class="item_grp">
                                                        <p class="title" style="font-size: 14px;">
                                                            {{cvCertifications().at(certifIndex).get('certificat').value}}
                                                        </p>
                                                        <p class="sub_title"><strong>
                                                                {{cvCertifications().at(certifIndex).get('institute').value}}</strong><span></span>
                                                        </p>
                                                        <span class="timeline"
                                                            [style.color]="secondcolor">{{cvCertifications().at(certifIndex).get('date').value}}</span>
                                                    </div>

                                                </div>
                                                <br />
                                            </li>

                                        </ul>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('formations').value.length" formArrayName="formations"
                                        class="edu_sec">
                                        <div class="button" [style.color]="secondcolor"
                                            [style.border]="'solid 2px '+secondcolor">Formations</div>
                                        <ul *ngFor="let formation of cvFormations().controls; let formationIndex=index">
                                            <li [formGroupName]="formationIndex">
                                                <div class="item">
                                                    <div class="item_grp">
                                                        <p class="title" style="font-size: 14px;">
                                                            {{cvFormations().at(formationIndex).get('title').value}}</p>
                                                        <p class="sub_title">
                                                            <strong>{{cvFormations().at(formationIndex).get('former').value}}

                                                            </strong><span></span> </p>
                                                        <span class="timeline"
                                                            [style.color]="secondcolor">{{cvFormations().at(formationIndex).get('period').value}}</span>
                                                    </div>
                                                    <p class="content" style="color: #323537; font-size: 12px;"
                                                        [innerHTML]="cvFormations().at(formationIndex).get('description').value">
                                                    </p>
                                                </div>
                                                <br />
                                            </li>

                                        </ul>
                                    </div>

                                    <!-- EDUCATION PROFESSIONAL -->

                                    <div *ngIf="cvFormGroup.get('educations').value.length && isProfessional" formArrayName="educations"
                                        class="edu_sec">
                                        <div class="button" [style.color]="secondcolor"
                                            [style.border]="'solid 2px '+secondcolor">Education</div>
                                        <ul *ngFor="let education of cvEducations().controls; let educationIndex=index">
                                            <li [formGroupName]="educationIndex">
                                                <div class="item">
                                                    <div class="item_grp">
                                                        <p class="title" style="font-size: 14px;">
                                                            {{cvEducations().at(educationIndex).get('degree').value}}
                                                        </p>
                                                        <p class="sub_title"><strong>
                                                                {{cvEducations().at(educationIndex).get('institute').value}}

                                                            </strong> <span>{{" ,
                                                                "+cvEducations().at(educationIndex).get('city').value}}</span>
                                                        </p>
                                                        <span class="timeline"
                                                            [style.color]="secondcolor">{{cvEducations().at(educationIndex).get('period').value}}</span>
                                                    </div>

                                                </div>
                                                <br />
                                            </li>

                                        </ul>
                                    </div>

                                    <!-- EXPERIENCE BEGINNER -->
                                    <div *ngIf="cvFormGroup.get('experiences').value.length && isBeginner" formArrayName="experiences"
                                        class="exp_sec">
                                        <div class="button" [style.color]="secondcolor"
                                            [style.border]="'solid 2px '+secondcolor">{{'experiences' | translate}}
                                        </div>
                                        <ul
                                            *ngFor="let experience of cvExperiences().controls; let experienceIndex=index">
                                            <li [formGroupName]="experienceIndex">
                                                <div class="item">
                                                    <div class="item_grp">
                                                        <p class="title" style="font-size: 14px;">
                                                            {{cvExperiences().at(experienceIndex).get('function').value}}
                                                        </p>
                                                        <p class="sub_title"> <span
                                                                style="text-transform: uppercase; font-weight: 600; font-size: 12px;">
                                                                {{cvExperiences().at(experienceIndex).get('company').value}}
                                                            </span> <span
                                                                *ngIf="cvExperiences().at(experienceIndex).get('city').value.trim()">;
                                                                {{cvExperiences().at(experienceIndex).get('city').value}}
                                                            </span></p>
                                                        <span class="timeline"
                                                            [style.color]="secondcolor">{{cvExperiences().at(experienceIndex).get('period').value}}</span>
                                                    </div>
                                                    <p class="content" style="color: #2d3032; font-size: 12px;"
                                                        [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">
                                                    </p>

                                                </div>
                                                <br />
                                            </li>

                                        </ul>
                                    </div>




                                    <div *ngIf="cvFormGroup.get('customs').value.length" formArrayName="customs">
                                        <div *ngFor="let custom of cvCustoms().controls; let customIndex=index"
                                            [formGroupName]="customIndex" class="edu_sec">

                                            <div class="button" [style.color]="secondcolor"
                                                [style.border]="'solid 2px '+secondcolor">
                                                {{cvCustoms().at(customIndex).get('title').value}}</div>
                                            <ul>
                                                <li>
                                                    <div class="item">

                                                        <p class="content" style="color: #323537; font-size: 12px;"
                                                            [innerHTML]="cvCustoms().at(customIndex).get('description').value">
                                                        </p>
                                                    </div>
                                                    <br />
                                                </li>

                                            </ul>
                                        </div>
                                    </div>




                                </div>

                                <div class="resume_right" id="right_side1" [style.background]="firstcolor">
                                    <div class="profile_sec">

                                        <div class="img_holder" style="margin-bottom: 7px;">

                                            <img *ngIf="!imageToShow && imgURL" [src]="imgURL"
                                                style="border-radius: 50%; margin-right: -12px;" alt="profile image">
                                            <img *ngIf="imageToShow" [src]="imageToShow" style="border-radius: 50%;"
                                                alt="profile image">
                                        </div>
                                        <div class="profile_info" style="margin-top: -2px;">

                                            <p class="first_name" [style.color]="pagecolor">
                                               <span> {{this.cvFormGroup.get("firstname").value}}</span>
                                                <span style="font-weight: 600; ">
                                                    {{this.cvFormGroup.get("lastname").value }}</span>
                                            </p>

                                            <p class="role" style="color: #fff; margin-top: -3px; ">
                                                <span>
                                                {{this.cvFormGroup.get("profession").value}}
                                             </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('telephone').value.trim() || cvFormGroup.get('email').value.trim() ||
                                    cvFormGroup.get('address').value.trim() || cvFormGroup.get('linkedin').value.trim() ||
                                     cvFormGroup.get('birth').value.trim() || cvFormGroup.get('webSite').value.trim() ||
                                     cvFormGroup.get('marital').value.trim() ||
                                      cvFormGroup.get('city').value.trim()" class="contact_sec" [style.background]="secondcolor">

                                        <div class="button">{{'personalofdetails' | translate}}<span
                                                style="color: white;">_</span>{{'detailsofpersonal' | translate}}</div>

                                        <ul>
                                            <li class="item">

                                                <div class="content">

                                                    <p *ngIf="this.cvFormGroup.get('telephone').value && !this.cvFormGroup.get('telephone').value.includes('U2FsdG')"
                                                        class="subtitle" style="font-size: 12px;"> <strong
                                                            style="font-weight: 600;">Tel: </strong> <span>
                                                        {{cvFormGroup.get('telephone').value | encrypted | decrypted}}
                                                    </span>
                                                    </p>
                                                    <p *ngIf="this.cvFormGroup.get('telephone').value.includes('U2FsdG')"
                                                        class="subtitle" style="font-size: 12px;"> <strong
                                                            style="font-weight: 600;">Tel: </strong>
                                                        {{cvFormGroup.get('telephone').value | decrypted}}</p>

                                                </div>
                                            </li>
                                            <li class="item">

                                                <div class="content">

                                                    <p *ngIf="cvFormGroup.get('email').value" class="subtitle" style="font-size: 12px; "><strong>Email:
                                                        </strong> <span> {{cvFormGroup.get('email').value}} </span></p>
                                                </div>
                                            </li>
                                            <li *ngIf="cvFormGroup.get('linkedin').value.trim()" class="item">

                                                <div class="content">

                                                    <p class="subtitle" style="font-size: 12px;"> <strong>Linkedin:
                                                        </strong> <span> {{cvFormGroup.get('linkedin').value}} </span></p>
                                                </div>

                                            </li>
                                            <li *ngIf="cvFormGroup.get('webSite').value.trim()" class="item">

                                                <div class="content">

                                                    <p class="subtitle" style="font-size: 12px;"> <strong>{{'website' |
                                                            translate}}: </strong> <span> {{cvFormGroup.get('webSite').value}}</span>
                                                    </p>
                                                </div>

                                            </li>
                                            <li *ngIf="cvFormGroup.get('address').value.trim() || cvFormGroup.get('city').value.trim()" class="item">

                                                <div class="content">

                                                    <p *ngIf="this.cvFormGroup.get('address').value.includes('U2FsdG')"
                                                        class="subtitle" style="font-size: 12px;"> <strong>{{'address' |
                                                            translate}}: </strong> <span> {{cvFormGroup.get('address').value |
                                                        decrypted}} </span></p>
                                                    <p *ngIf="!this.cvFormGroup.get('address').value.includes('U2FsdG')"
                                                        class="subtitle" style="font-size: 12px;"> <strong>{{'address' |
                                                            translate}}: </strong> <span> {{cvFormGroup.get('address').value |
                                                        encrypted | decrypted}}</span></p>
                                                    <p *ngIf="cvFormGroup.get('city').value.trim()"
                                                        style="font-size: 12px; margin-left: 53px;">
                                                        <span>
                                                        {{cvFormGroup.get('city').value}}
                                                    </span>
                                                    </p>
                                                </div>

                                            </li>
                                            <li *ngIf="cvFormGroup.get('birth').value.trim()" class="item">

                                                <div class="content">

                                                    <p class="subtitle" style="font-size: 12px;"> <strong>{{'birth' |
                                                            translate}} : </strong>
                                                            <span>
                                                            {{cvFormGroup.get('birth').value}}
                                                        </span>
                                                    </p>
                                                </div>

                                            </li>
                                            <li *ngIf="cvFormGroup.get('marital').value.trim()" class="item">

                                                <div class="content">

                                                    <p class="subtitle" style="font-size: 12px;">
                                                        <span>
                                                        {{cvFormGroup.get('marital').value}}
                                                    </span>
                                                    </p>
                                                </div>

                                            </li>

                                        </ul>

                                    </div>

                                    <div *ngIf="cvFormGroup.get('skills').value.length" formArrayName="skills"
                                        class="skills_sec">
                                        <div class="button">{{'skills' | translate}}</div>
                                        <ul *ngFor="let skill of cvSkills().controls; let skillIndex=index">
                                            <li [formGroupName]="skillIndex" class="item">
                                                <div class="content">{{cvSkills().at(skillIndex).get('item').value}}
                                                </div>
                                                <div class="bar_wrap">
                                                    <p class="bar" [style.background]="pagecolor"
                                                        [style.width]="cvSkills().at(skillIndex).get('level').value">
                                                    </p>
                                                </div>
                                            </li>


                                        </ul>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('languages').value.length" class="skills_sec">
                                        <div class="button">{{'languages' | translate}}</div>
                                        <ul *ngFor="let lang of cvLanguages().controls; let langIndex=index">
                                            <li [formGroupName]="langIndex" class="item">
                                                <div class="content">{{cvLanguages().at(langIndex).get('item').value}}
                                                </div>
                                                <div class="bar_wrap">
                                                    <p [style.width]="cvLanguages().at(langIndex).get('level').value"
                                                        class="bar" [style.background]="pagecolor"></p>
                                                </div>
                                            </li>


                                        </ul>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('quality1').value.trim() || cvFormGroup.get('quality2').value.trim() ||
                                            cvFormGroup.get('quality3').value.trim() || cvFormGroup.get('quality4').value.trim()"
                                        class="skills_sec ">
                                        <div class="button ">{{'qualities' | translate}}</div>
                                        <div class="interest">
                                            <ul>
                                                <li> {{cvFormGroup.get('quality1').value}}</li>
                                                <li style="margin-top: 3px;"> {{cvFormGroup.get('quality2').value}}</li>

                                            </ul>
                                            <ul>
                                                <li> {{cvFormGroup.get('quality3').value}}</li>
                                                <li style="margin-top: 3px;"> {{cvFormGroup.get('quality4').value}}</li>

                                            </ul>
                                        </div>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('hobby1').value.trim() || cvFormGroup.get('hobby2').value.trim() ||
                                      cvFormGroup.get('hobby3').value.trim() || cvFormGroup.get('hobby4').value.trim()"
                                        class="skills_sec ">
                                        <div class="button ">{{'hobbies2' | translate}}<span
                                                style="color: white;">_</span>{{'hobbies3' | translate}}</div>
                                        <div class="interest">
                                            <ul>
                                                <li> {{cvFormGroup.get('hobby1').value}}</li>
                                                <li style="margin-top: 3px;"> {{cvFormGroup.get('hobby2').value}}</li>

                                            </ul>
                                            <ul>
                                                <li> {{cvFormGroup.get('hobby3').value}}</li>
                                                <li style="margin-top: 3px;"> {{cvFormGroup.get('hobby4').value}}</li>

                                            </ul>
                                        </div>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('references').value.length" formArrayName="references"
                                        class="contact_sec" [style.background]="secondcolor">
                                        <div [style.color]="secondcolor" class="button"> {{'references' | translate}}
                                        </div>
                                        <ul *ngFor="let certif of cvReferences().controls; let refIndex=index">
                                            <li class="item" [formGroupName]="refIndex">
                                                <div class="content">
                                                    <p class="title" style="font-size: 13px; color: #eeee;">
                                                        {{cvReferences().at(refIndex).get('fullname').value}}</p>
                                                    <p class="subtitle">
                                                        <strong>{{cvReferences().at(refIndex).get('organisation').value}}
                                                        </strong></p>
                                                    <p class="subtitle"> <strong>Tel :
                                                        </strong>{{cvReferences().at(refIndex).get('telephone').value}}
                                                    </p>
                                                    <p class="subtitle"> <strong>Email
                                                            :</strong>{{cvReferences().at(refIndex).get('email').value}}
                                                    </p>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>




                                </div>
                            </div>




                        </div>




                        <!--  END PAGE 1-->

                        <!--  START PAGE 2-->


                        <div class="wrapper" style="margin-top: 0;">
                            <div data-ng-controll class="resume_design" id="content2" #content2 style="margin-top: 0;">
                                <div class="resume_left" id="left_side2" #left_side2
                                    style="padding : 0; margin-top: 0px ">





                                </div>
                                <div class="resume_right" id="right_side2" #right_side2
                                    style="padding : 0; margin-top: 0px " [style.background]="firstcolor">





                                </div>
                            </div>
                        </div>

                        <!--  END PAGE 2-->

                        <!--  START PAGE 3-->

                        <div class="wrapper" style="margin-top: 0;">
                            <div data-ng-controll class="resume_design" id="content3" #content3 style="margin-top: 0;">
                                <div class="resume_left" id="left_side3" #left_side3
                                    style="padding : 0; margin-top: 0px ">



                                </div>
                                <div class="resume_right" id="right_side3" #right_side3 style="padding : 0;"
                                    [style.background]="firstcolor">

                                </div>
                            </div>

                        </div>
                        <!--  END PAGE 3-->




                    </div>

                </div>
            </form>
        </div>

    </div>
</div>

<!--   FORM ARRAY         -->
