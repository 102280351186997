<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg3">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Products Details</li>
            </ul>
            <h2>Products Details</h2>
        </div>
    </div>
</div>

<div class="product-details-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="products-details-image">
                    <owl-carousel-o [options]="detailsImageSlides">
                        <ng-template carouselSlide>
                            <img src="assets/images/icons-modeles/cv1.jpg" alt="image">
                        </ng-template>
                        <ng-template carouselSlide>
                            <img src="assets/images/icons-modeles/cv2.jpg" alt="image">
                        </ng-template>
                        <ng-template carouselSlide>
                            <img src="assets/images/icons-modeles/cv3.jpg" alt="image">
                        </ng-template>
                        <ng-template carouselSlide>
                            <img src="assets/images/icons-modeles/cv4.jpg" alt="image">
                        </ng-template>
                        <ng-template carouselSlide>
                            <img src="assets/images/icons-modeles/cv2.jpg" alt="image">
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>


            <div class="col-lg-7 col-md-12">


                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <form formGroup="abonnementFormGroup">
                            <div class="billing-details">

                                <div class="product-details-desc">
                                    <h3>Abonnement</h3>




                                    <div class="product-review">
                                        <div class="order-details">
                                            <div class="payment-box">

                                                <div class="payment-method">

                                                    <div class="row">
                                                        <div class="col-lg-12 col-md-12">


                                                        <div class="alert alert-danger" style="font-size: 18px;">
                                                            Le Paiement a été refusé </div>
                                                        </div>
                                                        <div class="col-lg-12 col-md-12">

                                                            <div class="row">
                                                                <div class="col-lg-6 col-md-6">
                                                                    <br>
                                                                    <button type="submit" (click)="cancel()"
                                                                        class="default-btn" style="background: #000;border: #000;"><i
                                                                            class='bx bx-plus icon-arrow before'></i><span
                                                                            class="label">Annuler</span><i
                                                                            class="bx bx-plus icon-arrow after"></i></button>
                                                                </div>
                                                                <div class="col-lg-6 col-md-6">
                                                                    <br>
                                                                    <button type="submit" (click)="tryagain()"
                                                                        class="default-btn"><i
                                                                            class='bx bx-plus icon-arrow before'></i><span
                                                                            class="label">Réessayer</span><i
                                                                            class="bx bx-plus icon-arrow after"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>

                                            </div>


                                        </div>

                                    </div>








                                </div>
                            </div>
                        </form>
                    </div>

                </div>

            </div>







        </div>
    </div>

</div>
