<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Rubriques</li>
            </ul>
            <h2>Rubriques d'un CV</h2>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/about5.jpg" class="shadow" alt="image">
                    <img src="assets/img/about/about6.jpg" class="shadow" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">About Us !</span> -->
                    <h2>Les rubriques d'un CV</h2>
                    <h6>Le CV (Curriculum Vitae) est un document à part entière de votre recherche d'emploi.</h6>
                    <p>Un CV bien organisé qui comprend les éléments et informations appropriés peut attirer l'attention d'un responsable du recrutement et vous aider à décrocher un entretien d'embauche. Bien que le CV de chaque personne soit différent,
                        il y a certaines sections que vous devez toujours inclure et d'autres qui sont facultatives. La façon dont vous organisez votre CV dépend de l'emploi pour lequel vous postulez et de l'étape où vous en êtes dans votre carrière.
                        Dans cet article, nous énumérons les sections standard et facultatives d'un CV et comment les rediger correctement. </p>

                    <p>Avec toute la pression pour rédiger un CV propre d'une page (ou 2 pages si vous avez assez de contenu à partager), il est important de rester vigilant lorsque vous choisissez les sections ou catégories que vous utilisez pour décrire
                        votre expérience et votre candidature à un nouvelle position.</p>
                </div>
            </div>
        </div>
        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Profil</h3>
                        <p>
                            L'idée est d'écrire 3-4 lignes de texte qui parlent de votre expérience, dans quel type d'entreprises, et de vos compétences ou domaines d'expertise spécifiques. Un bon profil a un impact lorsqu'il est bien écrit. Tout d'abord, cela brise la glace au
                            lieu de commencer brusquement avec votre emploi le plus récent. Le résumé est votre chance d'exprimer toute votre carrière avant qu'ils ne commencent à examiner chaque emploi individuel. Lorsqu'il est bien écrit, il peut également
                            préparer le lecteur à quelques concepts clés que vous prévoyez de renforcer plus tard dans le document.
                        </p>
                        <!--
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Activate Listening</li>
                            <li><i class='bx bx-check'></i> Brilliant minds</li>
                            <li><i class='bx bx-check'></i> Better. Best. Wow!</li>
                            <li><i class='bx bx-check'></i> Branding it better!</li>
                        </ul>
                        -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Compétences</h3>
                        <p>
                            Il s'agit d'une section de CV absolument nécessaire, car vous la verrez dans presque tous les exemples. Et bien que cela soit courant, il est recommandé aux candidats d'examiner attentivement ce qu'ils ont vraiment à offrir en termes de compétences techniques.
                            Si vous êtes un administrateur de systèmes informatiques ou un développeur de logiciels avec une longue liste de compétences de type "mot clé" très utiles, une section de compétences est un moyen efficace de montrer ces informations.
                            Les recruteurs techniques passent souvent au crible ces compétences, cherchant à voir si vous avez le bon type d'expérience ou si vous êtes familiarisé avec suffisamment de concepts techniques pour mériter un entretien.
                        </p>

                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Expérience professionelle</h3>
                        <p> Cette section expérience est au cœur de tout CV. Il y'a de nombreux CV qui ne la mentionnent même pas le premier poste occupé jusqu'à la page 2 (ils étaient tellement occupés à trop résumer leurs CV avec des compétences, des compétences
                            de base, du leadership, des résumés, etc), qu'ils ont oublié ce que le parcours est ce que attire le plus le recruteur. Certains CV le mettent sans le mot "professionnel", car c'est surtout sous-entendu et j'aime utiliser moins
                            de mots pour faire passer mon message de manière plus claire et plus directe.
                        </p>

                    </div>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Education</h3>
                        <p>Si vous avez un diplôme, de formation professionnelle, de licence ou supérieur, il appartient ici.Il est important de ne mentionner que les 2 ou 3 derniers diplômes obtenus. Ne mentionner que les cursus achevés. Mais concentrez-vous
                            sur le diplôme. N'enlisez pas votre section éducation avec des activités parascolaires. Le CV est généralement réservé à la liste des réalisations terminées et des références</p>
                        <!--
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Activate Listening</li>
                            <li><i class='bx bx-check'></i> Brilliant minds</li>
                            <li><i class='bx bx-check'></i> Better. Best. Wow!</li>
                            <li><i class='bx bx-check'></i> Branding it better!</li>
                        </ul>
                        -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Certifications</h3>
                        <p>
                            Cette rubrique concerne les certificats que vous avez eu en rapport avec votre travail et connexe à votre travail. Il est important d'énumérer les certifications qui sont toujours pertinentes à la fois pour votre domaine ET votre niveau de carrière spécifique.
                        </p>
                        <p>Si vous êtes à la recherche d'un emploi dans la vente et que vous avez obtenu votre certification CompTIA A+ pour la réparation d'ordinateurs il y a 8 ans, il est temps de vous en éloigner. Je sais qu'il est difficile d'omettre
                            l'expérience parce que nous pensons que chaque petit geste peut nous aider, mais cela nous égare souvent et nous décrit comme des candidats qui manquent potentiellement d'une carrière ciblée</p>

                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Centres d'intérêt /Loisirs</h3>
                        <p>
                            Les centres d'intérêt et les loisirs sont étroitement liés, mais ne sont pas toujours les mêmes. Les loisirs sont des activités dans lesquelles vous vous réalisez déjà, tandis que les centres d'intérêt sont des idées ou des sujets passifs. Par exemple,
                            vous pourriez énumérer les "voyages internationaux" comme loisir si c'est quelque chose que vous faites régulièrement. Si l'employeur souhaite en savoir plus sur ce passe-temps, il se peut qu'on vous demande d'élaborer lors
                            d'un entretien. Si vous êtes intéressé par les voyages mais que vous en avez fait très peu, cela serait considéré comme un intérêt. Vous pouvez l'inclure dans votre CV pour montrer aux employeurs potentiels que vous êtes ouvert
                            à des emplois qui nécessitent de voyager dans différents pays, par exemple.

                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--
<app-our-mission></app-our-mission>


<div class="story-area ptb-100">
    <app-our-story></app-our-story>
</div>

<div class="funfacts-area">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div>

<div class="values-area ptb-100">
    <app-our-values></app-our-values>
</div>

<div class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-two></app-instructors-style-two>
    </div>
</div>

<div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>
-->

<!--
<div class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>
-->
