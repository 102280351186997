import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { ConfirmBoxEvokeService } from "@costlydeveloper/ngx-awesome-popup";
import { Cover } from "../../cover-modele/cover";
import { CoverService } from "../cover.service";
import { TokenStorageService } from "../../cv-login-page/token-storage.service";

import { CoverThequeService } from "../cover-theque.service";

@Component({
    selector: "cover-bank-finance",
    templateUrl: "./cover-theque.component.html",
    styleUrls: ["./cover-theque.component.scss"],
})
export class CoverThequeBankFinanceComponent implements OnInit {
    listCover: Array<Cover> = [];

    isSuccess: boolean = false;
    coverFormGroup: FormGroup;

    constructor(
        private coverThequeService: CoverThequeService,
        private tokenStorageService: TokenStorageService,
        private coverService: CoverService,
        private fb: FormBuilder,
        private router: Router,
        private confirmBoxEvokeService: ConfirmBoxEvokeService
    ) {}
    isAdmin: boolean = false;
    ngOnInit(): void {
        this.findAll();
        if (
            this.tokenStorageService.getToken() &&
            this.tokenStorageService.getUser().role === "admin"
        ) {
            this.isAdmin = true;
        }

        // alert(this.tokenStorageService.getUser().email);
    }

    confirmBox(cover: Cover) {
        this.confirmBoxEvokeService
            .danger(
                "Supprimer la lettre de motivation",
                "Voulez-vous vraiment supprimer cette lettre?",
                "Oui",
                "Non"
            )
            .subscribe((resp) =>
                resp.success ? this.deleteCover(cover) : console.log()
            );
    }

    initCover(cover: Cover): void {
        //alert(this.decrypt.transform(cover.firstname));
        this.coverFormGroup = this.fb.group({
            name: [cover.name],
            photo: [cover.photo],
            firstname: [cover.firstname],
            lastname: [cover.lastname],
            profession: [cover.profession],
            telephone: [cover.telephone],
            email: [cover.email],
            linkedin: [cover.linkedin],

            city: [cover.city],

            firstcolor: [cover.firstcolor],
            secondcolor: [cover.secondcolor],
            thirdcolor: [cover.thirdcolor],
            pagecolor: [cover.pagecolor],
            fontfamily: [cover.fontfamily],
            theme: [cover.theme],
            idmodele: [cover.idmodele],

            flag: [cover.flag],
            flag2: [cover.flag2],
        });
    }

    spin1: boolean = true;
    findAll() {
        this.coverThequeService.findByFlag2(3).subscribe(
            (data) => {
                // alert(data);
                this.spin1 = true;
                this.listCover = data;
                this.isSuccess = true;
            },
            (error) => {
                this.spin1 = false;

                this.tokenStorageService.signOut2();
            },

            () => {
                this.listCover.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
                this.spin1 = false;
            }
        );
    }

    showCover(cover: Cover): void {
        let link = [
            "cover-letter/examples/m" + cover.idmodele + "-edit-cover",
            cover.id,
        ];
        this.router.navigate(link);
    }

    showCover2(cover: Cover): void {
        let link = ["cvs/m" + cover.idmodele + "-edit-cover", cover.id];
        this.router.navigate(link);
    }

    deleteCover(cover: Cover): void {
        this.coverService.deleteOne(cover.id).subscribe(
            (data) => {
                // alert(data);
            },
            (error) => {
                this.tokenStorageService.signOut2();
            },

            () => {
                this.ngOnInit();
            }
        );
    }

    async duplicateCover(cover: Cover) {
        let lenght = await this.listCover.length;
        if (this.tokenStorageService.getUser().role === "user") {
            if (lenght < 120) {
                this.initCover(cover);
                const data = cover;

                this.coverService.save(this.coverFormGroup.value).subscribe(
                    (data) => {
                        // alert(data);
                    },
                    (error) => {
                        this.tokenStorageService.signOut2();
                    },

                    () => {
                        this.ngOnInit();
                    }
                );
            } else {
                this.confirmBoxEvokeService
                    .info(
                        "Info",
                        "Vous avez déjà atteint le nombre maximal (6) de CV",
                        "Ok"
                    )
                    .subscribe((resp) => console.log("Déjà 6 CV !"));
            }
        } else {
            this.initCover(cover);
            const data = cover;

            this.coverService.save(this.coverFormGroup.value).subscribe(
                (data) => {
                    // alert(data);
                },
                (error) => {
                    this.tokenStorageService.signOut2();
                },

                () => {
                    this.ngOnInit();
                }
            );
        }
    }

    coverSkills(): FormArray {
        return this.coverFormGroup.get("skills") as FormArray;
    }

    coverExperiences(): FormArray {
        return this.coverFormGroup.get("experiences") as FormArray;
    }

    coverEducations(): FormArray {
        return this.coverFormGroup.get("educations") as FormArray;
    }

    coverCustoms(): FormArray {
        return this.coverFormGroup.get("customs") as FormArray;
    }

    coverFormations(): FormArray {
        return this.coverFormGroup.get("formations") as FormArray;
    }
    coverCertifications(): FormArray {
        return this.coverFormGroup.get("certifications") as FormArray;
    }
    coverReferences(): FormArray {
        return this.coverFormGroup.get("references") as FormArray;
    }

    coverLanguages(): FormArray {
        return this.coverFormGroup.get("languages") as FormArray;
    }

    initCoverSkill2(skill): FormGroup {
        return this.fb.group({
            item: [skill.item],
            level: [skill.level],
        });
    }

    initCoverExperience(): FormGroup {
        return this.fb.group({
            index: [0],
            period: [""],
            company: [""],
            city: [""],
            function: [""],
            description: [""],
        });
    }

    initCoverExperience2(experience): FormGroup {
        return this.fb.group({
            index: [experience.index],
            period: [experience.period],
            company: [experience.company],
            city: [experience.city],
            function: [experience.function],
            description: [experience.description],
        });
    }

    initCoverEducation2(education): FormGroup {
        return this.fb.group({
            index: [education.index],
            period: [education.period],
            institute: [education.institute],
            city: [education.city],
            country: [education.country],
            degree: [education.degree],
        });
    }

    initCoverCustom2(custom): FormGroup {
        return this.fb.group({
            index: [custom.index],
            title: [custom.title],
            description: [custom.description],
        });
    }

    initCoverFormation2(formation): FormGroup {
        return this.fb.group({
            index: [formation.index],
            period: [formation.period],
            title: [formation.title],
            description: [formation.description],
            former: [formation.former],
        });
    }

    initCoverCertification2(certification): FormGroup {
        return this.fb.group({
            index: [certification.index],
            date: [certification.date],
            certificat: [certification.certificat],
            institute: [certification.institute],
        });
    }

    initCoverReference2(reference): FormGroup {
        return this.fb.group({
            index: [reference.index],
            fullname: [reference.fullname],
            organisation: [reference.organisation],
            telephone: [reference.telephone],
            description: [reference.description],
            email: [reference.email],
        });
    }

    initCoverLanguage2(lang): FormGroup {
        return this.fb.group({
            item: [lang.item],
            level: [lang.level],
        });
    }
}
