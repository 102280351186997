import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CarouselModule } from "ngx-owl-carousel-o";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CountUpModule } from "ngx-countup";
import { TabsModule } from "ngx-tabset";
import { NgxScrollTopModule } from "ngx-scrolltop";
import { StickyNavModule } from "ng2-sticky-nav";
import { LightboxModule } from "ngx-lightbox";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ColorPickerModule } from "ngx-color-picker";
import { CookieService } from "ngx-cookie-service";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomePageOneComponent } from "./components/pages/home-page-one/home-page-one.component";
import { HomePageTwoComponent } from "./components/pages/home-page-two/home-page-two.component";
import { FooterComponent } from "./components/common/footer/footer.component";
import { PreloaderComponent } from "./components/common/preloader/preloader.component";
import { HomeoneAboutComponent } from "./components/pages/home-page-one/homeone-about/homeone-about.component";
import { HomeoneMainBannerComponent } from "./components/pages/home-page-one/homeone-main-banner/homeone-main-banner.component";
import { HeaderStyleOneComponent } from "./components/common/header-style-one/header-style-one.component";
import { CategoriesStyleOneComponent } from "./components/common/categories-style-one/categories-style-one.component";
import { FunfactsComponent } from "./components/common/funfacts/funfacts.component";
import { HomeoneCoursesComponent } from "./components/pages/home-page-one/homeone-courses/homeone-courses.component";
import { PartnerStyleOneComponent } from "./components/common/partner-style-one/partner-style-one.component";
import { InstructorsStyleOneComponent } from "./components/common/instructors-style-one/instructors-style-one.component";
import { BecomeInstructorPartnerComponent } from "./components/common/become-instructor-partner/become-instructor-partner.component";
import { FeedbackStyleOneComponent } from "./components/common/feedback-style-one/feedback-style-one.component";
import { BlogComponent } from "./components/common/blog/blog.component";
import { HeaderStyleTwoComponent } from "./components/common/header-style-two/header-style-two.component";
import { HometwoMainBannerComponent } from "./components/pages/home-page-two/hometwo-main-banner/hometwo-main-banner.component";
import { HometwoAboutComponent } from "./components/pages/home-page-two/hometwo-about/hometwo-about.component";
import { HometwoCoursesComponent } from "./components/pages/home-page-two/hometwo-courses/hometwo-courses.component";
import { OurMissionComponent } from "./components/common/our-mission/our-mission.component";
import { InstructorsStyleTwoComponent } from "./components/common/instructors-style-two/instructors-style-two.component";
import { WebinarCountdownComponent } from "./components/common/webinar-countdown/webinar-countdown.component";
import { StudentsFeedbackFormComponent } from "./components/common/students-feedback-form/students-feedback-form.component";
import { HomePageThreeComponent } from "./components/pages/home-page-three/home-page-three.component";
import { HomePageFourComponent } from "./components/pages/home-page-four/home-page-four.component";
import { HomePageFiveComponent } from "./components/pages/home-page-five/home-page-five.component";
import { HomePageSixComponent } from "./components/pages/home-page-six/home-page-six.component";
import { HomePageSevenComponent } from "./components/pages/home-page-seven/home-page-seven.component";
import { PartnerStyleTwoComponent } from "./components/common/partner-style-two/partner-style-two.component";
import { OfferComponent } from "./components/common/offer/offer.component";
import { CategoriesStyleTwoComponent } from "./components/common/categories-style-two/categories-style-two.component";
import { HomethreeCoursesComponent } from "./components/pages/home-page-three/homethree-courses/homethree-courses.component";
import { HomethreeAboutComponent } from "./components/pages/home-page-three/homethree-about/homethree-about.component";
import { FeedbackFormComponent } from "./components/common/feedback-form/feedback-form.component";
import { HomethreeMainBannerComponent } from "./components/pages/home-page-three/homethree-main-banner/homethree-main-banner.component";
import { HeaderStyleThreeComponent } from "./components/common/header-style-three/header-style-three.component";
import { HomefourMainBannerComponent } from "./components/pages/home-page-four/homefour-main-banner/homefour-main-banner.component";
import { HomefourCoursesComponent } from "./components/pages/home-page-four/homefour-courses/homefour-courses.component";
import { BoxesComponent } from "./components/common/boxes/boxes.component";
import { FeedbackStyleTwoComponent } from "./components/common/feedback-style-two/feedback-style-two.component";
import { HomefiveCoursesComponent } from "./components/pages/home-page-five/homefive-courses/homefive-courses.component";
import { HomefiveMainBannerComponent } from "./components/pages/home-page-five/homefive-main-banner/homefive-main-banner.component";
import { InstructorsStyleThreeComponent } from "./components/common/instructors-style-three/instructors-style-three.component";
import { FeaturesComponent } from "./components/common/features/features.component";
import { HomesixCoursesComponent } from "./components/pages/home-page-six/homesix-courses/homesix-courses.component";
import { CategoriesStyleThreeComponent } from "./components/common/categories-style-three/categories-style-three.component";
import { HomesixMainBannerComponent } from "./components/pages/home-page-six/homesix-main-banner/homesix-main-banner.component";
import { HeaderStyleFourComponent } from "./components/common/header-style-four/header-style-four.component";
import { HomesevenMainBannerComponent } from "./components/pages/home-page-seven/homeseven-main-banner/homeseven-main-banner.component";
import { HomesevenCoursesComponent } from "./components/pages/home-page-seven/homeseven-courses/homeseven-courses.component";
import { HowItWorksComponent } from "./components/common/how-it-works/how-it-works.component";
import { AboutPageOneComponent } from "./components/pages/about-page-one/about-page-one.component";
import { AboutPageTwoComponent } from "./components/pages/about-page-two/about-page-two.component";
import { OurStoryComponent } from "./components/common/our-story/our-story.component";
import { OurValuesComponent } from "./components/common/our-values/our-values.component";
import { InstructorsPageOneComponent } from "./components/pages/instructors-page-one/instructors-page-one.component";
import { InstructorsPageTwoComponent } from "./components/pages/instructors-page-two/instructors-page-two.component";
import { InstructorsPageThreeComponent } from "./components/pages/instructors-page-three/instructors-page-three.component";
import { InstructorsDetailsPageComponent } from "./components/pages/instructors-details-page/instructors-details-page.component";
import { GalleryPageComponent } from "./components/pages/gallery-page/gallery-page.component";
import { EventsPageComponent } from "./components/pages/events-page/events-page.component";
import { EventsDetailsPageComponent } from "./components/pages/events-details-page/events-details-page.component";
import { PricingPageComponent } from "./components/pages/pricing-page/pricing-page.component";
import { FeedbackPageComponent } from "./components/pages/feedback-page/feedback-page.component";
import { PartnerPageComponent } from "./components/pages/partner-page/partner-page.component";
import { LoginPageComponent } from "./components/pages/login-page/login-page.component";
import { FaqPageComponent } from "./components/pages/faq-page/faq-page.component";
import { ErrorPageComponent } from "./components/pages/error-page/error-page.component";
import { ComingSoonPageComponent } from "./components/pages/coming-soon-page/coming-soon-page.component";
import { BlogGridPageComponent } from "./components/pages/blog-grid-page/blog-grid-page.component";
import { BlogRightSidebarPageComponent } from "./components/pages/blog-right-sidebar-page/blog-right-sidebar-page.component";
import { BlogFullWidthPageComponent } from "./components/pages/blog-full-width-page/blog-full-width-page.component";
import { BlogDetailsPageComponent } from "./components/pages/blog-details-page/blog-details-page.component";
import { ShopGridPageComponent } from "./components/pages/shop-grid-page/shop-grid-page.component";
import { ShopFullWidthPageComponent } from "./components/pages/shop-full-width-page/shop-full-width-page.component";
import { ProductsDetailsPageComponent } from "./components/pages/products-details-page/products-details-page.component";
import { CartPageComponent } from "./components/pages/cart-page/cart-page.component";
import { ContactPageComponent } from "./components/pages/contact-page/contact-page.component";
import { CategoryPageOneComponent } from "./components/pages/category-page-one/category-page-one.component";
import { CategoryPageTwoComponent } from "./components/pages/category-page-two/category-page-two.component";
import { CategoryPageThreeComponent } from "./components/pages/category-page-three/category-page-three.component";
import { CoursesListPageComponent } from "./components/pages/courses-list-page/courses-list-page.component";
import { CoursesGridStyleOneColumnsTwoPageComponent } from "./components/pages/courses-grid-style-one-columns-two-page/courses-grid-style-one-columns-two-page.component";
import { CoursesGridStyleOneColumnsThreePageComponent } from "./components/pages/courses-grid-style-one-columns-three-page/courses-grid-style-one-columns-three-page.component";
import { CoursesGridStyleOneColumnsFourPageComponent } from "./components/pages/courses-grid-style-one-columns-four-page/courses-grid-style-one-columns-four-page.component";
import { CoursesGridStyleTwoColumnsTwoPageComponent } from "./components/pages/courses-grid-style-two-columns-two-page/courses-grid-style-two-columns-two-page.component";
import { CoursesGridStyleTwoColumnsThreePageComponent } from "./components/pages/courses-grid-style-two-columns-three-page/courses-grid-style-two-columns-three-page.component";
import { CoursesGridStyleTwoColumnsFourPageComponent } from "./components/pages/courses-grid-style-two-columns-four-page/courses-grid-style-two-columns-four-page.component";
import { CoursesGridStyleThreeColumnsTwoPageComponent } from "./components/pages/courses-grid-style-three-columns-two-page/courses-grid-style-three-columns-two-page.component";
import { CoursesGridStyleThreeColumnsThreePageComponent } from "./components/pages/courses-grid-style-three-columns-three-page/courses-grid-style-three-columns-three-page.component";
import { CoursesGridStyleThreeColumnsFourPageComponent } from "./components/pages/courses-grid-style-three-columns-four-page/courses-grid-style-three-columns-four-page.component";
import { CoursesDetailsPageComponent } from "./components/pages/courses-details-page/courses-details-page.component";
import { MyDashboardPageComponent } from "./components/pages/my-dashboard-page/my-dashboard-page.component";
import { OrdersPageComponent } from "./components/pages/orders-page/orders-page.component";
import { DownloadsPageComponent } from "./components/pages/downloads-page/downloads-page.component";
import { EditAddressPageComponent } from "./components/pages/edit-address-page/edit-address-page.component";
import { EditAccountPageComponent } from "./components/pages/edit-account-page/edit-account-page.component";
import { EditBillingAddressPageComponent } from "./components/pages/edit-billing-address-page/edit-billing-address-page.component";
import { EditShippingAddressPageComponent } from "./components/pages/edit-shipping-address-page/edit-shipping-address-page.component";

import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { AuthInterceptor } from "./components/pages/login-page/auth.interceptor";
import { RegisterPageComponent } from "./components/pages/register-page/register-page.component";

import { LoadingComponent } from "./components/pages/loading/loading.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { CvModeleComponent } from "./components/pages/cv-modele/cv-modele.component";
import { CvThemeComponent } from "./components/pages/cv-theme/cv-theme.component";
import { Theme1Component } from "./components/pages/cv-theme/theme1/theme1.component";
import { Theme2Component } from "./components/pages/cv-theme/theme2/theme2.component";
import { CVInterviewComponent } from "./components/pages/cv-interview/cv-interview.component";
import { CvInterviewBannerComponent } from "./components/pages/cv-interview/homeone-main-banner/homeone-main-banner.component";
import { CvHomeComponent } from "./components/pages/cv-home/cv-home.component";
import { CvHomeModelesComponent } from "./components/pages/cv-home/homefour-courses/homefour-courses.component";
import { CvDashboardComponent } from "./components/pages/cv-dashboard/cv-dashboard.component";
import { M1AddComponent } from "./components/pages/cv-modele/modeles/m1-add/m1-add.component";
import { M1EditComponent } from "./components/pages/cv-modele/modeles/m1-edit/m1-edit.component";
import { M2EditComponent } from "./components/pages/cv-modele/modeles/m2-edit/m2-edit.component";
import { M3EditComponent } from "./components/pages/cv-modele/modeles/m3-edit/m3-edit.component";
import { M4EditComponent } from "./components/pages/cv-modele/modeles/m4-edit/m4-edit.component";
import { M5EditComponent } from "./components/pages/cv-modele/modeles/m5-edit/m5-edit.component";
import { M6EditComponent } from "./components/pages/cv-modele/modeles/m6-edit/m6-edit.component";
import { M7EditComponent } from "./components/pages/cv-modele/modeles/m7-edit/m7-edit.component";
import { M8EditComponent } from "./components/pages/cv-modele/modeles/m8-edit/m8-edit.component";
import { M9EditComponent } from "./components/pages/cv-modele/modeles/m9-edit/m9-edit.component";
import { M10EditComponent } from "./components/pages/cv-modele/modeles/m10-edit/m10-edit.component";
import { M11EditComponent } from "./components/pages/cv-modele/modeles/m11-edit/m11-edit.component";
import { M12EditComponent } from "./components/pages/cv-modele/modeles/m12-edit/m12-edit.component";
import { M13EditComponent } from "./components/pages/cv-modele/modeles/m13-edit/m13-edit.component";
import { M14EditComponent } from "./components/pages/cv-modele/modeles/m14-edit/m14-edit.component";
import { M15EditComponent } from "./components/pages/cv-modele/modeles/m15-edit/m15-edit.component";
import { M16EditComponent } from "./components/pages/cv-modele/modeles/m16-edit/m16-edit.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { DecryptPipe, EncryptPipe } from "./encrypt-decrypt.pipe";
import { CVRegisterPageComponent } from "./components/pages/cv-register-page/cv-register-page.component";
import { CVLoginPageComponent } from "./components/pages/cv-login-page/cv-login-page.component";
import { CvTipsComponent } from "./components/pages/cv-tips/cv-tips.component";
import { CvPageComponent } from "./components/pages/cv-page/cv-page.component";
import { CvSectionsComponent } from "./components/pages/cv-sections/cv-sections.component";
import { M2AddComponent } from "./components/pages/cv-modele/modeles/m2-add/m2-add.component";
import { M3AddComponent } from "./components/pages/cv-modele/modeles/m3-add/m3-add.component";
import { M4AddComponent } from "./components/pages/cv-modele/modeles/m4-add/m4-add.component";
import { M5AddComponent } from "./components/pages/cv-modele/modeles/m5-add/m5-add.component";
import { M6AddComponent } from "./components/pages/cv-modele/modeles/m6-add/m6-add.component";
import { M7AddComponent } from "./components/pages/cv-modele/modeles/m7-add/m7-add.component";
import { M8AddComponent } from "./components/pages/cv-modele/modeles/m8-add/m8-add.component";
import { M9AddComponent } from "./components/pages/cv-modele/modeles/m9-add/m9-add.component";
import { M10AddComponent } from "./components/pages/cv-modele/modeles/m10-add/m10-add.component";
import { M11AddComponent } from "./components/pages/cv-modele/modeles/m11-add/m11-add.component";
import { M12AddComponent } from "./components/pages/cv-modele/modeles/m12-add/m12-add.component";
import { M13AddComponent } from "./components/pages/cv-modele/modeles/m13-add/m13-add.component";
import { M14AddComponent } from "./components/pages/cv-modele/modeles/m14-add/m14-add.component";
import { M15AddComponent } from "./components/pages/cv-modele/modeles/m15-add/m15-add.component";
import { M16AddComponent } from "./components/pages/cv-modele/modeles/m16-add/m16-add.component";

import { M2Component } from "./components/pages/cv-dashboard/modele2/m2-edit.component";
import { Modele1Component } from "./components/pages/cv-dashboard/modele0/modele1.component";
import { M1Component } from "./components/pages/cv-dashboard/modele1/m1-edit.component";
import { M3Component } from "./components/pages/cv-dashboard/modele3/m3-edit.component";
import { M4Component } from "./components/pages/cv-dashboard/modele4/m4-edit.component";
import { M5Component } from "./components/pages/cv-dashboard/modele5/m5-edit.component";
import { M6Component } from "./components/pages/cv-dashboard/modele6/m6-edit.component";
import { M7Component } from "./components/pages/cv-dashboard/modele7/m7-edit.component";
import { M8Component } from "./components/pages/cv-dashboard/modele8/m8-edit.component";
import { M9Component } from "./components/pages/cv-dashboard/modele9/m9-edit.component";
import { M10Component } from "./components/pages/cv-dashboard/modele10/m10-edit.component";
import { M11Component } from "./components/pages/cv-dashboard/modele11/m11-edit.component";
import { M12Component } from "./components/pages/cv-dashboard/modele12/m12-edit.component";
import { M13Component } from "./components/pages/cv-dashboard/modele13/m13-edit.component";
import { M14Component } from "./components/pages/cv-dashboard/modele14/m14-edit.component";
import { M15Component } from "./components/pages/cv-dashboard/modele15/m15-edit.component";
import { M16Component } from "./components/pages/cv-dashboard/modele16/m16-edit.component";
import {
    NgxAwesomePopupModule,
    ConfirmBoxConfigModule,
    ToastNotificationConfigModule,
} from "@costlydeveloper/ngx-awesome-popup";
import { CvPrivacyComponent } from "./components/pages/cv-privacy/cv-privacy.component";
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { ThemeDataService } from "./components/pages/cv-theme/cv-theme-data.service";
import { CvFaqComponent } from "./components/pages/cv-faq/cv-faq.component";
import { CvConditionComponent } from "./components/pages/cv-condition/cv-condition.component";
import { CvAboutComponent } from "./components/pages/cv-about/cv-about.component";
import { M17EditComponent } from "./components/pages/cv-modele/modeles/m17-edit/m17-edit.component";
import { M17AddComponent } from "./components/pages/cv-modele/modeles/m17-add/m17-add.component";
import { M18EditComponent } from "./components/pages/cv-modele/modeles/m18-edit/m18-edit.component";
import { M19EditComponent } from "./components/pages/cv-modele/modeles/m19-edit/m19-edit.component";
import { M20EditComponent } from "./components/pages/cv-modele/modeles/m20-edit/m20-edit.component";
import { M21EditComponent } from "./components/pages/cv-modele/modeles/m21-edit/m21-edit.component";
import { M22EditComponent } from "./components/pages/cv-modele/modeles/m22-edit/m22-edit.component";
import { M23EditComponent } from "./components/pages/cv-modele/modeles/m23-edit/m23-edit.component";
import { M24EditComponent } from "./components/pages/cv-modele/modeles/m24-edit/m24-edit.component";
import { M18AddComponent } from "./components/pages/cv-modele/modeles/m18-add/m18-add.component";
import { M19AddComponent } from "./components/pages/cv-modele/modeles/m19-add/m19-add.component";
import { M20AddComponent } from "./components/pages/cv-modele/modeles/m20-add/m20-add.component";
import { M21AddComponent } from "./components/pages/cv-modele/modeles/m21-add/m21-add.component";
import { M22AddComponent } from "./components/pages/cv-modele/modeles/m22-add/m22-add.component";
import { M23AddComponent } from "./components/pages/cv-modele/modeles/m23-add/m23-add.component";
import { M24AddComponent } from "./components/pages/cv-modele/modeles/m24-add/m24-add.component";
import { M17Component } from "./components/pages/cv-dashboard/modele17/m17-edit.component";
import { M18Component } from "./components/pages/cv-dashboard/modele18/m18-edit.component";
import { M19Component } from "./components/pages/cv-dashboard/modele19/m19-edit.component";
import { M20Component } from "./components/pages/cv-dashboard/modele20/m20-edit.component";
import { M21Component } from "./components/pages/cv-dashboard/modele21/m21-edit.component";
import { M22Component } from "./components/pages/cv-dashboard/modele22/m22-edit.component";
import { M23Component } from "./components/pages/cv-dashboard/modele23/m23-edit.component";
import { M24Component } from "./components/pages/cv-dashboard/modele24/m24-edit.component";
import { CVGetCodeComponent } from "./components/pages/cv-login-page/cv-get-code/cv-get-code.component";
import { CVResetPasswordComponent } from "./components/pages/cv-login-page/cv-reset-password/cv-reset-password.component";
import { CvThequeComponent } from "./components/pages/cv-cvtheque/cv-cvtheque.component";
import { CVHomesevenMainBannerComponent } from "./components/pages/cv-cvtheque/homeseven-main-banner/homeseven-main-banner.component";
import { CVHomefourMainBannerComponent3 } from "./components/pages/cv-home/homefour-main-banner/homefour-main-banner.component";
import { CvTarifComponent } from "./components/pages/cv-tarif/cv-tarif.component";
import { CvTutoComponent } from "./components/pages/cv-tuto/cv-tuto.component";
import { CvTutoBannerComponent } from "./components/pages/cv-tuto/homesix-main-banner/homesix-main-banner.component";
import { CvContactComponent } from "./components/pages/cv-contact/cv-contact";
import { CvHomethreeCoursesComponent } from "./components/pages/cv-contact/homethree-courses/homethree-courses.component";
import { CvHomethreeMainBannerComponent } from "./components/pages/cv-contact/homethree-main-banner/homethree-main-banner.component";
import { CVHomethreeAboutComponent } from "./components/pages/cv-contact/homethree-about/homethree-about.component";
import { CvFeedbackFormComponent } from "./components/common/cv-feedback-form/feedback-form.component";
import { CvPriceComponent } from "./components/pages/cv-price/cv-about.component";
import {
    CommonModule,
    HashLocationStrategy,
    LocationStrategy,
} from "@angular/common";
import { CVCheckoutComponent } from "./components/pages/cv-checkout/cv-checkout.component";
import { CVLoginSocialMediaPageComponent } from "./components/pages/cv-login-page/cv-login-social-media.component";
import { CoverDashboardComponent } from "./components/pages/cover-modele/cover-dashboard.component";

import { M2AddCoverComponent } from "./components/pages/cover-modele/modeles/m2-add-cover/m2-add-cover.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { M1EditCoverComponent } from "./components/pages/cover-modele/modeles/m1-edit-cover/m1-edit-cover.component";
import { M3AddCoverComponent } from "./components/pages/cover-modele/modeles/m3-add-cover/m3-add-cover.component";
import { M4AddCoverComponent } from "./components/pages/cover-modele/modeles/m4-add-cover/m4-add-cover.component";
import { M5AddCoverComponent } from "./components/pages/cover-modele/modeles/m5-add-cover/m5-add-cover.component";
import { M6AddCoverComponent } from "./components/pages/cover-modele/modeles/m6-add-cover/m6-add-cover.component";
import { M7AddCoverComponent } from "./components/pages/cover-modele/modeles/m7-add-cover/m7-add-cover.component";
import { M8AddCoverComponent } from "./components/pages/cover-modele/modeles/m8-add-cover/m8-add-cover.component";
import { M1AddCoverComponent } from "./components/pages/cover-modele/modeles/m1-add-cover/m1-add-cover.component";
import { M2EditCoverComponent } from "./components/pages/cover-modele/modeles/m2-edit-cover/m2-edit-cover.component";
import { M3EditCoverComponent } from "./components/pages/cover-modele/modeles/m3-edit-cover/m3-edit-cover.component";
import { M4EditCoverComponent } from "./components/pages/cover-modele/modeles/m4-edit-cover/m4-edit-cover.component";
import { M5EditCoverComponent } from "./components/pages/cover-modele/modeles/m5-edit-cover/m5-edit-cover.component";
import { M6EditCoverComponent } from "./components/pages/cover-modele/modeles/m6-edit-cover/m6-edit-cover.component";
import { M7EditCoverComponent } from "./components/pages/cover-modele/modeles/m7-edit-cover/m7-edit-cover.component";
import { M8EditCoverComponent } from "./components/pages/cover-modele/modeles/m8-edit-cover/m8-edit-cover.component";
import { CoverModeleComponent } from "./components/pages/cover-modele/modeles/cover-modele.component";
import { M1CoverComponent } from "./components/pages/cv-dashboard/clmodele1/m1-edit-cover.component";
import { M2CoverComponent } from "./components/pages/cv-dashboard/clmodele2/m2-edit-cover.component";
import { M3CoverComponent } from "./components/pages/cv-dashboard/clmodele3/m3-edit-cover.component";
import { M4CoverComponent } from "./components/pages/cv-dashboard/clmodele4/m4-edit-cover.component";
import { M5CoverComponent } from "./components/pages/cv-dashboard/clmodele5/m5-edit-cover.component";
import { M6CoverComponent } from "./components/pages/cv-dashboard/clmodele6/m6-edit-cover.component";
import { M7CoverComponent } from "./components/pages/cv-dashboard/clmodele7/m7-edit-cover.component";
import { M8CoverComponent } from "./components/pages/cv-dashboard/clmodele8/m8-edit-cover.component";
import { MCV2Component } from "./components/pages/cv-cvtheque/modele2/m2-edit.component";
import { MCV1Component } from "./components/pages/cv-cvtheque/modele1/m1-edit.component";
import { MCV3Component } from "./components/pages/cv-cvtheque/modele3/m3-edit.component";
import { MCV4Component } from "./components/pages/cv-cvtheque/modele4/m4-edit.component";
import { MCV5Component } from "./components/pages/cv-cvtheque/modele5/m5-edit.component";
import { MCV6Component } from "./components/pages/cv-cvtheque/modele6/m6-edit.component";
import { MCV7Component } from "./components/pages/cv-cvtheque/modele7/m7-edit.component";
import { MCV8Component } from "./components/pages/cv-cvtheque/modele8/m8-edit.component";
import { MCV9Component } from "./components/pages/cv-cvtheque/modele9/m9-edit.component";
import { MCV10Component } from "./components/pages/cv-cvtheque/modele10/m10-edit.component";
import { MCV11Component } from "./components/pages/cv-cvtheque/modele11/m11-edit.component";
import { MCV12Component } from "./components/pages/cv-cvtheque/modele12/m12-edit.component";
import { MCV13Component } from "./components/pages/cv-cvtheque/modele13/m13-edit.component";
import { MCV14Component } from "./components/pages/cv-cvtheque/modele14/m14-edit.component";
import { MCV15Component } from "./components/pages/cv-cvtheque/modele15/m15-edit.component";
import { MCV16Component } from "./components/pages/cv-cvtheque/modele16/m16-edit.component";
import { MCV17Component } from "./components/pages/cv-cvtheque/modele17/m17-edit.component";
import { MCV18Component } from "./components/pages/cv-cvtheque/modele18/m18-edit.component";
import { MCV19Component } from "./components/pages/cv-cvtheque/modele19/m19-edit.component";
import { MCV20Component } from "./components/pages/cv-cvtheque/modele20/m20-edit.component";
import { MCV21Component } from "./components/pages/cv-cvtheque/modele21/m21-edit.component";
import { MCV22Component } from "./components/pages/cv-cvtheque/modele22/m22-edit.component";
import { MCV23Component } from "./components/pages/cv-cvtheque/modele23/m23-edit.component";
import { MCV24Component } from "./components/pages/cv-cvtheque/modele24/m24-edit.component";
import { CoverHomesevenMainBannerComponent } from "./components/pages/cover-theque/homeseven-main-banner/homeseven-main-banner.component";
import { CoverThequeComponent } from "./components/pages/cover-theque/cover-theque.component";
import { MCover1Component } from "./components/pages/cover-theque/clmodele1/m1-edit-cover.component";
import { MCover2Component } from "./components/pages/cover-theque/clmodele2/m2-edit-cover.component";
import { MCover3Component } from "./components/pages/cover-theque/clmodele3/m3-edit-cover.component";
import { MCover4Component } from "./components/pages/cover-theque/clmodele4/m4-edit-cover.component";
import { MCover5Component } from "./components/pages/cover-theque/clmodele5/m5-edit-cover.component";
import { MCover6Component } from "./components/pages/cover-theque/clmodele6/m6-edit-cover.component";
import { MCover7Component } from "./components/pages/cover-theque/clmodele7/m7-edit-cover.component";
import { MCover8Component } from "./components/pages/cover-theque/clmodele8/m8-edit-cover.component";
import { M1Edit2Component } from "./components/pages/cv-cvtheque/modeles/m1-edit/m1-edit.component";
import { M2Edit2Component } from "./components/pages/cv-cvtheque/modeles/m2-edit/m2-edit.component";
import { M3Edit2Component } from "./components/pages/cv-cvtheque/modeles/m3-edit/m3-edit.component";
import { M4Edit2Component } from "./components/pages/cv-cvtheque/modeles/m4-edit/m4-edit.component";
import { M5Edit2Component } from "./components/pages/cv-cvtheque/modeles/m5-edit/m5-edit.component";
import { M6Edit2Component } from "./components/pages/cv-cvtheque/modeles/m6-edit/m6-edit.component";
import { M7Edit2Component } from "./components/pages/cv-cvtheque/modeles/m7-edit/m7-edit.component";
import { M8Edit2Component } from "./components/pages/cv-cvtheque/modeles/m8-edit/m8-edit.component";
import { M9Edit2Component } from "./components/pages/cv-cvtheque/modeles/m9-edit/m9-edit.component";
import { M10Edit2Component } from "./components/pages/cv-cvtheque/modeles/m10-edit/m10-edit.component";
import { M11Edit2Component } from "./components/pages/cv-cvtheque/modeles/m11-edit/m11-edit.component";
import { M12Edit2Component } from "./components/pages/cv-cvtheque/modeles/m12-edit/m12-edit.component";
import { M13Edit2Component } from "./components/pages/cv-cvtheque/modeles/m13-edit/m13-edit.component";
import { M14Edit2Component } from "./components/pages/cv-cvtheque/modeles/m14-edit/m14-edit.component";
import { M15Edit2Component } from "./components/pages/cv-cvtheque/modeles/m15-edit/m15-edit.component";
import { M16Edit2Component } from "./components/pages/cv-cvtheque/modeles/m16-edit/m16-edit.component";
import { M17Edit2Component } from "./components/pages/cv-cvtheque/modeles/m17-edit/m17-edit.component";
import { M18Edit2Component } from "./components/pages/cv-cvtheque/modeles/m18-edit/m18-edit.component";
import { M19Edit2Component } from "./components/pages/cv-cvtheque/modeles/m19-edit/m19-edit.component";
import { M20Edit2Component } from "./components/pages/cv-cvtheque/modeles/m20-edit/m20-edit.component";
import { M21Edit2Component } from "./components/pages/cv-cvtheque/modeles/m21-edit/m21-edit.component";
import { M22Edit2Component } from "./components/pages/cv-cvtheque/modeles/m22-edit/m22-edit.component";
import { M23Edit2Component } from "./components/pages/cv-cvtheque/modeles/m23-edit/m23-edit.component";
import { M24Edit2Component } from "./components/pages/cv-cvtheque/modeles/m24-edit/m24-edit.component";
import { M1EditCover2Component } from "./components/pages/cover-theque/modeles/m1-edit-cover/m1-edit-cover.component";
import { M2EditCover2Component } from "./components/pages/cover-theque/modeles/m2-edit-cover/m2-edit-cover.component";
import { M3EditCover2Component } from "./components/pages/cover-theque/modeles/m3-edit-cover/m3-edit-cover.component";
import { M4EditCover2Component } from "./components/pages/cover-theque/modeles/m4-edit-cover/m4-edit-cover.component";
import { M5EditCover2Component } from "./components/pages/cover-theque/modeles/m5-edit-cover/m5-edit-cover.component";
import { M6EditCover2Component } from "./components/pages/cover-theque/modeles/m6-edit-cover/m6-edit-cover.component";
import { M7EditCover2Component } from "./components/pages/cover-theque/modeles/m7-edit-cover/m7-edit-cover.component";
import { M8EditCover2Component } from "./components/pages/cover-theque/modeles/m8-edit-cover/m8-edit-cover.component";
import { CvThequeEnvironnementComponent } from "./components/pages/cv-cvtheque/theque-environnement/cv-cvtheque.component";
import { CvThequeInformatiqueComponent } from "./components/pages/cv-cvtheque/theque-informatique/cv-cvtheque.component";
import { CvThequeBankFinanceComponent } from "./components/pages/cv-cvtheque/theque-bank-finance/cv-cvtheque.component";
import { CvThequeIndustryComponent } from "./components/pages/cv-cvtheque/theque-industry/cv-cvtheque.component";
import { CvThequeMarketingComponent } from "./components/pages/cv-cvtheque/theque-marketing/cv-cvtheque.component";
import { CvThequeBTPComponent } from "./components/pages/cv-cvtheque/theque-btp/cv-cvtheque.component";
import { CvThequeCareComponent } from "./components/pages/cv-cvtheque/theque-care/cv-cvtheque.component";
import { CvThequeHumanComponent } from "./components/pages/cv-cvtheque/theque-human/cv-cvtheque.component";
import { CvThequeOthersComponent } from "./components/pages/cv-cvtheque/theque-others/cv-cvtheque.component";
import { CoverThequeEnvironnementComponent } from "./components/pages/cover-theque/theque-environnement/cover-theque.component";
import { CoverThequeInformatiqueComponent } from "./components/pages/cover-theque/theque-informatique/cover-theque.component";
import { CoverThequeBankFinanceComponent } from "./components/pages/cover-theque/theque-bank-finance/cover-theque.component";
import { CoverThequeIndustryComponent } from "./components/pages/cover-theque/theque-industry/cover-theque.component";
import { CoverThequeMarketingComponent } from "./components/pages/cover-theque/theque-marketing/cover-theque.component";
import { CoverThequeBtpComponent } from "./components/pages/cover-theque/theque-btp/cover-theque.component";
import { CoverThequeCareComponent } from "./components/pages/cover-theque/theque-care/cover-theque.component";
import { CoverThequeHumanComponent } from "./components/pages/cover-theque/theque-human/cover-theque.component";
import { CoverThequeOthersComponent } from "./components/pages/cover-theque/theque-other/cover-theque.component";

import { CvsCoverDashboarComponent } from "./components/pages/cv-dashboard/cover-dashboard.component";
import { CvsInfoDashboardComponent } from "./components/pages/cv-dashboard/info-dashboard.component";
import { CvNotice2Component } from "./components/pages/cv-cvtheque/modeles/cv-notice2.component";
import { CoverNotice2Component } from "./components/pages/cover-theque/modeles/cover-notice2.component";
import { CvsSubscriptionInfoDashboardComponent } from "./components/pages/cv-dashboard/subscription-dashboard.component";
import { ApproveComponent } from "./components/pages/cv-checkout/approve/approve.component";
import { DeclineComponent } from "./components/pages/cv-checkout/decline/decline.component";
import { JwtInterceptor } from "@auth0/angular-jwt";

export function createTranslateLoader(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}

@NgModule({
    declarations: [
        AppComponent,
        HomePageOneComponent,
        HomePageTwoComponent,
        FooterComponent,
        PreloaderComponent,
        HomeoneAboutComponent,
        HomeoneMainBannerComponent,
        HeaderStyleOneComponent,
        CategoriesStyleOneComponent,
        FunfactsComponent,
        HomeoneCoursesComponent,
        PartnerStyleOneComponent,
        InstructorsStyleOneComponent,
        BecomeInstructorPartnerComponent,
        FeedbackStyleOneComponent,
        BlogComponent,
        HeaderStyleTwoComponent,
        HometwoMainBannerComponent,
        HometwoAboutComponent,
        HometwoCoursesComponent,
        OurMissionComponent,
        InstructorsStyleTwoComponent,
        WebinarCountdownComponent,
        StudentsFeedbackFormComponent,
        HomePageThreeComponent,
        HomePageFourComponent,
        HomePageFiveComponent,
        HomePageSixComponent,
        HomePageSevenComponent,
        PartnerStyleTwoComponent,
        OfferComponent,
        CategoriesStyleTwoComponent,
        HomethreeCoursesComponent,
        HomethreeAboutComponent,
        FeedbackFormComponent,
        HomethreeMainBannerComponent,
        HeaderStyleThreeComponent,
        HomefourMainBannerComponent,
        HomefourCoursesComponent,
        BoxesComponent,
        FeedbackStyleTwoComponent,
        HomefiveCoursesComponent,
        HomefiveMainBannerComponent,
        InstructorsStyleThreeComponent,
        FeaturesComponent,
        HomesixCoursesComponent,
        CategoriesStyleThreeComponent,
        HomesixMainBannerComponent,
        HeaderStyleFourComponent,
        HomesevenMainBannerComponent,
        HomesevenCoursesComponent,
        HowItWorksComponent,
        AboutPageOneComponent,
        AboutPageTwoComponent,
        OurStoryComponent,
        OurValuesComponent,
        InstructorsPageOneComponent,
        InstructorsPageTwoComponent,
        InstructorsPageThreeComponent,
        InstructorsDetailsPageComponent,
        GalleryPageComponent,
        EventsPageComponent,
        EventsDetailsPageComponent,
        PricingPageComponent,
        FeedbackPageComponent,
        PartnerPageComponent,
        LoginPageComponent,
        RegisterPageComponent,
        FaqPageComponent,
        ErrorPageComponent,
        ComingSoonPageComponent,
        BlogGridPageComponent,
        BlogRightSidebarPageComponent,
        BlogFullWidthPageComponent,
        BlogDetailsPageComponent,
        ShopGridPageComponent,
        ShopFullWidthPageComponent,
        ProductsDetailsPageComponent,
        CartPageComponent,
        CVCheckoutComponent,
        ApproveComponent,
        DeclineComponent,
        ContactPageComponent,
        CategoryPageOneComponent,
        CategoryPageTwoComponent,
        CategoryPageThreeComponent,
        CoursesListPageComponent,
        CoursesGridStyleOneColumnsTwoPageComponent,
        CoursesGridStyleOneColumnsThreePageComponent,
        CoursesGridStyleOneColumnsFourPageComponent,
        CoursesGridStyleTwoColumnsTwoPageComponent,
        CoursesGridStyleTwoColumnsThreePageComponent,
        CoursesGridStyleTwoColumnsFourPageComponent,
        CoursesGridStyleThreeColumnsTwoPageComponent,
        CoursesGridStyleThreeColumnsThreePageComponent,
        CoursesGridStyleThreeColumnsFourPageComponent,
        CoursesDetailsPageComponent,
        MyDashboardPageComponent,
        CVRegisterPageComponent,
        CVLoginPageComponent,
        CvPageComponent,
        CvSectionsComponent,
        M1EditComponent,
        M2EditComponent,
        M3EditComponent,
        M4EditComponent,
        M5EditComponent,
        M6EditComponent,
        M7EditComponent,
        M8EditComponent,
        M9EditComponent,
        M10EditComponent,
        M11EditComponent,
        M12EditComponent,
        M13EditComponent,
        M14EditComponent,
        M15EditComponent,
        M16EditComponent,
        M17EditComponent,
        M18EditComponent,
        M19EditComponent,
        M20EditComponent,
        M21EditComponent,
        M22EditComponent,
        M23EditComponent,
        M24EditComponent,
        CvTipsComponent,

        CVInterviewComponent,
        OrdersPageComponent,
        DownloadsPageComponent,
        EditAddressPageComponent,
        EditAccountPageComponent,
        EditBillingAddressPageComponent,
        EditShippingAddressPageComponent,
        LoadingComponent,

        Modele1Component,
        M1Component,
        M2Component,
        M3Component,
        M4Component,
        M5Component,
        M6Component,
        M7Component,
        M8Component,
        M9Component,
        M10Component,
        M11Component,
        M12Component,
        M13Component,
        M14Component,
        M15Component,
        M16Component,
        M17Component,
        M18Component,
        M19Component,
        M20Component,
        M21Component,
        M22Component,
        M23Component,
        M24Component,

        MCV1Component,
        MCV2Component,
        MCV3Component,
        MCV4Component,
        MCV5Component,
        MCV6Component,
        MCV7Component,
        MCV8Component,
        MCV9Component,
        MCV10Component,
        MCV11Component,
        MCV12Component,
        MCV13Component,
        MCV14Component,
        MCV15Component,
        MCV16Component,
        MCV17Component,
        MCV18Component,
        MCV19Component,
        MCV20Component,
        MCV21Component,
        MCV22Component,
        MCV23Component,
        MCV24Component,

        M1Edit2Component,
        M2Edit2Component,
        M3Edit2Component,
        M4Edit2Component,
        M5Edit2Component,
        M6Edit2Component,
        M7Edit2Component,
        M8Edit2Component,
        M9Edit2Component,
        M10Edit2Component,
        M11Edit2Component,
        M12Edit2Component,
        M13Edit2Component,
        M14Edit2Component,
        M15Edit2Component,
        M16Edit2Component,
        M17Edit2Component,
        M18Edit2Component,
        M19Edit2Component,
        M20Edit2Component,
        M21Edit2Component,
        M22Edit2Component,
        M23Edit2Component,
        M24Edit2Component,

        CvModeleComponent,
        CvThemeComponent,
        CvDashboardComponent,
        CvsSubscriptionInfoDashboardComponent,
        Theme1Component,
        Theme2Component,
        CvInterviewBannerComponent,
        CvHomeComponent,
        CvHomeModelesComponent,
        EncryptPipe,
        DecryptPipe,
        M1AddComponent,
        M2AddComponent,
        M3AddComponent,
        M4AddComponent,
        M5AddComponent,
        M6AddComponent,
        M7AddComponent,
        M8AddComponent,
        M9AddComponent,
        M10AddComponent,
        M11AddComponent,
        M12AddComponent,
        M13AddComponent,
        M14AddComponent,
        M15AddComponent,
        M16AddComponent,
        M17AddComponent,
        M18AddComponent,
        M19AddComponent,
        M20AddComponent,
        M21AddComponent,
        M22AddComponent,
        M23AddComponent,
        M24AddComponent,
        CvPrivacyComponent,
        CvFaqComponent,
        CvConditionComponent,
        CvPageComponent,
        CvAboutComponent,
        CvPriceComponent,
        CVGetCodeComponent,
        CVResetPasswordComponent,
        CvThequeComponent,
        CVHomesevenMainBannerComponent,
        CVHomefourMainBannerComponent3,
        CvTarifComponent,
        CvTutoComponent,
        CvTutoBannerComponent,
        CvContactComponent,
        CvHomethreeCoursesComponent,
        CvHomethreeMainBannerComponent,
        CVHomethreeAboutComponent,
        CvFeedbackFormComponent,
        CVLoginSocialMediaPageComponent,
        CoverDashboardComponent,

        M1AddCoverComponent,
        M2AddCoverComponent,
        M3AddCoverComponent,
        M4AddCoverComponent,
        M5AddCoverComponent,
        M6AddCoverComponent,
        M7AddCoverComponent,
        M8AddCoverComponent,

        M1EditCoverComponent,
        M2EditCoverComponent,
        M3EditCoverComponent,
        M4EditCoverComponent,
        M5EditCoverComponent,
        M6EditCoverComponent,
        M7EditCoverComponent,
        M8EditCoverComponent,

        M1EditCover2Component,
        M2EditCover2Component,
        M3EditCover2Component,
        M4EditCover2Component,
        M5EditCover2Component,
        M6EditCover2Component,
        M7EditCover2Component,
        M8EditCover2Component,

        CoverModeleComponent,

        M1CoverComponent,
        M2CoverComponent,
        M3CoverComponent,
        M4CoverComponent,
        M5CoverComponent,
        M6CoverComponent,
        M7CoverComponent,
        M8CoverComponent,

        MCover1Component,
        MCover2Component,
        MCover3Component,
        MCover4Component,
        MCover5Component,
        MCover6Component,
        MCover7Component,
        MCover8Component,

        CoverHomesevenMainBannerComponent,
        CoverThequeComponent,

        CvThequeEnvironnementComponent,
        CvThequeInformatiqueComponent,
        CvThequeBankFinanceComponent,
        CvThequeIndustryComponent,
        CvThequeMarketingComponent,
        CvThequeBTPComponent,
        CvThequeCareComponent,
        CvThequeHumanComponent,
        CvThequeOthersComponent,

        CoverThequeEnvironnementComponent,
        CoverThequeInformatiqueComponent,
        CoverThequeBankFinanceComponent,
        CoverThequeIndustryComponent,
        CoverThequeMarketingComponent,
        CoverThequeBtpComponent,
        CoverThequeCareComponent,
        CoverThequeHumanComponent,
        CoverThequeOthersComponent,

        CvsCoverDashboarComponent,
        CvsInfoDashboardComponent,
        CvNotice2Component,
        CoverNotice2Component,
    ],
    imports: [
        HttpClientModule,
        FontAwesomeModule,
        BrowserModule,
        AppRoutingModule,
        CKEditorModule,
        BrowserAnimationsModule,
        CarouselModule,
        CountUpModule,
        TabsModule,
        NgxScrollTopModule,
        StickyNavModule,
        LightboxModule,
        FormsModule,
        ReactiveFormsModule,
        AngularEditorModule,
        ColorPickerModule,
        HttpClientInMemoryWebApiModule.forRoot(ThemeDataService, {
            dataEncapsulation: false,
            passThruUnknownUrl: true,
        }),
        NgxAwesomePopupModule.forRoot(), // Essential, mandatory main module.
        ConfirmBoxConfigModule.forRoot(), // Essential, mandatory confirm box module.
        ToastNotificationConfigModule.forRoot(), // Essential, mandatory toast module.

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],

    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        CookieService,
        EncryptPipe,
        DecryptPipe,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
    ],

    bootstrap: [AppComponent],
})
export class AppModule {}
