<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>FAQ</li>
            </ul>
            <h2>Foire Aux Questions</h2>
        </div>
    </div>
</div>

<div class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="faq-accordion-tab">
            <ngx-tabset>
                <ngx-tab tabTitle="Débuter avec iCV">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Qu'est ce que iCv-pro.com?</h3>
                                <p><strong>R:</strong> iCv est un site qui facilite la création des CV modernes et
                                    professionels, et met à votre disposition des outils et conseils pour mieux gérer votre processus d'emploi.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Comment faire pour créer un CV avec iCV?</h3>
                                <p><strong>R:</strong> Une fois sur notre page d'accueil, vous devez vous enregistrer.
                                    Après l'enregistrement, vous êtes automatiquement redirigé(e) sur votre tableau de bord
                                    où vous pouvez cliquer sur "Ajout CV",
                                    et ensuite choisir un modèle pour commencer la rédaction de votre CV. </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Puis-je utiliser un ordinateur ou un smartphone?</h3>
                                <p><strong>R:</strong> Avec les deux vous pouvez créer un magnifique CV. Mais plus l'écran est de grande taille, plus
                                le site est convivial. Un ordinateur serait donc le meilleur choix. </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Quel est le meilleur navigateur pour créer un CV avec iCV?</h3>
                                <p><strong>R:</strong> Pour un écran supérieur ou égal à 17 pouces, nous vous conseillons
                                    d'utiliser Firefox.
                                    Pour un écran inférieur à 16 pouces, nous vous conseillons Opera.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Comment choisir un modèle de CV?</h3>
                                <p><strong>R:</strong> Avant de commencer à créer votre CV, on vous donne la possibilité
                                    de choisir un modèle qui vous convient
                                    parmi les modèles présents dans le site.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Comment modifier un modèle pour un CV déjà créé?</h3>
                                <p><strong>R:</strong> C'est un opération très facile car une fois connecté(e), vous avez accès
                                    à tous vos CV.
                                    Avec un clic vous choisissez le CV à modifier, dans la partie supérieure de votre CV
                                    à gauche,
                                    vous pouvez modifier le modèle en un clic. Vous pouvez également choisir un thème
                                    qui vous convient et personnaliser les couleurs.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Comment accéder à mon CV avec un autre appareil ?</h3>
                                <p><strong>R:</strong> En vous connectant avec vos identifiants vous avez accès à vos CV
                                    partout où vou irez.</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Que faire en cas de bug?</h3>
                                <p><strong>R:</strong> Enregistrer les données puis actualiser la page.
                                    Au cas où le bug persiste, revenir sur la page d'accueil.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Comment puis-je télécharger un CV?</h3>
                                <p><strong>R:</strong> Pendant l'ajout ou la modification de votre CV, vous n'avez qu'à
                                    cliquer le bouton "Télécharger" pour télécharger votre CV au format PDF.
                                    Cette option est accessible pendant une période de promotion ou si votre abonnement n'est pas
                                    à terme.</p>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <!--
                <ngx-tab tabTitle="Prix ou Tarification">
                    <div class="row">

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Combien coûte le service?</h3>
                                <p><strong>R:</strong> Tout est libre d'accès pour une durée indterminée</p>

                                   <p> Le paiement sera une fois la période </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Combien de CV pour un compte?</h3>
                                <p><strong>R:</strong> Le nombre total de CV que vous pouvez créer est de six. Ceci vous permet
                                de personnaliser pour vos CV pour chaque candidature</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Comment annuler mon abonnement?</h3>
                                <p><strong>R:</strong> Votre abonnement s'annule automatiquement après 31 jours. Vous êtes le seul ou la seule
                                à pouvoir renouveller votre abonnement.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Pourrai-je télécharger sans abonnement?</h3>
                                <p><strong>A:</strong>Vous ne pouvez télécharger sans abonnement que durant les périodes de promotion. Les périodes de
                                promotion vous seront indiquées dans le site.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Quels sont les autres services disponibles?</h3>
                                <p><strong>R:</strong>Les autres services s'ajoutéront pendant les prochaines mises à jour. Parmi les services, nous aurons la création des lettres
                                de motivation, les offres d'emploi... </p>
                            </div>
                        </div>

                    </div>
                </ngx-tab>
                -->
                <ngx-tab tabTitle="Fonctionnalités">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Peut-on changer de couleur du CV?</h3>
                                <p><strong>R:</strong> Vous pouvez modifier la couleur de tous les modèles de CV.
                                    Il suffit de sélectionner un modèle, et vous avez la possibilité de modifier les couleurs sur trois zones du CV.
                                    Vous avez accès à toutes couleurs possibles...</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Comment faire si je n'arrive pas à télécharger mon CV ?</h3>
                                <p><strong>R:</strong> Il vous suffit de cliquer sur télécharger dans la page de modification du CV
                                  et d'attendre que le téléchargement puisse se terminer.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Combien de page au maximum peut avoir mon CV?</h3>
                                <p><strong>R:</strong> Chaque CV peut n'avoir que trois pages au maximum.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Comment éviter la coupure des mots en fin de page ?</h3>
                                <p><strong>R:</strong>Il suffit d'insérer des espaces dans les éléments précédents, soit ajouté du texte entre les descriptions des expériences professionnelles
                                    Vous pouvez utiliser l'expérience professionnelle ou l'éducation pour mettre les espaces ou ajouter du contenu,
                                    ou même d'ajouter un champ vide.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: La suppression d'un CV est-elle définitive?</h3>
                                <p><strong>R:</strong> Oui elle est définitive, lorsque vous supprimez un CV, il est impossible de le recupérer. Nous ne concervons pas vos données supprimées</p>
                            </div>
                        </div>


                    </div>
                </ngx-tab>


            </ngx-tabset>
        </div>
    </div>
</div>
<!--
<app-become-instructor-partner></app-become-instructor-partner>
-->
