<footer class="footer-area">
    <div class="container">
        <div class="row">
            <!--
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">2750 Quadra Street Victoria Road, New York, United States</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+11234567890">+1 (123) 456 7890</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:hello@ednuv.com">hello@ednuv.com</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                    </ul>
                </div>
            </div>
        -->

            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Support</h3>
                    <ul class="support-link">
                        <li><a routerLink="/privacy">Politique de Confidentialité</a></li>
                        <li><a routerLink="/faq">FAQ</a></li>
                      
                        <li><a routerLink="/conditions">Conditions générales</a></li>
                        <li><a routerLink="/about">A propos</a></li>
                        
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Conseils</h3>
                    <ul class="support-link">
                        <li><a routerLink="/conseils">Conseils pratiques</a></li>
                        <li><a routerLink="/entretien">Préparer son entretien</a></li>
                        <li><a routerLink="/page">Les pages dans un CV</a></li>
                        <li><a routerLink="/rubriques">Rubriques dans un CV</a></li>
                        
                        
                    </ul>
                </div>
            </div>
             

           
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Ressources</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/modeles">Modèles CV</a></li>
                        <li><a routerLink="/tutoriel">Tutoriel</a></li>
                        <li><a routerLink="/cvtheque">CVThèque</a></li>
                        <li><a routerLink="/tarification">Tarifs</a></li>
                       
                  
                    </ul>
                </div>
            </div>
         <!-- 
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>
                    <div class="newsletter-box">
                        <p>To get the latest news and latest updates from us.</p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
          -->

        </div>
    </div>

    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/logo.png" alt="image"></a>
            </div>
            <p><i class='bx bx-copyright'></i> 2022 <a routerLink="/" target="_blank"> iCV </a> conçu et développé par <a href="#" target="_blank"> Logiiplus </a> | Tous droits reservés.</p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
