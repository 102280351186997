import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { OwlOptions } from "ngx-owl-carousel-o";
import { CVCheckoutService } from "./cv-checkout.service";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import { TokenStorageService } from "../login-page/token-storage.service";

@Component({
    selector: "app-checkout-page",
    templateUrl: "./cv-checkout.component.html",
    styleUrls: ["./cv-checkout.component.scss"],
})
export class CVCheckoutComponent implements OnInit {
    abonnementFormGroup: FormGroup;
    cardFormGroup: FormGroup;
    monthFormGroup: FormGroup;
    mtnFormGroup: FormGroup;
    airtelFormGroup: FormGroup;
    vodacomFormGroup: FormGroup;

    constructor(
        private fb: FormBuilder,
        private cvCheckoutService: CVCheckoutService,
        private router: Router,
        private cookieService: CookieService,
        private tokenStorageService: TokenStorageService
    ) {}

    ngOnInit(): void {
        this.initAbonnementFormGroup();

        this.findOne();
        this.onRadioClick(1);
    }

    subscription: any = {};
    amount: number = 0;
    numberofmonths: number = 1;
    isoldprice: boolean = false;
    oldamount: number = 0;
    currency: string = "USD";
    currencycode: string = "$";
    reference: string = "";
    countries: Array<any> = [];
    isoperator: boolean = false;
    iscard2: boolean = true;
    coeffficient: number = 1;
    ismtn: boolean = true;
    isairtel: boolean = true;
    isvodacom: boolean = true;

    findOne() {
        this.cvCheckoutService.findOne(1).subscribe(
            (data) => {
                this.subscription = data;
                this.amount = this.subscription.cardamount;
                this.isoldprice = this.subscription.isoldprice;
                this.oldamount = this.subscription.oldamount;
                this.isoperator = this.subscription.isoperator;
                this.iscard2 = this.subscription.iscard;
                this.currency = this.subscription.currency;
                this.currencycode = this.subscription.currencycode;
                this.ismtn = this.subscription.ismtn;
                this.isairtel = this.subscription.isairtel;
                this.isvodacom = this.subscription.isvodacom;
            },
            (error) => {},

            () => {}
        );
    }

    findCountry(operator: string) {
        this.cvCheckoutService.findByCountry(operator).subscribe(
            (data) => {
                this.countries = data;
                // this.amount=
            },
            (error) => {},

            () => {}
        );
    }

    initAbonnementFormGroup(): void {
        this.abonnementFormGroup = this.fb.group({
            option: [""],
            option2: [""],
            country: [""],
            telephone: [""],
        });
    }

    price: number = 0;

    changeNumber(value: number) {
        if (value === 1) {
            this.numberofmonths = 1;
            this.coeffficient = 1;
        }
        if (value === 3) {
            this.numberofmonths = 3;
            this.coeffficient = 2;
        }
        if (value === 6) {
            this.numberofmonths = 6;
            this.coeffficient = 4;
        }
        if (value === 12) {
            this.numberofmonths = 12;
            this.coeffficient = 6;
        }
    }

    initCardFormGroup(): void {
        this.cardFormGroup = this.fb.group({
           // authorization: ["this.tokenStorageService.getToken()"],
           authorization: ["Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJcL2xvZ2luIiwicm9sZXMiOlsiTUVSQ0hBTlQiXSwiZXhwIjoxNzMyNzAxODk4LCJzdWIiOiI4OGJjMjhkZjZmZmNiMzEyMDFlOWU5ODQxOGNkN2ViZCJ9.AI6PF1mn9O3mq86RdIofyHgfq3NoGY7HX6kA6wO5GSk"],
            merchant: ["ASF"],
            reference: [this.reference],
            amount: [this.amount * this.coeffficient],
            currency: [this.currency],
            description: ["CARD PAYMENT"],
            callback_url: ["http://localhost:4200/subscription/paid/callback"],
            approve_url: ["http://localhost:4200/#/approve"],
            cancel_url: ["http://localhost:4200/#/s-abonner"],
            decline_url: ["http://localhost:4200/#/decline"],
        });
    }

    cancel() {
        const path = this.cookieService.get("lasturl");
        let link = [path];
        // this.cookieService.delete('lasturl');
        this.router.navigate(link);
    }

    calculateDate() {
        dayjs.locale("fr");
        const now = dayjs();
        const dateDans15Jours = now.add(15, "day").endOf("day");
        const ilYa90Jours = now.subtract(90, "day");
        const dateDans3Mois = now.add(3, "month");

        alert(
            "Ajourd'hui vient après demain : " + now.isAfter(dateDans15Jours)
        );
        alert(
            " AJourd'hui vient avant demain " + now.isBefore(dateDans15Jours)
        );
        const diffInDays = now.diff(dateDans15Jours, "month");
        const diffInDays2 = now.diff(dateDans3Mois, "day");
        const diffInDays3 = dateDans15Jours.diff(now, "day");
        const diffInDays4 = now.diff(dateDans3Mois, "month");
        alert(
            " d- " +
                diffInDays +
                " d- " +
                diffInDays2 +
                " d+ " +
                diffInDays3 +
                " m- " +
                diffInDays4
        );
        alert("Dans 15 jours 1: " + dateDans15Jours.format());
        alert(
            "Dans 15 jours 2: " + dateDans15Jours.format("dddd, D MMMM YYYY")
        );
        alert("Il y'a 90 jours : " + ilYa90Jours.format("dddd, D MMMM YYYY"));
        alert("Dans 3 mois : " + dateDans3Mois.format("dddd, D MMMM YYYY"));

        alert(now);
        //alert(now.format("dddd, D MMMM YYYY"));
        // alert(now.format("DD/MM/YYYY"));

        // alert(now.format("YYYY-MM-DD"));
        // alert(now.format("YYYY-MM-DD HH:mm:ss"));

        const jour = now.date();

        const days = now.daysInMonth(); // Nombre de jour dans le mois
        const mois = now.month() + 1; // Ajouter 1 pour obtenir le mois en format 1-12 plutôt que 0-11
        const annee = now.year();
        const heure = now.hour();
        const minutes = now.minute();
        const seconde = now.second();
        const millisecond = now.millisecond();

        alert(" days : " + jour + " ## " + days);
        alert(" days : " + jour + " ## " + days);
    }

    // Méthode de calcul de congés
    addWeekdayWithoutWeekend(startDate, numDays) {
        let result = dayjs(startDate); // Crée une instance de Dayjs pour la date de départ

        let daysToAdd = 0; // Initialise le nombre de jours à ajouter à 0
        while (daysToAdd < numDays) {
            // Boucle jusqu'à ce que le nombre de jours ajoutés soit égal au nombre de jours spécifié
            result = result.add(1, "day"); // Ajoute 1 jour à la date de départ
            if (result.day() !== 0 && result.day() !== 6) {
                // Vérifie si le jour ajouté est un jour de semaine (pas un samedi ou un dimanche)
                daysToAdd++; // Incrémente le nombre de jours ajoutés si le jour est un jour de semaine
            }
        }
        return result; // Retourne la date calculée
        //exple: const startDate = '2022-03-21'; // Date de départ
        //  const numDays = 10; // Nombre de jours à ajouter
        // console.log(result.format('YYYY-MM-DD'))
    }

    //en enlevons aussi les jours fériés
    addWeekdayWithoutHolidaysAndWeekends(startDate, numDays, holidays) {
        let result = dayjs(startDate); // Crée une instance de Dayjs pour la date de départ
        let daysToAdd = 0; // Initialise le nombre de jours à ajouter à 0
        while (daysToAdd < numDays) {
            // Boucle jusqu'à ce que le nombre de jours ajoutés soit égal au nombre de jours spécifié
            result = result.add(1, "day"); // Ajoute 1 jour à la date de départ
            if (result.day() !== 0 && result.day() !== 6) {
                // Vérifie si le jour ajouté est un jour de semaine (pas un samedi ou un dimanche)
                const isHoliday = holidays.some((holiday) =>
                    holiday.isSame(result, "day")
                ); // Vérifie si le jour ajouté est un jour férié
                if (!isHoliday) {
                    // Exclut le jour férié s'il existe
                    daysToAdd++; // Incrémente le nombre de jours ajoutés si le jour est un jour de semaine et n'est pas un jour férié
                }
            }
        }
        return result; // Retourne la date calculée
        /*
        const holidays = [
            dayjs('2022-04-01'), // Jour férié 1
            dayjs('2022-04-05'), // Jour férié 2
            // Ajoutez d'autres jours fériés si nécessaire
          ];
          */
    }

    initMtnFormGroup(): void {
        this.mtnFormGroup = this.fb.group({
            option: ["", Validators.required],
            country: ["", Validators.required],
            phone: ["", Validators.required],
            amount: ["", Validators.required],
        });
    }

    initAirtelFormGroup(): void {
        this.airtelFormGroup = this.fb.group({
            option: ["", Validators.required],
            country: ["", Validators.required],
            phone: ["", Validators.required],
            amount: ["", Validators.required],
        });
    }

    initVodacomFormGroup(): void {
        this.vodacomFormGroup = this.fb.group({
            option: ["", Validators.required],
            country: ["", Validators.required],
            phone: ["", Validators.required],
            amount: ["", Validators.required],
        });
    }

    @ViewChild("mtn") mtn: ElementRef;
    @ViewChild("airtel") airtel: ElementRef;
    @ViewChild("paypal") paypal: ElementRef;
    @ViewChild("visa") visa: ElementRef;
    @ViewChild("mastercard") mastercard: ElementRef;

    checkMTN(event) {
        // alert(this.abonnementFormGroup.value.option);
        // alert(this.abonnementFormGroup.get("option").value+" 2");
    }

    detailsImageSlides: OwlOptions = {
        loop: true,
        nav: false,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        items: 1,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>",
        ],
    };

    isCard: boolean = true;
    isMTN: boolean = false;
    isAirtel: boolean = false;
    isVodacom: boolean = false;

    onRadioClick(value: number) {
        //this.abonnementFormGroup.get("flag1").setValue(value);

        if (value === 1) {
            this.isCard = true;
            this.isMTN = false;
            this.isAirtel = false;
            this.isVodacom = false;
            this.initCardFormGroup();
            this.findOne();
        }
        if (value === 2) {
            this.isCard = false;
            this.isMTN = true;
            this.isAirtel = false;
            this.isVodacom = false;
            this.findCountry("mtn");
        }

        if (value === 3) {
            this.isCard = false;
            this.isMTN = false;
            this.isAirtel = true;
            this.isVodacom = false;
            this.findCountry("airtel");
        }

        if (value === 4) {
            this.isCard = false;
            this.isMTN = false;
            this.isAirtel = false;
            this.isVodacom = true;
            this.findCountry("vodacom");
        }
    }

    isMonth: boolean = true;
    isThreeMonths: boolean = false;
    isSixMonths: boolean = false;
    isYear: boolean = false;

    onRadioClickAmount(value: number) {
        if (value === 1) {
            this.numberofmonths = 1;
            this.price = this.amount;

            this.isMonth = true;
            this.isThreeMonths = false;
            this.isSixMonths = false;
            this.isYear = false;
        }
        if (value === 2) {
            this.numberofmonths = 3;
            this.price = this.amount * 2;

            this.isMonth = false;
            this.isThreeMonths = true;
            this.isSixMonths = false;
            this.isYear = false;
        }
        if (value === 4) {
            this.numberofmonths = 6;
            this.price = this.amount * 4;

            this.isMonth = false;
            this.isThreeMonths = false;
            this.isSixMonths = true;
            this.isYear = false;
        }
        if (value === 6) {
            this.numberofmonths = 12;
            this.price = this.amount * 6;

            this.isMonth = false;
            this.isThreeMonths = false;
            this.isSixMonths = false;
            this.isYear = true;
        }
    }

    selectCountry(event: any) {
        const name = event.target.value;

        const country = this.countries.find((c) => c.name === name);

        this.amount = country.amount;
        this.currency = country.currency;
        this.currencycode = country.currencycode;
    }

    getCharactersAfterKeyword(text: string, keyword: string): string {
        const index = text.indexOf(keyword);

        if (index === -1) {
            return "";
        }

        const startIndex = index + keyword.length;
        return text.substring(startIndex);
    }

    continue() {
        const now = dayjs();


        let day: string = "" + now.date();
        if (now.date() < 10) {
            day = "0" + day;
        }
        let month: string = "" + (now.month() + 1);
        if (now.month() + 1 < 10) {
            month = "0" + (now.month() + 1);
        }
        const year = now.year();
        let maner = "C";
        const id = this.tokenStorageService.getUser().id;
        if (this.isCard) {
            maner = "C";
        }
        if (this.isMTN) {
            maner = "M";
        }
        if (this.isAirtel) {
            maner = "A";
        }
        if (this.isVodacom) {
            maner = "V";
        }
        let nberofmonths: string = "" + this.numberofmonths;
        if (this.numberofmonths < 10) {
            nberofmonths = "0" + this.numberofmonths;
        }



        this.reference =
            id +
            "ICV" +
            day +
            "" +
            month +
            "" +
            year +
            "M" +
            nberofmonths +
            maner;

        const keyword = "ICV";

        //alert(this.getCharactersAfterKeyword(this.reference, keyword));
        const day2 = this.getCharactersAfterKeyword(
            this.reference,
            keyword
        ).substring(0, 2);
        const month2 = this.getCharactersAfterKeyword(
            this.reference,
            keyword
        ).substring(2, 4);
        const year2 = this.getCharactersAfterKeyword(
            this.reference,
            keyword
        ).substring(4, 8);
        const nberofmonth = this.getCharactersAfterKeyword(
            this.reference,
            keyword
        ).substring(9, 11);
       // alert("nber of months : " + nberofmonth);

        const num: number = parseInt(nberofmonth, 10);


        if (isNaN(num)) {

        } else {
          //  alert(" NBRE DE MOIS : "+num);
        }
       // alert(year2 + "-" + month2 + "-" + day2);
        let link = ["decline"];
        // this.cookieService.delete('lasturl');
        // this.router.navigate(link);
        let expirationDate = new Date();
        expirationDate.setMinutes(expirationDate.getMinutes() + 60);
        this.cookieService.set("reference", this.reference, expirationDate);
        setTimeout(() => {
            this.initCardFormGroup();
        }, 312);

        setTimeout(() => {
          //alert(JSON.stringify(this.cardFormGroup.value));
           this.cvCheckoutService.cardpaid(this.cardFormGroup.value).subscribe(
      (data) => {
        alert(data);


      },
      (error) => {

        alert("Error : "+error.error.message);
      },

      () => {
        //alert(JSON.stringify(this.listCV));

      }
    );

        }, 712);
    }
}
