<app-header-style-one></app-header-style-one>
<div class="login-area">
    <div class="row m-0">


        <div class="col-lg-12 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">


                            <h4>
                               Veuillez insérer votre adresse mail
                            </h4>
                            <p>{{loginMessage}}</p>
                            <form [formGroup]="emailFormGroup" >
                                <div class="form-group">
                                    <input class="form-control" type="email" placeholder="Email" formControlName="email">
                                    <div class="alert alert-danger" *ngIf="isSubmitted && formControls.email.errors &&
                                    formControls.email.errors.required">L'email est requis</div>
                                </div>
                                <p *ngIf="isError" class="text-danger">{{message}}</p>
                                <button *ngIf="!isClick" [disabled]="!emailFormGroup.valid" type="button" class="vvv" (click)="getCode()">Confirmer</button>

                                <button *ngIf="isClick" type="button" class="vvv" >
                                <span role="status" style="color: #f9f6f6;"
                                    class="spinner-border spinner-danger spinner-border-sm"></span>
                                 </button>

                                <div class="forgot-password">
                                    <a routerLink="/se-connecter">Retour</a>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
