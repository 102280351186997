import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cv-contact',
  templateUrl: './cv-contact.component.html',
  styleUrls: ['./cv-contact.component.scss']
})
export class CvContactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
