import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";

import { tap, catchError, map, timeout } from "rxjs/operators";

import { environment } from "src/environments/environment";

import { TokenStorageService } from "../login-page/token-storage.service";
import { Cv } from "../cv-dashboard/cv";
import { Flexcard } from "./flexcard";

@Injectable({
    providedIn: "root",
})
export class CVCheckoutService {
    private HOST = environment.baseUrl + "subscription/";
    private HOST2 = environment.baseUrl + "country/";
    private HOST3 = "https://beta-cardpayment.flexpay.cd/v1/pay";

    private httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    constructor(
        private http: HttpClient,
        private tokenStorage: TokenStorageService
    ) {}

    paid(flexcard: Flexcard): Observable<any> {
        return this.http.post(this.HOST3, flexcard);
    }

    cardpaid(flexcard: Flexcard) {

        const formData = new FormData();
        formData.append("authorization", flexcard.authorization);
        formData.append("amount", flexcard.amount);
        formData.append("reference", flexcard.reference);
        formData.append("merchant", flexcard.merchant);
        formData.append("callback_url", flexcard.callback_url);
        formData.append("currency", flexcard.currency);
        formData.append("description", flexcard.description);
        formData.append("cancel_url", flexcard.cancel_url);
        formData.append("decline_url", flexcard.decline_url);
        formData.append("approve_url", flexcard.approve_url);

        return this.http.post("https://beta-cardpayment.flexpay.cd/v1/pay", formData);
    }

    update(cv: Cv, id: number): Observable<any> {
        //alert(JSON.stringify(cv));
        const url = this.HOST + "update/" + id;
        return this.http.patch(url, cv);
    }

    findAll(): Observable<any> {
        return this.http.get(this.HOST + "findAll3", this.httpOptions);
    }

    findByCountry(operator: string): Observable<any> {
        return this.http.get(
            this.HOST2 + "findByOperator/" + operator,
            this.httpOptions
        );
    }

    findByFlag2(flag2: number): Observable<any> {
        return this.http.get(
            this.HOST + "findByFlag2/" + flag2,
            this.httpOptions
        );
    }

    findOne(id: number): Observable<any> {
        const url = this.HOST + "findOne/" + id;

        return this.http.get(url);
    }

    searchByname(term: string): Observable<any> {
        const url = this.HOST + "searchByName/" + term;
        if (!term.trim() || term.trim().length < 3) {
            return of([]);
        } else {
            return this.http.get(url);
        }
    }

    searchByname2(term: string): Observable<any> {
        const url = this.HOST + "searchByName2/" + term;
        if (!term.trim() || term.trim().length < 3) {
            return of([]);
        } else {
            return this.http.get(url);
        }
    }

    searchByname3(term: string): Observable<any> {
        const url = this.HOST + "searchByName3/" + term;
        if (!term.trim() || term.trim().length < 3) {
            return of([]);
        } else {
            return this.http.get(url);
        }
    }

    fetchImageById(photoname: string): Observable<Blob> {
        let url = this.HOST + "show-photo/" + photoname;
        let token = this.tokenStorage.getToken();
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        // console.log("Profile image URL is " + url);

        //return this.http.get(url,{headers: headers, responseType: 'blob'});
        return this.http.get(url, { responseType: "blob" });
    }

    private handleError<T>(operation = "operation", result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            console.log(`${operation} failed: ${error.message}`);

            return of(result as T);
        };
    }
}
