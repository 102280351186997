import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../cv-login-page/auth-signin.service";
import { TokenStorageService } from "../login-page/token-storage.service";
import { User_ } from "../login-page/user";
import { RegisterService } from "./register.service";

@Component({
    selector: "app-register-page",
    templateUrl: "./cv-register-page.component.html",
    styleUrls: ["./cv-register-page.component.scss"],
})
export class CVRegisterPageComponent implements OnInit {
    registerFormGroup: FormGroup;
    user: User_;
    isSubmitted = false;
    public showPassword: boolean;
    public showPasswordOnPress: boolean;

    isLoggedIn = false;
    isLoginFailed = false;
    errorMessage: string = "";
    loginMessage: string = "";
    roles: string[] = [];
    mode: number = 0;

    constructor(
        private fb: FormBuilder,
        private registerService: RegisterService,
        private tokenStorage: TokenStorageService,
        private authService: AuthService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.initRegisterForm();
    }

    initRegisterForm(): void {
        this.registerFormGroup = this.fb.group({
            firstname: ["", Validators.required],
            lastname: ["", Validators.required],
            email: ["", [Validators.required, Validators.email]],
            password: ["", [Validators.required, Validators.minLength(6)]],
        });
    }
    get formControls() {
        return this.registerFormGroup.controls;
    }

    isLoading = false;

    register() {
        this.loginMessage = "Tentative de connexion en cours ...";

        this.registerService.signup(this.registerFormGroup.value).subscribe(
            (resp) => {
                // this.tokenStorage.saveToken(data.accessToken);

                this.isLoginFailed = false;
                this.isLoggedIn = true;
                this.loginMessage = "Vous êtes connecté(e)";
            },
            (err) => {
                this.isLoginFailed = true;
                this.isLoading = false;
                this.errorMessage = err.error.message;

                this.mode = 1;
            },
            () => {
                this.isLoading = true;

                setTimeout(() => {
                    // alert(this.tokenStorage.getUser().id)
                    this.isLoading = false;
                    this.login();
                }, 3000);
            }
        );
    }

    login() {
        this.loginMessage = "Tentative de connexion en cours ...";
        let userLogin = {
            email: this.registerFormGroup.get("email").value,
            password: this.registerFormGroup.get("password").value,
        };

        this.authService.login(userLogin).subscribe(
            (resp) => {
                // this.tokenStorage.saveToken(data.accessToken);
                let jwtToken = resp.body.access_token;

                this.tokenStorage.saveToken("Bearer " + jwtToken);

                this.isLoginFailed = false;
                this.isLoggedIn = true;
                this.loginMessage = "Vous êtes connecté(e)";

                // Récupère l'URL de redirection depuis le service d'authentification
                // Si aucune redirection n'a été définis, redirige l'utilisateur vers la liste des patients.
                // let redirect = this.redirectUrl ? this.redirectUrl : '/patient/list';

                // Redirige l'utilisateur

                // this.reloadPage();
            },
            (err) => {
                alert(err.status);

                this.isLoginFailed = true;
                this.errorMessage = err.error.message;

                this.mode = 1;
            },
            () => {
                this.loadUser();

                setTimeout(() => {
                    // alert(this.tokenStorage.getUser().id)

                    if (this.tokenStorage.getUser() === null) {
                    } else {
                        let redirect = "/cvs";

                        this.router.navigate([redirect]);
                    }
                }, 1000);
            }
        );
    }

    googleConnect() {
        this.authService.googleConnect();
    }

    loadUser(): void {
        this.registerService.findOne().subscribe(
            (data) => {
                this.user = data;
                this.tokenStorage.saveUser(data);
            },
            (error) => {
                this.tokenStorage.signOut();
            },
            () => {}
        );
    }
}
