<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Tarifs</li>
            </ul>
            <h2>Tarifs</h2>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="col-lg-2 col-md-2 col-sm-2">
                </div>
                <div class="col-lg-8 col-md-8 col-sm-12 about-content">
                    <!-- <span class="sub-title">About Us !</span> -->
                    <h2 style="font-size: 25px;"> Tarif de l'abonnement .</h2>

                    <div class="features-text">
                        <h4 style="font-size: 18px">
                            L'abonnement est gratuit sur une période indeterminée.

                        </h4>
                        <br>

                    </div>


                </div>
                <div class="col-lg-2 col-md-2 col-sm-2">
                </div>

            </div>
        </div>

    </div>
</div>

<!--
<app-our-mission></app-our-mission>


<div class="story-area ptb-100">
    <app-our-story></app-our-story>
</div>

<div class="funfacts-area">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div>

<div class="values-area ptb-100">
    <app-our-values></app-our-values>
</div>

<div class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-two></app-instructors-style-two>
    </div>
</div>

<div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>
-->

<!--
<div class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>
-->
