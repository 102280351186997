import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Alert } from 'selenium-webdriver';
import { AuthService } from '../auth-signin.service';


@Component({
  selector: 'get-code',
  templateUrl: './cv-get-code.component.html',
  styleUrls: ['./cv-get-code.component.scss']
})
export class CVGetCodeComponent implements OnInit {

  emailFormGroup: FormGroup;

  isSubmitted  =  false;

  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage: string = '';
  loginMessage: string = '';
  roles: string[] = [];
  mode: number=0;

  constructor(private fb :FormBuilder,
    private authService : AuthService,
    private router: Router) { }

  ngOnInit(): void {
   this.initEmailForm()
  }

  initEmailForm() : void{
    this.emailFormGroup  =  this.fb.group({
        email: ['', [Validators.required, Validators.email]],

    });
  }


  get formControls() {
       return this.emailFormGroup.controls;
    }


    message : string="";
    isError: boolean=false;
    isClick :boolean=false;

      getCode(): void {
        this.isClick=true;
        this.authService.getCode(this.emailFormGroup.get('email').value)
          .subscribe(
            (data) => {

            this.isError=false;

            },
            (error) => {
              this.isClick=false;
              this.isError=true;
            this.message=error.error.message
            },
            () => {
              this.isClick=true;
             this.router.navigateByUrl('/reset-password');
            }
          );

      }


      reloadPage() {
        window.location.reload();
      }

}


