import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../cv-login-page/token-storage.service';
import { CvThequeService } from '../cv-cvtheque.service';
import {    Observable, Subject,  } from 'rxjs';
import { filter,map, distinctUntilChanged, debounceTime,switchMap } from 'rxjs/operators'
import { Cv } from '../modeles/cv';

@Component({
  selector: 'cv-homeseven-main-banner',
  templateUrl: './homeseven-main-banner.component.html',
  styleUrls: ['./homeseven-main-banner.component.scss']
})
export class CVHomesevenMainBannerComponent implements OnInit {


  private searchTerms = new Subject<string>();
  listCV$ : Observable<Cv[]>;
  isAdmin : boolean=false;

  constructor(
    private cvThequeService: CvThequeService,
    private tokenStorageService: TokenStorageService,
    private router: Router,

  ) { }


          ngOnInit(): void {

            this.listCV$=this.searchTerms.pipe(
                // {...."ab"..."abz"."ab"...."abc"......}
            debounceTime(500),
          // {......"ab"...."ab"...."abc"......}
            distinctUntilChanged(),
          // {......"ab"..........."abc"......}
          switchMap((term) => this.cvThequeService.searchByname2(term))
          // {.....List(ab)............List(abc)......}
            );
          //  this.findAll();
            if(this.tokenStorageService.getToken() && this.tokenStorageService.getUser().role==="admin"){
                this.isAdmin=true;
            }



        // alert(this.tokenStorageService.getUser().email);

      }

      search(term: string) : void{
              this.searchTerms.next(term);
      }

      goToDetail(cv: Cv): void {

        let link = ["cvtheque/m" + cv.idmodele + "-edit", cv.id];
        this.router.navigate(link);
      }

    }

