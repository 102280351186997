<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Mon Tableau de bord</li>
            </ul>
            <h2>Mon Tableau de bord</h2>
        </div>
    </div>
</div>



<!--
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            title
        </mat-expansion-panel-header>
        content
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            title2
        </mat-expansion-panel-header>
        content2
    </mat-expansion-panel>
</mat-accordion>
-->
<div class="my-dashboard-area ptb-100">
    <div class="container">

        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/cvs"  ><i class='bx bx-id-card'></i> Mes CV</a></li>
                <li><a routerLink="/cvs/cover_letters" class="active"><i class='bx bx-file'></i> Mes lettres de motivation</a></li>
                <li><a routerLink="/cvs/information"><i class='bx bx-user'></i>Mes informations</a></li>
                <li><a routerLink="/cvs/subscription"><i class='bx bx-file'></i>Mes abonnements</a></li>



            </ul>
        </div>
        <div class="myDashboard-content">

            <div class="products-details-tab-content">
                <div *ngIf="spin1" type="button" class="col-lg-12 col-md-12"
                style="padding: 7px; font-size: 12px;  height: 30px;width: 30px;">
                <span role="status" style="color: #003147;"
                    class="spinner-border spinner-danger spinner-border-sm"></span>
               </div>

                <div class="col-lg-12 col-md-12">

                <div class="row">

                    <div *ngIf="listCoverSize  <= maxcover" class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box mb-30 " style="padding: 0;">
                            <div class="product-image" style="padding: 0;">
                                <a routerLink="/cover-letter">
                                    <div  class="imgDiv">
                                    <img src="assets/img/add1-1.jpg" alt="image">

                                </div>
                                </a>
                                <a routerLink="/cover-letter" class="add-to-cart-btn">Ajouter une lettre de motivation<i class='bx bx-file'></i></a>
                            </div>
                            <div class="product-content" style="max-height: 60px; margin-top: -21px; margin-bottom: 21px;">
                                <a style="font-size:23px ;margin-right: 7px; float: left;" routerLink="/cover-modeles" href="javascript:" class="add-to-cart-btn"> <i class='bx bx-plus-circle'></i></a>
                                <a style="font-size:15px ;margin-right: 7px; float: right;" routerLink="/cover-modeles" href="javascript:" class="add-to-cart-btn"> Ajout d'une lettre</a>

                            </div>

                        </div>
                    </div>




                    <div *ngFor="let cover of listCover" class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box mb-30 " style="padding: 0;">

                            <div  class="product-image" style="padding: 0;">
                                <a (click)="showCover(cover)" style="padding: 0;">
                                    <div  class="imgDiv">
                                        <m1-cover *ngIf="cover.idmodele===1" [cover]="cover"></m1-cover>
                                        <m2-cover *ngIf="cover.idmodele===2"  [cover]="cover"></m2-cover>
                                        <m3-cover *ngIf="cover.idmodele===3"  [cover]="cover"></m3-cover>
                                        <m4-cover *ngIf="cover.idmodele===4"  [cover]="cover"></m4-cover>
                                        <m5-cover *ngIf="cover.idmodele===5"  [cover]="cover"></m5-cover>
                                        <m6-cover *ngIf="cover.idmodele===6"  [cover]="cover"></m6-cover>
                                        <m7-cover *ngIf="cover.idmodele===7" [cover]="cover"></m7-cover>
                                        <m8-cover *ngIf="cover.idmodele===8"  [cover]="cover"></m8-cover>

                                    </div>
                                    <!--
                                    <img src="assets/img/shop/shop6.jpg" alt="image">
                                    <img src="assets/img/shop/shop6-1.jpg" alt="image">
                                    -->
                                </a>
                                <a (click)="showCover(cover)" href="javascript:" class="add-to-cart-btn">Modifier <i class='bx bx-edit'></i></a>
                            </div>
                            <div class="product-content" style="max-height: 60px; margin-top: -21px; margin-bottom: 21px;">
                                <a *ngIf="!isClick" style="font-size:23px ;margin-right: 7px; float: left;" (click)="showCover(cover)" href="javascript:" class="add-to-cart-btn"> <i class='bx bx-edit'></i></a>
                                <a *ngIf="!isClick" style="font-size:23px ;margin-right: 7px; float: left;" (click)="duplicateCover(cover)" title="Dupliquer" href="javascript:" class="add-to-cart-btn"><i class="bx bxs-duplicate"></i>  </a>
                                <a *ngIf="!isClick" style="font-size:23px ; float: left;" (click)="confirmBoxCover(cover)" title="Supprimer" href="javascript:" class="add-to-cart-btn"> <i class='bx bx-trash'></i>  </a>
                                <a *ngIf="isClick" style="font-size:23px ; float: left; margin-top: -4px;" title="Supprimer" href="javascript:" class="add-to-cart-btn">  <span role="status" style="color: #202122;"
                                    class="spinner-border spinner-danger spinner-border-sm"></span>  </a>
                                <a style="font-size:15px ; float: right;" (click)="showCover(cover)" routerLink="/single-products">{{cover.firstname+" "+cover.lastname}}</a>

                            </div>
                        </div>

                    </div>

              </div>


            </div>
            </div>

        </div>
    </div>
</div>
