import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cv-home',
  templateUrl: './cv-home.component.html',
  styleUrls: ['./cv-home.component.scss']
})
export class CvHomeComponent implements OnInit {

  constructor() { }


  isFunc : boolean=false;

  ngOnInit(): void {



  }




}
