<app-header-style-one></app-header-style-one>

<app-homefour-main-banner3></app-homefour-main-banner3>

<!--
FONCTIONNALITES
-->
<div class="funfacts-style-two ptb-70">
    <div class="container">
        <app-funfacts></app-funfacts>
    </div>
</div>
<app-features></app-features>
<!--
FONCTIONNALITES
-->
<app-boxes></app-boxes>

<div class="about-area ptb-100">
<div class="container-fluid">

    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="about-content left-content">
                <span class="sub-title" >AYEZ UN CV PROFESSIONNEL EN QUELQUES MINUTES AVEC ICV</span>
                <h2>L'importance d'avoir un CV bien rédigé </h2>
                <h6>Faites en sorte que votre CV se démarque de la concurrence</h6>
                <p>Gardez le recruteur intéressé afin que vous ayez la chance de décrocher un entretien.
                    Illustrez clairement vos compétences et vos réalisations professionnelles en rédigeant votre CV de façon conviviale afin de
                    ne pas passer inaperçu lors d'un processus de recrutement.
                </p>
                <p> Avec nos <a routerLink="/modeles"> <strong>modèles de CV</strong> </a> et nos conseils, faites en sorte que le resumé
                     de ce qui représente tout votre parcours et vos réalisations soit rédigé
                de façon à captiver les attentions, c'est l'élément qui fera pencher la balance de votre côté. </p>

                <div class="signature">
                    <img src="assets/img/signature.png" alt="image">
                </div>

            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div  routerLink="/modeles" class="about-right-image">
                <img src="assets/img/cv1.png" alt="image">
                <img src="assets/img/cv11.jpg" alt="image">

                <div class="text-box">
                    <div class="inner">
                        Votre
                        <span>CV</span>
                        en quelques minutes
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</div>
<!--
<app-our-mission></app-our-mission>
-->

<!--
<div class="courses-categories-area bg-image pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Courses Categories</span>
            <h2>Browse Trending Categories</h2>
            <a routerLink="/courses-category-style-1" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">View All</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <app-categories-style-one></app-categories-style-one>
    </div>
</div>
-->

<app-offer></app-offer>



<!--
<div class="feedback-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Ce que disent les utilisateurs</h2>
        </div>
        <app-feedback-style-one></app-feedback-style-one>
    </div>
</div>
-->


<div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Notre Entreprise et nos Partenaires</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>
<!--
<app-students-feedback-form></app-students-feedback-form>
-->
<!--
<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-one></app-instructors-style-one>
    </div>
</div>
-->



<!--
<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Explore News</span>
            <h2>Our Latest Insights</h2>
            <a routerLink="/blog-style-1" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">Read All</span><i class="bx bx-book-reader icon-arrow after"></i></a>
        </div>
        <app-blog></app-blog>
    </div>
</div>
-->

<!--
<app-become-instructor-partner></app-become-instructor-partner>
-->
