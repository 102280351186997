<div class="checkout-area ptb-100" style="margin-left : 12px; margin-right: 33px ">
    <div class="container">
        <div class="user-actions" style="margin-bottom: 0; ">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-6 ">

                    <span >

                        <a style=" font-size: 15px;font-weight: 600;" routerLink="/cover-letter/examples">
                            <i class="bx bx-arrow-back"></i>
                            {{'examples' | translate}}
                        </a>
                    </span>
                </div>


                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 ">
                    <div class="form-group">
                        <h3>CONSEILS PRATIQUES</h3>






                    </div>


                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">

                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">



                </div>
            </div>


        </div>

        <div id="content_1" class="row " style="height: 1000pt; overflow: scroll;">


            <div class="col-lg-12 col-md-12" style="margin-bottom: -12px;">
                <div class="billing-details ">

                    <div class="row ">


                        <div class="col-lg-12 col-md-12" style="padding: 33px;">
                            <div class="story-content">
                                <p>Une lettre de motivation est un document d'une page que vous soumettez
                                    dans le cadre de votre candidature (accompagné de votre CV).</p>

                                <p> Il a pour but de vous présenter et résumer brièvement votre parcours
                                    professionnel. En moyenne, votre lettre de motivation doit contenir
                                    entre 250 et 400 mots.</p>

                                <p> Une bonne lettre de motivation peut susciter l'intérêt du responsable
                                    des ressources humaines et l'amener à lire votre CV.

                                </p>
                                <p>
                                    La lettre de motivation est le premier élément qu'un
                                    employeur lit de vous dans la plus part des cas. Votre lettre doit
                                    immédiatement indiquer le poste
                                    pour lequel vous postulez, puis donner des informations qui démontrent
                                    pourquoi vous devriez être considéré pour le poste. Ne répétez pas
                                    toutes les informations contenues dans votre CV. Au lieu de cela,
                                    développez les éléments de CV directement applicables
                                    au poste pour lequel vous postulez. Les informations suivantes doivent
                                    être incluses dans votre lettre de motivation:

                                </p>

                                <h3>Les détails personnels</h3>
                                <p> Commencez votre lettre de motivation par vos coordonnées. Il devrait
                                    être en bloc,souvent sur la marge gauche de votre document, vers le
                                    haut.
                                </p>
                                <div class="about-text">

                                    <ul class="features-list">
                                        <li><i class='bx bx-check'></i> Prénom et Nom</li>
                                        <li><i class='bx bx-check'></i> Adresse physique Actuelle</li>
                                        <li><i class='bx bx-check'></i> Numéro de téléphone</li>
                                        <li><i class='bx bx-check'></i> Linkedin (Optionel)</li>
                                    </ul>
                                </div>

                                <h3>Date </h3>
                                <p>Inclure la date à laquelle vous envoyez votre candidature. Elle est
                                    souvent précédée de la ville où vous vous situez suivi d'une virgule.
                                </p>

                                <h3>Destinataire : responsable de recrutement de la société cible</h3>
                                <p>
                                    Vous devez adresser une lettre de motivation au responsable du
                                    recrutement du poste pour lequel vous postulez ou au responsable des
                                    ressources humaines de l'entreprise. Souvent le titre (poste occupé) ou
                                    role de la de la personne suffit.
                                    Plusieurs formules de politesse sont possibles pour démarrer une lettre
                                    de motivation, mais celle-ci est sans doute la plus conseillée : "À
                                    l'attention de Madame / Monsieur la / le...".
                                </p>

                                <h3>Objet </h3>

                                <p> L'objet d'une lettre de motivation peut être constitué des éléments
                                    suivants:
                                </p>
                                <div class="about-text">


                                    <ul class="features-list">
                                        <li><i class='bx bx-check'></i> Intitulé du poste publié</li>
                                        <li><i class='bx bx-check'></i> Nom de l’entreprise, école ou centre
                                            de formation que vous visez.</li>
                                        <li><i class='bx bx-check'></i> Numéro de l’offre d’emploi.</li>

                                    </ul>
                                </div>

                                <h3>Salutations </h3>

                                <p>
                                    Préférez
                                    simplement « Madame » ou « Monsieur » ou encore « Madame / Monsieur »
                                    lorsque
                                    vous ne maîtrisez pas le sexe du destinataire sans pour autant rajouter
                                    le nom de famille.

                                </p>
                                <h3> Introduction</h3>
                                <p>
                                    Le premier paragraphe de votre lettre de motivation doit fournir les
                                    détails de base sur qui vous êtes et pourquoi vous voulez le poste.
                                    Incluez le titre du poste pour lequel vous postulez, donnez un aperçu
                                    général des raisons pour lesquelles vous excelleriez dans le poste et
                                    des raisons pour lesquelles vous êtes enthousiasmé par le poste. Vous
                                    pouvez mentionner comment vous avez entendu parler du poste et pourquoi
                                    vous avez décidé de postuler, ce qui est une stratégie particulièrement
                                    efficace si un autre employé vous a référé au poste. Passez en revue
                                    l'offre d'emploi pour les principales forces requises pour le poste et
                                    utilisez votre paragraphe d'introduction pour expliquer comment vous
                                    présentez ces qualités.

                                </p>
                                <h3>Situation actuelle, Qualifications</h3>
                                <p>
                                    Après l'introduction, concentrez-vous sur votre situation actuelle,
                                    votre histoire et vos
                                    qualifications. Cela vous permet d'attirer l'attention de l'employeur en
                                    partageant immédiatement comment vous pouvez être un atout pour son
                                    équipe.
                                    Fournissez plus de détails sur les informations que vous incluez dans
                                    votre CV et concentrez-vous sur la manière dont vos expériences
                                    s'appliquent spécifiquement au poste. Expliquez
                                    comment vous avez contribué au succès des projets passés et attirez
                                    l'attention sur l'impact de vos actions.

                                </p>

                                <h3>Motivations</h3>
                                <p>
                                    Le paragraphe suivant doit démontrer que vous comprenez la mission de
                                    l'entreprise et que vous avez effectué des recherches sur le poste.
                                    Concentrez-vous sur la façon dont vos objectifs s'alignent sur les leurs
                                    et connectez-vous aux éléments que vous aimez dans la culture
                                    d'entreprise. Expliquez comment le travail que vous faites peut
                                    bénéficier mutuellement à votre avenir et aux besoins de votre futur
                                    employeur. Cette section de votre lettre de motivation vous permet de
                                    montrer que vous pouvez non seulement bien faire le travail, mais aussi
                                    vous intégrer à une équipe et apporter une attitude positive sur le lieu
                                    de travail.

                                </p>

                                <h3>Fin</h3>
                                <p>
                                    Le dernier paragraphe doit résumer votre intérêt et suggérer les
                                    prochaines étapes pour procéder à la demande. Remerciez le responsable
                                    du recrutement d'avoir pris le temps d'examiner votre candidature et
                                    exprimez votre intérêt à discuter du poste ou à planifier une entrevue.
                                    Votre lettre de motivation doit indiquer comment vous pouvez bénéficier
                                    à l'entreprise, alors concentrez-vous sur les compétences et le talent
                                    que vous espérez apporter à leur équipe.

                                </p>
                                <p> Signez avec une phrase de clôture professionnelle et votre signature ou votre nom.</p>



                            </div>
                        </div>




                    </div>

                </div>










            </div>


        </div>





    </div>


</div>
