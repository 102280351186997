import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './auth-signin.service';
import { TokenStorageService } from './token-storage.service';
import { User_ } from './user';

@Component({
  selector: 'app-login-social-media',
  templateUrl: './cv-login-social-media.component.html',
  styleUrls: ['./cv-login-page.component.scss']
})
export class CVLoginSocialMediaPageComponent implements OnInit {

  loginFormGroup: FormGroup;
  user: User_;
  isSubmitted  =  false;
  public showPasswordOnPress: boolean;

  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage: string = '';
  loginMessage: string = '';
  roles: string[] = [];
  mode: number=0;
  access_token: any;

  constructor(private cookieService: CookieService,
    private authService : AuthService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {

  //this.access_token=this.cookieService.get("token");

  let token= this.route.snapshot.params["token"];

 this.tokenStorage.saveToken("Bearer "+token); // change




  this.chargeUser();
  this.isLoginFailed = false;
  this.isLoggedIn = true;
  this.loginMessage = 'Vous êtes connecté(e)';
  setTimeout(() => {
 //  alert(this.cookieService.get("token"))
   // this.cookieService.delete("token");
     if(this.tokenStorage.getToken()===null){
      this.isLoginFailed = true;
      this.isLoggedIn = false;
      this.loginMessage = 'Connexion échouée !';


      this.mode=1;
      setTimeout(() => {

       let redirect ='/se-connecter';
       this.router.navigate([redirect]);
      }, 1273);
     }
     else {

      setTimeout(() => {

         let redirect ='/cvs';

         this.router.navigate([redirect]);
        }, 1273);
     }
     }, 127);


  }



  get formControls() {
       return this.loginFormGroup.controls;
    }



    login2(){
        console.log(this.loginFormGroup.value);
        this.isSubmitted = true;
        if(this.loginFormGroup.invalid){
          return;


        }
      //  this.authService.login(this.loginForm.value);
        this.router.navigateByUrl('/admin');
      }


   login() {
        this.loginMessage = 'Tentative de connexion en cours ...';

       this.authService.login(this.loginFormGroup.value).subscribe(
          (resp) => {


           // this.tokenStorage.saveToken(data.accessToken);
            let jwtToken=  resp.body.access_token;

           this.tokenStorage.saveToken("Bearer "+jwtToken);


            this.isLoginFailed = false;
            this.isLoggedIn = true;
            this.loginMessage = 'Vous êtes connecté(e)';

              // Récupère l'URL de redirection depuis le service d'authentification
              // Si aucune redirection n'a été définis, redirige l'utilisateur vers la liste des patients.
             // let redirect = this.redirectUrl ? this.redirectUrl : '/patient/list';





              // Redirige l'utilisateur


           // this.reloadPage();
          },
          err => {




            if(err.error.message=== undefined){
              this.errorMessage="Error Server"
            }else{
            this.errorMessage = err.error.message;
            }

            this.mode=1;
          },
          ()=>{

           // this.getCvs();
            this.chargeUser();

            setTimeout(() => {
             // alert(this.tokenStorage.getUser().id)

              if(this.tokenStorage.getUser()===null){
                let redirect ='/cvs';
                this.router.navigate([redirect]);
              }
              else {

                  let redirect ='/cvs';

                  this.router.navigate([redirect]);

              }
              }, 1000);


          }
        );
      }

       googleConnect(){

        this.authService.googleConnect();


      }

      getCvs(): void {

        this.authService.getCvs()
          .subscribe(
            (data) => {




            },
            (error) => {

              // alert(error.error.message);
             //  alert(this.tokenStorage.getUser())

            },
            () => {

            }
          );

      }


      chargeUser(): void {

        this.authService.findOne()
          .subscribe(
            (data) => {

              this.user=data;
              this.tokenStorage.saveUser(data);

            },
            (error) => {

              this.tokenStorage.signOut();
            },
            () => {


            }
          );
      }



      reloadPage() {
        window.location.reload();
      }

}


