import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";

import { tap, catchError, map, timeout } from "rxjs/operators";

import { environment } from "src/environments/environment";

import { TokenStorageService } from "../login-page/token-storage.service";

@Injectable({
    providedIn: "root",
})
export class ModeleService {
    private HOST =  environment.baseUrl+ 'modele/';
    private httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    constructor(
        private http: HttpClient,
        private tokenStorage: TokenStorageService
    ) {}

    findAll(): Observable<any> {
        return this.http.get(this.HOST + "findAll", this.httpOptions);
    }

    findOne(id: number): Observable<any> {
        const url = this.HOST + "findOne/" + id;

        return this.http.get(url);
    }



    private handleError<T>(operation = "operation", result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            console.log(`${operation} failed: ${error.message}`);

            return of(result as T);
        };
    }
}
