import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../../login-page/token-storage.service';

@Component({
  selector: 'app-homefour-main-banner',
  templateUrl: './homefour-main-banner.component.html',
  styleUrls: ['./homefour-main-banner.component.scss']
})
export class HomefourMainBannerComponent implements OnInit {

  constructor(private tokenStorageService: TokenStorageService) { }

  tokenStorage : any;

  ngOnInit(): void {
    this.tokenStorage=this.tokenStorageService;
  }

}
