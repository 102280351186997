import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Reset, UserLogin, User_ } from './user';
import { Contact } from './contact';

const AUTH_API =  environment.baseUrl+ 'user/'; //"http://localhost:3000/user/";
//const AUTH_API3 ="http://localhost:3000";
const AUTH_API2 =environment.baseUrl+ 'cv/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private roles: Array<any>=[];
  public username2: string;
  public isRLogs:boolean;

  constructor(private http: HttpClient) { }

  login(user: UserLogin ): Observable<any> {
    return this.http.post(AUTH_API + 'login', {
     email : user.email,
     password: user.password
    }, {observe: 'response'});
  }

  findOne(): Observable<any> {
    return this.http.get(AUTH_API + 'findOne', httpOptions);
  }

  googleConnect(): Observable<any>{
    return this.http.get(AUTH_API + "auth/google/callback");
}

  sendmessage(contact: Contact): Observable<any> {
    return this.http.post(AUTH_API + "sendmessage", contact);
}

  getCvs(): Observable<any> {

    return this.http.get(AUTH_API2+"findAll", httpOptions);
  }

  getCode(email : string): Observable<any> {
    return this.http.get(AUTH_API+"getCode/"+email, httpOptions);
  }

  resetPassword(reset : Reset): Observable<any> {
    return this.http.post(AUTH_API + 'resetPassword', {
      code: reset.code,
      password: reset.password
    }, httpOptions);
  }


  isAdmin(){
    return false;
  }

  register(user): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    }, httpOptions);
  }

}
