<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Mon Tableau de bord</li>
            </ul>
            <h2>Mon Tableau de bord</h2>
        </div>
    </div>
</div>



<!--
CV
-->

<div class="my-dashboard-area ptb-100">
    <div class="container">

        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/cvs" class="active" ><i class='bx bx-id-card'></i> Mes CV</a></li>
                <li><a routerLink="/cvs/cover_letters"><i class='bx bx-file'></i> Mes lettres de motivation</a></li>
                <li><a routerLink="/cvs/information"><i class='bx bx-user'></i>Mes informations</a></li>
                <li><a routerLink="/cvs/subscription"><i class='bx bx-file'></i>Mes abonnements</a></li>



            </ul>
        </div>
        <div class="myDashboard-content">

            <div class="products-details-tab-content" >
                <div *ngIf="spin1" type="button" class="col-lg-12 col-md-12"
                style="padding: 7px; font-size: 12px;  height: 30px;width: 30px;">
                <span role="status" style="color: #003147;"
                    class="spinner-border spinner-danger spinner-border-sm"></span>
               </div>

                <div class="col-lg-12 col-md-12">

                <div class="row">

                    <div *ngIf="listCVSize  <= maxcv" class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box mb-30 " style="padding: 0;">
                            <div class="product-image" style="padding: 0;">
                                <a routerLink="/modeles">
                                    <div  class="imgDiv">
                                    <img src="assets/img/add1.jpg" alt="image">

                                </div>
                                </a>
                                <a routerLink="/modeles" class="add-to-cart-btn">Ajouter un CV <i class='bx bx-file'></i></a>
                            </div>
                            <div class="product-content" style="max-height: 60px; margin-top: -21px; margin-bottom: 21px;">
                                <a style="font-size:23px ;margin-right: 7px; float: left;" routerLink="/modeles" href="javascript:" class="add-to-cart-btn"> <i class='bx bx-plus-circle'></i></a>
                                <a style="font-size:15px ;margin-right: 7px; float: right;" routerLink="/modeles" href="javascript:" class="add-to-cart-btn"> Ajout d'un CV</a>

                            </div>

                        </div>
                    </div>




                    <div *ngFor="let cv of listCV" class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-product-box mb-30 " style="padding: 0;">

                            <div  class="product-image" style="padding: 0;">
                                <a (click)="showCv(cv)" style="padding: 0;">
                                    <div  class="imgDiv">
                                        <modele1 *ngIf="cv.idmodele===1" [cv]="cv"></modele1>
                                        <modele2 *ngIf="cv.idmodele===2" [cv]="cv"></modele2>
                                        <modele3 *ngIf="cv.idmodele===3" [cv]="cv"></modele3>
                                        <modele4 *ngIf="cv.idmodele===4" [cv]="cv"></modele4>
                                        <modele5 *ngIf="cv.idmodele===5" [cv]="cv"></modele5>
                                        <modele6 *ngIf="cv.idmodele===6" [cv]="cv"></modele6>
                                        <modele7 *ngIf="cv.idmodele===7" [cv]="cv"></modele7>
                                        <modele8 *ngIf="cv.idmodele===8" [cv]="cv"></modele8>
                                        <modele9 *ngIf="cv.idmodele===9" [cv]="cv"></modele9>
                                        <modele10 *ngIf="cv.idmodele===10" [cv]="cv"></modele10>
                                        <modele11 *ngIf="cv.idmodele===11" [cv]="cv"></modele11>
                                        <modele12 *ngIf="cv.idmodele===12" [cv]="cv"></modele12>
                                        <modele13 *ngIf="cv.idmodele===13" [cv]="cv"></modele13>
                                        <modele14 *ngIf="cv.idmodele===14" [cv]="cv"></modele14>
                                        <modele15 *ngIf="cv.idmodele===15" [cv]="cv"></modele15>
                                        <modele16 *ngIf="cv.idmodele===16" [cv]="cv"></modele16>
                                        <modele17 *ngIf="cv.idmodele===17" [cv]="cv"></modele17>
                                        <modele18 *ngIf="cv.idmodele===18" [cv]="cv"></modele18>
                                        <modele19 *ngIf="cv.idmodele===19" [cv]="cv"></modele19>
                                        <modele20 *ngIf="cv.idmodele===20" [cv]="cv"></modele20>
                                        <modele21 *ngIf="cv.idmodele===21" [cv]="cv"></modele21>
                                        <modele22 *ngIf="cv.idmodele===22" [cv]="cv"></modele22>
                                        <modele23 *ngIf="cv.idmodele===23" [cv]="cv"></modele23>
                                        <modele24 *ngIf="cv.idmodele===24" [cv]="cv"></modele24>
                                    </div>
                                    <!--
                                    <img src="assets/img/shop/shop6.jpg" alt="image">
                                    <img src="assets/img/shop/shop6-1.jpg" alt="image">
                                    -->
                                </a>
                                <a (click)="showCv(cv)" href="javascript:" class="add-to-cart-btn">Modifier <i class='bx bx-edit'></i></a>
                            </div>
                            <div class="product-content" style="max-height: 60px; margin-top: -21px; margin-bottom: 21px;">
                                <a *ngIf="!isClick" style="font-size:23px ;margin-right: 7px; float: left;" (click)="showCv(cv)" href="javascript:" class="add-to-cart-btn"> <i class='bx bx-edit'></i></a>
                                <a *ngIf="!isClick" style="font-size:23px ;margin-right: 7px; float: left;" (click)="duplicateCv(cv)" title="Dupliquer" href="javascript:" class="add-to-cart-btn"><i class="bx bxs-duplicate"></i>  </a>
                                <a *ngIf="!isClick" style="font-size:23px ; float: left;" (click)="confirmBox(cv)" title="Supprimer" href="javascript:" class="add-to-cart-btn"> <i class='bx bx-trash'></i>  </a>
                                <a *ngIf="isClick" style="font-size:23px ; float: left; margin-top: -4px;" title="Supprimer" href="javascript:" class="add-to-cart-btn">  <span role="status" style="color: #202122;"
                                    class="spinner-border spinner-danger spinner-border-sm"></span>  </a>

                                <a style="font-size:15px ; float: right;" (click)="showCv(cv)" >{{cv.firstname+" "+cv.lastname}}</a>

                            </div>
                        </div>

                    </div>

              </div>


            </div>
            </div>
        </div>
    </div>
</div>
