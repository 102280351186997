<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Mes Cvs</li>
            </ul>
            <h2>Mes CVs</h2>
        </div>
    </div>
</div>

<div class="shop-area ptb-100">
    <div class="container">
        <div class="woocommerce-topbar">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="topbar-result-count">
                        <p>Showing 1 – 6 of 54</p>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="topbar-ordering-and-search">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-5 offset-lg-4 offset-md-1 col-sm-6">
                                <div class="topbar-ordering">
                                    <select class="form-select">
                                        <option>Sort by popularity</option>
                                        <option>Sort by latest</option>
                                        <option>Default sorting</option>
                                        <option>Sort by rating</option>
                                        <option>Sort by new</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 col-sm-6">
                                <div class="topbar-search">
                                    <form>
                                        <label><i class="bx bx-search"></i></label>
                                        <input type="text" class="input-search" placeholder="Search here...">
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a routerLink="/single-products">
                            <img src="assets/img/add1.jpg" alt="image">
                            <img src="assets/img/add1.jpg" alt="image">
                        </a>
                        <a routerLink="/cart" class="add-to-cart-btn">Ajouter un CV <i class='bx bx-file'></i></a>
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a routerLink="/single-products">
                            <img src="assets/img/shop/shop2.jpg" alt="image">
                            <img src="assets/img/shop/shop2-1.jpg" alt="image">
                        </a>
                        <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i class='bx bx-cart'></i></a>
                        <div class="sale-btn">Sale!</div>
                    </div>
                    <div class="product-content">
                        <h3><a routerLink="/single-products">Book Chicks</a></h3>
                        <div class="price">
                            <span class="old">$652.50</span>
                            <span class="new">$541.50</span>
                        </div>
                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star-half'></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a routerLink="/single-products">
                            <img src="assets/img/shop/shop3.jpg" alt="image">
                            <img src="assets/img/shop/shop3-1.jpg" alt="image">
                        </a>
                        <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i class='bx bx-cart'></i></a>
                    </div>
                    <div class="product-content">
                        <h3><a routerLink="/single-products">Book Divas</a></h3>
                        <div class="price">
                            <span class="new">$140.50</span>
                        </div>
                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bx-star'></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a routerLink="/single-products">
                            <img src="assets/img/shop/shop4.jpg" alt="image">
                            <img src="assets/img/shop/shop4-1.jpg" alt="image">
                        </a>
                        <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i class='bx bx-cart'></i></a>
                    </div>
                    <div class="product-content">
                        <h3><a routerLink="/single-products">Book Smart</a></h3>
                        <div class="price">
                            <span class="old">$600.00</span>
                            <span class="new">$547.00</span>
                        </div>
                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a routerLink="/single-products">
                            <img src="assets/img/shop/shop5.jpg" alt="image">
                            <img src="assets/img/shop/shop5-1.jpg" alt="image">
                        </a>
                        <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i class='bx bx-cart'></i></a>
                    </div>
                    <div class="product-content">
                        <h3><a routerLink="/single-products">Book Broads</a></h3>
                        <div class="price">
                            <span class="new">$655.50</span>
                        </div>
                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bx-star'></i>
                            <i class='bx bx-star'></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a routerLink="/single-products">
                            <img src="assets/img/shop/shop6.jpg" alt="image">
                            <img src="assets/img/shop/shop6-1.jpg" alt="image">
                        </a>
                        <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i class='bx bx-cart'></i></a>
                    </div>
                    <div class="product-content">
                        <h3><a routerLink="/single-products">Page Turners</a></h3>
                        <div class="price">
                            <span class="new">$415.00</span>
                        </div>
                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/shop-grid" class="page-numbers">2</a>
                    <a routerLink="/shop-grid" class="page-numbers">3</a>
                    <a routerLink="/shop-grid" class="page-numbers">4</a>
                    <a routerLink="/shop-grid" class="page-numbers">5</a>
                    <a routerLink="/shop-grid" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
