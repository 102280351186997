<div class="marge">
    <div class="row" style="padding: 0;">


        <!--   CV TEMPLATE -->




        <form [formGroup]="cvFormGroup" style="margin: 0;">

            <div class="col-lg-12 col-md-12 col-sm-12" style="padding: 0;">

                <!--  START PAGE 1-->


                <div class="container2" id="content1" #content1>
                    <div class="left_side" id="left_side1" #left_side1>
                        <div class="profileText">
                            <div *ngIf="!imageToShow && imgURL" class="imgBx">

                                <img class="" [src]="imgURL" alt="" />

                            </div>
                            <div *ngIf="imageToShow" class="imgBx">
                                <img *ngIf="imageToShow" class="img_class" [src]="imageToShow">
                            </div>
                            <h3>{{this.cvFormGroup.get("firstname").value+" "+this.cvFormGroup.get("lastname").value}}
                                <br /><span> {{this.cvFormGroup.get("profession").value}} </span></h3>

                        </div>
                        <div *ngIf="cvFormGroup.get('profile').value" class="contactInfo education">
                            <h5 class="title"><img *ngIf="!isIcon" src="assets/img/personal1.png" style="margin-right: 4px;" width="8" height="8">Profil</h5>

                            <p style="font-size: 5px; word-wrap: break-word;">

                                {{cvFormGroup.get('profile').value}}
                            </p>
                        </div>
                        <div class="contactInfo">
                            <h5 class="title" style="font-size: 7px;"><img *ngIf="!isIcon" src="assets/img/coord.png" style="margin-right: 4px;" width="9" height="9">Détails personnels </h5>
                            <ul>
                                <li *ngIf="cvFormGroup.get('telephone').value" class="liTitle">

                                    <img src="assets/img/phone4.png" width="7" height="7">

                                    <span class="liText" style="font-size: 6px;">  {{cvFormGroup.get('telephone').value}}</span>
                                </li>
                                <li *ngIf="cvFormGroup.get('email').value" class="liTitle">
                                    <img id="ic1" src="assets/img/envelop2.png" width="7" height="7">
                                    <span class="liText" style="font-size: 6px;">{{cvFormGroup.get('email').value}}</span>
                                </li>

                                <li *ngIf="cvFormGroup.get('address').value" class="liTitle">
                                    <img id="ic2" src="assets/img/map.png" width="7" height="7">
                                    <span class="liText" style="font-size: 6px;">{{cvFormGroup.get('address').value}}</span> <br/>
                                    <span class="liText" style="margin-left: 2px; margin-top: 0; font-size: 6px;">
                                                {{cvFormGroup.get('city').value+" , "+cvFormGroup.get('country').value}}</span>
                                </li>

                                <li id="ic3" *ngIf="cvFormGroup.get('webSite').value" class="liTitle">
                                    <img src="assets/img/globe.png" width="7" height="7">
                                    <span class="liText" style="font-size: 6px;">{{cvFormGroup.get('webSite').value}}</span>

                                </li>

                                <li id="ic4" *ngIf="cvFormGroup.get('linkedin').value" class="liTitle">
                                    <img src="assets/img/linkedin2.png" width="7" height="7">
                                    <span class="liText" style="font-size: 6px;">{{cvFormGroup.get('linkedin').value}}</span>
                                </li>

                                <li>
                                    <span class="icon"><i class=""></i></span>
                                    <span class="text"></span>
                                </li>

                            </ul>
                        </div>

                        <!--
                                <div class="contactInfo education">
                                    <h5 class="title">Education</h5>
                                    <ul>
                                        <li>
                                            <strong>2012-213</strong>
                                            <p>Master degree in Computer science</p>
                                            <p>Université Marien Ngouabi</p>
                                        </li>
                                        <li>
                                            <strong>2009-2011</strong>
                                            <p>Licence de </p>
                                            <p>University Name</p>
                                        </li>
                                        <li>
                                            <strong>2007-2009</strong>
                                            <p>Master degree in Computer science</p>
                                            <p>University Name</p>
                                        </li>
                                    </ul>
                                </div>
                                -->

                        <div *ngIf="cvFormGroup.get('skills').value.length" formArrayName="skills" class="contactInfo skills1" style="margin-top: -15px;">
                            <h5 class="title"><img *ngIf="!isIcon" src="assets/img/skills3.png" style="margin-right: 4px;" width="7" height="7">Compétences</h5>
                            <div *ngFor="let skill of cvSkills().controls; let skillIndex=index">
                                <div [formGroupName]="skillIndex">
                                    <ul>
                                        <li>
                                            <span class="text">{{cvSkills().at(skillIndex).get('item').value}}</span>
                                            <span class="percent">
                                                        <div [style.width]="cvSkills().at(skillIndex).get('level').value"></div>
                                                </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                        <!--

                                <div class="about language" formArrayName="skills">
                                    <h2 class="title2">Professional skills</h2>
                                    <div *ngFor="let skill of cvSkills().controls; let skillIndex=index">

                                        <div [formGroupName]="skillIndex">


                                            <div class="box">
                                                <h4 formControlName="skill">{{cvSkills().at(skillIndex).get('skill').value}} </h4>
                                                <div class="percent">
                                                    <div [style.width]="cvSkills().at(skillIndex).get('level').value"></div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                             -->

                    </div>
                    <div class="right_side" id="right_side1" #right_side1 style="padding-bottom: 12px;">


                        <div *ngIf="cvFormGroup.get('experiences').value.length" class="about" formArrayName="experiences">

                            <h2 class="title2"> <img *ngIf="!isIcon" [src]="imageToShow2" width="12" height="12"> Expérience professionnelle</h2>
                            <div *ngFor="let experience of cvExperiences().controls; let experienceIndex=index">

                                <div [formGroupName]="experienceIndex">
                                    <div class="box">
                                        <div class="year_company">
                                            <h5>{{cvExperiences().at(experienceIndex).get('period').value}}</h5>
                                            <h5 class="company">
                                                {{cvExperiences().at(experienceIndex).get('company').value}}
                                            </h5>
                                            <span>
                                                {{cvExperiences().at(experienceIndex).get('city').value}}
                                            </span>

                                        </div>
                                        <div class="text">
                                            <h4>
                                                {{cvExperiences().at(experienceIndex).get('function').value}}
                                            </h4>

                                            <p style="font-size: 3px; word-wrap: break-word;">
                                                {{cvExperiences().at(experienceIndex).get('description').value}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!-- EDUCATION -->

                        <div *ngIf="cvFormGroup.get('educations').value.length" class="about" formArrayName="educations">
                            <h2 class="title2"><img *ngIf="!isIcon" src="assets/img/education1.png" width="12" height="12"> Education</h2>
                            <div *ngFor="let education of cvEducations().controls; let educationIndex=index">
                                <div [formGroupName]="educationIndex">

                                    <div class="box">
                                        <div class="year_company">
                                            <h5> {{cvEducations().at(educationIndex).get('period').value}} </h5>

                                        </div>
                                        <div class="text">
                                            <h4>{{cvEducations().at(educationIndex).get('degree').value}} <span *ngIf="cvEducations().at(educationIndex).get('domain').value"> en</span> {{cvEducations().at(educationIndex).get('domain').value}}
                                            </h4>

                                            <p style="font-size: 8px; word-wrap: break-word;">
                                                <strong> {{cvEducations().at(educationIndex).get('institute').value}} </strong> {{" , "+cvEducations().at(educationIndex).get('city').value+" , "+ cvEducations().at(educationIndex).get('country').value}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>



                        <!-- LANGUES -->

                        <div *ngIf="cvFormGroup.get('languages').value.length" class="about language" formArrayName="languages">
                            <h2 class="title2"> <img *ngIf="!isIcon" src="assets/img/language1.png" style="margin-right: 4px;" width="12" height="12">Langues</h2>
                            <div *ngFor="let lang of cvLanguages().controls; let langIndex=index">
                                <div [formGroupName]="langIndex">

                                    <div class="box">
                                        <h4>{{cvLanguages().at(langIndex).get('item').value}}</h4>
                                        <div class="percent">
                                            <div [style.width]="cvLanguages().at(langIndex).get('level').value"></div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                        <!--

                                <div class="about language" formArrayName="skills">
                                    <h2 class="title2">Professional skills</h2>
                                    <div *ngFor="let skill of cvSkills().controls; let skillIndex=index">

                                        <div [formGroupName]="skillIndex">


                                            <div class="box">
                                                <h4 formControlName="skill">{{cvSkills().at(skillIndex).get('skill').value}} </h4>
                                                <div class="percent">
                                                    <div [style.width]="cvSkills().at(skillIndex).get('level').value"></div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                             -->

                        <div *ngIf="cvFormGroup.get('hobby1').value.trim() || cvFormGroup.get('hobby2').value.trim() ||
                        cvFormGroup.get('hobby3').value.trim() || cvFormGroup.get('hobby4').value.trim()" class="about interest">
                            <h2 class="title2"> <img *ngIf="!isIcon" src="assets/img/interest2.png" style="margin-right: 4px;" width="12" height="12">Centres d'intérêt</h2>
                            <ul>
                                <li> {{cvFormGroup.get('hobby1').value}}</li>
                                <li> {{cvFormGroup.get('hobby2').value}}</li>
                                <li>{{cvFormGroup.get('hobby3').value}}</li>
                                <li> {{cvFormGroup.get('hobby4').value}}</li>
                            </ul>
                        </div>
                    </div>

                </div>

                <!--  END PAGE 1-->







            </div>


        </form>
    </div>

</div>


<!--   FORM ARRAY         -->
