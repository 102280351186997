import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './auth-signin.service';
import { TokenStorageService } from './token-storage.service';
import { User_ } from './user';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  loginFormGroup: FormGroup;
  user: User_;
  isSubmitted  =  false;

  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage: string = '';
  loginMessage: string = '';
  roles: string[] = [];
  mode: number=0;

  constructor(private fb :FormBuilder,
    private authService : AuthService,
    private tokenStorage: TokenStorageService,
    private router: Router) { }

  ngOnInit(): void {

    this.initLoginForm();

  }

  initLoginForm() : void{
    this.loginFormGroup  =  this.fb.group({
        email: ['', Validators.required],
        password: ['', Validators.required]
    });
  }

  get formControls() {
       return this.loginFormGroup.controls;
    }

    login2(){
        console.log(this.loginFormGroup.value);
        this.isSubmitted = true;
        if(this.loginFormGroup.invalid){
          return;


        }
      //  this.authService.login(this.loginForm.value);
        this.router.navigateByUrl('/admin');
      }


   login() {
        this.loginMessage = 'Tentative de connexion en cours ...';

       this.authService.login(this.loginFormGroup.value).subscribe(
          (resp) => {


           // this.tokenStorage.saveToken(data.accessToken);
            let jwtToken=  resp.body.access_token;

           this.tokenStorage.saveToken("Bearer "+jwtToken);


            this.isLoginFailed = false;
            this.isLoggedIn = true;
            this.loginMessage = 'Vous êtes connecté(e)';

              // Récupère l'URL de redirection depuis le service d'authentification
              // Si aucune redirection n'a été définis, redirige l'utilisateur vers la liste des patients.
             // let redirect = this.redirectUrl ? this.redirectUrl : '/patient/list';





              // Redirige l'utilisateur


           // this.reloadPage();
          },
          err => {

           alert(err.status);

            this.isLoginFailed = true;
            this.errorMessage = err.error.message;

            this.mode=1;
          },
          ()=>{

            this.getCvs();
            this.chargeUser();

            setTimeout(() => {
             // alert(this.tokenStorage.getUser().id)

              if(this.tokenStorage.getUser()===null){
                let redirect ='/personnel/list';
                this.router.navigate([redirect]);
              }
              else {

                  let redirect ='/cvs';

                  this.router.navigate([redirect]);

              }
              }, 1000);


          }
        );
      }

      getCvs(): void {

        this.authService.getCvs()
          .subscribe(
            (data) => {




            },
            (error) => {

               alert(error.error.message);
               alert(this.tokenStorage.getUser())

            },
            () => {

            }
          );

      }


      chargeUser(): void {

        this.authService.findOne()
          .subscribe(
            (data) => {

              this.user=data;
              this.tokenStorage.saveUser(data);

            },
            (error) => {

              this.tokenStorage.signOut();
            },
            () => {


            }
          );
      }



      reloadPage() {
        window.location.reload();
      }

}


