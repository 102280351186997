import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Cv } from './cv';
import { MyDashboardService } from './my-dashboard.service';

@Component({
  selector: 'app-my-dashboard-page',
  templateUrl: './my-dashboard-page.component.html',
  styleUrls: ['./my-dashboard-page.component.scss']
})
export class MyDashboardPageComponent implements OnInit {

  constructor(
      private dashboardService : MyDashboardService,
      private router : Router) { }

  ngOnInit(): void {

    this.findAll();


  }

listCV : Array<Cv>=[];
isSuccess : boolean=false;


  findAll() {
        this.dashboardService.findAll().subscribe(
            (data) => {
                this.listCV = data;
                this.isSuccess = true;

            },
            (error) => {
                alert("Veuillez recharger votre page svp!");
            },

            () => {

                this.listCV=this.listCV.reverse();

            }
        );

    }


    showCv(cv: Cv): void {
        let link = ["my-dashboard/m1-edit", cv.id];
        this.router.navigate(link);
      }


}
