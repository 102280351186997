
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

// import library classes
import { ConfirmBoxEvokeService } from '@costlydeveloper/ngx-awesome-popup';

import { Cover } from './cover';
import { TokenStorageService } from '../cv-login-page/token-storage.service';
import { CoverDashboardService } from './cover-dashboard.service';
import { CoverService } from './cover.service';



@Component({
  selector: 'cover-dashboard',
  templateUrl: './cover-dashboard.component.html',
  styleUrls: ['./cover-dashboard.component.scss']
})
export class CoverDashboardComponent implements OnInit {

  constructor(
    private dashboardService: CoverDashboardService,
    private tokenStorageService: TokenStorageService,
    private coverService: CoverService,
    private fb: FormBuilder,
    private router: Router,
    private confirmBoxEvokeService: ConfirmBoxEvokeService
  ) { }


  ngOnInit(): void {

   // this.findAll();
    // alert(this.tokenStorageService.getUser().email);


  }

  confirmBox(cover : Cover) {
    this.confirmBoxEvokeService
      .danger('Supprimer le CV', 'Voulez-vous vraiment supprimer ce CV ?', 'Oui', 'Non')
      .subscribe(
        (resp) => resp.success?this.deleteCover(cover):console.log() );
  }


  listCV: Array<Cover> = [];
  isSuccess: boolean = false;
  coverFormGroup: FormGroup;
  initCV(cover: Cover): void {
    //alert(this.decrypt.transform(cover.firstname));
    this.coverFormGroup = this.fb.group({
      name: [cover.name],
      photo: [cover.photo],
      firstname: [cover.firstname],
      lastname: [cover.lastname],
      profession: [cover.profession],

      telephone: [cover.telephone],
      email: [cover.email],

      address: [cover.address],
      city: [cover.city],


      firstcolor: [cover.firstcolor],
      secondcolor: [cover.secondcolor],
      thirdcolor: [cover.thirdcolor],
      pagecolor: [cover.pagecolor],
      fontfamily: [cover.fontfamily],

      idmodele: [cover.idmodele],
      skills: this.fb.array([]),
      experiences: this.fb.array([]),
      educations: this.fb.array([]),
      customs: this.fb.array([]),
      formations: this.fb.array([]),
      certifications: this.fb.array([]),
      references: this.fb.array([]),
      languages: this.fb.array([]),
    });
  }


  findAll() {
    this.dashboardService.findAll().subscribe(
      (data) => {
        // alert(data);
        this.listCV = data;
        this.isSuccess = true;

      },
      (error) => {

        this.tokenStorageService.signOut2();
      },

      () => {
        //alert(JSON.stringify(this.listCV));
        this.listCV = this.listCV.reverse();

      }
    );

  }


  showCover(cover: Cover): void {

    let link = ["covers/m" + cover.idmodele + "-edit", cover.id];
    this.router.navigate(link);
  }

  deleteCover(cover: Cover): void {



  }

  duplicateCover(cover: Cover): void {

    if (this.listCV.length < 6) {
      this.initCV(cover);
      const data = cover;






    }
    else {

        this.confirmBoxEvokeService
          .info('Info', 'Vous avez déjà atteint le nombre maximal (6) de CV', 'Ok')
          .subscribe(
            (resp) => console.log("Déjà 6 CV !") );

    }

  }

  coverSkills(): FormArray {
    return this.coverFormGroup.get("skills") as FormArray;
  }

  coverExperiences(): FormArray {
    return this.coverFormGroup.get("experiences") as FormArray;
  }

  coverEducations(): FormArray {
    return this.coverFormGroup.get("educations") as FormArray;
  }

  coverCustoms(): FormArray {
    return this.coverFormGroup.get("customs") as FormArray;
  }

  coverFormations(): FormArray {
    return this.coverFormGroup.get("formations") as FormArray;
  }
  coverCertifications(): FormArray {
    return this.coverFormGroup.get("certifications") as FormArray;
  }
  coverReferences(): FormArray {
    return this.coverFormGroup.get("references") as FormArray;
  }

  coverLanguages(): FormArray {
    return this.coverFormGroup.get("languages") as FormArray;
  }

  initCoverSkill2(skill): FormGroup {
    return this.fb.group({
      item: [skill.item],
      level: [skill.level],
    });
  }

  initCoverExperience(): FormGroup {
    return this.fb.group({
      index: [0],
      period: [""],
      company: [""],
      city: [""],
      function: [""],
      description: [""],
    });
  }

  initCoverExperience2(experience): FormGroup {
    return this.fb.group({
      index: [experience.index],
      period: [experience.period],
      company: [experience.company],
      city: [experience.city],
      function: [experience.function],
      description: [experience.description],
    });
  }

  initCoverEducation2(education): FormGroup {
    return this.fb.group({
      index: [education.index],
      period: [education.period],
      institute: [education.institute],
      city: [education.city],
      country: [education.country],
      degree: [education.degree],
    });
  }

  initCoverCustom2(custom): FormGroup {
    return this.fb.group({
      index: [custom.index],
      title: [custom.title],
      description: [custom.description],
    });
  }


  initCoverFormation2(formation): FormGroup {
    return this.fb.group({
      index: [formation.index],
      period: [formation.period],
      title: [formation.title],
      description: [formation.description],
      former: [formation.former],
    });
  }

  initCoverCertification2(certification): FormGroup {
    return this.fb.group({
      index: [certification.index],
      date: [certification.date],
      certificat: [certification.certificat],
      institute: [certification.institute],
    });
  }


  initCoverReference2(reference): FormGroup {
    return this.fb.group({
      index: [reference.index],
      fullname: [reference.fullname],
      organisation: [reference.organisation],
      telephone: [reference.telephone],
      description: [reference.description],
      email: [reference.email],
    });
  }


  initCoverLanguage2(lang): FormGroup {
    return this.fb.group({
      item: [lang.item],
      level: [lang.level],
    });
  }


}
