import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { ConfirmBoxEvokeService } from "@costlydeveloper/ngx-awesome-popup";
import { Cv } from "../../cv-dashboard/cv";
import { TokenStorageService } from "../../cv-login-page/token-storage.service";
import { CvService } from "../../cv-modele/modeles/cv.service";
import { CvThequeService } from "../cv-cvtheque.service";

@Component({
    selector: "cvtheque",
    templateUrl: "./cv-cvtheque.component.html",
    styleUrls: ["./cv-cvtheque.component.scss"],
})
export class CvThequeIndustryComponent implements OnInit {
    listCV: Array<Cv> = [];

    isSuccess: boolean = false;
    cvFormGroup: FormGroup;

    constructor(
        private cvThequeService: CvThequeService,
        private tokenStorageService: TokenStorageService,
        private cvService: CvService,
        private fb: FormBuilder,
        private router: Router,
        private confirmBoxEvokeService: ConfirmBoxEvokeService
    ) {}
    isAdmin: boolean = false;
    ngOnInit(): void {
        this.findAll();
        if (
            this.tokenStorageService.getToken() &&
            this.tokenStorageService.getUser().role === "admin"
        ) {
            this.isAdmin = true;
        }

        // alert(this.tokenStorageService.getUser().email);
    }

    confirmBox(cv: Cv) {
        this.confirmBoxEvokeService
            .danger(
                "Supprimer le CV",
                "Voulez-vous vraiment supprimer ce CV ?",
                "Oui",
                "Non"
            )
            .subscribe((resp) =>
                resp.success ? this.deleteCv(cv) : console.log()
            );
    }

    initCV(cv: Cv): void {
        //alert(this.decrypt.transform(cv.firstname));
        this.cvFormGroup = this.fb.group({
            name: [cv.name],
            photo: [cv.photo],
            firstname: [cv.firstname],
            lastname: [cv.lastname],
            profession: [cv.profession],
            profile: [cv.profile],
            telephone: [cv.telephone],
            email: [cv.email],
            linkedin: [cv.linkedin],
            webSite: [cv.webSite],
            address: [cv.address],
            city: [cv.city],
            country: [cv.country],
            birth: [cv.birth],
            marital: [cv.marital],
            quality1: [cv.quality1],
            quality2: [cv.quality2],
            quality3: [cv.quality3],
            quality4: [cv.quality4],
            hobby1: [cv.hobby1],
            hobby2: [cv.hobby2],
            hobby3: [cv.hobby3],
            hobby4: [cv.hobby4],
            firstcolor: [cv.firstcolor],
            secondcolor: [cv.secondcolor],
            thirdcolor: [cv.thirdcolor],
            pagecolor: [cv.pagecolor],
            fontfamily: [cv.fontfamily],
            theme: [cv.theme],
            idmodele: [cv.idmodele],
            skills: this.fb.array([]),
            experiences: this.fb.array([]),
            educations: this.fb.array([]),
            customs: this.fb.array([]),
            formations: this.fb.array([]),
            certifications: this.fb.array([]),
            references: this.fb.array([]),
            languages: this.fb.array([]),
            flag: [cv.flag],
        });
    }

    spin1: boolean = true;
    findAll() {
        this.cvThequeService.findByFlag2(5).subscribe(
            (data) => {
                // alert(data);
                this.spin1 = true;
                this.listCV = data;
                this.isSuccess = true;
            },
            (error) => {
                this.spin1 = false;
                this.tokenStorageService.signOut2();
            },

            () => {
                this.listCV.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
                this.spin1 = false;
            }
        );
    }

    showCv(cv: Cv): void {
        localStorage.setItem("icv_cv", JSON.stringify(cv));

        let link = ["cvtheque/m" + cv.idmodele + "-edit", cv.id];
        this.router.navigate(link);
    }

    deleteCv(cv: Cv): void {
        this.cvService.deleteOne(cv.id).subscribe(
            (data) => {
                // alert(data);
            },
            (error) => {
                this.tokenStorageService.signOut2();
            },

            () => {
                this.ngOnInit();
            }
        );
    }

    showCv2(cv: Cv): void {
        localStorage.setItem("icv_cv", JSON.stringify(cv));

        let link = ["cvs/m" + cv.idmodele + "-edit", cv.id];
        this.router.navigate(link);
    }

    async duplicateCv(cv: Cv) {
        let lenght = await this.listCV.length;
        if (this.tokenStorageService.getUser().role === "user") {
            if (lenght < 6) {
                this.initCV(cv);
                const data = cv;

                data.skills.forEach((el) => {
                    this.cvSkills().push(this.initCvSkill2(el));
                });

                data.experiences.forEach((el) => {
                    this.cvExperiences().push(this.initCvExperience2(el));
                });

                data.educations.forEach((el) => {
                    this.cvEducations().push(this.initCvEducation2(el));
                });

                data.customs.forEach((el) => {
                    this.cvCustoms().push(this.initCvCustom2(el));
                });

                data.formations.forEach((el) => {
                    this.cvFormations().push(this.initCvFormation2(el));
                });
                data.certifications.forEach((el) => {
                    this.cvCertifications().push(this.initCvCertification2(el));
                });
                data.references.forEach((el) => {
                    this.cvReferences().push(this.initCvReference2(el));
                });

                data.languages.forEach((el) => {
                    this.cvLanguages().push(this.initCvLanguage2(el));
                });

                this.cvService.save(this.cvFormGroup.value).subscribe(
                    (data) => {
                        // alert(data);
                    },
                    (error) => {
                        this.tokenStorageService.signOut2();
                    },

                    () => {
                        this.ngOnInit();
                    }
                );
            } else {
                this.confirmBoxEvokeService
                    .info(
                        "Info",
                        "Vous avez déjà atteint le nombre maximal (6) de CV",
                        "Ok"
                    )
                    .subscribe((resp) => console.log("Déjà 6 CV !"));
            }
        } else {
            this.initCV(cv);
            const data = cv;

            data.skills.forEach((el) => {
                this.cvSkills().push(this.initCvSkill2(el));
            });

            data.experiences.forEach((el) => {
                this.cvExperiences().push(this.initCvExperience2(el));
            });

            data.educations.forEach((el) => {
                this.cvEducations().push(this.initCvEducation2(el));
            });

            data.customs.forEach((el) => {
                this.cvCustoms().push(this.initCvCustom2(el));
            });

            data.formations.forEach((el) => {
                this.cvFormations().push(this.initCvFormation2(el));
            });
            data.certifications.forEach((el) => {
                this.cvCertifications().push(this.initCvCertification2(el));
            });
            data.references.forEach((el) => {
                this.cvReferences().push(this.initCvReference2(el));
            });

            data.languages.forEach((el) => {
                this.cvLanguages().push(this.initCvLanguage2(el));
            });

            this.cvService.save(this.cvFormGroup.value).subscribe(
                (data) => {
                    // alert(data);
                },
                (error) => {
                    this.tokenStorageService.signOut2();
                },

                () => {
                    this.ngOnInit();
                }
            );
        }
    }

    cvSkills(): FormArray {
        return this.cvFormGroup.get("skills") as FormArray;
    }

    cvExperiences(): FormArray {
        return this.cvFormGroup.get("experiences") as FormArray;
    }

    cvEducations(): FormArray {
        return this.cvFormGroup.get("educations") as FormArray;
    }

    cvCustoms(): FormArray {
        return this.cvFormGroup.get("customs") as FormArray;
    }

    cvFormations(): FormArray {
        return this.cvFormGroup.get("formations") as FormArray;
    }
    cvCertifications(): FormArray {
        return this.cvFormGroup.get("certifications") as FormArray;
    }
    cvReferences(): FormArray {
        return this.cvFormGroup.get("references") as FormArray;
    }

    cvLanguages(): FormArray {
        return this.cvFormGroup.get("languages") as FormArray;
    }

    initCvSkill2(skill): FormGroup {
        return this.fb.group({
            item: [skill.item],
            level: [skill.level],
        });
    }

    initCvExperience(): FormGroup {
        return this.fb.group({
            index: [0],
            period: [""],
            company: [""],
            city: [""],
            function: [""],
            description: [""],
        });
    }

    initCvExperience2(experience): FormGroup {
        return this.fb.group({
            index: [experience.index],
            period: [experience.period],
            company: [experience.company],
            city: [experience.city],
            function: [experience.function],
            description: [experience.description],
        });
    }

    initCvEducation2(education): FormGroup {
        return this.fb.group({
            index: [education.index],
            period: [education.period],
            institute: [education.institute],
            city: [education.city],
            country: [education.country],
            degree: [education.degree],
        });
    }

    initCvCustom2(custom): FormGroup {
        return this.fb.group({
            index: [custom.index],
            title: [custom.title],
            description: [custom.description],
        });
    }

    initCvFormation2(formation): FormGroup {
        return this.fb.group({
            index: [formation.index],
            period: [formation.period],
            title: [formation.title],
            description: [formation.description],
            former: [formation.former],
        });
    }

    initCvCertification2(certification): FormGroup {
        return this.fb.group({
            index: [certification.index],
            date: [certification.date],
            certificat: [certification.certificat],
            institute: [certification.institute],
        });
    }

    initCvReference2(reference): FormGroup {
        return this.fb.group({
            index: [reference.index],
            fullname: [reference.fullname],
            organisation: [reference.organisation],
            telephone: [reference.telephone],
            description: [reference.description],
            email: [reference.email],
        });
    }

    initCvLanguage2(lang): FormGroup {
        return this.fb.group({
            item: [lang.item],
            level: [lang.level],
        });
    }
}
