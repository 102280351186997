import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { UserLogin } from "../login-page/user";

const AUTH_API =  environment.baseUrl+ 'user/';
const AUTH_API3 ="http://localhost:3000";

const AUTH_API2 = environment.baseUrl+ 'cv/';

const httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
    providedIn: "root",
})
export class RegisterService {
    private roles: Array<any> = [];
    public username2: string;
    public isRLogs: boolean;

    constructor(private http: HttpClient) {}

    login(user: UserLogin): Observable<any> {
        return this.http.post(
            AUTH_API + "login",
            {
                email: user.email,
                password: user.password,
            },
            { observe: "response" }
        );
    }

    findOne(): Observable<any> {
        return this.http.get(AUTH_API + "findOne", httpOptions);
    }

    googleConnect(){
        return this.http.get(AUTH_API + "auth/google/callback", httpOptions);
    }

    isAdmin() {
        // for(let r of this.roles){
        //  if(r.authority==='ADMIN') return true;
        // }
        return false;
    }

    signup(user): Observable<any> {
        return this.http.post(
            AUTH_API + "register",
            {
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                password: user.password,
            },
            httpOptions
        );
    }
}
