<div class="banner-section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content">
                    <span class="sub-title">Contact-nous</span>
                    <h1>Pour toutes vos questions, remarques et suggestions, laissez-nous un message</h1>
                    <p>Pour tout problème basé sur l'utilisation de iCV</p>
                    <div class="btn-box">
                        <a routerLink="/faq" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i>
                            <span class="label">Foire aux questions</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                        <a [routerLink] = "['/tutoriel']"  class="video-btn"><span><i class='bx bx-play-circle'></i></span>Tutoriel</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divider"></div>
</div>