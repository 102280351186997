<div id="cvt" class="container-fluid" style="height: 100%; width: 100%;">
    <div class="row">

        <!--   CV TEMPLATE -->

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">


            <form [formGroup]="cvFormGroup" style="z-index : -1">
                <div id="cvt2">
                    <div id="cvt10">

                        <!--  START PAGE 1-->

                        <div class="wrapper">
                            <div class="resume" id="content1">
                                <div class="right" id="right_side1">

                                    <div class="name_role">
                                        <div class="name" style="margin-bottom: -0; margin-top: 12px; font-size: 33px; padding: 21px;
                                        padding-bottom: 0;" [style.color]="secondcolor">
                                            {{this.cvFormGroup.get("firstname").value}}
                                            {{this.cvFormGroup.get("lastname").value}}
                                        </div>
                                        <div class="role" [style.color]="firstcolor"
                                            style="font-size: 21px; padding: 21px;padding-top: -3px;padding-bottom: -3px; margin-top: -7px;">
                                            {{this.cvFormGroup.get("profession").value}}
                                        </div>
                                    </div>


                                    <div class="right_inner">


                                        <div *ngIf="cvFormGroup.get('profile').value.trim()"
                                            class="exp" style="margin-top: -21px;">

                                            <div class="exp_wrap">
                                                <div class="about" style="color: #383535; font-size: 12px">
                                                    {{this.cvFormGroup.get("profile").value}}
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <!-- EDUCATION BEGINNER-->
                                        <div *ngIf="cvFormGroup.get('educations').value.length && isBeginner"
                                            formArrayName="educations" class="education">
                                            <div class="title">
                                                Education
                                            </div>
                                            <div class="education_wrap">
                                                <ul>
                                                    <li *ngFor="let education of cvEducations().controls; let educationIndex=index"
                                                        style="margin-bottom: 12px ;">
                                                        <div [formGroupName]="educationIndex" class="li_wrap">
                                                            <div style="font-size: 12px;" class="date">
                                                                {{cvEducations().at(educationIndex).get('period').value}}
                                                                <p style="font-size: 12px;">
                                                                    {{cvEducations().at(educationIndex).get('city').value}}
                                                                </p>
                                                            </div>
                                                            <div class="info">
                                                                <p class="info_title" style="font-size: 12px;">
                                                                    {{cvEducations().at(educationIndex).get('degree').value}}
                                                                </p>
                                                                <p class="info_com">
                                                                    {{cvEducations().at(educationIndex).get('institute').value}}
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </li>


                                                </ul>
                                            </div>
                                        </div>

                                        <!-- EXPERIENCE PROFESSIONAL-->
                                        <div *ngIf="cvFormGroup.get('experiences').value.length && isProfessional"
                                            formArrayName="experiences" class="exp">
                                            <div class="title">
                                                {{'experiences' | translate}}
                                            </div>
                                            <div class="exp_wrap">
                                                <ul>
                                                    <li *ngFor="let experience of cvExperiences().controls; let experienceIndex=index"
                                                        style="margin-bottom: 12px ;">
                                                        <div [formGroupName]="experienceIndex" class="li_wrap">
                                                            <div style="font-size: 12px;" class="date">
                                                                {{cvExperiences().at(experienceIndex).get('period').value}}
                                                                <p style="font-size: 12px;">
                                                                    {{cvExperiences().at(experienceIndex).get('city').value}}
                                                                </p>
                                                            </div>
                                                            <div class="info">
                                                                <p style="font-size: 12px;" class="info_title">
                                                                    {{cvExperiences().at(experienceIndex).get('function').value}}

                                                                </p>
                                                                <p class="info_com">
                                                                    {{cvExperiences().at(experienceIndex).get('company').value}}
                                                                </p>
                                                                <p class="info_cont" style="font-size: 12px; word-wrap: break-word;"
                                                                    [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>


                                                </ul>
                                            </div>
                                        </div>

                                        <div *ngIf="cvFormGroup.get('certifications').value.length"
                                            formArrayName="certifications" class="education">
                                            <div class="title">
                                                {{'certificates' | translate}}
                                            </div>
                                            <div class="education_wrap">
                                                <ul>
                                                    <li *ngFor="let certif of cvCertifications().controls; let certifIndex=index"
                                                        style="margin-bottom: 12px ;">
                                                        <div [formGroupName]="certifIndex" class="li_wrap">
                                                            <div style="font-size: 12px;" class="date">
                                                                {{cvCertifications().at(certifIndex).get('date').value}}

                                                            </div>
                                                            <div class="info">
                                                                <p class="info_title">
                                                                    {{cvCertifications().at(certifIndex).get('certificat').value}}
                                                                </p>
                                                                <p class="info_com">
                                                                    {{cvCertifications().at(certifIndex).get('institute').value}}
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </li>


                                                </ul>
                                            </div>
                                        </div>

                                        <div *ngIf="cvFormGroup.get('formations').value.length"
                                            formArrayName="formations" class="education">
                                            <div class="title">
                                                Formations
                                            </div>
                                            <div class="education_wrap">
                                                <ul>
                                                    <li *ngFor="let formation of cvFormations().controls; let formationIndex=index"
                                                        style="margin-bottom: 12px ;">
                                                        <div [formGroupName]="formationIndex" class="li_wrap">
                                                            <div style="font-size: 12px;" class="date">
                                                                {{cvFormations().at(formationIndex).get('period').value}}
                                                                <p></p>
                                                            </div>
                                                            <div class="info">
                                                                <p class="info_title" style="font-size: 12px;">
                                                                    {{cvFormations().at(formationIndex).get('title').value}}
                                                                </p>
                                                                <p class="info_com">
                                                                    {{cvFormations().at(formationIndex).get('former').value}}
                                                                </p>
                                                                <p class="info_cont" style="font-size: 12px; word-wrap: break-word;"
                                                                    [innerHTML]="cvFormations().at(formationIndex).get('description').value">

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>


                                                </ul>
                                            </div>
                                        </div>

                                        <!-- EDUCATION PROFESSIONAL-->
                                        <div *ngIf="cvFormGroup.get('educations').value.length && isProfessional"
                                            formArrayName="educations" class="education">
                                            <div class="title">
                                                Education
                                            </div>
                                            <div class="education_wrap">
                                                <ul>
                                                    <li *ngFor="let education of cvEducations().controls; let educationIndex=index"
                                                        style="margin-bottom: 12px ;">
                                                        <div [formGroupName]="educationIndex" class="li_wrap">
                                                            <div style="font-size: 12px;" class="date">
                                                                {{cvEducations().at(educationIndex).get('period').value}}
                                                                <p style="font-size: 12px;">
                                                                    {{cvEducations().at(educationIndex).get('city').value}}
                                                                </p>
                                                            </div>
                                                            <div class="info">
                                                                <p class="info_title" style="font-size: 12px;">
                                                                    {{cvEducations().at(educationIndex).get('degree').value}}
                                                                </p>
                                                                <p class="info_com">
                                                                    {{cvEducations().at(educationIndex).get('institute').value}}
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </li>


                                                </ul>
                                            </div>
                                        </div>
                                        <!-- EXPERIENCE PROFESSIONAL-->
                                        <div *ngIf="cvFormGroup.get('experiences').value.length && isBeginner"
                                            formArrayName="experiences" class="exp">
                                            <div class="title">
                                                {{'experiences' | translate}}
                                            </div>
                                            <div class="exp_wrap">
                                                <ul>
                                                    <li *ngFor="let experience of cvExperiences().controls; let experienceIndex=index"
                                                        style="margin-bottom: 12px ;">
                                                        <div [formGroupName]="experienceIndex" class="li_wrap">
                                                            <div style="font-size: 12px;" class="date">
                                                                {{cvExperiences().at(experienceIndex).get('period').value}}
                                                                <p style="font-size: 12px;">
                                                                    {{cvExperiences().at(experienceIndex).get('city').value}}
                                                                </p>
                                                            </div>
                                                            <div class="info">
                                                                <p style="font-size: 12px;" class="info_title">
                                                                    {{cvExperiences().at(experienceIndex).get('function').value}}

                                                                </p>
                                                                <p class="info_com">
                                                                    {{cvExperiences().at(experienceIndex).get('company').value}}
                                                                </p>
                                                                <p class="info_cont" style="font-size: 12px; word-wrap: break-word;"
                                                                    [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>


                                                </ul>
                                            </div>
                                        </div>



                                        <!-- RUBRIQUE PERSONNALISEE -->
                                        <div *ngIf="cvFormGroup.get('customs').value.length" formArrayName="customs">
                                            <div *ngFor="let custom of cvCustoms().controls; let customIndex=index"
                                                [formGroupName]="customIndex" class="education">
                                                <div class="title">
                                                    {{cvCustoms().at(customIndex).get('title').value}}
                                                </div>
                                                <div class="education_wrap">
                                                    <ul>
                                                        <li style="margin-bottom: 12px ;">
                                                            <div class="li_wrap">

                                                                <div class="info">

                                                                    <p style="font-size: 12px;"
                                                                        [innerHTML]="cvCustoms().at(customIndex).get('description').value">
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>


                                                    </ul>
                                                </div>
                                            </div>
                                        </div>




                                        <div *ngIf="cvFormGroup.get('references').value.length"
                                            formArrayName="references" class="education">
                                            <div class="title">
                                                {{'references' | translate}}
                                            </div>
                                            <div class="education_wrap">
                                                <ul>
                                                    <li *ngFor="let certif of cvReferences().controls; let refIndex=index"
                                                        style="margin-bottom: 12px ;">
                                                        <div [formGroupName]="refIndex" class="li_wrap">

                                                            <div class="info">
                                                                <p class="info_title">
                                                                    {{cvReferences().at(refIndex).get('fullname').value}}
                                                                </p>
                                                                <p class="info_com"
                                                                    style="font-size: 13px; margin-bottom: 3px; font-weight: 600;">
                                                                    {{cvReferences().at(refIndex).get('organisation').value}}
                                                                </p>
                                                                <p *ngIf="cvReferences().at(refIndex).get('telephone').value.trim()"
                                                                    class="info_com"
                                                                    style="font-size: 12px; margin-bottom: 7px;">
                                                                    <strong>TEL
                                                                        :</strong>{{cvReferences().at(refIndex).get('telephone').value}}
                                                                </p>
                                                                <p *ngIf="cvReferences().at(refIndex).get('email').value.trim()"
                                                                    class="info_com"
                                                                    style="font-size: 12px; margin-bottom: 7px;">
                                                                    <strong>EMAIL
                                                                        :</strong>{{cvReferences().at(refIndex).get('email').value}}
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </li>


                                                </ul>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <div class="left" id="left_side1" [style.background]="firstcolor">
                                    <div class="img_holder">
                                        <img *ngIf="!imageToShow && imgURL" [src]="imgURL"
                                            style="margin-top: -21px; border-radius: 50%;" alt="picture">
                                        <img *ngIf="imageToShow" [src]="imageToShow"
                                            style="margin-top: -21px; border-radius: 50%;" alt="picture">
                                    </div>
                                    <div class="contact_wrap pb">
                                        <div class="title" style="font-size: 17px; color: #fff;">

                                        </div>
                                        <div class="contact" style="padding: 12px 0 12px 0">
                                            <ul>
                                                <li *ngIf="cvFormGroup.get('telephone').value.trim()"
                                                    style="margin-bottom: 7px;">
                                                    <div class="li_wrap">
                                                        <div class="icon"><img style="" src="assets/img/phone8.png"
                                                                width="17" height="17"></div>
                                                        <div class="text"> <span
                                                                *ngIf="this.cvFormGroup.get('telephone').value.includes('U2FsdG')">
                                                                {{cvFormGroup.get('telephone').value |
                                                                decrypted}}</span>
                                                            <span
                                                                *ngIf="!this.cvFormGroup.get('telephone').value.includes('U2FsdG')">
                                                                {{cvFormGroup.get('telephone').value | encrypted |
                                                                decrypted}}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li *ngIf="cvFormGroup.get('email').value.trim()"
                                                    style="margin-bottom: 7px;">
                                                    <div class="li_wrap">
                                                        <div class="icon"><img id="ic1" src="assets/img/env2.png"
                                                                width="17" height="17"></div>
                                                        <div class="text">{{cvFormGroup.get('email').value}}</div>
                                                    </div>
                                                </li>
                                                <li *ngIf="cvFormGroup.get('webSite').value.trim()"
                                                    style="margin-bottom: 7px;">
                                                    <div class="li_wrap">
                                                        <div class="icon"><img src="assets/img/globe2.png" width="15"
                                                                height="15"></div>
                                                        <div class="text">{{cvFormGroup.get('webSite').value}}</div>
                                                    </div>
                                                </li>
                                                <li *ngIf="cvFormGroup.get('linkedin').value.trim()"
                                                    style="margin-bottom: 7px;">
                                                    <div class="li_wrap">
                                                        <div class="icon"> <img src="assets/img/linkedin4.png"
                                                                width="18" height="18"></div>
                                                        <div class="text">{{cvFormGroup.get('linkedin').value}}</div>
                                                    </div>
                                                </li>
                                                <li *ngIf="cvFormGroup.get('address').value.trim()"
                                                    style="margin-bottom: 7px;">
                                                    <div class="li_wrap">
                                                        <div class="icon"><img id="ic2" src="assets/img/map2.png"
                                                                width="17" height="17"></div>
                                                        <div class="text"> <span
                                                                *ngIf="this.cvFormGroup.get('address').value.includes('U2FsdG')">
                                                                {{cvFormGroup.get('address').value | decrypted}}
                                                                <span>{{", "+ cvFormGroup.get('city').value}}</span>
                                                            </span>

                                                            <span
                                                                *ngIf="!this.cvFormGroup.get('address').value.includes('U2FsdG')">
                                                                {{cvFormGroup.get('address').value | encrypted |
                                                                decrypted}} <span>{{", "+
                                                                    cvFormGroup.get('city').value}}</span></span>

                                                        </div>
                                                    </div>
                                                </li>
                                                <li *ngIf="cvFormGroup.get('birth').value.trim()"
                                                    style="margin-bottom: 7px;">
                                                    <div class="li_wrap">
                                                        <div class="icon"><img id="ic2" src="assets/img/birth2.png"
                                                                width="17" height="17"></div>
                                                        <div class="text">Né(e) le {{cvFormGroup.get('birth').value}}
                                                        </div>

                                                    </div>
                                                </li>
                                                <li *ngIf="cvFormGroup.get('marital').value.trim()"
                                                    style="margin-bottom: 7px;">
                                                    <div class="li_wrap">
                                                        <div class="icon"> <img id="ic2" src="assets/img/marital2.png"
                                                                width="17" height="17"></div>
                                                        <div class="text">{{cvFormGroup.get('marital').value}}</div>

                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div *ngIf="cvFormGroup.get('skills').value.length" formArrayName="skills"
                                        class="skills_wrap pb" style="margin-bottom: 12px;">
                                        <div class="title" style="font-size: 17px; color: #fff;">
                                            Compétences
                                        </div>
                                        <div class="skills">
                                            <div>
                                                <div *ngFor="let skill of cvSkills().controls; let skillIndex=index"
                                                    [formGroupName]="skillIndex"
                                                    style="margin:15px; margin-bottom: -3px;">
                                                    <p style="margin-bottom: 5px; color: aliceblue; font-size: 15px;">
                                                        {{cvSkills().at(skillIndex).get('item').value}}</p>
                                                    <div style="height: 6px; background: #000; ">
                                                        <div style="height: 100%; background: #1273bb;"
                                                            [style.width]="cvSkills().at(skillIndex).get('level').value"
                                                            [style.background]="pagecolor"> </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="cvFormGroup.get('languages').value.length" formArrayName="languages"
                                        class="skills_wrap pb" style="margin-bottom: 12px;">
                                        <div class="title" style="font-size: 17px; color: #fff;">
                                            Langues
                                        </div>
                                        <div class="skills">
                                            <div>
                                                <div *ngFor="let lang of cvLanguages().controls; let langIndex=index"
                                                    [formGroupName]="langIndex"
                                                    style="margin:15px; margin-bottom: -3px;">
                                                    <p style="margin-bottom: 5px; color: aliceblue; font-size: 15px;">
                                                        {{cvLanguages().at(langIndex).get('item').value}}</p>
                                                    <div style="height: 6px; background: #000; ">
                                                        <div style="height: 100%; background: #1273bb;"
                                                            [style.width]="cvLanguages().at(langIndex).get('level').value"
                                                            [style.background]="pagecolor"> </div>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="cvFormGroup.get('quality1').value.trim() || cvFormGroup.get('quality2').value.trim() ||
                                    cvFormGroup.get('quality3').value.trim() || cvFormGroup.get('quality4').value.trim()"
                                        class="hobbies_wrap pb">
                                        <div class="title" style="font-size: 17px; color: #fff;">
                                            Qualités
                                        </div>
                                        <div class="">
                                            <div>
                                                <div>
                                                    <div style=" margin: 15px; color: aliceblue; ">
                                                        <span style="width: 50%; float: left;">
                                                            {{cvFormGroup.get('quality1').value}}
                                                        </span>

                                                        <span style="width: 50%; float: right;">
                                                            {{cvFormGroup.get('quality2').value}}
                                                        </span>
                                                    </div>
                                                    <br>
                                                    <div style="margin: 15px; color: aliceblue; ">
                                                        <span style="width: 50%; float: left; ">
                                                            {{cvFormGroup.get('quality3').value}}
                                                        </span>

                                                        <span style="width: 50%; float: right; ">
                                                            {{cvFormGroup.get('quality4').value}}
                                                        </span>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('hobby1').value.trim() || cvFormGroup.get('hobby2').value.trim() ||
                                      cvFormGroup.get('hobby3').value.trim() || cvFormGroup.get('hobby4').value.trim()"
                                        class="hobbies_wrap pb" style="margin-top: 21px;">
                                        <div class="title" style="font-size: 17px; color: #fff;">
                                            {{'hobbies2' | translate}}<span s
                                                [style.color]="firstcolor">_</span>{{'hobbies3' | translate}}
                                        </div>
                                        <div class="">
                                            <div>
                                                <div>
                                                    <div style=" margin: 15px; color: aliceblue; ">
                                                        <span style="width: 50%; float: left;">
                                                            {{cvFormGroup.get('hobby1').value}}
                                                        </span>

                                                        <span style="width: 50%; float: right;">
                                                            {{cvFormGroup.get('hobby2').value}}
                                                        </span>
                                                    </div>
                                                    <br>
                                                    <div style="margin: 15px; color: aliceblue; ">
                                                        <span style="width: 50%; float: left; ">
                                                            {{cvFormGroup.get('hobby3').value}}
                                                        </span>

                                                        <span
                                                            style="width: 50%; float: right; ">{{cvFormGroup.get('hobby4').value}}</span>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>




                    </div>

                </div>
            </form>
        </div>

    </div>
</div>

<!--   FORM ARRAY         -->
