import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ModeleService } from '../../cv-modele/cv-modele.service';
import { Modele } from '../../cv-modele/modele';

@Component({
    selector: 'cv-home-modeles',
    templateUrl: './homefour-courses.component.html',
    styleUrls: ['./homefour-courses.component.scss']
})
export class CvHomeModelesComponent implements OnInit {

    listModele: Array<Modele>=[];
    modele : Modele;

  constructor(private modeleService: ModeleService, private router : Router) { }

  ngOnInit(): void {
      this.findAll();

  }

  findAll(){
    this.modeleService.findAll().subscribe(
        (data) => {

            this.listModele = data;

        },
        (error) => {
            alert("Rechargez la page svp!");
        },
        () => {

        }
    );

  }

  findOne(id : number){
    this.modeleService.findOne(id).subscribe(
        (data) => {

            this.modele = data;

        },
        (error) => {
            alert("Rechargez la page svp!");
        },
        () => {

        }
    );

  }

  showThemes(idModele): void {
    let link = ["themes", idModele];
    this.router.navigate(link);
  }

    coursesSlides: OwlOptions = {
		loop: false,
		nav: true,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1,
			},
			768: {
				items: 2,
			},
			1200: {
				items: 3,
			}
		}
    }

}
