

<div id="cvt" class="container-fluid" style="height: 100%; width: 100%;">
    <div class="row">

        <!--   CV TEMPLATE -->

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <!-- CV RESUME-->

            <form [formGroup]="cvFormGroup">

                <div id="cvt2" class="">

                    <div id="cvt10"  class="">



                            <!--  START PAGE 1-->
                            <div class="resume_design" id="content1">
                                <div class="resume_left" id="left_side1">
                                    <div *ngIf="cvFormGroup.get('profile').value.trim()" class="about_sec">
                                        <div class="button" [style.color]="secondcolor"
                                            [style.border]="'solid 2px '+secondcolor">{{'profile' | translate}}</div>
                                        <p style="font-size: 12px; word-wrap: break-word;">

                                            {{this.cvFormGroup.get("profile").value}}
                                        </p>

                                    </div>
                                    <!-- EDUCATION BEGINNER -->

                                    <div *ngIf="cvFormGroup.get('educations').value.length && isBeginner" formArrayName="educations"
                                        class="edu_sec">
                                        <div class="button" [style.color]="secondcolor"
                                            [style.border]="'solid 2px '+secondcolor">Education</div>
                                        <ul *ngFor="let education of cvEducations().controls; let educationIndex=index">
                                            <li [formGroupName]="educationIndex">
                                                <div class="item">
                                                    <div class="item_grp">
                                                        <p class="title" style="font-size: 14px;">
                                                            {{cvEducations().at(educationIndex).get('degree').value}}
                                                        </p>
                                                        <p class="sub_title"><strong>
                                                                {{cvEducations().at(educationIndex).get('institute').value}}

                                                            </strong> <span>{{" ,
                                                                "+cvEducations().at(educationIndex).get('city').value}}</span>
                                                        </p>
                                                        <span class="timeline"
                                                            [style.color]="secondcolor">{{cvEducations().at(educationIndex).get('period').value}}</span>
                                                    </div>

                                                </div>
                                                <br />
                                            </li>

                                        </ul>
                                    </div>

                                    <!-- EXPERIENCE PROFESSIONAL -->
                                    <div *ngIf="cvFormGroup.get('experiences').value.length && isProfessional" formArrayName="experiences"
                                        class="exp_sec">
                                        <div class="button" [style.color]="secondcolor"
                                            [style.border]="'solid 2px '+secondcolor">{{'experiences' | translate}}
                                        </div>
                                        <ul
                                            *ngFor="let experience of cvExperiences().controls; let experienceIndex=index">
                                            <li [formGroupName]="experienceIndex">
                                                <div class="item">
                                                    <div class="item_grp">
                                                        <p class="title" style="font-size: 14px;">
                                                            {{cvExperiences().at(experienceIndex).get('function').value}}
                                                        </p>
                                                        <p class="sub_title"> <span
                                                                style="text-transform: uppercase; font-weight: 600; font-size: 12px;">
                                                                {{cvExperiences().at(experienceIndex).get('company').value}}
                                                            </span> <span
                                                                *ngIf="cvExperiences().at(experienceIndex).get('city').value.trim()">;
                                                                {{cvExperiences().at(experienceIndex).get('city').value}}
                                                            </span></p>
                                                        <span class="timeline"
                                                            [style.color]="secondcolor">{{cvExperiences().at(experienceIndex).get('period').value}}</span>
                                                    </div>
                                                    <p class="content" style="color: #2d3032; font-size: 12px;"
                                                        [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">
                                                    </p>

                                                </div>
                                                <br />
                                            </li>

                                        </ul>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('certifications').value.length"
                                        formArrayName="certifications" class="edu_sec">
                                        <div class="button" [style.color]="secondcolor"
                                            [style.border]="'solid 2px '+secondcolor">{{'certificates' | translate}}
                                        </div>
                                        <ul *ngFor="let certif of cvCertifications().controls; let certifIndex=index">
                                            <li [formGroupName]="certifIndex">
                                                <div class="item">
                                                    <div class="item_grp">
                                                        <p class="title" style="font-size: 14px;">
                                                            {{cvCertifications().at(certifIndex).get('certificat').value}}
                                                        </p>
                                                        <p class="sub_title"><strong>
                                                                {{cvCertifications().at(certifIndex).get('institute').value}}</strong><span></span>
                                                        </p>
                                                        <span class="timeline"
                                                            [style.color]="secondcolor">{{cvCertifications().at(certifIndex).get('date').value}}</span>
                                                    </div>

                                                </div>
                                                <br />
                                            </li>

                                        </ul>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('formations').value.length" formArrayName="formations"
                                        class="edu_sec">
                                        <div class="button" [style.color]="secondcolor"
                                            [style.border]="'solid 2px '+secondcolor">Formations</div>
                                        <ul *ngFor="let formation of cvFormations().controls; let formationIndex=index">
                                            <li [formGroupName]="formationIndex">
                                                <div class="item">
                                                    <div class="item_grp">
                                                        <p class="title" style="font-size: 14px;">
                                                            {{cvFormations().at(formationIndex).get('title').value}}</p>
                                                        <p class="sub_title">
                                                            <strong>{{cvFormations().at(formationIndex).get('former').value}}

                                                            </strong><span></span> </p>
                                                        <span class="timeline"
                                                            [style.color]="secondcolor">{{cvFormations().at(formationIndex).get('period').value}}</span>
                                                    </div>
                                                    <p class="content" style="color: #2d3032; font-size: 12px;"
                                                        [innerHTML]="cvFormations().at(formationIndex).get('description').value">
                                                    </p>
                                                </div>
                                                <br />
                                            </li>

                                        </ul>
                                    </div>

                                    <!-- EDUCATION PROFESSIONAL -->

                                    <div *ngIf="cvFormGroup.get('educations').value.length && isProfessional" formArrayName="educations"
                                        class="edu_sec">
                                        <div class="button" [style.color]="secondcolor"
                                            [style.border]="'solid 2px '+secondcolor">Education</div>
                                        <ul *ngFor="let education of cvEducations().controls; let educationIndex=index">
                                            <li [formGroupName]="educationIndex">
                                                <div class="item">
                                                    <div class="item_grp">
                                                        <p class="title" style="font-size: 14px;">
                                                            {{cvEducations().at(educationIndex).get('degree').value}}
                                                        </p>
                                                        <p class="sub_title"><strong>
                                                                {{cvEducations().at(educationIndex).get('institute').value}}

                                                            </strong> <span>{{" ,
                                                                "+cvEducations().at(educationIndex).get('city').value}}</span>
                                                        </p>
                                                        <span class="timeline"
                                                            [style.color]="secondcolor">{{cvEducations().at(educationIndex).get('period').value}}</span>
                                                    </div>

                                                </div>
                                                <br />
                                            </li>

                                        </ul>
                                    </div>

                                    <!-- EXPERIENCE BEGINNER -->
                                    <div *ngIf="cvFormGroup.get('experiences').value.length && isBeginner" formArrayName="experiences"
                                        class="exp_sec">
                                        <div class="button" [style.color]="secondcolor"
                                            [style.border]="'solid 2px '+secondcolor">{{'experiences' | translate}}
                                        </div>
                                        <ul
                                            *ngFor="let experience of cvExperiences().controls; let experienceIndex=index">
                                            <li [formGroupName]="experienceIndex">
                                                <div class="item">
                                                    <div class="item_grp">
                                                        <p class="title" style="font-size: 14px;">
                                                            {{cvExperiences().at(experienceIndex).get('function').value}}
                                                        </p>
                                                        <p class="sub_title"> <span
                                                                style="text-transform: uppercase; font-weight: 600; font-size: 12px;">
                                                                {{cvExperiences().at(experienceIndex).get('company').value}}
                                                            </span> <span
                                                                *ngIf="cvExperiences().at(experienceIndex).get('city').value.trim()">;
                                                                {{cvExperiences().at(experienceIndex).get('city').value}}
                                                            </span></p>
                                                        <span class="timeline"
                                                            [style.color]="secondcolor">{{cvExperiences().at(experienceIndex).get('period').value}}</span>
                                                    </div>
                                                    <p class="content" style="color: #2d3032; font-size: 12px;"
                                                        [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">
                                                    </p>

                                                </div>
                                                <br />
                                            </li>

                                        </ul>
                                    </div>



                                    <div *ngIf="cvFormGroup.get('customs').value.length" formArrayName="customs">
                                        <div *ngFor="let custom of cvCustoms().controls; let customIndex=index"
                                            [formGroupName]="customIndex" class="edu_sec">

                                            <div class="button" [style.color]="secondcolor"
                                                [style.border]="'solid 2px '+secondcolor">
                                                {{cvCustoms().at(customIndex).get('title').value}}</div>
                                            <ul>
                                                <li>
                                                    <div class="item">

                                                        <p class="content" style="color: #2d3032; font-size: 12px;"
                                                            [innerHTML]="cvCustoms().at(customIndex).get('description').value">
                                                        </p>
                                                    </div>
                                                    <br />
                                                </li>

                                            </ul>
                                        </div>
                                    </div>


                                    <div *ngIf="cvFormGroup.get('references').value.length" formArrayName="references"
                                        class="edu_sec">
                                        <div class="button" [style.color]="secondcolor"
                                            [style.border]="'solid 2px '+secondcolor">{{'references' | translate}}</div>
                                        <ul *ngFor="let certif of cvReferences().controls; let refIndex=index">
                                            <li [formGroupName]="refIndex">
                                                <div class="item">
                                                    <div class="item_grp">
                                                        <p class="title" style="font-size: 14px;">
                                                            {{cvReferences().at(refIndex).get('fullname').value}} </p>
                                                        <p class="sub_title"><strong>
                                                                {{cvReferences().at(refIndex).get('organisation').value}}</strong>
                                                        </p>
                                                        <p class="sub_title"><strong>Tel : </strong>
                                                            {{cvReferences().at(refIndex).get('telephone').value}}
                                                            <strong>-- Email :
                                                            </strong>{{cvReferences().at(refIndex).get('email').value}}
                                                        </p>


                                                    </div>

                                                </div>
                                                <br />
                                            </li>

                                        </ul>
                                    </div>

                                </div>

                                <div class="resume_right" id="right_side1" [style.background]="firstcolor">
                                    <div class="profile_sec">

                                        <div class="img_holder">

                                            <img *ngIf="!imageToShow && imgURL" [src]="imgURL" alt="profile image">
                                            <img *ngIf="imageToShow" [src]="imageToShow" alt="profile image">
                                            <br>
                                            <br>
                                        </div>

                                        <div class="profile_info">
                                            <p class="first_name" [style.color]="pagecolor">
                                               <span>{{this.cvFormGroup.get("firstname").value}}</span>
                                                <span style="font-weight: 600;">
                                                    {{this.cvFormGroup.get("lastname").value }}</span>
                                            </p>

                                            <p class="role" style="color: #fff; margin-top: -3px; ">
                                                <span>
                                                {{this.cvFormGroup.get("profession").value}}
                                             </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('telephone').value.trim() || cvFormGroup.get('email').value.trim() ||
                                    cvFormGroup.get('address').value.trim() || cvFormGroup.get('linkedin').value.trim() ||
                                     cvFormGroup.get('birth').value.trim() || cvFormGroup.get('webSite').value.trim() ||
                                     cvFormGroup.get('marital').value.trim() ||
                                      cvFormGroup.get('city').value.trim()" class="contact_sec" [style.background]="secondcolor">

                                        <div class="button">{{'personalofdetails' | translate}}<span
                                                style="color: white;">_</span>{{'detailsofpersonal' | translate}}</div>

                                        <ul>
                                            <li class="item">

                                                <div class="content">

                                                    <p *ngIf="this.cvFormGroup.get('telephone').value && !this.cvFormGroup.get('telephone').value.includes('U2FsdG')"
                                                        class="subtitle" style="font-size: 12px;"> <strong
                                                            style="font-weight: 600;">Tel: </strong> <span>
                                                        {{cvFormGroup.get('telephone').value | encrypted | decrypted}}
                                                    </span>
                                                    </p>
                                                    <p *ngIf="this.cvFormGroup.get('telephone').value.includes('U2FsdG')"
                                                        class="subtitle" style="font-size: 12px;"> <strong
                                                            style="font-weight: 600;">Tel: </strong>
                                                        {{cvFormGroup.get('telephone').value | decrypted}}</p>

                                                </div>
                                            </li>
                                            <li class="item">

                                                <div class="content">

                                                    <p *ngIf="cvFormGroup.get('email').value" class="subtitle" style="font-size: 12px; "><strong>Email:
                                                        </strong> <span> {{cvFormGroup.get('email').value}} </span></p>
                                                </div>
                                            </li>
                                            <li *ngIf="cvFormGroup.get('linkedin').value.trim()" class="item">

                                                <div class="content">

                                                    <p class="subtitle" style="font-size: 12px;"> <strong>Linkedin:
                                                        </strong> <span> {{cvFormGroup.get('linkedin').value}} </span></p>
                                                </div>

                                            </li>
                                            <li *ngIf="cvFormGroup.get('webSite').value.trim()" class="item">

                                                <div class="content">

                                                    <p class="subtitle" style="font-size: 12px;"> <strong>{{'website' |
                                                            translate}}: </strong> <span> {{cvFormGroup.get('webSite').value}}</span>
                                                    </p>
                                                </div>

                                            </li>
                                            <li *ngIf="cvFormGroup.get('address').value.trim() || cvFormGroup.get('city').value.trim()" class="item">

                                                <div class="content">

                                                    <p *ngIf="this.cvFormGroup.get('address').value.includes('U2FsdG')"
                                                        class="subtitle" style="font-size: 12px;"> <strong>{{'address' |
                                                            translate}}: </strong> <span> {{cvFormGroup.get('address').value |
                                                        decrypted}} </span></p>
                                                    <p *ngIf="!this.cvFormGroup.get('address').value.includes('U2FsdG')"
                                                        class="subtitle" style="font-size: 12px;"> <strong>{{'address' |
                                                            translate}}: </strong> <span> {{cvFormGroup.get('address').value |
                                                        encrypted | decrypted}}</span></p>
                                                    <p *ngIf="cvFormGroup.get('city').value.trim()"
                                                        style="font-size: 12px; margin-left: 53px;">
                                                        <span>
                                                        {{cvFormGroup.get('city').value}}
                                                    </span>
                                                    </p>
                                                </div>

                                            </li>
                                            <li *ngIf="cvFormGroup.get('birth').value.trim()" class="item">

                                                <div class="content">

                                                    <p class="subtitle" style="font-size: 12px;"> <strong>{{'birth' |
                                                            translate}} : </strong>
                                                            <span>
                                                            {{cvFormGroup.get('birth').value}}
                                                        </span>
                                                    </p>
                                                </div>

                                            </li>
                                            <li *ngIf="cvFormGroup.get('marital').value.trim()" class="item">

                                                <div class="content">

                                                    <p class="subtitle" style="font-size: 12px;">
                                                        <span>
                                                        {{cvFormGroup.get('marital').value}}
                                                    </span>
                                                    </p>
                                                </div>

                                            </li>

                                        </ul>

                                    </div>

                                    <div *ngIf="cvFormGroup.get('skills').value.length" formArrayName="skills"
                                        class="skills_sec">
                                        <div class="button">{{'skills' | translate}}</div>
                                        <ul *ngFor="let skill of cvSkills().controls; let skillIndex=index">
                                            <li [formGroupName]="skillIndex" class="item">
                                                <div class="content">{{cvSkills().at(skillIndex).get('item').value}}
                                                </div>
                                                <div class="bar_wrap">
                                                    <p class="bar" [style.background]="pagecolor"
                                                        [style.width]="cvSkills().at(skillIndex).get('level').value">
                                                    </p>
                                                </div>
                                            </li>


                                        </ul>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('languages').value.length" class="skills_sec">
                                        <div class="button">{{'languages' | translate}}</div>
                                        <ul *ngFor="let lang of cvLanguages().controls; let langIndex=index">
                                            <li [formGroupName]="langIndex" class="item">
                                                <div class="content">{{cvLanguages().at(langIndex).get('item').value}}
                                                </div>
                                                <div class="bar_wrap">
                                                    <p [style.width]="cvLanguages().at(langIndex).get('level').value"
                                                        class="bar" [style.background]="pagecolor"></p>
                                                </div>
                                            </li>


                                        </ul>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('quality1').value.trim() || cvFormGroup.get('quality2').value.trim() ||
                                            cvFormGroup.get('quality3').value.trim() || cvFormGroup.get('quality4').value.trim()"
                                        class="skills_sec ">
                                        <div class="button ">{{'qualities' | translate}}</div>
                                        <div class="interest">
                                            <ul>
                                                <li> {{cvFormGroup.get('quality1').value}}</li>
                                                <li style="margin-top: 3px;"> {{cvFormGroup.get('quality2').value}}</li>

                                            </ul>
                                            <ul>
                                                <li> {{cvFormGroup.get('quality3').value}}</li>
                                                <li style="margin-top: 3px;"> {{cvFormGroup.get('quality4').value}}</li>

                                            </ul>
                                        </div>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('hobby1').value.trim() || cvFormGroup.get('hobby2').value.trim() ||
                                      cvFormGroup.get('hobby3').value.trim() || cvFormGroup.get('hobby4').value.trim()"
                                        class="skills_sec ">
                                        <div class="button ">{{'hobbies2' | translate}}<span
                                                style="color: white;">_</span>{{'hobbies3' | translate}}</div>
                                        <div class="interest">
                                            <ul>
                                                <li> {{cvFormGroup.get('hobby1').value}}</li>
                                                <li style="margin-top: 3px;"> {{cvFormGroup.get('hobby2').value}}</li>

                                            </ul>
                                            <ul>
                                                <li> {{cvFormGroup.get('hobby3').value}}</li>
                                                <li style="margin-top: 3px;"> {{cvFormGroup.get('hobby4').value}}</li>

                                            </ul>
                                        </div>
                                    </div>


                                </div>
                            </div>






                        </div>




                        <!--  END PAGE 1-->





                </div>
            </form>
        </div>

    </div>
</div>

<!--   FORM ARRAY         -->
