import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../../pages/cv-login-page/token-storage.service';

@Component({
  selector: 'app-header-style-one',
  templateUrl: './header-style-one.component.html',
  styleUrls: ['./header-style-one.component.scss']
})
export class HeaderStyleOneComponent implements OnInit {

    constructor(private tokenStorageService: TokenStorageService) { }

    tokenStorage : any;

    ngOnInit(): void {
      this.tokenStorage=this.tokenStorageService;
      if(this.tokenStorageService.getToken()===null){
          this.tokenStorageService.signOut3();
      }
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

}
