<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Confidentialité</li>
            </ul>
            <h2>Politique de confidentialité</h2>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/about7.jpg" class="shadow" alt="image">
                    <img src="assets/img/about/about8.jpg" class="shadow" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">About Us !</span> -->
                    <h2>Sécurité des informations sensibles</h2>
                    <h6>La protection des données sensibles à caractère privé</h6>
                    <p>Nous maintenons des mesures de sécurité techniques, physiques et administratives appropriées conçues
                         pour protéger la sécurité de vos informations contre la perte, l'utilisation abusive, l'accès non autorisé ou accidentel, 
                         la divulgation, la copie, l'utilisation ou la modification.</p>
                    <p></p>
                    <div class="features-text">
                        <h5>Nous n'utilisons pas vos données pour des fins personnels</h5>
                      
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> 
                                Lorsque vous enregistrez un compte, utilisez nos services, téléchargez un CV (abonnement),
                                 vous partagez volontairement certaines informations avec nous,
                                 y compris, mais sans s'y limiter, votre nom, 
                                votre adresse e-mail, numéro de portable et/ou l'identifiant de votre compte compte Paypal...</li>
                            <li><i class='bx bx-check'></i> nous limitons notre collecte de données personnelles
                                à seules les données personnelles qui sont nécessaires à des fins légitimes ;</li>
                            
                            <li><i class='bx bx-check'></i>nous prenons des mesures de sécurité adéquates pour protéger
                                vos données personnelles ; </li>
                            <li><i class='bx bx-check'></i>nous respectons votre droit d’obtenir, à votre demande, vos
                                données personnelles à des fins de contrôle, de correction ou de suppression. </li>

                        </ul>

                      
                        <p></p>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Chiffrement des données sensibles</h3>
                        <p>
                            Les données sensibles tels que les mots de passe, les numéros de téléphone, les adresses personnelles,
                            sont chiffrées de bout en bout. Merci de juste respecté les les champs désignants ces données afin de nous aider à
                            maintenir le respect de ces données.

                        </p>
                        <!--
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Activate Listening</li>
                            <li><i class='bx bx-check'></i> Brilliant minds</li>
                            <li><i class='bx bx-check'></i> Better. Best. Wow!</li>
                            <li><i class='bx bx-check'></i> Branding it better!</li>
                        </ul>
                        -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Création et Enregistrement d'un CV</h3>
                        <p>
                            En créant un CV, vous créez automatiquement un compte pour iCV.
                            Ce compte fait partie de votre abonnement. Nous utilisons les données suivantes pour créer
                            votre iCV et compte :

                        </p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Nom et adresse; Numéro de téléphone; adresse email; données
                                de paiement</li>
                            <li><i class='bx bx-check'></i> Sexe; photo; date de naissance; </li>
                            <li><i class='bx bx-check'></i>Adresse IP</li>
                            <li><i class='bx bx-check'></i>Les informations telles que mentionnées sur votre CV,
                                comme par exemple votre expérience professionnelle et vos formations</li>

                        </ul>
                       
                    </div>
                   <br>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Enregistrement d'un CV</h3>
                        
                        <p>
                            Lors de la création de votre CV, nous sauvegardons dans votre compte les données personnelles que
                            vous avez transmises.
                            Nous les sauvegardons lors du changement de modèle et lorsque vous cliquez sur "Sauvegarder".
                            Vous pouvez vous connecter à votre compte avec l'adresse e-mail que vous nous avez indiquée
                            comme nom d'utilisateur et
                            le mot de passe . Nous sauvegardons ces données pour que vous ne deviez pas à chaque
                            fois les saisir. Par contre vous pouvez juste vous déconnecter pour sortir de la session. Une session peut être 
                            conserver pour une durée maximale de 10 jours dans le navigateur, quand vous ne cliquez pas sur "Déconnecter" 
                            .
                        </p>

                    </div>
                    <p></p>
                </div>
                

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Délais de conservation</h3>
                        <p>
                            iCV sauvegarde vos données personnelles aussi longtemps que vous pour réaliser les
                            objectifs formulés
                            dans cette déclaration. A cet égard, nous conservons vos données personnelles tout en protégeant vos données sensibles par un chiffrement
                            de bout en bout jusqu'à ce que
                            vous décider de le supprimer de vous même. Veuillez noter qu'un CV supprimé ne peut-être recupéré.
                            Les données vous appartiennent, vous avez la liberté de les supprimer comme bon vous semble.
                            
                        </p>
                        <p></p>
                        <p></p>
                        <!--
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Activate Listening</li>
                            <li><i class='bx bx-check'></i> Brilliant minds</li>
                            <li><i class='bx bx-check'></i> Better. Best. Wow!</li>
                            <li><i class='bx bx-check'></i> Branding it better!</li>
                        </ul>
                        -->
                    </div>
                    <p></p>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Durée d'une session</h3>
                        <p>
                            Une session peut durer jusqu'à 10 jours sans vous déconnecter. Pour sortir de votre session vous dévez vous déconnecter.
                            Lorsque vous utilisez un équipement ou un terminal qui n'est pas le votre, nous vous conseillons de vous déconnnecter afin de ne pas laisser
                            votre session ouverte dans un appareil qui n'est pas le votre. Vous éviterez ainsi tout exploitation de vos données personnelles.

                            
                        </p>
                        <!--
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Activate Listening</li>
                            <li><i class='bx bx-check'></i> Brilliant minds</li>
                            <li><i class='bx bx-check'></i> Better. Best. Wow!</li>
                            <li><i class='bx bx-check'></i> Branding it better!</li>
                        </ul>
                        -->
                    </div>
                    <p></p>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Nous contacter via le formulaire</h3>
                        <p>
                          Quand vous nous envoyez un message en utilisant notre formulaire de contact sur le site Internet,
                            ou lorsque vous nous envoyez un e-mail, les données que vous nous envoyez seront
                            sauvegardées aussi longtemps que nécessaire, d'après la nature du formulaire ou le contenu
                            de votre e-mail,
                            pour obtenir une réponse et un traitement complets. Dans le formulaire de contact,
                            nous vous demanderons en tous les cas de communiquer votre nom, votre adresse email,
                             votre localisation, l'objet ainsi
                            que le contenu du message.
                        </p>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row">
               
             
               

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Message d'appréciation</h3>
                        <p>
                            Lorsque vous appréciez notre site via icv-pro.com ou via les réseau sociaux,
                            il peut arriver que nous publions votre message d'appréciation sur notre site.
                           Nous pourrons décider ainsi de publier ce message en utilisant votre nom et votre photo de profil, 
                           avec votre permission pour ce dernier.

                        </p>

                    </div>
                    <p></p>
                </div>

  

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Cookies</h3>
                        <p>
                            Nous n'utilisons pas de cookies pour tout notre site, 
                           Aucune donnée personnelle n'est prélévée.
                           Par contre nous utlisons Google Analytics pour être de l'audience de notre notre site web.
                        </p>
                        <p>
                           
                        </p>
                     <p></p>
                    </div>
                    <p></p>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3> Google Analytics sans consentement de Cookie</h3>
                       
                        Nous n'utilisons pas de bannière de consentement de cookies pour les raisons suivantes:
                            <ul class="features-list">
                                <li><i class='bx bx-check'></i>  Nous ne faisons la collecte des données dans le cadre des fonctionnalités de publicité;</li>
                                <li><i class='bx bx-check'></i> Nous ne faisons pas de la conservation de données. Lorsque vous supprimez un Cv, toutes vos données liées à ce CV son supprimées de façon définitive ;</li>
                                <li><i class='bx bx-check'></i>  Nous n'utilisons aucun lien avec Google Ads ;</li>
               
                            </ul>
                            <p>
                            
                            </p>

                    </div>
                    <p></p>
                </div>
                
               
               
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>  Modifications dans la déclaration de confidentialité </h3>
                        <p>
                            Le droit d'apporter des modifications dans cette déclaration de confidentialité nous ait reservé.
                             Il est vivement conseillé de
                             régulièrement consulter notre déclaration de confidentialité et de cookies pour rester informé de ces modifications.
                        </p>

                    </div>
                    <p></p>
                </div>

              

            </div>
        </div>
    </div>
</div>

<!--
<app-our-mission></app-our-mission>


<div class="story-area ptb-100">
    <app-our-story></app-our-story>
</div>

<div class="funfacts-area">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div>

<div class="values-area ptb-100">
    <app-our-values></app-our-values>
</div>

<div class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-two></app-instructors-style-two>
    </div>
</div>

<div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>
-->

<!--
<div class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>
-->