<app-header-style-one></app-header-style-one>
<div class="login-area">
    <div class="row m-0">

        <div class="col-lg-12 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/black-logo.png" alt="image"></a>
                            </div>
                            <h3>Bienvenue! </h3>
                            <div class="alert alert-success" *ngIf="isLoggedIn">
                                <i class="bx bx-check"></i> {{loginMessage}}</div>

                            <p *ngIf="isLoginFailed" class="text text-danger">{{loginMessage}}</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
