

    <div id="cvt" class="container-fluid" style="height: 100%; width: 100%;">
        <div class="row">


        <!--   CV TEMPLATE -->

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">


            <form [formGroup]="cvFormGroup">

                <div id="cvt2">

                    <div id="cvt10">



                        <div id="content1" class="resume-wrapper-inner mx-auto text-left bg-white shadow-lg text-justify" style="width: 595.35pt ; min-width: 595.35pt; height: 841.995pt;min-height:841.995pt;max-width :595.35pt ">
                            <header class="resume-header " style="width: 595.35pt;min-width: 595.35pt; height: 165pt ;max-height: 165pt;max-width: 595.35pt; position: relative;" [style.background]="firstcolor">
                                <div class="media " style="width: 595.35pt;min-width: 595.35pt; max-width: 595.35pt; height: 165pt ;max-height: 165pt; padding-left: 43px;padding-top: 12px;">


                                    <img *ngIf="!imageToShow && imgURL" class="mr-3 img-fluid picture mx-auto" [src]="imgURL" alt="" >
                                    <img *ngIf="imageToShow" class="mr-auto picture mx-auto" [src]="imageToShow" alt="" >


                                    <div class="media-body p-4" style="width: 435.35pt;min-width: 435.35pt; height: 155pt ;max-height: 155pt; margin-top: -12px;">
                                        <div class="primary-info">
                                            <h1 class="mt-0 mb-1 text-uppercase text-uppercase" style="font-size: 26px;" [style.color]="pagecolor">{{this.cvFormGroup.get("firstname").value+" "+this.cvFormGroup.get("lastname").value}}</h1>
                                            <div class="title mb-3" style="font-size: 14px; margin-top : 0"> <strong>  {{this.cvFormGroup.get("profession").value}} </strong></div>
                                            <ul class="list-unstyled">
                                                <li style="font-size: 13px;" *ngIf="cvFormGroup.get('telephone').value.trim()"> <strong>{{'phone' | translate}} :</strong> <span *ngIf="!this.cvFormGroup.get('telephone').value.includes('U2FsdG')"> {{cvFormGroup.get('telephone').value | encrypted | decrypted}} </span>
                                                    <span *ngIf="this.cvFormGroup.get('telephone').value.includes('U2FsdG')"> {{cvFormGroup.get('telephone').value | decrypted}} </span>
                                                </li>
                                                <li style="font-size: 13px;" *ngIf="cvFormGroup.get('email').value.trim()"><strong>Email :</strong> {{cvFormGroup.get('email').value}}</li>
                                                <li *ngIf="cvFormGroup.get('linkedin').value.trim()" style="font-size: 13px;"><strong>Linkedin :

                                                </strong> {{cvFormGroup.get('linkedin').value}} <strong *ngIf="cvFormGroup.get('webSite').value.trim()">{{'website' | translate}} :</strong>{{cvFormGroup.get('webSite').value}}</li>

                                                <li *ngIf="cvFormGroup.get('address').value.trim() || cvFormGroup.get('city').value.trim()" style="font-size: 13px;">
                                                    <span style="font-weight: 700;">{{'address' | translate}} :</span> <span *ngIf="this.cvFormGroup.get('address').value.includes('U2FsdG')"> {{cvFormGroup.get('address').value | decrypted}} </span>
                                                    <span *ngIf="!this.cvFormGroup.get('address').value.includes('U2FsdG')"> {{cvFormGroup.get('address').value | encrypted | decrypted}} </span> {{' ,'+cvFormGroup.get('city').value}} </li>

                                                <li *ngIf="cvFormGroup.get('birth').value.trim()" style="font-size: 13px;"> <strong>{{'birth' | translate}} :</strong> {{cvFormGroup.get('birth').value}}</li>
                                                <li *ngIf="cvFormGroup.get('marital').value.trim()" style="font-size: 13px; margin-bottom: 21px;"> {{cvFormGroup.get('marital').value}}</li>
                                            </ul>
                                        </div>
                                        <!--//primary-info-->

                                        <!--//secondary-info-->

                                    </div>
                                    <!--//media-body-->
                                </div>
                                <!--//media-->
                            </header>
                            <div class="resume-body p-5" style="min-width: 595.35pt; width:595.35pt ;">

                                <!--//summary-section-->
                                <div class="row">
                                    <div class="col-4" id="right_side1" style="padding-left: 12px; padding-right: 12px;padding-top: 15px; background: #e0e0e0;">
                                        <section *ngIf="cvFormGroup.get('skills').value.length" formArrayName="skills" class="resume-section skills-section mb-5">
                                            <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3" [style.color]="secondcolor">{{'skills' | translate}}</h2>
                                            <div class="resume-section-content">
                                                <div class="resume-skill-item">

                                                    <ul class="list-unstyled ">
                                                        <li *ngFor="let skill of cvSkills().controls; let skillIndex=index" class="mb-2">
                                                            <div class="resume-skill-name" [formGroupName]="skillIndex">{{cvSkills().at(skillIndex).get('item').value}}</div>
                                                            <div [formGroupName]="skillIndex" class="progress resume-progress" style="background-color: #fff;">
                                                                <div class="progress-bar theme-progress-bar-dark" role="progressbar" [style.width]="cvSkills().at(skillIndex).get('level').value"
                                                                 aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" [style.background]="secondcolor"></div>
                                                            </div>
                                                        </li>

                                                    </ul>
                                                </div>


                                            </div>
                                        </section>


                                        <section *ngIf="cvFormGroup.get('languages').value.length" formArrayName="languages" class="resume-section language-section mb-4">
                                            <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3" [style.color]="secondcolor">{{'languages' | translate}}</h2>
                                            <div class="resume-section-content">
                                                <ul class="list-unstyled resume-lang-list">
                                                    <li *ngFor="let lang of cvLanguages().controls; let langIndex=index" class="mb-2">
                                                        <div [formGroupName]="langIndex" class="resume-skill-name">{{cvLanguages().at(langIndex).get('item').value}}</div>
                                                        <div class="progress resume-progress" style="background-color: #fff;">
                                                            <div class="progress-bar theme-progress-bar-dark" role="progressbar" [style.width]="cvLanguages().at(langIndex).get('level').value" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" [style.background]="secondcolor"></div>
                                                        </div>
                                                    </li>



                                                </ul>
                                            </div>
                                        </section>
                                        <section *ngIf="cvFormGroup.get('quality1').value.trim() || cvFormGroup.get('quality2').value.trim() ||
                                        cvFormGroup.get('quality3').value.trim() || cvFormGroup.get('quality4').value.trim()" class="resume-section interests-section mb-4">
                                            <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3" [style.color]="secondcolor">{{'qualities' | translate}}</h2>
                                            <div class="resume-section-content">
                                                <ul class="list-unstyled" style=" display: grid; grid-template-columns: repeat(2, 1fr);">

                                                    <li class="mb-1">{{cvFormGroup.get('quality1').value}}</li>
                                                    <li class="mb-1">{{cvFormGroup.get('quality2').value}}</li>
                                                </ul>
                                                <ul class="list-unstyled" style=" display: grid; grid-template-columns: repeat(2, 1fr);">
                                                    <li class="mb-1">{{cvFormGroup.get('quality3').value}}</li>
                                                    <li class="mb-1">{{cvFormGroup.get('quality4').value}}</li>

                                                </ul>
                                            </div>
                                        </section>
                                        <section *ngIf="cvFormGroup.get('hobby1').value.trim() || cvFormGroup.get('hobby2').value.trim() ||
                                        cvFormGroup.get('hobby3').value.trim() || cvFormGroup.get('hobby4').value.trim()" class="resume-section interests-section mb-4">
                                            <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3" [style.color]="secondcolor">{{'hobbies' | translate}}</h2>
                                            <div class="resume-section-content">
                                                <ul class="list-unstyled" style=" display: grid; grid-template-columns: repeat(2, 1fr);">

                                                    <li class="mb-1">{{cvFormGroup.get('hobby1').value}}</li>
                                                    <li class="mb-1">{{cvFormGroup.get('hobby2').value}}</li>
                                                </ul>
                                                <ul class="list-unstyled" style=" display: grid; grid-template-columns: repeat(2, 1fr);">
                                                    <li class="mb-1">{{cvFormGroup.get('hobby3').value}}</li>
                                                    <li class="mb-1">{{cvFormGroup.get('hobby4').value}}</li>

                                                </ul>
                                            </div>
                                        </section>

                                        <section *ngIf="cvFormGroup.get('references').value.length" formArrayName="references" class="resume-section experience-section mb-3">
                                            <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3" [style.color]="secondcolor">{{'references' | translate}}</h2>
                                            <div class="resume-section-content">
                                                <div class="resume-timeline position-relative">
                                                    <!-- project1 -->
                                                    <article *ngFor="let certif of cvReferences().controls; let refIndex=index" class="resume-timeline-item position-relative pb-2">
                                                        <div [formGroupName]="refIndex" class="resume-timeline-item-header mb-2">
                                                            <div class="d-flex flex-column flex2">
                                                                <h3 class="resume-position-title font-weight-bold mb-1">{{cvReferences().at(refIndex).get('fullname').value}}</h3>

                                                            </div>
                                                            <div class="resume-position-time" [style.color]="firstcolor"> <strong>{{cvReferences().at(refIndex).get('organisation').value}}</strong> </div>
                                                            <div *ngIf="cvReferences().at(refIndex).get('telephone').value.trim()" class="resume-position-time" style="font-size: 12px;"> <strong>Tel :</strong> {{cvReferences().at(refIndex).get('telephone').value}} </div>
                                                            <div *ngIf="cvReferences().at(refIndex).get('email').value.trim()" class="resume-position-time" style="font-size: 12px;"> <strong>Email:</strong> {{cvReferences().at(refIndex).get('email').value}} </div>
                                                        </div>

                                                    </article>

                                                    <!-- /project1 -->
                                                    <!-- project1 -->


                                                </div>
                                            </div>
                                        </section>

                                    </div>

                                    <div class="col-8" id="left_side1" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;  ">

                                        <section *ngIf="cvFormGroup.get('profile').value.trim()" class="resume-section summary-section mb-3">
                                            <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3" [style.color]="secondcolor">{{'profile' | translate}}</h2>
                                            <div class="resume-section-content text-justify">
                                                <p style="font-size: 12px; word-wrap: break-word;">

                                                    {{this.cvFormGroup.get("profile").value}}
                                                </p>
                                            </div>
                                        </section>

                                        <!--//education-section Beginner-->
                                        <section *ngIf="cvFormGroup.get('educations').value.length && isBeginner" class="resume-section experience-section mb-2">
                                            <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3" [style.color]="secondcolor">Education</h2>
                                            <div class="resume-section-content">
                                                <div class="resume-timeline position-relative">
                                                    <!-- project1 -->
                                                    <article *ngFor="let education of cvEducations().controls; let educationIndex=index" class="resume-timeline-item position-relative pb-2">
                                                        <div [formGroupName]="educationIndex" class="resume-timeline-item-header mb-2">
                                                            <div class="d-flex flex-column flex2">
                                                                <h3 class="resume-position-title font-weight-bold mb-1" [style.color]="firstcolor">
                                                                    {{cvEducations().at(educationIndex).get('degree').value}} </h3>
                                                                <div class="resume-company-name ml-auto">{{cvEducations().at(educationIndex).get('period').value}}

                                                                </div>
                                                            </div>
                                                            <div class="resume-position-time"> <strong> {{cvEducations().at(educationIndex).get('institute').value}}</strong><span>{{" ,
                                                                "+cvEducations().at(educationIndex).get('city').value}}</span> </div>
                                                        </div>

                                                    </article>

                                                    <!-- /project1 -->
                                                    <!-- project1 -->


                                                </div>
                                            </div>
                                        </section>


                                        <!--//experience-section Professional-->
                                        <section *ngIf="cvFormGroup.get('experiences').value.length && isProfessional" formArrayName="experiences" class="resume-section experience-section mb-2">
                                            <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-2" [style.color]="secondcolor">{{'experiences' | translate}}</h2>
                                            <div class="resume-section-content">
                                                <div class="resume-timeline position-relative">
                                                    <!-- project1 -->
                                                    <article *ngFor="let experience of cvExperiences().controls; let experienceIndex=index" class="resume-timeline-item position-relative pb-3">
                                                        <div [formGroupName]="experienceIndex" class="resume-timeline-item-header mb-2">
                                                            <div class="d-flex flex-column flex2">
                                                                <h3 class="resume-position-title font-weight-bold mb-1" [style.color]="firstcolor"> {{cvExperiences().at(experienceIndex).get('function').value}}</h3>
                                                                <div class="resume-company-name ml-auto" style="margin-left: 0;">{{cvExperiences().at(experienceIndex).get('period').value}}</div>
                                                            </div>
                                                            <div class="resume-position-time"><strong> {{cvExperiences().at(experienceIndex).get('company').value}}

                                                            </strong><span> {{', '+cvExperiences().at(experienceIndex).get('city').value}}</span></div>
                                                        </div>
                                                        <div class="resume-timeline-item-desc text-justify">
                                                            <p style="font-size: 12px;" [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">
                                                            </p>

                                                        </div>
                                                    </article>
                                                    <!-- /project1 -->
                                                    <!-- project1 -->



                                                </div>
                                            </div>
                                        </section>

                                        <!--//certification-section-->
                                        <section *ngIf="cvFormGroup.get('certifications').value.length" formArrayName="certifications" class="resume-section experience-section mb-2">
                                            <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3" [style.color]="secondcolor">{{'certificates' | translate}}</h2>
                                            <div class="resume-section-content">
                                                <div class="resume-timeline position-relative">
                                                    <!-- project1 -->
                                                    <article *ngFor="let certif of cvCertifications().controls; let certifIndex=index" class="resume-timeline-item position-relative pb-2">
                                                        <div [formGroupName]="certifIndex" class="resume-timeline-item-header mb-2">
                                                            <div class="d-flex flex-column flex2">
                                                                <h3 class="resume-position-title font-weight-bold mb-1" [style.color]="firstcolor"> {{cvCertifications().at(certifIndex).get('certificat').value}}</h3>
                                                                <div class="resume-company-name ml-auto">{{cvCertifications().at(certifIndex).get('date').value}}</div>
                                                            </div>
                                                            <div class="resume-position-time"> <strong>  {{cvCertifications().at(certifIndex).get('institute').value}}</strong> </div>
                                                        </div>

                                                    </article>

                                                    <!-- /project1 -->



                                                </div>
                                            </div>
                                        </section>

                                        <section *ngIf="cvFormGroup.get('formations').value.length" formArrayName="formations" class="resume-section experience-section mb-2">
                                            <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3" [style.color]="secondcolor">Formations</h2>
                                            <div class="resume-section-content">
                                                <div class="resume-timeline position-relative">
                                                    <!-- project1 -->
                                                    <article *ngFor="let formation of cvFormations().controls; let formationIndex=index" class="resume-timeline-item position-relative pb-3">
                                                        <div [formGroupName]="formationIndex" class="resume-timeline-item-header mb-2">
                                                            <div class="d-flex flex-column flex2">
                                                                <h3 class="resume-position-title font-weight-bold mb-1" [style.color]="firstcolor"> {{cvFormations().at(formationIndex).get('title').value}}</h3>
                                                                <div class="resume-company-name ml-auto">{{cvFormations().at(formationIndex).get('period').value}}</div>
                                                            </div>
                                                            <div class="resume-position-time"><strong>{{cvFormations().at(formationIndex).get('former').value}}</strong><span></span></div>
                                                        </div>
                                                        <div class="resume-timeline-item-desc text-justify">
                                                            <p style="font-size: 12px;" [innerHTML]="cvFormations().at(formationIndex).get('description').value">
                                                            </p>

                                                        </div>
                                                    </article>
                                                    <!-- /project1 -->
                                                    <!-- project1 -->



                                                </div>
                                            </div>
                                        </section>


                                        <!--//education-section Professional-->
                                        <section *ngIf="cvFormGroup.get('educations').value.length && isProfessional" class="resume-section experience-section mb-2">
                                            <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3" [style.color]="secondcolor">Education</h2>
                                            <div class="resume-section-content">
                                                <div class="resume-timeline position-relative">
                                                    <!-- project1 -->
                                                    <article *ngFor="let education of cvEducations().controls; let educationIndex=index" class="resume-timeline-item position-relative pb-2">
                                                        <div [formGroupName]="educationIndex" class="resume-timeline-item-header mb-2">
                                                            <div class="d-flex flex-column flex2">
                                                                <h3 class="resume-position-title font-weight-bold mb-1" [style.color]="firstcolor">
                                                                    {{cvEducations().at(educationIndex).get('degree').value}} </h3>
                                                                <div class="resume-company-name ml-auto">{{cvEducations().at(educationIndex).get('period').value}}

                                                                </div>
                                                            </div>
                                                            <div class="resume-position-time"> <strong> {{cvEducations().at(educationIndex).get('institute').value}}</strong><span>{{" ,
                                                                "+cvEducations().at(educationIndex).get('city').value}}</span> </div>
                                                        </div>

                                                    </article>

                                                    <!-- /project1 -->
                                                    <!-- project1 -->


                                                </div>
                                            </div>
                                        </section>

                                        <!--//experience-section Beginner-->
                                        <section *ngIf="cvFormGroup.get('experiences').value.length && isBeginner" formArrayName="experiences" class="resume-section experience-section mb-2">
                                            <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-2" [style.color]="secondcolor">{{'experiences' | translate}}</h2>
                                            <div class="resume-section-content">
                                                <div class="resume-timeline position-relative">
                                                    <!-- project1 -->
                                                    <article *ngFor="let experience of cvExperiences().controls; let experienceIndex=index" class="resume-timeline-item position-relative pb-3">
                                                        <div [formGroupName]="experienceIndex" class="resume-timeline-item-header mb-2">
                                                            <div class="d-flex flex-column flex2">
                                                                <h3 class="resume-position-title font-weight-bold mb-1" [style.color]="firstcolor"> {{cvExperiences().at(experienceIndex).get('function').value}}</h3>
                                                                <div class="resume-company-name ml-auto" style="margin-left: 0;">{{cvExperiences().at(experienceIndex).get('period').value}}</div>
                                                            </div>
                                                            <div class="resume-position-time"><strong> {{cvExperiences().at(experienceIndex).get('company').value}}

                                                            </strong><span> {{', '+cvExperiences().at(experienceIndex).get('city').value}}</span></div>
                                                        </div>
                                                        <div class="resume-timeline-item-desc text-justify">
                                                            <p style="font-size: 12px;" [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">
                                                            </p>

                                                        </div>
                                                    </article>
                                                    <!-- /project1 -->
                                                    <!-- project1 -->



                                                </div>
                                            </div>
                                        </section>

                                        <div *ngIf="cvFormGroup.get('customs').value.length" formArrayName="customs">
                                            <section *ngFor="let custom of cvCustoms().controls; let customIndex=index" [formGroupName]="customIndex" class="resume-section experience-section mb-2">
                                                <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3" [style.color]="secondcolor">{{cvCustoms().at(customIndex).get('title').value}}</h2>
                                                <div class="resume-section-content">
                                                    <div class="resume-timeline position-relative">
                                                        <!-- project1 -->
                                                        <article class="resume-timeline-item position-relative pb-3">

                                                            <div class="resume-timeline-item-desc text-justify">
                                                                <p style="font-size: 12px;" [innerHTML]="cvCustoms().at(customIndex).get('description').value">
                                                                </p>

                                                            </div>
                                                        </article>
                                                        <!-- /project1 -->
                                                        <!-- project1 -->



                                                    </div>
                                                </div>
                                            </section>
                                        </div>




                                    </div>



                                </div>
                            </div>
                        </div>



                        <!--  END PAGE 1-->



                    </div>

                </div>
            </form>
        </div>

    </div>
</div>

<!--   FORM ARRAY         -->
