import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";

import { tap, catchError, map, timeout } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { TokenStorageService } from "../cv-login-page/token-storage.service";





@Injectable({
    providedIn: "root",
})
export class ThemeService {

    private HOST2 =  environment.baseUrl+ 'theme/';
    private HOST= "api/themes";
    private HOST3= "api/themes2";
    private httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    constructor(
        private http: HttpClient,
        private tokenStorage: TokenStorageService
    ) {}

    findAll(idModele: number): Observable<any> {

        return this.http.get<any[]>(this.HOST).pipe(
            tap((response) => this.log(response)),
            catchError((error) => this.handleError(error, []))
          );
    }

    findAll3(idModele: number): Observable<any> {

        return this.http.get<any[]>(this.HOST3).pipe(
            tap((response) => this.log(response)),
            catchError((error) => this.handleError(error, []))
          );
    }


    findAll2(idModele: number): Observable<any> {

        return this.http.get(this.HOST + "findAll/"+idModele, this.httpOptions);
    }

    findAll4(idModele: number): Observable<any> {

        return this.http.get(this.HOST3 + "findAll/"+idModele, this.httpOptions);
    }

    findOne(id: number): Observable<any> {
        const url =this.HOST+'/'+id
        return this.http.get(url);
    }
    findOne3(id: number): Observable<any> {
        const url =this.HOST3+'/'+id
        return this.http.get(url);
    }

    findOne2(id: number): Observable<any> {

        const url = this.HOST + "findOne/" + id;
        return this.http.get(url);
    }

    private log(response: any) {
        console.table(response);
      }

    private handleError(error: Error, errorValue: any) {
        console.error(error);
        return of(errorValue);
      }

    private handleErro2<T>(operation = "operation", result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            console.log(`${operation} failed: ${error.message}`);

            return of(result as T);
        };
    }


}
