import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";

import { tap, catchError, map, timeout } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { Cv } from "./cv";
import { TokenStorageService } from "../../cv-login-page/token-storage.service";

@Injectable({
    providedIn: "root",
})
export class CvService {
    private HOST = environment.baseUrl + "cv/";
    private httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    constructor(
        private http: HttpClient,
        private tokenStorage: TokenStorageService
    ) {}

    save(cv: Cv): Observable<any> {
        return this.http.post(this.HOST + "save", cv);
    }

    saveFormData(cv: Cv, file: File): Observable<any> {
        const formData = new FormData();

        formData.append("cv", JSON.stringify(cv));

        /*
formData.append('etudiant',new Blob([JSON.stringify(etudiant)], {
  type: "application/jdon"
}));
*/
        formData.append("file", file);

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                responseType: "text",

                Authorization: this.tokenStorage.getToken(),
            }),
        };

        const request = new HttpRequest(
            "POST",
            this.HOST + "saveData",
            formData,
            {
                reportProgress: true,
                responseType: "text",
            }
        );

        return this.http.request(request);

        // return this.http.post(this.HOST+'saveEtudiant', formData);
    }

    upload(file: File): Observable<any> {
        const formData = new FormData();

        formData.append("file", file, "aaaa.png");

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                responseType: "text",

                Authorization: this.tokenStorage.getToken(),
            }),
        };

        const request = new HttpRequest(
            "POST",
            this.HOST + "upload",
            formData,
            {
                reportProgress: true,
                responseType: "text",
            }
        );

        return this.http.request(request);
    }

    update(cv: Cv, id: string): Observable<any> {
        localStorage.setItem("icv_cv", JSON.stringify(cv));
        const url = this.HOST + "update/" + id;
        return this.http.patch(url, cv);
    }

    findAll(): Observable<any> {
        return this.http.get(this.HOST + "findAll", this.httpOptions);
    }

    findOne3(id: string): Observable<any> {
        // Fallback to HTTP request or return an error observable

        const url = this.HOST + "findOne/" + id;
        return this.http.get(url);
    }
    findOne(id: string): Observable<any> {
        const localData = localStorage.getItem("icv_cv");

        if (localData) {
            return of(JSON.parse(localData));
        } else {
            // Fallback to HTTP request or return an error observable
            const url = this.HOST + "findOne/" + id;
            return this.http.get(url);
        }
    }

    changeModele(cv: Cv, id: string): Observable<any> {
        localStorage.setItem("icv_cv", JSON.stringify(cv));
        const localData = localStorage.getItem("icv_cv");

        if (localData) {
            return of(JSON.parse(localData));
        } else {
            // Fallback to HTTP request or return an error observable
            const url = this.HOST + "findOne/" + id;
            return this.http.get(url);
        }
    }

    findOne2(id: string): Observable<any> {
        const url = this.HOST + "findOne2/" + id;

        return this.http.get(url);
    }

    deleteOne(id: string): Observable<any> {
        const url = this.HOST + "delete/" + id;

        return this.http.get(url);
    }

    fetchImageById(photoname: string): Observable<Blob> {
        let url = this.HOST + "show-photo/" + photoname;
        let token = this.tokenStorage.getToken();
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        // console.log("Profile image URL is " + url);

        //return this.http.get(url,{headers: headers, responseType: 'blob'});
        return this.http.get(url, { responseType: "blob" });
    }

    fetchImageById2(url: string): Observable<Blob> {
        return this.http.get(url, { responseType: "blob" });
    }

    private handleError<T>(operation = "operation", result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            console.log(`${operation} failed: ${error.message}`);

            return of(result as T);
        };
    }
}
