<app-header-style-one></app-header-style-one>

<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Modèles Lettres de motivation</li>
            </ul>
            <h2>Choisir un modèle</h2>
        </div>
    </div>
</div>

<div class="courses-area ptb-100">
    <div class="container">
        <div class="courses-topbar">
            <div class="row align-items-center">





            </div>
        </div>



        <div class="row">

            <div class="col-lg-3 col-md-4 col-sm-6" *ngFor=" let modele of listModele2">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a *ngIf="modele.id===1" style="border: 1px solid #cccc; " (click)="addCover(1)"
                            class="d-block"><img src="assets/images/icons-modeles-cl/1.png" alt="image"></a>
                        <a *ngIf="modele.id===2" style="border: 1px solid #cccc;" (click)="addCover(2)"
                            class="d-block"><img src="assets/images/icons-modeles-cl/2.png" alt="image"></a>
                        <a *ngIf="modele.id===3" style="border: 1px solid #cccc;" (click)="addCover(3)"
                            class="d-block"><img src="assets/images/icons-modeles-cl/3.png" alt="image"></a>
                        <a *ngIf="modele.id===4" style="border: 1px solid #cccc;" (click)="addCover(4)"
                            class="d-block"><img src="assets/images/icons-modeles-cl/4.png" alt="image"></a>
                        <a *ngIf="modele.id===5" style="border: 1px solid #cccc;" (click)="addCover(5)"
                            class="d-block"><img src="assets/images/icons-modeles-cl/5.png" alt="image"></a>
                        <a *ngIf="modele.id===6" style="border: 1px solid #cccc;" (click)="addCover(6)"
                            class="d-block"><img src="assets/images/icons-modeles-cl/6.png" alt="image"></a>
                        <a *ngIf="modele.id===7" style="border: 1px solid #cccc;" (click)="addCover(7)"
                            class="d-block"><img src="assets/images/icons-modeles-cl/7.png" alt="image"></a>
                        <a *ngIf="modele.id===8" style="border: 1px solid #cccc;" (click)="addCover(8)"
                            class="d-block"><img src="assets/images/icons-modeles-cl/8.png" alt="image"></a>


                        <div class="courses-tag">
                            <a *ngIf="modele.id!==17" (click)="addCover(modele.id)" class="d-block">Cliquez pour
                                choisir</a>
                        </div>
                    </div>

                </div>
            </div>


        </div>


    </div>
</div>
