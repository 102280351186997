
            <div class="checkout-area ptb-100" style="margin-left : 12px; margin-right: 33px ">
                <div class="container">
                    <div class="user-actions" style="margin-bottom: 0; ">
                        <div class="row">
                            <div   class="col-lg-3 col-md-3 col-sm-6 ">

                                <span >

                                    <a  style=" font-size: 15px;font-weight: 600;"
                                        routerLink="/cvtheque">
                                        <i class="bx bx-arrow-back"></i>
                                        {{'Cvtheque' | translate}}
                                    </a>
                                </span>
                            </div>


                            <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 ">
                                <div class="form-group">
                                    <h3>CONSEILS PRATIQUES</h3>






                                </div>


                            </div>
                            <div  class="col-lg-2 col-md-2 col-sm-2 col-xs-2">

                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">



                            </div>
                        </div>


                    </div>

                        <div id="content_1" class="row " style="height: 1000pt; overflow: scroll;">


                            <div class="col-lg-12 col-md-12" style="margin-bottom: -12px;">
                                <div class="billing-details " >

                                    <div class="row " >


                                        <div class="col-lg-12 col-md-12"  style="padding: 33px;">
                                            <div class="story-content">
                                                <p> Vous êtes à la recherche d'un nouveau travail ? Vous voulez repondre à une offre d'emploi ?
                                                    C'est donc le bon moment d'écrire ou d'améliorer votre CV. Pour mieux reprsenter votre profil ainsi que votre parcours,
                                                    il est donc conseiller d'organiser vos informations dans différents rubriques.
                                            </p>
                                            <p>Pour qu'un CV se fasse distinguer dès la première lecture et pour prouver que
                                                 vous êtes le candidat parfait pour le poste, celui-ci ne doit présenter que les éléments
                                                 essentiels en ce qui vous concerne. Ceux-ci doivent ressortir de façon claire et
                                                ne pas être mêlés à  de détails qui n’ont pas de lien direct avec le poste sur lequel vous postulez</p>

                                                <h3>Nom et prénom</h3>
                                                <p> Cette section est obligatoire, il n'est pas obligatoire de mettre tous les noms et prénoms.
                                                    Le nom de famille et un prénom peuvent être suffisant.
                                                   </p>
                                                <h3>Titre du profil</h3>
                                                <p>C'est une rubrique importante, vous pouvez inclure votre profession, le titre de l'offre à laquelle vous postulez... </p>

                                                <h3>Photo du CV</h3>
                                                <p>En ce qui concerne l'ajout ou non de la photo sur votre CV, deux avis sont opposés: l'un qui trouve que c'est important d'avoir une idée sur le candidat
                                                    et l'autre qui n'approuve pas cette pratique. Il est important de noter que la plupart des pays francophone ne sont pas contre, par contre cela peut être discriminatoire
                                                dans certains pays anglophones </p>

                                                <h3>Détails personnel</h3>
                                                <p>Dans cette section l'adresse, le numéro de téléphone et l"adresse email sont des informations incoutournables.</p>
                                                <p>Votre adresse email joue un lien avec l’image que vous projetez. Il est donc important que celle-ci témoigne de votre professionnalisme. Nous recommandons une adresse sobre qui contient votre nom et prénom</p>
                                            <h3>Profil</h3>
                                            <p>C'est une section facultative , qui comprend un résumé de vos compétences, expériences et objectifs rédigés spécifiquement pour une offre d'emploi.</p>

                                              <h3>Compétences</h3>
                                                <p>Il y'a des compétences qui sont évidentes telles que << l'esprit d"équipe; la ponstualité, l'autonomie... >>,
                                                il n'est donc pas necessaire de les préciser. Il est donc conseiller de présenter les compétences qui vous mettent en valeur et ont liens direct avec l'offre d'emploi. </p>

                                                <h3>Expérience professionelle</h3>
                                                <p>Incluez votre expérience de travail dans l'ordre chronologique inverse <strong> (avec l'exprience la plus récente en premier).</strong>
                                                    Cette section peut inclure des emplois, des stages et du bénévolat. Les élèves du secondaire peuvent également inclure des clubs et des équipes sportives,
                                                     le cas échéant.
                                                   Inclure le nom de l'entreprise, le poste et les dates d'emploi.
                                                    Énumérez environ trois tâches, réalisations ou compétences importantes acquises à chaque emploi.
                                                     Utilisez des verbes d'action pour décrire vos réalisations ; évitez les expressions passives
                                                      telles que responsable de et devoirs inclus. </p>

                                                      <p>Les expériences présentées dans le CV ne doivent pas trop s'éloigner de l'offre.
                                                         Il n’est donc pas nécessaire de mentionner vos emplois étudiants si vous avez déjà plus cinq ans
                                                         d’expérience professionnelle dans votre domaine.</p>

                                                <h3>Education</h3>
                                                <p> Voici plus d'informations sur ce qu'il faut inclure et comment formater une section d'éducation de CV.

                                                    Incluez les diplômes que vous avez obtenus dans <strong>l'ordre chronologique inverse
                                                    (le diplôme le plus récent figurant en premier).</strong>
                                                    Assurez-vous d'inclure le nom de chaque établissement, son emplacement et votre date d'obtention du diplôme (ou la date prévue d'obtention du diplôme).
                                                    Le cas échéant, incluez vos domaines majeurs / mineurs, ainsi que  tous les honneurs, publications et projets.
                                                    À moins que vous ne soyez actuellement étudiant, n'incluez pas votre diplôme d'études secondaires. </p>

                                                    <p>Si vous avez effectué des études supérieures, il n’est pas nécessaire de mentionner vos études secondaires. En effet, le recruteur s’attardera seulement à votre dernier diplôme ou à celui qui est le pertinent pour le poste à combler. Par contre, il peut être intéressant de détailler des projets pertinents qui ont pu voir
                                                        le jour pendant ces formations tel qu’une thèse ou encore un projet de fin d’études.</p>


                                        </div>
                                        </div>




                                    </div>

                                </div>










                            </div>


                        </div>





                </div>


            </div>
