<div class="boxes-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item">
                    <h3>Conseils pratiques </h3>
                    <p>Ce qui fait la différence en optant pour un CV professionnel et moderne.</p>
                    <a routerLink="/conseils" class="boxes-btn">Voir plus<i class='bx bx-plus'></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item bg-image">
                    <h3>Préparer son entrétien</h3>
                    <p>Comment faire bonne impression lors d'un entretien.</p>
                    <a routerLink="/entretien" class="boxes-btn"> Voir plus <i class='bx bx-plus'></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item bg-color">
                    <h3>Les pages dans un CV</h3>
                    <p>Nombre de page adéquat pour un CV professionnel.</p>
                    <a routerLink="/page" class="boxes-btn">Voir plus<i class='bx bx-plus'></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item">
                    <h3>Rubriques dans un CV</h3>
                    <p>Ce que doit contenir chaque rubrique sur votre CV </p>
                    <a routerLink="/rubriques" class="boxes-btn">Voir plus<i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
