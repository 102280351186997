<app-header-style-one></app-header-style-one>
<div class="login-area">
    <div class="row m-0">

        <div class="col-lg-12 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">



                            <p *ngIf="mode===0">{{loginMessage}}</p>

                            <form [formGroup]="resetFormGroup" (ngSubmit)="login()" autocomplete="off" >
                                <div class="form-group">
                                    <p style="text-align:left">Code reçu par email </p>
                                    <input class="form-control" autocapitalize="" autocomplete="off" type="text" placeholder="Code" formControlName="code">
                                    <div class="alert alert-danger" *ngIf="isSubmitted && formControls.code.errors &&
                                    formControls.code.errors.required">Le code est requis</div>
                                </div>
                                <div class="form-group">
                                    <p style="text-align:left">Nouveau mot de passe </p>
                                    <input type="password" autocomplete="off" class="form-control" placeholder="Nouveau mot de passe *" formControlName="password" required minlength="6" formControlName="password" [ngClass]="{ 'is-invalid':
                                    resetFormGroup.controls.password.errors && !resetFormGroup.controls.password.valid &&
                                   (resetFormGroup.controls.password.dirty || resetFormGroup.controls.password.touched )}"
                                  >

                                  <div class="text text-danger " *ngIf="!resetFormGroup.controls.password.valid && resetFormGroup.controls.password.touched" >
                                       Le mot de passe est invalide, minimum 6 caractères !
                                   </div>
                                </div>
                                <p *ngIf="mode===1" class="text-danger">{{loginMessage}}</p>
                                <button type="button" [disabled]="!resetFormGroup.valid" class="btn-disable" (click)="reset()">Valider</button>
                                <div class="forgot-password">
                                    <a routerLink="/send-email">Retour</a>
                                </div>



                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
