import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmBoxEvokeService } from '@costlydeveloper/ngx-awesome-popup';
import { Cover } from '../../cover-modele/cover';
import { CoverService } from '../../cover-modele/cover.service';
import { TokenStorageService } from '../../cv-login-page/token-storage.service';

import { CoverThequeService } from '../cover-theque.service';
import {    Observable, Subject,  } from 'rxjs';
import { filter,map, distinctUntilChanged, debounceTime,switchMap } from 'rxjs/operators'


@Component({
  selector: 'cover-homeseven-main-banner',
  templateUrl: './homeseven-main-banner.component.html',
  styleUrls: ['./homeseven-main-banner.component.scss']
})
export class CoverHomesevenMainBannerComponent implements OnInit {

    listCover: Array<Cover> = [];


    private searchTerms = new Subject<string>();
    listCover$ : Observable<Cover[]>;



        constructor(
            private coverThequeService: CoverThequeService,
            private tokenStorageService: TokenStorageService,

            private router: Router,

        ) {

          }
          isAdmin : boolean=false;
          ngOnInit(): void {

            this.listCover$ =this.searchTerms.pipe(
                // {...."ab"..."abz"."ab"...."abc"......}
            debounceTime(500),
          // {......"ab"...."ab"...."abc"......}
            distinctUntilChanged(),
          // {......"ab"..........."abc"......}
          switchMap((term) => this.coverThequeService.searchByname2(term))
          // {.....List(ab)............List(abc)......}
            );
          //  this.findAll();
            if(this.tokenStorageService.getToken() && this.tokenStorageService.getUser().role==="admin"){
                this.isAdmin=true;
            }



        // alert(this.tokenStorageService.getUser().email);

      }

      search(term: string) : void{
              this.searchTerms.next(term);
      }

      goToDetail(cover: Cover) {
        let link = ["cover-letter/examples/m" + cover.idmodele + "-edit-cover", cover.id];
        this.router.navigate(link);
      }

    }
