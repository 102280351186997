import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserLogin, User_ } from './user';

const AUTH_API ="http://localhost:3000/user/";

const AUTH_API2 ="http://localhost:3000/cv/";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private roles: Array<any>=[];
  public username2: string;
  public isRLogs:boolean;

  constructor(private http: HttpClient) { }



  login(user: UserLogin ): Observable<any> {
    return this.http.post(AUTH_API + 'login', {
     email : user.email,
     password: user.password
    }, {observe: 'response'});
  }

  findOne(): Observable<any> {
    return this.http.get(AUTH_API + 'findOne', httpOptions);
  }

  getCvs(): Observable<any> {

    return this.http.get(AUTH_API2+"findAll", httpOptions);
  }

  isAdmin(){

   // for(let r of this.roles){
    //  if(r.authority==='ADMIN') return true;
   // }
    return false;
  }

  register(user): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    }, httpOptions);
  }

}
