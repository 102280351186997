<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg3">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Products Details</li>
            </ul>
            <h2>Products Details</h2>
        </div>
    </div>
</div>

<div class="product-details-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="products-details-image">
                    <owl-carousel-o [options]="detailsImageSlides">
                        <ng-template carouselSlide>
                            <img src="assets/images/icons-modeles/cv1.jpg" alt="image">
                        </ng-template>
                        <ng-template carouselSlide>
                            <img src="assets/images/icons-modeles/cv2.jpg" alt="image">
                        </ng-template>
                        <ng-template carouselSlide>
                            <img src="assets/images/icons-modeles/cv3.jpg" alt="image">
                        </ng-template>
                        <ng-template carouselSlide>
                            <img src="assets/images/icons-modeles/cv4.jpg" alt="image">
                        </ng-template>
                        <ng-template carouselSlide>
                            <img src="assets/images/icons-modeles/cv2.jpg" alt="image">
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>


            <div class="col-lg-7 col-md-12">


                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <form formGroup="abonnementFormGroup">
                            <div class="billing-details">

                                <div class="product-details-desc">
                                    <h3>Abonnement</h3>



                                    <div class="product-review">
                                        <div class="order-details">
                                            <div class="price">
                                                <span class="new-price" style="color: black;">Choisir un moyen de
                                                    paiement</span>
                                            </div>
                                            <div class="payment-box">

                                                <div class="payment-method">

                                                    <div class="row">
                                                        <div *ngIf="iscard2" class="col-lg-12 col-md-12">
                                                            <p>
                                                                <input type="radio" formControlName="option2"
                                                                    [checked]="isCard" (change)="onRadioClick(1)"
                                                                    id="carte" name="radio-group">
                                                                <label for="carte"> Carte Bancaire
                                                                    <img src="assets/img/payment/2.svg" alt="image">
                                                                    <img src="assets/img/payment/3.svg" alt="image">
                                                                </label>

                                                            </p>


                                                        </div>
                                                        <div *ngIf="isoperator" class="col-lg-12 col-md-12">
                                                            <div class="row">
                                                                <div *ngIf="ismtn" class="col-lg-4 col-md-4">


                                                                    <p>
                                                                        <input type="radio" (change)="onRadioClick(2)"
                                                                            formControlName="option2" id="mtn"
                                                                            name="radio-group" value="mtn">
                                                                        <label for="mtn">MTN
                                                                            <img height="28" width="28"
                                                                                src="assets/img/payment/6.svg"
                                                                                alt="image">
                                                                        </label>
                                                                    </p>


                                                                </div>
                                                                <div *ngIf="isairtel" class="col-lg-4 col-md-4">



                                                                    <p>
                                                                        <input formControlName="option2"
                                                                            (change)="onRadioClick(3)" value="airtel"
                                                                            type="radio" id="airtel" name="radio-group">
                                                                        <label for="airtel">Airtel
                                                                            <img height="28" width="28"
                                                                                src="assets/img/payment/5.svg"
                                                                                alt="image">
                                                                        </label>
                                                                    </p>


                                                                </div>

                                                                <div *ngIf="isvodacom" class="col-lg-4 col-md-4">

                                                                    <p>
                                                                        <input type="radio" (change)="onRadioClick(4)"
                                                                            formControlName="option2" id="vodacom"
                                                                            name="radio-group">
                                                                        <label for="vodacom">Vodacom
                                                                            <img height="37" width="37"
                                                                                src="assets/img/payment/vod.png"
                                                                                alt="image">
                                                                        </label>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div class="product-meta">

                                        <br>

                                    </div>

                                    <div class="price">
                                        <span style="color: #000;">Prix: </span> <span class="new-price">{{amount}}
                                            {{currencycode}}</span>
                                        <span *ngIf="isoldprice" class="old-price">{{oldamount}} {{currencycode}}</span>
                                    </div>


                                    <div class="product-review">
                                        <div class="order-details">
                                            <div class="payment-box">

                                                <div class="payment-method">

                                                    <div class="row">
                                                        <div class="col-lg-3 col-md-3">



                                                            <p>
                                                                <input formControlName="option" [checked]="isMonth"
                                                                    (change)="onRadioClickAmount(1)" type="radio"
                                                                    id="month" name="month">
                                                                <label for="month" style="font-size: 15px;"> 1 mois
                                                                    ({{amount}} {{currencycode}})

                                                                </label>
                                                            </p>


                                                        </div>
                                                        <div class="col-lg-3 col-md-3">



                                                            <p>
                                                                <input formControlName="option"
                                                                    [checked]="isThreeMonths"
                                                                    (change)="onRadioClickAmount(2)" type="radio"
                                                                    id="three" name="month">
                                                                <label for="three"> 3 mois ({{2*amount}}
                                                                    {{currencycode}})

                                                                </label>
                                                            </p>


                                                        </div>
                                                        <div class="col-lg-3 col-md-3">



                                                            <p>
                                                                <input formControlName="option" [checked]="isSixMonths"
                                                                    (change)="onRadioClickAmount(4)" type="radio"
                                                                    id="six" name="month">
                                                                <label for="six"> 6 mois ({{4*amount}} {{currencycode}})

                                                                </label>
                                                            </p>


                                                        </div>
                                                        <div class="col-lg-3 col-md-3">



                                                            <p>
                                                                <input formControlName="option" [checked]="isYear"
                                                                    (change)="onRadioClickAmount(6)" type="radio"
                                                                    id="year" name="month">
                                                                <label for="year"> 12 mois ({{6*amount}}
                                                                    {{currencycode}})

                                                                </label>
                                                            </p>


                                                        </div>
                                                        <div class="col-lg-12 col-md-12">
                                                            <br>
                                                        </div>


                                                        <div class="col-lg-6 col-md-6">



                                                            <div *ngIf="!isCard" class="form-group">
                                                                <label>Pays <span class="required">*</span></label>
                                                                <div class="select-box">
                                                                    <select class="form-select"
                                                                        (change)="selectCountry($event)"
                                                                        formControlName="country">

                                                                        <option>-- Selectionnez un pays --
                                                                        </option>

                                                                        <option *ngFor="let country of countries"
                                                                            [value]="country.name">{{country.name}}
                                                                            ({{country.code}})</option>

                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!isCard" class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Numéro de téléphone <span
                                                                        class="required">*</span></label>
                                                                <input type="number" class="form-control"
                                                                    style="background: #fff; color: #000; border :#c1c2c3 1px solid">
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12 col-md-12">

                                                            <div class="row">
                                                                <div class="col-lg-6 col-md-6">
                                                                    <br>
                                                                    <button type="submit" (click)="cancel()"
                                                                        class="default-btn" style="background: #000;border: #000;"><i
                                                                            class='bx bx-plus icon-arrow before'></i><span
                                                                            class="label">Annuler</span><i
                                                                            class="bx bx-plus icon-arrow after"></i></button>
                                                                </div>
                                                                <div class="col-lg-6 col-md-6">
                                                                    <br>
                                                                    <button type="submit" (click)="continue()"
                                                                        class="default-btn"><i
                                                                            class='bx bx-plus icon-arrow before'></i><span
                                                                            class="label">Continuer</span><i
                                                                            class="bx bx-plus icon-arrow after"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>

                                            </div>


                                        </div>

                                    </div>








                                </div>
                            </div>
                        </form>
                    </div>

                </div>

            </div>







        </div>
    </div>

</div>
