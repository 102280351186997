import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtModule, JwtHelperService} from '@auth0/angular-jwt';
import { UserLogin } from './user';



const TOKEN_KEY = 'token';
const USER_KEY = 'auth-user';



@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  private role: any;
  private date: Date;


  jwtToken: string;
  decodedToken: { [key: string]: string };

  constructor(private router: Router) { }

  signOut() {


   // window.sessionStorage.clear();
    localStorage.clear();

    let redirect ='/login';
    // Redirige l'utilisateur
    this.router.navigate([redirect]);

  }



  public saveToken(token: string) {
    //window.sessionStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
   // window.sessionStorage.setItem(TOKEN_KEY, token);

  }

  public login(userInfo: UserLogin){

    localStorage.setItem('ACCESS_TOKEN', "access_token");
  }


  public getToken(): string {
    //return sessionStorage.getItem(TOKEN_KEY);
    return localStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user) {
    // window.sessionStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
   }

   public getUser() {
     return JSON.parse(localStorage.getItem(USER_KEY));
   }


  public getRole(){
    let jwtHelper= new JwtHelperService();

    this.role=jwtHelper.decodeToken(this.getToken()).roles;

    return this.role;
  }

  public getUser2(): any {
    const user = localStorage.getItem(USER_KEY);
     if (user) {
      return JSON.parse(user);
     }

    return {};
    }




}
