<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Conditions générales</li>
            </ul>
            <h2>Conditions générales</h2>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
           
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">About Us !</span> -->
                    <h2>1. Conditions d'utilisation</h2>

                    <div class="features-text">
                        <p>ICV est un site conçu, détenu et exploité par la société Logiiplus.
                            L'accès à iCv est gratuit et volontaire, sauf quand vous decidez de vous soumettre à un abonnement mensuel
                        pour avoir accès aux téléchargements à volonté durant la période votre abonnement.</p>
                        <p>Nous vous recommandons de lire ces Conditions d’utilisation avec attention avant de commencer à créer votre CV. 
                            Si vous commencer à utiliser ce site sans lire les conditions d'utilisation, équivaut à dire que vous êtes d'accord avec ces conditions.</p>
                            
                      <p>Les Conditions d’utilisation seront mises à jour avec le temps,
                        sans préavis à l’Utilisateur, afin d'être conforme à de nouvelles exigences législatives ou 
                        jurisprudentielles et/ou de nouveaux besoins ou intérêts commerciaux ou en raison de changements 
                        dans les documents professionnels ou fonctionnalités de notre site web. La version 
                        actualisée de ces Conditions d’utilisation prendra effet dès que publiées. 
                        Par conséquent, 
                        l’Utilisateur reconnaît et accepte qu’il lui incombe de consulter régulièrement les Conditions d’utilisation applicables.</p>

                        <p>  
                            Votre compte doit être protégé avec un nom d'utilisateur et un mot de passe.
                             Surtout, il est de votre responsabilité de maintenir la confidentialité de votre compte et de votre mot de passe. 
                             La société Logiiplus exige que vous surveillez toutes les activités de  votre compte ou avec mot de passe. 
                             Vous êtes donc responsable pour toutes ces activités s'opérant sur votre compte, d'où il est nécessaire de protéger votre compte avec
                             un mot de passe fort, en cas d'activité suspecte, prière de modifier votre mot de passe ou d'en informer Logiiplus.
                        </p>

                        <p>Tout utilisateur qui utilise et navigue sur ce site doit respecter les lois et 
                            règlements applicables.

                            L’invalidité, totale ou partielle, de toute clause
                             de ces Conditions d’utilisation par une décision juridique, n’affectera pas la validité des autres clauses.</p>
                   
                             <p></p>
                            </div>
                </div>
            </div>
        </div>


        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-11 col-md-12">
                    <div class="story-content">
                        <h3>2. Mise à jour et disponibilité </h3>
                        <p>2.1 Pour les raisons de mise à jour il se peut que le site ne soit pas disponible, mais la société Logiiplus
                            s'rrangera à ce que  Les mises à jour seront faites de façon permanente par la société Logiiplus. Certaines remarques et suggestionss
                            seront prises en compte afin d'améliorer le service fourni.
                        </p>
                       

                        <p>2.2 Logiiplus fait preuve de toute la diligence raisonnable pour s'assurer que les informations figurant 
                            sur le Site soient exactes et à jour. Toutefois, des erreurs ou omissions peuvent survenir.
                            
                            </p>
                            <p>2.3 Logiiplus veillera à ce que le Site soit disponible de façon ininterrompue et à ce que les transmissions se fassent sans erreur.
                                 Toutefois, en raison de la nature d’internet, cela ne peut pas être garanti.</p>
                       
                                 <p></p>
                    </div>

                    <div class="story-content">
                        <h3>3. Votre conduite</h3>
                        <p>
                            Vous prenez l'engagement de ne pas utiliser le site d'une manière qui provoque ou qui soit susceptible de 
                            créer une interruption du fonctionnement du site ou de l’accès à celui-ci, de l’endommager ou
                             de le compromettre de quelque façon que ce soit. Vous êtes responsable de toutes les communications 
                             électroniques et des contenus envoyés à partir de votre ordinateur vers iCV et 
                             vous ne devez utiliser le Site qu'à des fins licites.
                             Vous ne devez pas utiliser le Site pour l’une des opérations suivantes :
                        </p>
                        <p>3.1 
                            à des fins frauduleuses, ou en rapport avec celles-ci, ou une conduite qui provoque
                             une infraction pénale ou toute autre infraction qui viole les lois,
                             les règlements ou toute réglementation en vigueur applicable;</p>

                        <p>3.2 D'envoyer , diffuser , utiliser ou réutiliser tout matériel qui est illégal , offensant, 
                            abusif , indécent, diffamatoire , harcelant , obscène , menaçant ou autrement répréhensible;
                             ou en violation du droit d'auteur , de la marque, de la confidentialité ,
                              de la vie privée ou de tout autre droit , ou de toute autre manière nuisible aux tiers ,
                               ou qui contient ou consiste en des virus informatiques , 
                            des activités politiques ,de sollicitation commerciale , des chaînes de lettres , du mailing de masse ou "spam" ;
                            </p>
                       <p>3.3 pour fabriquer, transmettre ou stocker sur le Site des copies électroniques de documents protégés par 
                        le droit d’auteur, 
                        sans l'autorisation du propriétaire du droit d’auteur.</p>
                        <p></p>
                    </div>

                </div>

                <div class="col-lg-11 col-md-12">
                    <div class="story-content">
                        <h3>4. Propiété intellectuelle </h3>
                        <p>4.1 Le site web icv-pro.com et les modèles de CV est la propriété de l'entreprise Logiiplus ou de ses fournisseurs 
                            de contenu. 
                            Toute utilisation ou copies de l'un quelconque de ces éléments, 
                            sans l'autorisation écrite de Logiiplus, est strictement interdite, à l'exception des cas autorisés par la loi.
                        </p>
                        <p>
                           4.2 vous avez le droit d'utiliser les documents créés avec iCV-pro.com durant toute la durée de votre abonnement ou pendant
                             les périodes d'abonnement. Une fois votre abbonnement terminé, vous n'êtes plus en 
                            droit de télécharger les documents créés.
                             </p>

                        <p></p>
                       
                       
                    </div>
                    <div class="story-content">
                        <h3>5. Changement de Services ou modification des Termes d'Utilisation </h3>
                        <p>La société Logiiplus se réserve le droit, comme bon lui semble, d’apporter des modifications sur le site web sur tous les aspects, en ce qui concerne les droits et les termes.
                             Dès lors que vous utilisez le site, vous vous engagez à respecter ces politiques et ces Termes d’Utilisation.
                            La notification d’une quelconque modification sera faite via le Site. Si vous continuez à l'utiliser après la notification 
                    ,Le fait de continuer à utiliser signifiera que vous êtes en accord avec les Termes d’Utilisation modifiés.
                        </p>
                        
                        <p></p>
                       
                       
                    </div>
                    <div class="story-content">
                        <h3>6. Nos détails </h3>
                        <p> Le site icv-pro.com est déténu et exploité par la société Logiiplus, entreprise inscrite au registre du commerce et du credit mobilier sous le numéro CG-BZV-01-2022-A10-00046 au Congo 
                            Brazzaville, 
                            dont le siège social se situe à la rue Moulenda numéro 458; Plateaux des 15 ans, Brazzaville, Congo.
                        </p>
                        
                        <p></p>
                       
                       
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<!--
<app-our-mission></app-our-mission>


<div class="story-area ptb-100">
    <app-our-story></app-our-story>
</div>

<div class="funfacts-area">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div>

<div class="values-area ptb-100">
    <app-our-values></app-our-values>
</div>

<div class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-two></app-instructors-style-two>
    </div>
</div>

<div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>
-->

<!--
<div class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>
-->
