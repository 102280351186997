<loading *ngIf="isLoading">
    {{ isLoading }}</loading>

<div id="cvt" class="container-fluid" style="height: 100%; width: 100%;">
    <div class="row">

        <div id="cvt1" class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="height: 100%;  ">

            <div class="checkout-area ptb-100" style="margin-left : 12px; margin-right: 33px ">
                <div class="container">
                    <div class="user-actions" style="margin-bottom: 0; ">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-6 ">
                                <span *ngIf="isOpen">
                                    <a *ngIf="!this.tokenStorage.getToken()" style=" font-size: 15px; font-weight: 600;"
                                        routerLink="/">
                                        <i class="bx bx-arrow-back"></i>
                                        {{'home' | translate}}
                                    </a>
                                    <a *ngIf="this.tokenStorage.getToken()" style=" font-size: 15px;font-weight: 600;"
                                        routerLink="/cvs">
                                        <i class="bx bx-arrow-back"></i>
                                        {{'dashboard' | translate}}
                                    </a>
                                </span>
                            </div>

                            <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                <div class="form-group">
                                    <button *ngIf="isOpen" type="button" class="form-control btn btn-sm btn-secondary"
                                        style="padding: 3px;padding-bottom: 29px; font-size: 12px;  color: #fff; background: #003147; height: 30px;width: 110px;"
                                        (click)="save()">

                                        <i style="color: #fff; font-size: 18px;" class="bx bx-save"></i>
                                        {{'save' | translate}} </button>
                                    <button *ngIf="spin1" type="button" class=" form-control btn "
                                        style="padding: 7px; font-size: 12px;  height: 30px;width: 30px;">
                                        <span role="status" style="color: #003147;"
                                            class="spinner-border spinner-danger spinner-border-sm"></span>
                                    </button>

                                    <button *ngIf="isOpen" type="button" class=" form-control btn btn-sm btn-secondary"
                                        (click)="openPDF()"
                                        style="padding: 3px;padding-bottom: 29px; font-size: 12px; color: #fff; background: #003147; height: 10px;width: 110px;">
                                        <i style="color: #fff; font-size: 18px;" class="bx bx-download"></i>
                                        {{'download' | translate}} </button>







                                </div>

                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div class=" input-group">
                                    <label class="input-group-text" style="padding-bottom: 7px; padding-left: 3px;"
                                        for=""><i class="bx bx-globe"></i></label>
                                    <select class="form-select" (click)="selectLanguage($event)" [value]="userLanguage"
                                        style="padding: 7px; font-size: 13px;  height: 33px;width: auto; font-weight: 600;"
                                        name="" id="">

                                        <option value="fr">Français</option>
                                        <option value="en">English</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                               
                            </div>
                        </div>

                    </div>
                    <form [formGroup]="cvFormGroup">
                        <div id="content_1" class="row" style="height: 1000pt; overflow: scroll;">

                            <div class="col-lg-12 col-md-12" style="margin-bottom: -12px;">
                                <div class="billing-details">

                                    <div class="row">

                                        <div class="col-lg-4 col-md-4">

                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-check" style="margin-left: 30px; margin-top: 12px">
                                                    <input type="checkbox" class="form-check-input"
                                                        style="margin-bottom: -3px;" id="icon1" name="icon1"
                                                        (click)="checkBtn($event)">
                                                    <label class="form-check-label "
                                                        style="margin-left: 12px;">{{'withouticon' | translate}}</label>
                                                </div>

                                            </div>


                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">

                                                    <label class="hoverable" for="fileInput">
                                                        <img *ngIf="!imageToShow" class="img_class"
                                                            [src]="url ? url : 'assets/img/ph.png'">
                                                        <img *ngIf="imageToShow" class="img_class" [src]="imageToShow">
                                                        <div class="hover-text">Choisir une photo</div>
                                                        <div class="background"></div>
                                                    </label>

                                                    <input type="file" #photo id="fileInput" accept=".png, .jpg, .jpeg"
                                                        (change)="selectFile2($event)">


                                                    <button *ngIf="url || imgURL || imageToShow" (click)="delete()"
                                                        style="font-size: 12px; margin-bottom: 3px">
                                                        <i class="bx bx-trash"></i>
                                                        {{'delete' | translate}}</button>
                                                    <br />

                                                </div>
                                            </div>

                                        </div>


                                        <div class="col-lg-8 col-md-8">
                                            <div class="row">


                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">



                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">

                                                        <input type="radio" (change)="onRadioClick(1)"
                                                            formControlName="flag1" [checked]="isProfessional" [value]="1"> <strong>   {{'professional' | translate}}</strong>

                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">

                                                        <input type="radio" (change)="onRadioClick(2)"
                                                            formControlName="flag1" [checked]="isBeginner" [value]="2"> <strong>   {{'beginner' | translate}}</strong>

                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                     <p *ngIf="isProfessional" style="color: #000;">
                                                        {{'professionalText' | translate}}
                                                     </p>

                                                     <p *ngIf="isBeginner" style="color: #000;">
                                                        {{'beginnerText' | translate}}
                                                     </p>
                                                    </div>
                                                </div>




                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">


                                                        <input type="text" placeholder="{{'firstname' | translate}}"
                                                            formControlName="firstname"
                                                            [value]="cvFormGroup.get('firstname').value"
                                                            class="form-control">

                                                            <p style="font-size: 12px;">{{'afirstname' | translate}}</p>

                                                    </div>
                                                </div>


                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">


                                                        <input type="text" formControlName="lastname"
                                                            [value]="cvFormGroup.get('lastname').value"
                                                            placeholder="{{'lastname' | translate}}"
                                                            class="form-control">

                                                      <p style="font-size: 12px;" >{{'alastname' | translate}}</p>
                                                    </div>
                                                </div>
                                                


                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">


                                                        <input type="text" formControlName="profession"
                                                            [value]="cvFormGroup.get('profession').value"
                                                            title="{{'profession' | translate}}"
                                                            placeholder="{{'profession' | translate}}"
                                                            class="form-control">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6" *ngIf="isAdmin">
                                                    <div class="form-group">


                                                        <input type="text" formControlName="name"
                                                            placeholder="{{'cv_name' | translate}}"
                                                            class="form-control">


                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6" *ngIf="isAdmin">
                                                    <div class="form-group">


                                                        <input type="text" placeholder="{{'flag' | translate}}"
                                                            formControlName="flag2" class="form-control">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                        <div class="col-12" style="margin-right: 12px;">

                                            <div class="tabs">

                                                <div class="tab">
                                                    <input class="input2" type="checkbox" id="rd2" #rd2
                                                        (change)="check2($event)">
                                                    <label class="tab-label" for="rd2"
                                                        style="font-size: 14px; text-transform: uppercase;">{{'profile'
                                                        | translate}}
                                                    </label>

                                                    <div class="tab-content">
                                                        <div class="row">
                                                        <div class="col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="textarea-wrapper">

                                                                    <textarea formControlName="profile"
                                                                        style="width:100%; border:1px solid #999; padding: 7px;"
                                                                        rows="5" maxlength="1200"></textarea>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="tab">

                                                <input class="input2" #rd3 type="checkbox" id="rd3"
                                                    (change)="check3($event)">
                                                <label class="tab-label" for="rd3"
                                                    style="font-size: 14px; text-transform: uppercase;">
                                                    {{'details' | translate}} </label>
                                                <div class="tab-content">

                                                    <div class="row">



                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">

                                                                <input
                                                                    *ngIf="isCrypt && !this.cvFormGroup.get('telephone').value.includes('U2FsdG')"
                                                                    type="text" formControlName="telephone"
                                                                    [value]="this.cvFormGroup.get('telephone').value"
                                                                    title="{{'phone' | translate}}"
                                                                    placeholder="{{'phone' | translate}}"
                                                                    class="form-control">
                                                                <input
                                                                    *ngIf="!isCrypt || this.cvFormGroup.get('telephone').value.includes('U2FsdG')"
                                                                    type="text" placeholder="{{'phone' | translate}}"
                                                                    class="form-control">

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">

                                                                <input type="email" formControlName="email"
                                                                    placeholder="Email" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <input type="text" formControlName="linkedin"
                                                                    placeholder="Linkedin" class="form-control">
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">

                                                                <input type="text" formControlName="webSite"
                                                                    title="{{'website' | translate}}"
                                                                    placeholder="{{'website' | translate}}"
                                                                    class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">

                                                                <input
                                                                    *ngIf="isCrypt && !this.cvFormGroup.get('address').value.includes('U2FsdG')"
                                                                    type="text" formControlName="address"
                                                                    [value]="this.cvFormGroup.get('address').value"
                                                                    title="{{'address' | translate}}"
                                                                    placeholder="{{'address' | translate}}"
                                                                    class="form-control">
                                                                <input
                                                                    *ngIf="!isCrypt || this.cvFormGroup.get('address').value.includes('U2FsdG')"
                                                                    type="text" placeholder="Adresse"
                                                                    class="form-control">

                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">

                                                                <input type="text" formControlName="city"
                                                                    title="{{'city' | translate}}"
                                                                    placeholder="{{'city' | translate}}"
                                                                    class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">

                                                                <input type="text" formControlName="birth"
                                                                    title="{{'birth' | translate}}"
                                                                    placeholder="{{'birth' | translate}}"
                                                                    class="form-control">
                                                            </div>
                                                        </div>



                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">

                                                                <input type="text" formControlName="marital"
                                                                    title="{{'marital' | translate}}"
                                                                    placeholder="{{'marital' | translate}}"
                                                                    class="form-control">
                                                            </div>
                                                        </div>



                                                        <div class="col-lg-12 col-md-12">
                                                            <div class="form-group">

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tab">
                                                <input class="input2" #rd4 type="checkbox" id="rd4"
                                                    (change)="check4($event)">
                                                <label class="tab-label" for="rd4"
                                                    style="font-size: 14px; text-transform: uppercase;">{{'skills' |
                                                    translate}} </label>
                                                <div class="tab-content">
                                                    <div class="row">
                                                        <div formArrayName="skills" class="col-lg-12 col-md-12">
                                                            <div class="col-lg-12 col-md-12"
                                                                *ngFor="let skill of cvSkills().controls; let skillIndex=index">
                                                                <div class="row" [formGroupName]="skillIndex"
                                                                    style="margin-bottom: 7px;">

                                                                    <div class="col-lg-5 col-md-5">
                                                                        <div class="form-group">

                                                                            <input type="text" formControlName="item"
                                                                                title="{{'skill' | translate}}"
                                                                                placeholder="{{'skill' | translate}}"
                                                                                class="form-control">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-4">
                                                                        <div class="form-group">

                                                                            <select (ngModelChange)="selectSkill()"
                                                                                class="form-control"
                                                                                formControlName="level"
                                                                                title="{{'level' | translate}}">
                                                                                <option value="25%">25%</option>
                                                                                <option value="50%">50%</option>
                                                                                <option value="50%">55%</option>
                                                                                <option value="50%">60%</option>
                                                                                <option value="65%">65%</option>
                                                                                <option value="70%">70%</option>
                                                                                <option value="75%">75%</option>
                                                                                <option value="80%">80%</option>
                                                                                <option value="85%">85%</option>
                                                                                <option value="90%">90%</option>
                                                                                <option value="95%">95%</option>
                                                                                <option value="99%">99%</option>
                                                                            </select>

                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 col-md-3">
                                                                        <div class="form-group">

                                                                            <button type="button"
                                                                                title="{{'delete' | translate}}"
                                                                                style=" color: #fff; background: #7C151F;"
                                                                                class="form-control"
                                                                                (click)="removeCvSkill(skillIndex)">
                                                                                <i class="bx bx-trash"></i> </button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>


                                                            <div class="col-lg-12 col-md-12" style="margin-top: 7px; ">
                                                                <div class="form-group">
                                                                    <button type="button"
                                                                        class=" form-control btn btn-sm btn-secondary"
                                                                        style="padding: 7px; font-size: 12px; color: #fff; background: #1273bb;"
                                                                        (click)="addCvSkill()">
                                                                        <i class="bx bx-plus"></i>
                                                                        {{'addskill' | translate}} </button>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>




                                        </div>
                                    </div>




                                    <div class="col-12">

                                        <div class="tabs">
                                            <div class="tab">
                                                <input class="input2" type="checkbox" #rd6 id="rd6"
                                                    (change)="check6($event)">
                                                <label class="tab-label" for="rd6"
                                                    style="font-size: 14px; text-transform: uppercase;">{{'experiences'
                                                    | translate}} </label>
                                                <div class="tab-content">

                                                    <div class="row">

                                                        <div formArrayName="experiences" class="col-lg-12 col-md-12">
                                                            <div class="col-lg-12 col-md-12"
                                                                *ngFor="let experience of  cvExperiences().controls; let experienceIndex = index">
                                                                <div class="row" [formGroupName]="experienceIndex">
                                                                    <div class="col-lg-12 col-md-12"
                                                                        style=" margin-top: 12px; margin-bottom: 3px;">
                                                                        <div class="row">
                                                                            <div class="col-lg-6 col-md-6">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        style="font-size: 15px; text-transform: uppercase;">{{'experience'
                                                                                        | translate}}
                                                                                        {{experienceIndex+1}}

                                                                                        <span
                                                                                            class="required"></span></label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-2 col-md-2">
                                                                                <div class="form-group">
                                                                                    <button type="button"
                                                                                        title="{{'up' | translate}}"
                                                                                        class="form-control"
                                                                                        style="padding: 7px; color: #fff; background: #255277;"
                                                                                        (click)="moveUp(cvExperiences(),experienceIndex)">
                                                                                        <i class="bx bx-up-arrow"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-2 col-md-2">
                                                                                <div class="form-group">
                                                                                    <button type="button"
                                                                                        title="{{'down' | translate}}"
                                                                                        class="form-control"
                                                                                        style="padding: 7px; color: #fff; background: #3d4246;"
                                                                                        (click)="moveDown(cvExperiences(),experienceIndex)">
                                                                                        <i class="bx bx-down-arrow"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-2 col-md-2">
                                                                                <div class="form-group">

                                                                                    <button type="button"
                                                                                        title="{{'delete' | translate}}"
                                                                                        class="form-control"
                                                                                        style="padding: 7px; font-size: 12px; color: #fff; background: #7C151F;"
                                                                                        (click)="removeCvExperience(experienceIndex)">
                                                                                        <i class="bx bx-trash"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row">

                                                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                                                            <div class="form-group">

                                                                                <input formControlName="period"
                                                                                    type="text"
                                                                                    placeholder="{{'period' | translate}}"
                                                                                    title="{{'period' | translate}}"
                                                                                    class="form-control">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                                                            <div class="form-group">

                                                                                <input formControlName="function"
                                                                                    type="text"
                                                                                    placeholder="{{'position' | translate}}"
                                                                                    title="{{'position' | translate}}"
                                                                                    class="form-control">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                                                            <div class="form-group">

                                                                                <input formControlName="company"
                                                                                    type="text"
                                                                                    title="{{'company' | translate}}"
                                                                                    placeholder="{{'company' | translate}}"
                                                                                    class="form-control">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                                                            <div class="form-group">

                                                                                <input formControlName="city"
                                                                                    type="text"
                                                                                    title="{{'city' | translate}}"
                                                                                    placeholder="{{'city' | translate}}"
                                                                                    class="form-control">
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                                                            <div class="form-group"
                                                                                (click)="selectSkill()">
                                                                                <angular-editor
                                                                                    formControlName="description"
                                                                                    [config]="config">
                                                                                </angular-editor>

                                                                            </div>
                                                                        </div>


                                                                    </div>



                                                                </div>
                                                            </div>



                                                            <div class="col-lg-12 col-md-12"
                                                                style="margin-top: 7px; margin-bottom: 7px;">
                                                                <div class="form-group">
                                                                    <button type="button"
                                                                        class=" form-control btn btn-sm btn-secondary"
                                                                        style="padding: 7px; font-size: 12px; color: #fff; background: #1273bb;"
                                                                        (click)="addCvExperience()">
                                                                        <i class="bx bx-plus"></i>
                                                                        {{'addexperience' | translate}} </button>
                                                                </div>
                                                            </div>



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="tab">

                                                <input class="input2" type="checkbox" id="rd7" #rd7
                                                    (change)="check7($event)">
                                                <label class="tab-label"
                                                    style="font-size: 14px; text-transform: uppercase;"
                                                    for="rd7">{{'education' | translate}} </label>
                                                <div class="tab-content">
                                                    <div class="row">
                                                        <div formArrayName="educations" class="col-lg-12 col-md-12">
                                                            <div class="col-lg-12 col-md-12"
                                                                *ngFor="let education of  cvEducations().controls; let educationIndex=index">
                                                                <div class="row" [formGroupName]="educationIndex">
                                                                    <div class="col-lg-12 col-md-12"
                                                                        style=" margin-top: 12px; margin-bottom: 3px;">
                                                                        <div class="row">
                                                                            <div class="col-lg-6 col-md-6">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        style="font-size: 15px; ">{{'education'
                                                                                        | translate}}
                                                                                        {{educationIndex+1}}
                                                                                        <span
                                                                                            class="required"></span></label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-2 col-md-2">
                                                                                <div class="form-group">
                                                                                    <button type="button"
                                                                                        title="{{'up' | translate}}"
                                                                                        class="form-control"
                                                                                        style="padding: 7px; color: #fff; background: #255277;"
                                                                                        (click)="moveUp(cvEducations(),educationIndex)">
                                                                                        <i class="bx bx-up-arrow"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-2 col-md-2">
                                                                                <div class="form-group">
                                                                                    <button type="button"
                                                                                        title="{{'down' | translate}}"
                                                                                        class="form-control"
                                                                                        style="padding: 7px; color: #fff; background: #3d4246;"
                                                                                        (click)="moveDown(cvEducations(),educationIndex)">
                                                                                        <i class="bx bx-down-arrow"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-2 col-md-2">
                                                                                <div class="form-group">

                                                                                    <button type="button"
                                                                                        title="{{'delete' | translate}}"
                                                                                        class="form-control"
                                                                                        style="padding: 7px; font-size: 12px; color: #fff; background: #7C151F;"
                                                                                        (click)="removeCvEducation(educationIndex)">
                                                                                        <i
                                                                                            class="bx bx-trash"></i></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6 col-md-6">
                                                                        <div class="form-group">

                                                                            <input type="text" formControlName="period"
                                                                                placeholder="{{'period' | translate}}"
                                                                                class="form-control">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6 col-md-6">
                                                                        <div class="form-group">

                                                                            <input type="text" formControlName="degree"
                                                                                placeholder="{{'study' | translate}}"
                                                                                class="form-control">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6 col-md-6">
                                                                        <div class="form-group">

                                                                            <input type="text"
                                                                                formControlName="institute"
                                                                                placeholder="{{'school' | translate}}"
                                                                                class="form-control">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-md-6">
                                                                        <div class="form-group">

                                                                            <input type="text" formControlName="city"
                                                                                placeholder="{{'city' | translate}}"
                                                                                class="form-control">
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 col-md-12"
                                                                style="margin-top: 7px; margin-bottom: 7px;">
                                                                <div class="form-group">
                                                                    <button type="button"
                                                                        class=" form-control btn btn-sm btn-secondary"
                                                                        style="padding: 7px; font-size: 12px; color: #fff; background: #1273bb;"
                                                                        (click)="addCvEducation()">
                                                                        <i class="bx bx-plus"></i>
                                                                        {{'addeducation' | translate}} </button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="tab">

                                                <input class="input2" type="checkbox" id="rd5" #rd5
                                                    (change)="check5($event)">
                                                <label class="tab-label"
                                                    style="font-size: 14px; text-transform: uppercase;"
                                                    for="rd5">{{'formation' | translate}} </label>
                                                <div class="tab-content">

                                                    <div class="row">
                                                        <div formArrayName="formations" class="col-lg-12 col-md-12">
                                                            <div class="col-lg-12 col-md-12"
                                                                *ngFor="let formation of  cvFormations().controls; let formationIndex=index">
                                                                <div class="row" [formGroupName]="formationIndex">
                                                                    <div class="col-lg-12 col-md-12"
                                                                        style=" margin-top: 12px; margin-bottom: 3px;">
                                                                        <div class="row">
                                                                            <div class="col-lg-6 col-md-6">
                                                                                <div class="form-group">
                                                                                    <label style="font-size: 15px; ">
                                                                                        {{'formation' | translate}}
                                                                                        {{formationIndex+1}}
                                                                                        <span
                                                                                            class="required"></span></label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-2 col-md-2">
                                                                                <div class="form-group">
                                                                                    <button type="button"
                                                                                        title="{{'up' | translate}}"
                                                                                        class="form-control"
                                                                                        style="padding: 7px; color: #fff; background: #255277;"
                                                                                        (click)="moveUp(cvFormations(),formationIndex)">
                                                                                        <i class="bx bx-up-arrow"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-2 col-md-2">
                                                                                <div class="form-group">
                                                                                    <button type="button"
                                                                                        title="{{'down' | translate}}"
                                                                                        class="form-control"
                                                                                        style="padding: 7px; color: #fff; background: #3d4246;"
                                                                                        (click)="moveDown(cvFormations(),formationIndex)">
                                                                                        <i class="bx bx-down-arrow"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-2 col-md-2">
                                                                                <div class="form-group">

                                                                                    <button type="button"
                                                                                        title="{{'delete' | translate}}"
                                                                                        class="form-control"
                                                                                        style="padding: 7px; font-size: 12px; color: #fff; background: #7C151F;"
                                                                                        (click)="removeCvFormation(formationIndex)">
                                                                                        <i
                                                                                            class="bx bx-trash"></i></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12 col-md-12">
                                                                        <div class="form-group">

                                                                            <input type="text" formControlName="title"
                                                                                placeholder="{{'formation' | translate}}"
                                                                                class="form-control">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6 col-md-6">
                                                                        <div class="form-group">

                                                                            <input type="text" formControlName="period"
                                                                                placeholder="{{'period' | translate}}"
                                                                                class="form-control">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6 col-md-6">
                                                                        <div class="form-group">

                                                                            <input type="text" formControlName="former"
                                                                                placeholder="{{'organized' | translate}}"
                                                                                class="form-control">
                                                                        </div>
                                                                    </div>






                                                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                                                        <div class="form-group" (click)="selectSkill()">
                                                                            <angular-editor
                                                                                formControlName="description"
                                                                                [config]="config"></angular-editor>

                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 col-md-12"
                                                                style="margin-top: 7px; margin-bottom: 7px;">
                                                                <div class="form-group">
                                                                    <button type="button"
                                                                        class=" form-control btn btn-sm btn-secondary"
                                                                        style="padding: 7px; font-size: 12px; color: #fff; background: #1273bb;"
                                                                        (click)="addCvFormation()">
                                                                        <i class="bx bx-plus"></i>
                                                                        {{'addformation' | translate}} </button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="tab">
                                                <input class="input2" type="checkbox" id="rd8" #rd8
                                                    (change)="check8($event)">
                                                <label class="tab-label"
                                                    style="font-size: 14px;text-transform: uppercase;"
                                                    for="rd8">{{'languages' | translate}}</label>
                                                <div class="tab-content">
                                                    <div class="row">
                                                        <div formArrayName="languages" class="col-lg-12 col-md-12">
                                                            <div class="col-lg-12 col-md-12"
                                                                *ngFor="let lang of cvLanguages().controls; let langIndex=index">
                                                                <div class="row" [formGroupName]="langIndex"
                                                                    style="margin-bottom: 7px;">

                                                                    <div class="col-lg-5 col-md-5">
                                                                        <div class="form-group">

                                                                            <input type="text" formControlName="item"
                                                                                placeholder="{{'language' | translate}}"
                                                                                class="form-control">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-4">
                                                                        <div class="form-group">

                                                                            <select (ngModelChange)="selectSkill()"
                                                                                class="form-control"
                                                                                formControlName="level">
                                                                                <option value="25%">25%</option>
                                                                                <option value="50%">50%</option>
                                                                                <option value="50%">55%</option>
                                                                                <option value="50%">60%</option>
                                                                                <option value="65%">65%</option>
                                                                                <option value="70%">70%</option>
                                                                                <option value="75%">75%</option>
                                                                                <option value="80%">80%</option>
                                                                                <option value="85%">85%</option>
                                                                                <option value="90%">90%</option>
                                                                                <option value="95%">95%</option>
                                                                                <option value="99%">99%</option>
                                                                            </select>

                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 col-md-3">
                                                                        <div class="form-group">

                                                                            <button type="button"
                                                                                title="{{'delete' | translate}}"
                                                                                style=" color: #fff; background: #7C151F;"
                                                                                class="form-control"
                                                                                (click)="removeCvlanguage(langIndex)">
                                                                                <i class="bx bx-trash"></i> </button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>


                                                            <div class="col-lg-12 col-md-12" style="margin-top: 12px; ">
                                                                <div class="form-group">
                                                                    <button type="button"
                                                                        class=" form-control btn btn-sm btn-secondary"
                                                                        style="padding: 7px; font-size: 12px; color: #fff; background: #1273bb;"
                                                                        (click)="addCvLanguage()">
                                                                        <i class="bx bx-plus"></i>
                                                                        {{'addlanguage' | translate}} </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tab">
                                                <input class="input2" type="checkbox" id="rd9" #rd9
                                                    (change)="check9($event)">
                                                <label class="tab-label"
                                                    style="font-size: 14px; text-transform: uppercase;"
                                                    for="rd9">{{'hobbies' | translate}}</label>
                                                <div class="tab-content">

                                                    <div class="row">

                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">

                                                                <input formControlName="hobby1" type="text"
                                                                    placeholder="{{'hobby' | translate}}"
                                                                    class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">

                                                                <input formControlName="hobby2" type="text"
                                                                    placeholder="{{'hobby' | translate}}"
                                                                    class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">

                                                                <input formControlName="hobby3" type="text"
                                                                    placeholder="{{'hobby' | translate}}"
                                                                    class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">

                                                                <input formControlName="hobby4" type="text"
                                                                    placeholder="{{'hobby' | translate}}"
                                                                    class="form-control">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tab">
                                                <input class="input2" type="checkbox" id="rd1" #rd1
                                                    (change)="check1($event)">
                                                <label class="tab-label"
                                                    style="font-size: 14px; text-transform: uppercase;"
                                                    for="rd1">{{'qualities' | translate}}</label>
                                                <div class="tab-content">

                                                    <div class="row">

                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">

                                                                <input formControlName="quality1" type="text"
                                                                    placeholder="{{'quality' | translate}}"
                                                                    class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">

                                                                <input formControlName="quality2" type="text"
                                                                    placeholder="{{'quality' | translate}}"
                                                                    class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">

                                                                <input formControlName="quality3" type="text"
                                                                    placeholder="{{'quality' | translate}}"
                                                                    class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">

                                                                <input formControlName="quality4" type="text"
                                                                    placeholder="{{'quality' | translate}}"
                                                                    class="form-control">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tab">
                                                <input class="input2" type="checkbox" id="rd11" #rd11
                                                    (change)="check11($event)">
                                                <label class="tab-label" for="rd11"
                                                    style="font-size: 14px;text-transform: uppercase;">{{'certificates'
                                                    | translate}}</label>
                                                <div class="tab-content">

                                                    <div class="row">
                                                        <div formArrayName="certifications" class="col-lg-12 col-md-12">
                                                            <div class="col-lg-12 col-md-12"
                                                                *ngFor="let certification of  cvCertifications().controls; let certificationIndex=index">
                                                                <div class="row" [formGroupName]="certificationIndex">

                                                                    <div class="row">
                                                                        <div class="col-lg-6 col-md-6">
                                                                            <div class="form-group">
                                                                                <label
                                                                                    style="font-size: 15px; text-transform: uppercase;">{{'certficate'
                                                                                    | translate}}
                                                                                    {{certificationIndex+1}}
                                                                                    <span
                                                                                        class="required"></span></label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2 col-md-2">
                                                                            <div class="form-group">
                                                                                <button type="button"
                                                                                    title="{{'up' | translate}}"
                                                                                    class="form-control"
                                                                                    style="padding: 7px; color: #fff; background: #255277;"
                                                                                    (click)="moveUp(cvCertifications(),certificationIndex)">
                                                                                    <i class="bx bx-up-arrow"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2 col-md-2">
                                                                            <div class="form-group">
                                                                                <button type="button"
                                                                                    title="{{'down' | translate}}"
                                                                                    class="form-control"
                                                                                    style="padding: 7px; color: #fff; background: #3d4246;"
                                                                                    (click)="moveDown(cvCertifications(),certificationIndex)">
                                                                                    <i class="bx bx-down-arrow"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2 col-md-2">
                                                                            <div class="form-group">

                                                                                <button type="button"
                                                                                    title="{{'delete' | translate}}"
                                                                                    class="form-control"
                                                                                    style="padding: 7px; font-size: 12px; color: #fff; background: #7C151F;"
                                                                                    (click)="removeCvCertification(certificationIndex)">
                                                                                    <i class="bx bx-trash"></i></button>

                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div class="col-lg-12 col-md-12">
                                                                        <div class="form-group">

                                                                            <input type="text"
                                                                                formControlName="certificat"
                                                                                placeholder="{{'certificate' | translate}}"
                                                                                class="form-control">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-md-6">
                                                                        <div class="form-group">

                                                                            <input type="text" formControlName="date"
                                                                                placeholder="{{'date' | translate}}"
                                                                                class="form-control">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6 col-md-6">
                                                                        <div class="form-group">

                                                                            <input type="text"
                                                                                formControlName="institute"
                                                                                placeholder="{{'academy' | translate}}"
                                                                                class="form-control">
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 col-md-12"
                                                                style="margin-top: 7px; margin-bottom: 7px;">
                                                                <div class="form-group">
                                                                    <button type="button"
                                                                        class=" form-control btn btn-sm btn-secondary"
                                                                        style="padding: 7px; font-size: 12px; color: #fff; background: #1273bb;"
                                                                        (click)="addCvCertification()">
                                                                        <i class="bx bx-plus"></i>
                                                                        {{'addcertificate' | translate}}</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="tab">
                                                <input class="input2" type="checkbox" id="rd12" #rd12
                                                    (change)="check12($event)">
                                                <label class="tab-label" for="rd12"
                                                    style="font-size: 14px; text-transform: uppercase;">{{'references' |
                                                    translate}} </label>
                                                <div class="tab-content">

                                                    <div class="row">
                                                        <div formArrayName="references" class="col-lg-12 col-md-12">
                                                            <div class="col-lg-12 col-md-12"
                                                                *ngFor="let ref of  cvReferences().controls; let refIndex=index">
                                                                <div class="row" [formGroupName]="refIndex">

                                                                    <div class="row">
                                                                        <div class="col-lg-6 col-md-6">
                                                                            <div class="form-group">
                                                                                <label
                                                                                    style="font-size: 15px; text-transform: uppercase;">{{'reference'
                                                                                    | translate}}
                                                                                    {{refIndex+1}}
                                                                                    <span
                                                                                        class="required"></span></label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2 col-md-2">
                                                                            <div class="form-group">
                                                                                <button type="button"
                                                                                    title="'{{up' | translate}}"
                                                                                    class="form-control"
                                                                                    style="padding: 7px; color: #fff; background: #255277;"
                                                                                    (click)="moveUp(cvReferences(),refIndex)">
                                                                                    <i class="bx bx-up-arrow"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2 col-md-2">
                                                                            <div class="form-group">
                                                                                <button type="button"
                                                                                    title="{{'down' | translate}}"
                                                                                    class="form-control"
                                                                                    style="padding: 7px; color: #fff; background: #3d4246;"
                                                                                    (click)="moveDown(cvReferences(),refIndex)">
                                                                                    <i class="bx bx-down-arrow"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2 col-md-2">
                                                                            <div class="form-group">

                                                                                <button type="button"
                                                                                    title="{{'delete' | translate}}"
                                                                                    class="form-control"
                                                                                    style="padding: 7px; font-size: 12px; color: #fff; background: #7C151F;"
                                                                                    (click)="removeCvReference(refIndex)">
                                                                                    <i class="bx bx-trash"></i></button>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div class="col-lg-6 col-md-6">
                                                                        <div class="form-group">

                                                                            <input type="text"
                                                                                formControlName="fullname"
                                                                                placeholder="{{'name' | translate}}"
                                                                                class="form-control">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-md-6">
                                                                        <div class="form-group">

                                                                            <input type="text"
                                                                                formControlName="organisation"
                                                                                placeholder="{{'company' | translate}}"
                                                                                class="form-control">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6 col-md-6">
                                                                        <div class="form-group">

                                                                            <input type="text"
                                                                                formControlName="telephone"
                                                                                placeholder="{{'phone' | translate}}"
                                                                                class="form-control">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6 col-md-6">
                                                                        <div class="form-group">

                                                                            <input type="text" formControlName="email"
                                                                                placeholder="Email"
                                                                                class="form-control">
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 col-md-12"
                                                                style="margin-top: 7px; margin-bottom: 7px;">
                                                                <div class="form-group">
                                                                    <button type="button"
                                                                        class=" form-control btn btn-sm btn-secondary"
                                                                        style="padding: 7px; font-size: 12px; color: #fff; background: #1273bb;"
                                                                        (click)="addCvReference()">
                                                                        <i class="bx bx-plus"></i>
                                                                        {{'addreference' | translate}}</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="tab">
                                                <input class="input2" type="checkbox" #rd0 id="rd0"
                                                    (change)="check0($event)">
                                                <label class="tab-label" for="rd0"
                                                    style="font-size: 14px; text-transform: uppercase;">{{'customsection'
                                                    | translate}} </label>
                                                <div class="tab-content">

                                                    <div class="row">

                                                        <div formArrayName="customs" class="col-lg-12 col-md-12">
                                                            <div class="col-lg-12 col-md-12"
                                                                *ngFor="let custom of  cvCustoms().controls; let customIndex = index">
                                                                <div class="row" [formGroupName]="customIndex">
                                                                    <div class="col-lg-12 col-md-12"
                                                                        style=" margin-top: 12px; margin-bottom: 3px;">
                                                                        <div class="row">
                                                                            <div class="col-lg-6 col-md-6">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        style="font-size: 15px; text-transform: uppercase;">{{'section'
                                                                                        | translate}}
                                                                                        {{customIndex+1}}

                                                                                        <span
                                                                                            class="required"></span></label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-2 col-md-2">
                                                                                <div class="form-group">
                                                                                    <button type="button"
                                                                                        title="'{{up' | translate}}"
                                                                                        class="form-control"
                                                                                        style="padding: 7px; color: #fff; background: #255277;"
                                                                                        (click)="moveUp(cvCustoms(),customIndex)">
                                                                                        <i class="bx bx-up-arrow"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-2 col-md-2">
                                                                                <div class="form-group">
                                                                                    <button type="button"
                                                                                        title="{{'down' | translate}}"
                                                                                        class="form-control"
                                                                                        style="padding: 7px; color: #fff; background: #3d4246;"
                                                                                        (click)="moveDown(cvCustoms(),customIndex)">
                                                                                        <i class="bx bx-down-arrow"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-2 col-md-2">
                                                                                <div class="form-group">

                                                                                    <button type="button"
                                                                                        title="{{'delete' | translate}}"
                                                                                        class="form-control"
                                                                                        style="padding: 7px; font-size: 12px; color: #fff; background: #7C151F;"
                                                                                        (click)="removeCvCustom(customIndex)">
                                                                                        <i
                                                                                            class="bx bx-trash"></i></button>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>

                                                                    <div class="row">


                                                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                                                            <div class="form-group">

                                                                                <input formControlName="title"
                                                                                    type="text"
                                                                                    title="{{'title' | translate}}"
                                                                                    placeholder="{{'title' | translate}}"
                                                                                    class="form-control">
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                                                            <div class="form-group"
                                                                                (click)="selectSkill()">
                                                                                <angular-editor
                                                                                    formControlName="description"
                                                                                    [config]="config">
                                                                                </angular-editor>

                                                                            </div>
                                                                        </div>


                                                                    </div>



                                                                </div>
                                                            </div>



                                                            <div class="col-lg-12 col-md-12"
                                                                style="margin-top: 7px; margin-bottom: 7px;">
                                                                <div class="form-group">
                                                                    <button type="button"
                                                                        class=" form-control btn btn-sm btn-secondary"
                                                                        style="padding: 7px; font-size: 12px; color: #fff; background: #1273bb;"
                                                                        (click)="addCvCustom()">
                                                                        <i class="bx bx-plus"></i>
                                                                        {{'addsection' | translate}} </button>
                                                                </div>
                                                            </div>



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="tab">
                                                <input class="input2" type="checkbox" id="rd10" #rd10
                                                    (change)="check10($event)">
                                                <label class="tab-label text-warning" for="rd10"
                                                    style="font-size: 14px">IMPORTANT ! </label>
                                                <div class="tab-content">
                                                    <div class="row">
                                                        <div class="col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="textarea-wrapper">
                                                                    <p style="font-weight: bold;">le nombre maximum de
                                                                        pages est de 3. Toutes les cases ne sont pas à
                                                                        remplir, ne remplissez que les sections que
                                                                        vous trouvez pertinent afin d'optimiser en
                                                                        nombre de pages </p>
                                                                    <p>Rechargez la page en cas d'un téléchargement
                                                                        deffectueux !</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                        <div class="form-group">
                                            <button *ngIf="isOpen" type="button"
                                                class="form-control btn btn-sm btn-secondary"
                                                style="padding: 3px;padding-bottom: 29px; font-size: 12px;  color: #fff; background: #003147; height: 30px;width: 110px;"
                                                (click)="save()">

                                                <i style="color: #fff; font-size: 18px;" class="bx bx-save"></i>
                                                {{'save' | translate}} </button>
                                            <button *ngIf="spin1" type="button" class=" form-control btn "
                                                style="padding: 7px; font-size: 12px;  height: 30px;width: 30px;">
                                                <span role="status" style="color: #003147;"
                                                    class="spinner-border spinner-danger spinner-border-sm"></span>
                                            </button>

                                            <button *ngIf="isOpen" type="button"
                                                class=" form-control btn btn-sm btn-secondary" (click)="openPDF()"
                                                style="padding: 3px;padding-bottom: 29px; font-size: 12px; color: #fff; background: #003147; height: 10px;width: 110px;">
                                                <i style="color: #fff; font-size: 18px;" class="bx bx-download"></i>
                                                {{'download' | translate}} </button>


                                        </div>
                                    </div>






                                </div>
                            </div>




                        </div>

                </div>
                </form>



            </div>

        </div>

    </div>

    <!--   CV TEMPLATE -->

    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

        <div class="container">
            <div class="user-actions" style="background: #47494b; padding-top : 7px; padding-bottom: 3px;">


                <div id="content_2" class="row">

                    <div class="col-lg-2 col-md-2 col-sm-6 icon1" style="z-index : 40">
                        <div #iconselect2 (click)="onChangeModele()" id="my-icon-select2" style="margin: 0;"></div>
                        <label style="margin-left:3px ; color: #fff; font-weight: 400;">{{'modeles' |
                            translate}}</label>
                        <input #selectedText2 type="text" id="selected-text2" name="selected-text2"
                            style="width:65px; height:2px; visibility: hidden;">
                    </div>

                    <div class="col-lg-2 col-md-2 col-sm-6 icon1" style="z-index : 30;">
                        <div #iconselect (click)="onChangeTheme()" id="my-icon-select" style="margin: 0;"></div>
                        <label style="margin-left:3px ; color: #fff; font-weight: 400;">{{'themes' |
                            translate}}</label>
                        <input #selectedText type="text" id="selected-text" name="selected-text"
                            style="width:65px; height: 3px; visibility: hidden;">
                    </div>

                    <div class="col-lg-1 col-md-2 col-sm-2">
                        <input [(colorPicker)]="firstcolor" class="color1" [style.background]="firstcolor"
                            [cpPosition]="'right'" [cpOKButton]="true"
                            [cpPresetColors]="[ '#871919','#0c3d87', '#333536','#525214','#1b3f23','#3d3a30']"
                            [style.width]="'33px'" [cpOutputFormat]="'hex'"
                            >
                        <label style="margin-left:3px ; color: #fff; font-weight: 400;"></label>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-lg-1 col-md-2 col-sm-2">
                        <input [(colorPicker)]="secondcolor" class="color1" [style.background]="secondcolor"
                        [cpPosition]="'right'" [cpOKButton]="true"
                        [cpPresetColors]="[ '#496376','#365f7e', '#832c2c','#357666','#5e5b2c','#265819']"
                        [style.width]="'33px'" [cpOutputFormat]="'hex'"
                            >
                        <label style="margin-left:3px ; color: #fff; font-weight: 400;"></label>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-lg-1 col-md-2 col-sm-2">
                        <input [(colorPicker)]="pagecolor" class="color1" [style.background]="pagecolor"
                            [cpPosition]="'right'" [style.width]="'33px'"
                        [cpPresetColors]="[ '#ffffff','#d4e2f7', '#d7e1e7','#f1e4e4','#e2efe9','#e0dbcf']"
                        [cpOKButton]="true" [cpOutputFormat]="'hex'"
                            >
                        <label style="margin-left:3px ; color: #fff; font-weight: 400;"></label>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12 icon1">
                        <div class=" input-group">
                            <label class="input-group-text" style="padding-bottom: 7px; padding-left: 3px;" for>
                                <i class="bx bx-font-family"></i>
                            </label>
                            <select class="form-select" (click)="selectFontFamily($event)" [value]="family1"
                                style="padding: 7px; font-size: 14px;  height: 33px;width: auto; font-weight: 600;"
                                >
                                <option style="font-family: Arial, Helvetica, sans-serif;" value="arial"> Arial</option>
                                <option style="font-family: 'Arial Black', Times, serif;" value="arialb"> Arial Black</option>
                                <option style="font-family: 'Bahnschrift', sans-serif" value="bahnschrift"> Bahnschrift </option>
                                <option style="font-family: calibri;" value="calibri"> Calibri</option>
                                <option style="font-family:'Constantia', Times, serif;" value="canstania"> Canstania</option>
                                <option style="font-family: 'Century Gothic'" value="century"> Century G.</option>
                                <option style="font-family: 'Comic Sans Serif'" value="comic"> Comic S. </option>
                                <option style="font-family: 'Courier', Times, serif;" value="courier"> Courier</option>
                                <option style="font-family: 'Ebrima', Times, serif;" value="ebrima"> Ebrima</option>
                                <option style="font-family: Georgia,serif" value="georgia"> Georgia</option>
                                <option style="font-family:Lucida Sans Unicode,Lucida Grande,sans-seri" value="lucida">Lucida  </option>
                                <option style="font-family: 'Poppins'" value="poppins"> Poppins</option>
                                <option style="font-family: 'Tahoma', Times, serif;" value="tahoma"> Tahoma</option>
                                <option style="font-family: 'Times New Roman' ;" value="times"> Times N </option>
                                <option style="font-family: Verdana ;" value="verdana"> Verdana</option>




                            </select>
                        </div>
                    </div>

                </div>

            </div>

        </div>


        <form [formGroup]="cvFormGroup" style="z-index : -1">
            <div id="cvt2" class="div_body">
                <div class="marge cvt20" [ngClass]="family1">

                    <!--  START PAGE 1-->


                    <div id="content1" class="resume">

                        <div id="left_side1" class="resume_left" [style.background]="firstcolor">

                            <div class="resume_profile">
                                <img *ngIf="!imageToShow && imgURL" [src]="imgURL" alt="profile_pic"
                                    style="width: 160px; height: 160px; float: left; border-radius: 50%; margin : 12px">
                                <img *ngIf="imageToShow" [src]="imageToShow" alt="profile_pic"
                                    style="width: 160px; height: 160px; float: left; border-radius: 50%; margin : 12px">

                                <div id="text2" class="text2" [style.background]="secondcolor">
                                    <div style="margin-bottom: 12px; " [style.color]="pagecolor">
                                        {{this.cvFormGroup.get("firstname").value}}</div>
                                    <p></p>
                                    <span style="margin-bottom:3px ; margin-top: 7px;"
                                        [style.color]="pagecolor">{{this.cvFormGroup.get("lastname").value}}</span>

                                </div>

                            </div>
                            <div
                                style="background-color: aliceblue; font-size: 17px; color: black; width: 280px; text-align: center; font-weight: bold; margin-bottom: 3px;">
                                {{this.cvFormGroup.get("profession").value}}
                            </div>

                            <div class="resume_content">
                                <div class="resume_item resume_info">

                                    <ul>

                                        <li *ngIf="cvFormGroup.get('telephone').value.trim()">
                                            <div class="icon">
                                                <img style="margin-left: 7px; margin-top: 3px;"
                                                    src="assets/img/phone8.png" width="17" height="17">
                                            </div>
                                            <div class="data">
                                                <span
                                                    *ngIf="this.cvFormGroup.get('telephone').value.includes('U2FsdG')">
                                                    {{cvFormGroup.get('telephone').value | decrypted}}</span>
                                                <span
                                                    *ngIf="!this.cvFormGroup.get('telephone').value.includes('U2FsdG')">
                                                    {{cvFormGroup.get('telephone').value | encrypted |
                                                    decrypted}}</span>

                                            </div>
                                        </li>
                                        <li *ngIf="cvFormGroup.get('email').value.trim()">
                                            <div class="icon">
                                                <img id="ic1" src="assets/img/env2.png" width="17" height="17"
                                                    style="margin-left: 7px; margin-top: 9px;">
                                            </div>
                                            <div class="data">
                                                {{cvFormGroup.get('email').value}}
                                            </div>
                                        </li>
                                        <li>
                                            <div *ngIf="cvFormGroup.get('linkedin').value.trim()" class="icon">
                                                <img src="assets/img/linkedin4.png" width="15" height="15"
                                                    style="margin-left: 9px; margin-top: 7px;">
                                            </div>
                                            <div class="data">
                                                {{cvFormGroup.get('linkedin').value}}
                                            </div>
                                        </li>
                                        <li *ngIf="cvFormGroup.get('webSite').value.trim()">
                                            <div class="icon" style="padding: 5px 0 0 8px">
                                                <img src="assets/img/globe2.png" width="15" height="15">
                                            </div>
                                            <div class="data">
                                                {{cvFormGroup.get('webSite').value}}
                                            </div>
                                        </li>
                                        <li *ngIf="cvFormGroup.get('address').value.trim() || cvFormGroup.get('city').value.trim()">
                                            <div class="icon">
                                                <img id="ic2" src="assets/img/map2.png" width="17" height="17"
                                                    style="margin-left: 7px; margin-top: 9px;">
                                            </div>
                                            <div class="data">
                                                <span *ngIf="this.cvFormGroup.get('address').value.includes('U2FsdG')">
                                                    {{cvFormGroup.get('address').value | decrypted}}</span>
                                                <span *ngIf="!this.cvFormGroup.get('address').value.includes('U2FsdG')">
                                                    {{cvFormGroup.get('address').value | encrypted | decrypted}}</span>
                                                <br /> {{cvFormGroup.get('city').value}}
                                            </div>
                                        </li>

                                        <li *ngIf="cvFormGroup.get('birth').value.trim()">
                                            <div class="icon">
                                                <img id="ic2" src="assets/img/birth2.png" width="17" height="17"
                                                    style="margin-left: 7px; margin-top: 7px;">
                                            </div>
                                            <div class="data">
                                                {{'birth' | translate}} : {{cvFormGroup.get('birth').value}}
                                            </div>
                                        </li>

                                        <li *ngIf="cvFormGroup.get('marital').value.trim()">
                                            <div class="icon">
                                                <img id="ic2" src="assets/img/marital2.png" width="17" height="17"
                                                    style="margin-left: 7px; margin-top: 10px;">
                                            </div>
                                            <div class="data">
                                                {{cvFormGroup.get('marital').value}}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div *ngIf="cvFormGroup.get('skills').value.length" formArrayName="skills"
                                    class="resume_item resume_skills">
                                    <div class="title">
                                        <p class="bold"> {{'skills' | translate}}</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let skill of cvSkills().controls; let skillIndex=index"
                                            [formGroupName]="skillIndex">

                                            <div class="skill_name">
                                                {{cvSkills().at(skillIndex).get('item').value}}
                                            </div>
                                            <div class="skill_progress">
                                                <span [style.background]="pagecolor"
                                                    [style.width]="cvSkills().at(skillIndex).get('level').value"></span>
                                            </div>

                                        </li>


                                    </ul>
                                </div>
                                <div *ngIf="cvFormGroup.get('languages').value.length" formArrayName="languages"
                                    class="resume_item resume_skills">
                                    <div class="title">
                                        <p class="bold"> {{'languages' | translate}}</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let lang of cvLanguages().controls; let langIndex=index"
                                            [formGroupName]="langIndex">

                                            <div class="skill_name">
                                                {{cvLanguages().at(langIndex).get('item').value}}
                                            </div>
                                            <div class="skill_progress">
                                                <span [style.background]="pagecolor"
                                                    [style.width]="cvLanguages().at(langIndex).get('level').value"></span>
                                            </div>
                                            <div class="skill_per">{{cvLanguages().at(langIndex).get('level').value}}
                                            </div>

                                        </li>


                                    </ul>
                                </div>

                                <div *ngIf="cvFormGroup.get('quality1').value.trim() || cvFormGroup.get('quality2').value.trim() ||
                                    cvFormGroup.get('quality3').value.trim() || cvFormGroup.get('quality4').value.trim()"
                                    class="resume_item resume_skills">
                                    <div class="title">
                                        <p class="bold"> {{'qualities' | translate}}</p>
                                    </div>
                                    <ul>
                                        <li>
                                            <div class="quality">
                                                {{cvFormGroup.get('quality1').value}}
                                            </div>

                                            <div class="quality">
                                                {{cvFormGroup.get('quality2').value}}
                                            </div>
                                        </li>
                                        <li>
                                            <div class="quality">
                                                {{cvFormGroup.get('quality3').value}}
                                            </div>

                                            <div class="quality"> {{cvFormGroup.get('quality4').value}}</div>
                                        </li>

                                    </ul>
                                </div>
                                <div *ngIf="cvFormGroup.get('hobby1').value.trim() || cvFormGroup.get('hobby2').value.trim() ||
                                    cvFormGroup.get('hobby3').value.trim() || cvFormGroup.get('hobby4').value.trim()"
                                    class="resume_item resume_skills">
                                    <div class="title">
                                        <p class="bold"> {{'hobbies' | translate}}</p>
                                    </div>
                                    <ul>
                                        <li>
                                            <div class="quality">
                                                {{cvFormGroup.get('hobby1').value}}
                                            </div>

                                            <div class="quality">{{cvFormGroup.get('hobby2').value}}</div>
                                        </li>
                                        <li>
                                            <div class="quality">
                                                {{cvFormGroup.get('hobby3').value}}
                                            </div>

                                            <div class="quality">{{cvFormGroup.get('hobby4').value}}</div>
                                        </li>

                                    </ul>
                                </div>
                                <div *ngIf="cvFormGroup.get('references').value.length" formArrayName="references"
                                    class="resume_item resume_social">
                                    <div class="title">
                                        <p class="bold"> {{'references' | translate}}</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let certif of cvReferences().controls; let refIndex=index">

                                            <div [formGroupName]="refIndex" class="data">
                                                <p style="color: #dfd5d5;" class="semi-bold">
                                                    {{cvReferences().at(refIndex).get('fullname').value}}</p>
                                                <p style="color: #dfd5d5;"><strong>
                                                        {{cvReferences().at(refIndex).get('organisation').value}}
                                                    </strong></p>
                                                <p style="color: #dfd5d5;"
                                                    *ngIf="cvReferences().at(refIndex).get('telephone').value.trim()">
                                                    <strong>{{'phone' | translate}} : </strong>
                                                    {{cvReferences().at(refIndex).get('telephone').value}} </p>
                                                <p style="color: #dfd5d5;"
                                                    *ngIf="cvReferences().at(refIndex).get('email').value.trim()">
                                                    <strong>Email : </strong>
                                                    {{cvReferences().at(refIndex).get('email').value}} </p>


                                            </div>
                                            <p style="color: #fff; font-size: 3px;">_</p>
                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="resume_right" id="right_side1">
                            <div *ngIf="this.cvFormGroup.get('profile').value.trim()" class="resume_item resume_about"
                                style="margin-top: -33px;  ">

                                <p style="font-size: 12px; word-wrap: break-word; margin-top: 12px;">

                                    {{this.cvFormGroup.get("profile").value}}
                                </p>
                            </div>

                            <!-- EDUCATION BEGINNER -->
                                <div *ngIf="cvFormGroup.get('educations').value.length && isBeginner" formArrayName="educations"
                                    class="resume_item resume_education" style="margin-bottom: -27px;" >
                                    <div class="title">
                                        <p class="bold">Education</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let education of cvEducations().controls; let educationIndex=index"
                                            [formGroupName]="educationIndex">

                                            <div class="info">
                                                <p class="semi-bold">
                                                    <strong class="date">{{cvEducations().at(educationIndex).get('period').value}}
                                                    </strong>
                                                    <strong style="margin-left: 7px;"> | </strong>
                                                    <strong style="margin-right: 7px;">
                                                        {{cvEducations().at(educationIndex).get('degree').value}}
                                                    </strong>
                                                </p>
                                                <p> <strong style="text-transform: uppercase; font-size: 15px;">
                                                        {{cvEducations().at(educationIndex).get('institute').value}}</strong><strong style="margin-left: 7px;"> | </strong>
                                                        <strong style="margin-right: 7px;">{{cvEducations().at(educationIndex).get('city').value}}</strong>
                                                </p>
                                            </div>
                                            <p style="color: #fff; font-size: 3px;">_</p>
                                        </li>

                                    </ul>

                                </div>

                                <!-- EXPERIENCE PROFESSIONAL -->
                                <div *ngIf="cvFormGroup.get('experiences').value.length && isProfessional" formArrayName="experiences"
                                    class="resume_item resume_work" style="margin-bottom: -27px; ">
                                    <div class="title">
                                        <p class="bold"> {{'experiences' | translate}}</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let experience of cvExperiences().controls; let experienceIndex=index"
                                            [formGroupName]="experienceIndex">

                                            <div class="info">
                                                <p class="semi-bold">

                                                    <strong style="text-transform: uppercase; font-size: 15px;">
                                                        {{cvExperiences().at(experienceIndex).get('company').value}}</strong>
                                                    <strong style="margin-left: 7px;"> | </strong>
                                                    <strong style="margin-left: 7px;">
                                                        {{cvExperiences().at(experienceIndex).get('function').value}}
                                                    </strong>
                                                </p>
                                                <p>
                                                    <strong class="date">
                                                        {{cvExperiences().at(experienceIndex).get('period').value}}</strong>
                                                        <strong style="margin-left: 7px;"> | </strong>
                                                    <span style="margin-left: 7px;"> {{cvExperiences().at(experienceIndex).get('city').value}}</span>
                                                </p>
                                                <p style="font-size: 12px; word-wrap: break-word;"
                                                    [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">

                                                </p>
                                            </div>
                                            <p style="color: #fff; font-size: 3px;">_</p>
                                        </li>


                                    </ul>
                                </div>


                                <div *ngIf="cvFormGroup.get('certifications').value.length"
                                    formArrayName="certifications" class="resume_item resume_education" style="margin-bottom: -27px; " >
                                    <div class="title">
                                        <p class="bold"> {{'certificates' | translate}}</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let certif of cvCertifications().controls; let certifIndex=index"
                                            [formGroupName]="certifIndex">
                                            <div class="date">
                                                {{cvCertifications().at(certifIndex).get('date').value}}
                                                <strong style="margin-left: 7px;"> | </strong>
                                                <strong style="margin-right: 7px;">
                                                    {{cvCertifications().at(certifIndex).get('certificat').value}}</strong>
                                            </div>
                                            <div class="info">

                                                <p> <strong style="text-transform: uppercase; font-size: 15px;">
                                                        {{cvCertifications().at(certifIndex).get('institute').value}}</strong><span></span>
                                                </p>
                                            </div>
                                            <p style="color: #fff; font-size: 3px;">_</p>
                                        </li>

                                    </ul>

                                </div>

                                <div *ngIf="cvFormGroup.get('formations').value.length" formArrayName="formations"
                                    class="resume_item resume_work" style="margin-bottom: -27px; ">
                                    <div class="title">
                                        <p class="bold">Formations</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let formation of cvFormations().controls; let formationIndex=index"
                                            [formGroupName]="formationIndex">
                                            <div class="date">
                                                {{cvFormations().at(formationIndex).get('period').value}}
                                                <strong style="margin-left: 7px;"> | </strong>
                                                <strong style="margin-right: 7px;">
                                                    {{cvFormations().at(formationIndex).get('title').value}}</strong>

                                            </div>
                                            <div class="info">

                                                <p> <strong style="text-transform: uppercase; font-size: 15px;">
                                                        {{cvFormations().at(formationIndex).get('former').value}}</strong><span>
                                                    </span></p>
                                                <p style="font-size: 12px; word-wrap: break-word;"
                                                    [innerHTML]="cvFormations().at(formationIndex).get('description').value">
                                                </p>
                                            </div>
                                            <p style="color: #fff; font-size: 3px;">_</p>
                                        </li>

                                    </ul>
                                </div>


                                <!-- EDUCATION PROFESSIONAL -->
                                <div *ngIf="cvFormGroup.get('educations').value.length && isProfessional" formArrayName="educations"
                                    class="resume_item resume_education" style="margin-bottom: -27px; ">
                                    <div class="title">
                                        <p class="bold">Education</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let education of cvEducations().controls; let educationIndex=index"
                                        [formGroupName]="educationIndex">

                                        <div class="info">
                                            <p class="semi-bold">
                                                <strong class="date">{{cvEducations().at(educationIndex).get('period').value}}
                                                </strong>
                                                <strong style="margin-left: 7px;"> | </strong>
                                                <strong style="margin-right: 7px;">
                                                    {{cvEducations().at(educationIndex).get('degree').value}}
                                                </strong>
                                            </p>
                                            <p> <strong style="text-transform: uppercase; font-size: 15px;">
                                                    {{cvEducations().at(educationIndex).get('institute').value}}</strong><strong style="margin-left: 7px;"> | </strong>
                                                    <strong style="margin-right: 7px;">{{cvEducations().at(educationIndex).get('city').value}}</strong>
                                            </p>
                                        </div>
                                        <p style="color: #fff; font-size: 3px;">_</p>
                                    </li>

                                    </ul>

                                </div>

                                <!-- EXPERIENCE BEGINNER -->
                                <div *ngIf="cvFormGroup.get('experiences').value.length && isBeginner" formArrayName="experiences"
                                    class="resume_item resume_work" style="margin-bottom: -27px; ">
                                    <div class="title">
                                        <p class="bold"> {{'experiences' | translate}}</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let experience of cvExperiences().controls; let experienceIndex=index"
                                        [formGroupName]="experienceIndex">

                                        <div class="info">
                                            <p class="semi-bold">

                                                <strong style="text-transform: uppercase; font-size: 15px;">
                                                    {{cvExperiences().at(experienceIndex).get('company').value}}</strong>
                                                <strong style="margin-left: 7px;"> | </strong>
                                                <strong style="margin-left: 7px;">
                                                    {{cvExperiences().at(experienceIndex).get('function').value}}
                                                </strong>
                                            </p>
                                            <p>
                                                <strong class="date">
                                                    {{cvExperiences().at(experienceIndex).get('period').value}}</strong>
                                                    <strong style="margin-left: 7px;"> | </strong>
                                                <span style="margin-left: 7px;"> {{cvExperiences().at(experienceIndex).get('city').value}}</span>
                                            </p>
                                            <p style="font-size: 12px; word-wrap: break-word;"
                                                [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">

                                            </p>
                                        </div>
                                        <p style="color: #fff; font-size: 3px;">_</p>
                                    </li>


                                    </ul>
                                </div>

                            <!-- RUBRIQUE PERSONNALISEE -->
                            <div *ngIf="cvFormGroup.get('customs').value.length" formArrayName="customs">
                                <div *ngFor="let custom of cvCustoms().controls; let customIndex=index"
                                    [formGroupName]="customIndex" class="resume_item resume_education">
                                    <div class="title">
                                        <p class="bold">{{cvCustoms().at(customIndex).get('title').value}}</p>
                                    </div>
                                    <ul>
                                        <li>
                                            <div class="info">

                                                <p style="font-size: 12px; word-wrap: break-word;"
                                                    [innerHTML]="cvCustoms().at(customIndex).get('description').value">
                                                </p>
                                            </div>
                                            <p style="color: #fff; font-size: 3px;">_</p>
                                        </li>

                                    </ul>

                                </div>
                            </div>




                        </div>

                    </div>

                    <!--  END PAGE 1-->

                    <!--  START PAGE 2-->


                    <div class="resume" id="content2" #content2>

                        <div id="left_side2" class="resume_left" #left_side2 [style.background]="firstcolor">





                        </div>
                        <div id="right_side2" class="resume_right" #right_side2 style="padding : 0;  "
                            [style.background]="pagecolor">





                        </div>
                    </div>


                    <!--  END PAGE 2-->

                    <!--  START PAGE 3-->


                    <div class="resume" id="content3" #content3>
                        <div id="left_side3" class="resume_left" #left_side3 [style.background]="firstcolor">



                        </div>
                        <div id="right_side3" class="resume_right" #right_side3 style="padding : 0;"
                            [style.background]="pagecolor">

                        </div>
                    </div>


                    <!--  END PAGE 3-->



                </div>

            </div>
        </form>
    </div>

</div>
</div>

<!--   FORM ARRAY         -->
