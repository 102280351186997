import { Component, Input, OnInit } from '@angular/core';
import { ThemeService } from '../cv-theme.service';

@Component({
  selector: 'theme2',
  templateUrl: './theme2.component.html',
  styleUrls: ['./theme2.component.scss']
})
export class Theme2Component implements OnInit {

    constructor(private themeService: ThemeService) { }

    @Input()
    idModele : number;

    ngOnInit(): void {
        this.findAll(this.idModele);
    }

    listTheme =[];
    findAll(idModele: number) {
        this.themeService.findAll(idModele).subscribe(
            (data) => {
                this.listTheme=data;
            },
            (error) => {
                alert("Themes non trouvé !");
            },
            () => {}
        );
    }

}
