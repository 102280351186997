<app-header-style-one></app-header-style-one>

<cover-homeseven-main-banner></cover-homeseven-main-banner>


<!--
<app-homeseven-courses></app-homeseven-courses>

<app-how-it-works></app-how-it-works>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>

        <app-instructors-style-one></app-instructors-style-one>
    </div>

    <div id="particles-js-circle-bubble-3"></div>
</div>

<div class="feedback-area ptb-100">
    <div class="container">
        <app-feedback-style-one></app-feedback-style-one>
    </div>
</div>

<div class="partner-area ptb-70">
    <div class="container">
        <app-partner-style-two></app-partner-style-two>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>
-->




<div class="my-dashboard-area ptb-100">
    <div class="container">

        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/cover-letter/examples/informatique" ><i class='bx bx-desktop'></i> Informatique</a></li>
                <li><a routerLink="/cover-letter/examples/environnement" class="active"><i class='bx bx-shape-triangle'></i> Environnement </a></li>
                <li><a routerLink="/cover-letter/examples/bank-finance"><i class='bx bx-dollar'></i> Banque-Finance</a></li>
                <li><a routerLink="/cover-letter/examples/marketing"><i class='bx bx-target-lock'></i> Commerce-Marketing</a></li>
                <li><a routerLink="/cover-letter/examples/industry"><i class='bx bx-bar-chart-alt-2'></i> Industrie</a></li>
                <li><a routerLink="/cover-letter/examples/btp"><i class='bx bx-home'></i>BTP-Urbanisme</a></li>
                <li><a routerLink="/cover-letter/examples/health"> <i class='bx bx-first-aid'></i>Santé</a></li>
                <li><a routerLink="/cover-letter/examples/human"><i class='bx bx-group'></i>Sciences humaines</a></li>
                <li><a routerLink="/cover-letter/examples/others"> <i class='bx bxs-book-reader'></i>Autres</a></li>

            </ul>
        </div>
        <div class="myDashboard-content">

            <div class="products-details-tab-content">
                <div *ngIf="spin1" type="button" class="col-lg-12 col-md-12"
                style="padding: 7px; font-size: 12px;  height: 30px;width: 30px;">
                <span role="status" style="color: #003147;"
                    class="spinner-border spinner-danger spinner-border-sm"></span>
               </div>

                <div class="col-lg-12 col-md-12">

                <div class="row">



                    <div *ngFor="let cover of listCover" class="col-lg-4 col-md-6 col-sm-6">

                        <div class="single-product-box mb-30 " style="padding: 0;">

                            <div  class="product-image" style="padding: 0;">
                                <a (click)="showCover(cover)" style="padding: 0;">
                                    <div  class="imgDiv">
                                        <mcover1 *ngIf="cover.idmodele===1" [cover]="cover"></mcover1>
                                        <mcover2 *ngIf="cover.idmodele===2" [cover]="cover"></mcover2>
                                        <mcover3 *ngIf="cover.idmodele===3" [cover]="cover"></mcover3>
                                        <mcover4 *ngIf="cover.idmodele===4" [cover]="cover"></mcover4>
                                        <mcover5 *ngIf="cover.idmodele===5" [cover]="cover"></mcover5>
                                        <mcover6 *ngIf="cover.idmodele===6" [cover]="cover"></mcover6>
                                        <mcover7 *ngIf="cover.idmodele===7" [cover]="cover"></mcover7>
                                        <mcover8 *ngIf="cover.idmodele===8" [cover]="cover"></mcover8>

                                    </div>
                                    <!--
                                    <img src="assets/img/shop/shop6.jpg" alt="image">
                                    <img src="assets/img/shop/shop6-1.jpg" alt="image">
                                    -->
                                </a>
                                <a (click)="showCover(cover)" href="javascript:" class="add-to-cart-btn">Voir <i class='bx bx-file'></i></a>
                            </div>
                            <div class="product-content" style="max-height: 60px; margin-top: -21px; margin-bottom: 21px;">
                                <a *ngIf="isAdmin" style="font-size:18px ;margin-right: 3px; float: left;" (click)="showCover2(cover)" href="javascript:" class="add-to-cart-btn"> <i class='bx bx-edit'></i></a>

                                <a *ngIf="isAdmin" style="font-size:18px ; float: left;" (click)="confirmBox(cover)" title="Supprimer" href="javascript:" class="add-to-cart-btn"> <i class='bx bx-trash'></i>  </a>
                                <a style="font-size:14px ; float: right;" (click)="showCover(cover)" > <strong>{{cover.name}}</strong></a>

                            </div>
                        </div>

                    </div>

              </div>


            </div>
            </div>
        </div>
    </div>
</div>

