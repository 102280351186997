<section class="mission-area ptb-100">
    <div class="container">
        <div class="mission-content">
            <div class="section-title text-start">
                <span class="sub-title">Reussir son entrétien</span>
                <h2>Comment réussir son entretien d'embauche</h2>
            </div>
            <div class="mission-slides">
                <owl-carousel-o [options]="missionSlides">
                    <ng-template carouselSlide>
                        <h3>Qu'est ce qu'un entretien ?</h3>
                        <p> Un entretien sert à établir une compréhension mututelle entre le candidat et l'organisation. </p>
                        <p>C'est une conversation qui a lieu entre un employeur et un candidat à un emploi. Tout au long de l'entretien d'embauche, l'employeur évalue les aptitudes qu'a le candidat pour pouvoir être récruter au poste proposé.</p>

                    </ng-template>
                    <ng-template carouselSlide>
                        <h3>Une occasion pour s'améliorer</h3>
                        <p>L'entretien est l'une des étapes les plus importantes de la sélection pour un emploi ou un stage. Lors de l'entretien, les candidats et l'intervieweur identifient un intérêt mutuel et obtiennent des réponses aux questions. Au cours
                            de l'entretien, l'employeur évalue vos qualifications, votre niveau de motivation et si vous comprenez ce que vous voulez obtenir de ce poste. Considérant les entretiens en termes d'avantages pour le candidat, ils vous donnent
                            ensuite l'opportunité d'évaluer l'entreprise, sa concordance avec vos attentes et vos objectifs.</p>
                        <p>Un entretien est toujours une excellente occasion d'améliorer la compétence de présentation de soi, qui est extrêmement importante dans le monde moderne.</p>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
