import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "./auth-signin.service";
import { TokenStorageService } from "./token-storage.service";
import { User_ } from "./user";

@Component({
    selector: "app-login-page",
    templateUrl: "./cv-login-page.component.html",
    styleUrls: ["./cv-login-page.component.scss"],
})
export class CVLoginPageComponent implements OnInit {
    loginFormGroup: FormGroup;
    user: User_;
    isSubmitted = false;
    public showPasswordOnPress: boolean;

    isLoggedIn = false;
    isLoginFailed = false;
    errorMessage: string = "";
    loginMessage: string = "";
    roles: string[] = [];
    mode: number = 0;

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private tokenStorage: TokenStorageService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.initLoginForm();
    }

    initLoginForm(): void {
        this.loginFormGroup = this.fb.group({
            email: ["", [Validators.required, Validators.email]],
            password: ["", [Validators.required, Validators.minLength(5)]],
        });
    }

    get formControls() {
        return this.loginFormGroup.controls;
    }

    login2() {
        console.log(this.loginFormGroup.value);
        this.isSubmitted = true;
        if (this.loginFormGroup.invalid) {
            return;
        }
        //  this.authService.login(this.loginForm.value);
        this.router.navigateByUrl("/admin");
    }

    isClick: boolean = false;
    login() {
        this.isClick = true;
        this.loginMessage = "Tentative de connexion en cours ...";

        this.authService.login(this.loginFormGroup.value).subscribe(
            (resp) => {
                // this.tokenStorage.saveToken(data.accessToken);
                let jwtToken = resp.body.access_token;

                this.tokenStorage.saveToken("Bearer " + jwtToken);

                this.isLoginFailed = false;
                this.isLoggedIn = true;
                this.loginMessage = "Vous êtes connecté(e)";

                // Récupère l'URL de redirection depuis le service d'authentification
                // Si aucune redirection n'a été définis, redirige l'utilisateur vers la liste des patients.
                // let redirect = this.redirectUrl ? this.redirectUrl : '/patient/list';

                // Redirige l'utilisateur

                // this.reloadPage();
            },
            (err) => {
                this.isClick = false;
                this.isLoginFailed = true;
                if (err.error.message === undefined) {
                    this.errorMessage = "Error Server";
                } else {
                    this.errorMessage = err.error.message;
                }

                this.mode = 1;
            },
            () => {
                // this.getCvs();
                this.chargeUser();

                setTimeout(() => {
                    // alert(this.tokenStorage.getUser().id)

                    if (this.tokenStorage.getUser() === null) {
                        let redirect = "/cvs";
                        this.router.navigate([redirect]);
                    } else {
                        let redirect = "/cvs";

                        this.router.navigate([redirect]);
                    }
                }, 1000);
            }
        );
    }

    googleConnect() {
        this.loginMessage = "Tentative de connexion en cours ...";

        this.authService.googleConnect().subscribe(
            (resp) => {
                // this.tokenStorage.saveToken(data.accessToken);
                let jwtToken = resp.body.access_token;

                this.tokenStorage.saveToken("Bearer " + jwtToken);

                this.isLoginFailed = false;
                this.isLoggedIn = true;
                this.loginMessage = "Vous êtes connecté(e)";

                // Récupère l'URL de redirection depuis le service d'authentification
                // Si aucune redirection n'a été définis, redirige l'utilisateur vers la liste des patients.
                // let redirect = this.redirectUrl ? this.redirectUrl : '/patient/list';

                // Redirige l'utilisateur

                // this.reloadPage();
            },
            (err) => {
                this.isLoginFailed = true;
                if (err.error.message === undefined) {
                    this.errorMessage = "Error Server";
                } else {
                    this.errorMessage = err.error.message;
                }

                this.mode = 1;
            },
            () => {
                // this.getCvs();
                this.chargeUser();

                setTimeout(() => {
                    // alert(this.tokenStorage.getUser().id)

                    if (this.tokenStorage.getUser() === null) {
                        let redirect = "/cvs";
                        this.router.navigate([redirect]);
                    } else {
                        let redirect = "/cvs";

                        this.router.navigate([redirect]);
                    }
                }, 1000);
            }
        );
    }

    getCvs(): void {
        this.authService.getCvs().subscribe(
            (data) => {},
            (error) => {
                // alert(error.error.message);
                //  alert(this.tokenStorage.getUser())
            },
            () => {}
        );
    }

    chargeUser(): void {
        this.authService.findOne().subscribe(
            (data) => {
                this.user = data;
                this.tokenStorage.saveUser(data);
            },
            (error) => {
                this.tokenStorage.signOut();
            },
            () => {}
        );
    }

    reloadPage() {
        window.location.reload();
    }
}
