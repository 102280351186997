import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { TranslateService } from "@ngx-translate/core";


@Injectable({
    providedIn: "root",
})
export class LanguageService {

    private keyLanguage='userLanguage';
    private _userLanguage='';
    private supportedLanguages = ['en', 'fr'];

    constructor(private translate : TranslateService){
        this.initLanguage();
        translate.use(this._userLanguage);
    }

    initLanguage(){
        const value= localStorage.getItem(this.keyLanguage)
         /*
        if(value!=null){
            this._userLanguage=value;
        }else{
              const browserLanguage= navigator.language.split('-')[0];
              this._userLanguage='fr';

              if(this.supportedLanguages.includes(this._userLanguage)){
                  this._userLanguage=browserLanguage;
                  localStorage.setItem(this.keyLanguage, browserLanguage);
              }


        }
         */
        this._userLanguage='fr';
        localStorage.setItem(this.keyLanguage, this._userLanguage);
    }

    setLanguage(language){
        this._userLanguage=language;
        localStorage.setItem(this.keyLanguage, this._userLanguage);
        this.translate.use(this._userLanguage);

    }

    get userLanguage(){
        return this._userLanguage;
    }

    getAvailablelanguage(){
        return this.supportedLanguages;
    }

}
