<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Mon Tableau de bord</li>
            </ul>
            <h2>Mon Tableau de bord</h2>
        </div>
    </div>
</div>


<!--
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            title
        </mat-expansion-panel-header>
        content
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            title2
        </mat-expansion-panel-header>
        content2
    </mat-expansion-panel>
</mat-accordion>
-->
<div class="my-dashboard-area ptb-100">
    <div class="container">

        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/cvs"  ><i class='bx bx-id-card'></i> Mes CV</a></li>
                <li><a routerLink="/cvs/cover_letters"><i class='bx bx-file'></i> Mes lettres de motivation</a></li>
                <li><a routerLink="/cvs/information" class="active"><i class='bx bx-user'></i>Mes informations</a></li>
                <li><a routerLink="/cvs/subscription"><i class='bx bx-file'></i>Mes abonnements</a></li>


            </ul>
        </div>
        <div class="myDashboard-content">

            <div class="products-details-tab-content">

                <div class="col-lg-12 col-md-12">

                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="billing-details">
                                <h3 class="title"></h3>
                                <div class="row">

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input  class="form-control" [value]="user.email" readonly="readonly">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label>Prénom <span class="required"></span></label>
                                            <input  class="form-control" [value]="user.firstname" readonly="readonly">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label>Nom <span class="required"></span></label>
                                            <input  class="form-control" [value]="user.lastname" readonly="readonly">
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                        </div>
                    </div>



            </div>
            </div>

        </div>
    </div>
</div>
