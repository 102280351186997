import { Component, Input, OnInit } from '@angular/core';
import { ThemeService } from '../cv-theme.service';

@Component({
  selector: 'theme1',
  templateUrl: './theme1.component.html',
  styleUrls: ['./theme1.component.scss']
})
export class Theme1Component implements OnInit {

  constructor(private themeService: ThemeService) { }

  @Input()
  idModele : number;

  ngOnInit(): void {
      this.findAll(this.idModele);
  }

  listThemes :Array<any> =[];
  findAll(idModele: number) {
      this.themeService.findAll(idModele).subscribe(
          (data) => {
              this.listThemes=data;

          },
          (error) => {
              alert("Themes non trouvé !");
          },
          () => {}
      );
  }

}
