import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ConfirmBoxEvokeService } from "@costlydeveloper/ngx-awesome-popup";
import { CvDashboardService } from "../cv-dashboard/cv-dashboard.service";
import { TokenStorageService } from "../cv-login-page/token-storage.service";
import { ModeleService } from "./cv-modele.service";
import { Modele } from "./modele";
import { Cv } from "./modeles/cv";

@Component({
    selector: "cv-modele",
    templateUrl: "./cv-modele.component.html",
    styleUrls: ["./cv-modele.component.scss"],
})
export class CvModeleComponent implements OnInit {
    listModele: Array<Modele> = [];
    modele: Modele;

    constructor(
        private modeleService: ModeleService,
        private router: Router,
        private dashboardService: CvDashboardService,
        private tokenStorageService: TokenStorageService,
        private confirmBoxEvokeService: ConfirmBoxEvokeService
    ) {}

    tokenStorage: any;
    icv_cv: any;
    ngOnInit(): void {
        this.tokenStorage = this.tokenStorageService;
        //this.findAll();
        //this.findAllCV();
        this.icv_cv = localStorage.getItem("icv_cv");
    }

    listCV: Array<Cv> = [];
    findAllCV() {
        this.dashboardService.findAll().subscribe(
            (data) => {
                this.listCV = data;
            },
            (error) => {},

            () => {}
        );
    }

    listModele2: Array<any> = [
        {
            id: 1,
            name: "modele1",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 2,
            name: "modele2",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 3,
            name: "modele3",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 4,
            name: "modele4",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 5,
            name: "modele5",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 6,
            name: "modele6",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 7,
            name: "modele7",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 8,
            name: "modele8",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 9,
            name: "modele9",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 10,
            name: "modele10",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 11,
            name: "modele11",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 12,
            name: "modele12",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 13,
            name: "modele13",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 14,
            name: "modele14",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 15,
            name: "modele15",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 16,
            name: "modele16",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 17,
            name: "modele17",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 18,
            name: "modele18",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 19,
            name: "modele19",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 20,
            name: "modele20",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 21,
            name: "modele21",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 22,
            name: "modele22",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 23,
            name: "modele23",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
        {
            id: 24,
            name: "modele24",
            firstcolor: "",
            secondcolor: "",
            thirdcolor: "",
            pagecolor: "",
            fontfamily: "",
            flag: "1",
        },
    ];

    findAll() {
        this.modeleService.findAll().subscribe(
            (data) => {
                this.listModele = data;
            },
            (error) => {
                let link = ["/se-connecter"];
                this.confirmBoxEvokeService
                    .danger("Se connecter", "Veuillez-vous connecter !", "Ok")
                    .subscribe((resp) => this.router.navigate(link));
            },
            () => {}
        );
    }

    findOne(id: number) {
        this.modeleService.findOne(id).subscribe(
            (data) => {
                this.modele = data;
            },
            (error) => {
                let link = [""];
                this.confirmBoxEvokeService
                    .danger("Erreur", "Retour à la page d'accueil !", "Ok")
                    .subscribe((resp) => this.router.navigate(link));
            },
            () => {}
        );
    }

    addCv(idModele): void {
        if (this.tokenStorageService.getToken() === null) {
            let link = ["se-connecter"];
            this.router.navigate(link);
        } else {
            if (this.listCV.length < 6) {
                let link = ["modeles/m" + idModele + "-add"];
                this.router.navigate(link);
            } else {
                let link = ["cvs"];
                this.confirmBoxEvokeService
                    .info(
                        "Info",
                        "Vous avez déjà atteint le nombre maximal (6) de CV",
                        "Ok"
                    )
                    .subscribe((resp) => this.router.navigate(link));
            }
        }
    }
}
