<app-header-style-one></app-header-style-one>

<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Modèles</li>
            </ul>
            <h2>Choisir un modèle</h2>
        </div>
    </div>
</div>

<div class="courses-area ptb-100">
    <div class="container">
        <div class="courses-topbar">
            <div class="row align-items-center">





            </div>
        </div>



        <div class="row">

            <div class="col-lg-3 col-md-4 col-sm-6" *ngFor=" let modele of listModele2">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a *ngIf="modele.id===1" style="border: 1px solid #cccc; " (click)="addCv(1)"
                            class="d-block"><img src="assets/images/icons-modeles/1.png" alt="image"></a>
                        <a *ngIf="modele.id===2" style="border: 1px solid #cccc;" (click)="addCv(2)"
                            class="d-block"><img src="assets/images/icons-modeles/2.png" alt="image"></a>
                        <a *ngIf="modele.id===3" style="border: 1px solid #cccc;" (click)="addCv(3)"
                            class="d-block"><img src="assets/images/icons-modeles/3.png" alt="image"></a>
                        <a *ngIf="modele.id===4" style="border: 1px solid #cccc;" (click)="addCv(4)"
                            class="d-block"><img src="assets/images/icons-modeles/4.png" alt="image"></a>
                        <a *ngIf="modele.id===5" style="border: 1px solid #cccc;" (click)="addCv(5)"
                            class="d-block"><img src="assets/images/icons-modeles/5.png" alt="image"></a>
                        <a *ngIf="modele.id===6" style="border: 1px solid #cccc;" (click)="addCv(6)"
                            class="d-block"><img src="assets/images/icons-modeles/6.png" alt="image"></a>
                        <a *ngIf="modele.id===7" style="border: 1px solid #cccc;" (click)="addCv(7)"
                            class="d-block"><img src="assets/images/icons-modeles/7.png" alt="image"></a>
                        <a *ngIf="modele.id===8" style="border: 1px solid #cccc;" (click)="addCv(8)"
                            class="d-block"><img src="assets/images/icons-modeles/8.png" alt="image"></a>
                        <a *ngIf="modele.id===9" style="border: 1px solid #cccc;" (click)="addCv(9)"
                            class="d-block"><img src="assets/images/icons-modeles/9.png" alt="image"></a>
                        <a *ngIf="modele.id===10" style="border: 1px solid #cccc;" (click)="addCv(10)"
                            class="d-block"><img src="assets/images/icons-modeles/10.png" alt="image"></a>
                        <a *ngIf="modele.id===11" style="border: 1px solid #cccc;" (click)="addCv(11)"
                            class="d-block"><img src="assets/images/icons-modeles/11.png" alt="image"></a>
                        <a *ngIf="modele.id===12" style="border: 1px solid #cccc;" (click)="addCv(12)"
                            class="d-block"><img src="assets/images/icons-modeles/12.png" alt="image"></a>
                        <a *ngIf="modele.id===13" style="border: 1px solid #cccc;" (click)="addCv(13)"
                            class="d-block"><img src="assets/images/icons-modeles/13.png" alt="image"></a>
                        <a *ngIf="modele.id===14" style="border: 1px solid #cccc;" (click)="addCv(14)"
                            class="d-block"><img src="assets/images/icons-modeles/14.png" alt="image"></a>
                        <a *ngIf="modele.id===15" style="border: 1px solid #cccc;" (click)="addCv(15)"
                            class="d-block"><img src="assets/images/icons-modeles/15.png" alt="image"></a>
                        <a *ngIf="modele.id===16" style="border: 1px solid #cccc;" (click)="addCv(16)"
                            class="d-block"><img src="assets/images/icons-modeles/16.png" alt="image"></a>

                        <a *ngIf="modele.id===17" style="border: 1px solid #cccc;" (click)="addCv(17)"
                            class="d-block"><img src="assets/images/icons-modeles/17.png" alt="image"></a>

                        <a *ngIf="modele.id===18" style="border: 1px solid #cccc;" (click)="addCv(18)"
                            class="d-block"><img src="assets/images/icons-modeles/18.png" alt="image"></a>

                        <a *ngIf="modele.id===19" style="border: 1px solid #cccc;" (click)="addCv(19)"
                            class="d-block"><img src="assets/images/icons-modeles/19.png" alt="image"></a>

                        <a *ngIf="modele.id===20" style="border: 1px solid #cccc;" (click)="addCv(20)"
                            class="d-block"><img src="assets/images/icons-modeles/20.png" alt="image"></a>

                        <a *ngIf="modele.id===21" style="border: 1px solid #cccc;" (click)="addCv(21)"
                            class="d-block"><img src="assets/images/icons-modeles/21.png" alt="image"></a>

                        <a *ngIf="modele.id===22" style="border: 1px solid #cccc;" (click)="addCv(22)"
                            class="d-block"><img src="assets/images/icons-modeles/22.png" alt="image"></a>

                        <a *ngIf="modele.id===23" style="border: 1px solid #cccc;" (click)="addCv(23)"
                            class="d-block"><img src="assets/images/icons-modeles/23.png" alt="image"></a>

                        <a *ngIf="modele.id===24" style="border: 1px solid #cccc;" (click)="addCv(24)"
                            class="d-block"><img src="assets/images/icons-modeles/24.png" alt="image"></a>

                        <div class="courses-tag">
                            <a *ngIf="modele.id!==17" (click)="addCv(modele.id)" class="d-block">Cliquez pour
                                choisir</a>
                        </div>
                    </div>

                </div>
            </div>


        </div>


    </div>
</div>