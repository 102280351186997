<div class="offer-area pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Captivez l'attention</span>
            <h2>Créer un CV remarquable en quelques minutes</h2>
            <p>Chaque élément de votre CV doit être travaillé afin que votre employeur se souvienne de vous. Captivez le plus d'attention possible pour passer à l'étape suivante.
            </p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-book-reader'></i>
                    </div>
                    <h3>Conseiller pratique</h3>
                    <p>Bénéficiez des conseils pratiques sur la rédactions de votre CV, car il est important de distinguer ce qui est crucial de ce qui est superflu dans un CV.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-target-lock'></i>
                    </div>
                    <h3>Notre objectif</h3>
                    <p>iCV a été conçu pour vous aider à améliorer vos candidatures en mettant à votre disposition une plate-forme moderne et simple d'utilisation pour la création de vos CV.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-sun'></i>
                    </div>
                    <h3>Exprimez votre créativité</h3>
                    <p>Chacun de nos modèles dispose de 24 thèmes proposés avec presque une infinité de possibilités sur la personnalisation des couleurs. Vous pouvez adopter un style qui rime à votre cible.</p>
                </div>
            </div>
        </div>
    </div>
</div>
