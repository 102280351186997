<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Accueil</a></li>
                <li>Lettre de motivation</li>
            </ul>
            <h2>Lettre de motivation</h2>
        </div>
    </div>
</div>


<div class="courses-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <h2>Créer une lettre de motivation</h2>
            <p>iCV vous permet également de créer ou générer une lettre de motivation, créez une lettre de motivation à partir de zéro ou choisissez parmi nos modèles
                de lettre de motivation. Trouvez des modèles en fonction de votre métier et fonction de votre type de demande.</p>

             <!--
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-code-alt'></i>
                    </div>
                    <h3>Web Development</h3>
                    <span>60 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-camera'></i>
                    </div>
                    <h3>Photography </h3>
                    <span>21 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-layer'></i>
                    </div>
                    <h3>Graphics Design</h3>
                    <span>58 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bxs-flag-checkered'></i>
                    </div>
                    <h3>Web Language</h3>
                    <span>99 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-health'></i>
                    </div>
                    <h3>Health & Fitness</h3>
                    <span>21 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-line-chart'></i>
                    </div>
                    <h3>Business Studies</h3>
                    <span>49 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-bar-chart-alt-2'></i>
                    </div>
                    <h3>Humanities</h3>
                    <span>21 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bxs-drink'></i>
                    </div>
                    <h3>Science</h3>
                    <span>12 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>
            -->
        </div>
    </div>
</div>


<app-become-instructor-partner></app-become-instructor-partner>
