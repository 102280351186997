<loading *ngIf="isLoading && cv">{{ isLoading }}</loading>


<div id="cvt"  class="container-fluid" style="height: 100%; width: 100%;" *ngIf="this.cv">
    <div class="row" >


        <div id="cvt1" class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="height: 100%;  ">

            <cv-notice2> </cv-notice2>

        </div>

        <!--   CV TEMPLATE -->

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

            <div class="container">
                <div class="user-actions" style="background: #47494b; padding-top : 7px; padding-bottom: 7px; height : 50px">


                    <div id="content_2" class="row">


                        <div style="visibility: hidden; max-height: 3px;"  class="col-lg-1 col-md-1 col-sm-1 icon1">
                            <div #iconselect2  id="my-icon-select2" style="margin: 0;"></div>

                            <input #selectedText2 type="text" id="selected-text2" name="selected-text2"
                                style="width:65px; height:2px; visibility: hidden;">
                        </div>


                        <div style="visibility: hidden; max-height: 3px;"  class="col-lg-1 col-md-1 col-sm-1 icon1" >
                            <div #iconselect (click)="onChangeTheme()" id="my-icon-select" style="margin: 0;"></div>
                            <label style="margin-left:3px ; color: #fff; font-weight: 400;">
                                {{'themes' |
                                translate}}
                            </label>
                            <input #selectedText type="text" id="selected-text" name="selected-text"
                                style="width:65px; height: 3px; visibility: hidden;">
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2"></div>


                        <div class="col-lg-4 col-md-4 col-sm-4"  >
                            <button *ngIf="isOpen" type="button" class=" form-control btn btn-sm btn-secondary"
                            (click)="useExample(cv)"
                            style="padding: 7px; font-size: 14px; color: #fff; background: #003147; height: 33px;">
                            <i style="color: #fff; font-size: 15px;" class="bx bxs-duplicate"></i>
                            {{'usethiscv' | translate}}
                        </button>
                        <button *ngIf="!isOpen" type="button" class=" form-control btn "
                        style="padding: 7px; font-size: 12px;  height: 30px;width: 30px;">
                        <span role="status" style="color: #003147;"
                            class="spinner-border spinner-danger spinner-border-sm"></span>
                    </button>
                        </div>

                        <div class="col-lg-2 col-md-2 col-sm-2"></div>

                        <div class="col-lg-2 col-md-2 col-sm-2"></div>


                    </div>


                </div>


            </div>

            <form [formGroup]="cvFormGroup" style="z-index : -1">
                <div id="cvt2" class="div_body" [ngClass]="family1">
                    <div class="marge cvt20">

                        <!--  START PAGE 1-->



                        <div id="content1" class="resume">

                            <div id="left_side1" class="resume_left" [style.background]="firstcolor">

                                <div class="resume_profile">
                                    <img *ngIf="!imageToShow && imgURL" [src]="imgURL" alt="profile_pic">
                                    <img *ngIf="imageToShow" [src]="imageToShow" alt="profile_pic">

                                    <div class="text2" id="text2" [style.background]="secondcolor">
                                        <span [style.color]="pagecolor">{{this.cvFormGroup.get("firstname").value+"
                                            "+this.cvFormGroup.get("lastname").value}}</span>

                                    </div>
                                </div>
                                <div
                                    style="background-color: aliceblue; font-size: 17px; color: black; width: 280px; text-align: center; font-weight: bold; margin-bottom: 3px;">
                                    {{this.cvFormGroup.get("profession").value}}
                                </div>

                                <div class="resume_content">
                                    <div class="resume_item resume_info">

                                        <ul>

                                            <li *ngIf="cvFormGroup.get('telephone').value.trim()">
                                                <div class="icon">
                                                    <img style="margin-left: 7px; margin-top: 3px;"
                                                        src="assets/img/phone8.png" width="17" height="17">
                                                </div>
                                                <div class="data">
                                                    <span
                                                        *ngIf="this.cvFormGroup.get('telephone').value.includes('U2FsdG')">
                                                        {{cvFormGroup.get('telephone').value | decrypted}}</span>
                                                    <span
                                                        *ngIf="!this.cvFormGroup.get('telephone').value.includes('U2FsdG')">
                                                        {{cvFormGroup.get('telephone').value | encrypted |
                                                        decrypted}}</span>

                                                </div>
                                            </li>
                                            <li *ngIf="cvFormGroup.get('email').value.trim()">
                                                <div class="icon">
                                                    <img id="ic1" src="assets/img/env2.png" width="17" height="17"
                                                        style="margin-left: 7px; margin-top: 9px;">
                                                </div>
                                                <div class="data">
                                                    {{cvFormGroup.get('email').value}}
                                                </div>
                                            </li>
                                            <li>
                                                <div *ngIf="cvFormGroup.get('linkedin').value.trim()" class="icon">
                                                    <img src="assets/img/linkedin4.png" width="15" height="15"
                                                        style="margin-left: 9px; margin-top: 7px;">
                                                </div>
                                                <div class="data">
                                                    {{cvFormGroup.get('linkedin').value}}
                                                </div>
                                            </li>
                                            <li *ngIf="cvFormGroup.get('webSite').value.trim()">
                                                <div class="icon" style="padding: 5px 0 0 8px">
                                                    <img src="assets/img/globe2.png" width="15" height="15">
                                                </div>
                                                <div class="data">
                                                    {{cvFormGroup.get('webSite').value}}
                                                </div>
                                            </li>
                                            <li *ngIf="cvFormGroup.get('address').value.trim() || cvFormGroup.get('city').value.trim()">
                                                <div class="icon">
                                                    <img id="ic2" src="assets/img/map2.png" width="17" height="17"
                                                        style="margin-left: 7px; margin-top: 9px;">
                                                </div>
                                                <div class="data">
                                                    <span
                                                        *ngIf="this.cvFormGroup.get('address').value.includes('U2FsdG')">
                                                        {{cvFormGroup.get('address').value | decrypted}}</span>
                                                    <span
                                                        *ngIf="!this.cvFormGroup.get('address').value.includes('U2FsdG')">
                                                        {{cvFormGroup.get('address').value | encrypted |
                                                        decrypted}}</span>
                                                    <br /> {{cvFormGroup.get('city').value}}
                                                </div>
                                            </li>

                                            <li *ngIf="cvFormGroup.get('birth').value.trim()">
                                                <div class="icon">
                                                    <img id="ic2" src="assets/img/birth2.png" width="17" height="17"
                                                        style="margin-left: 7px; margin-top: 7px;">
                                                </div>
                                                <div class="data">
                                                    {{'birth' | translate}} : {{cvFormGroup.get('birth').value}}
                                                </div>
                                            </li>

                                            <li *ngIf="cvFormGroup.get('marital').value.trim()">
                                                <div class="icon">
                                                    <img id="ic2" src="assets/img/marital2.png" width="17" height="17"
                                                        style="margin-left: 7px; margin-top: 10px;">
                                                </div>
                                                <div class="data">
                                                    {{cvFormGroup.get('marital').value}}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div *ngIf="cvFormGroup.get('skills').value.length" formArrayName="skills"
                                        class="resume_item resume_skills">
                                        <div class="title">
                                            <p class="bold"> {{'skills' | translate}}</p>
                                        </div>
                                        <ul>
                                            <li *ngFor="let skill of cvSkills().controls; let skillIndex=index"
                                                [formGroupName]="skillIndex">

                                                <div class="skill_name">
                                                    {{cvSkills().at(skillIndex).get('item').value}}
                                                </div>
                                                <div class="skill_progress">
                                                    <span [style.background]="pagecolor"
                                                        [style.width]="cvSkills().at(skillIndex).get('level').value"></span>
                                                </div>

                                            </li>


                                        </ul>
                                    </div>
                                    <div *ngIf="cvFormGroup.get('languages').value.length" formArrayName="languages"
                                        class="resume_item resume_skills">
                                        <div class="title">
                                            <p class="bold"> {{'languages' | translate}}</p>
                                        </div>
                                        <ul>
                                            <li *ngFor="let lang of cvLanguages().controls; let langIndex=index"
                                                [formGroupName]="langIndex">

                                                <div class="skill_name">
                                                    {{cvLanguages().at(langIndex).get('item').value}}
                                                </div>
                                                <div class="skill_progress">
                                                    <span [style.background]="pagecolor"
                                                        [style.width]="cvLanguages().at(langIndex).get('level').value"></span>
                                                </div>
                                                <div class="skill_per">
                                                    {{cvLanguages().at(langIndex).get('level').value}}</div>

                                            </li>


                                        </ul>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('quality1').value.trim() || cvFormGroup.get('quality2').value.trim() ||
                                    cvFormGroup.get('quality3').value.trim() || cvFormGroup.get('quality4').value.trim()"
                                        class="resume_item resume_skills">
                                        <div class="title">
                                            <p class="bold"> {{'qualities' | translate}}</p>
                                        </div>
                                        <ul>
                                            <li>
                                                <div class="quality">
                                                    {{cvFormGroup.get('quality1').value}}
                                                </div>

                                                <div class="quality">
                                                    {{cvFormGroup.get('quality2').value}}
                                                </div>
                                            </li>
                                            <li>
                                                <div class="quality">
                                                    {{cvFormGroup.get('quality3').value}}
                                                </div>

                                                <div class="quality"> {{cvFormGroup.get('quality4').value}}</div>
                                            </li>

                                        </ul>
                                    </div>
                                    <div *ngIf="cvFormGroup.get('hobby1').value.trim() || cvFormGroup.get('hobby2').value.trim() ||
                                    cvFormGroup.get('hobby3').value.trim() || cvFormGroup.get('hobby4').value.trim()"
                                        class="resume_item resume_skills">
                                        <div class="title">
                                            <p class="bold"> {{'hobbies' | translate}}</p>
                                        </div>
                                        <ul>
                                            <li>
                                                <div class="quality">
                                                    {{cvFormGroup.get('hobby1').value}}
                                                </div>

                                                <div class="quality">{{cvFormGroup.get('hobby2').value}}</div>
                                            </li>
                                            <li>
                                                <div class="quality">
                                                    {{cvFormGroup.get('hobby3').value}}
                                                </div>

                                                <div class="quality">{{cvFormGroup.get('hobby4').value}}</div>
                                            </li>

                                        </ul>
                                    </div>
                                    <div *ngIf="cvFormGroup.get('references').value.length" formArrayName="references"
                                        class="resume_item resume_social">
                                        <div class="title">
                                            <p class="bold"> {{'references' | translate}}</p>
                                        </div>
                                        <ul>
                                            <li *ngFor="let certif of cvReferences().controls; let refIndex=index">

                                                <div [formGroupName]="refIndex" class="data">
                                                    <p style="color: #dfd5d5;" class="semi-bold">
                                                        {{cvReferences().at(refIndex).get('fullname').value}}</p>
                                                    <p style="color: #dfd5d5;"><strong>
                                                            {{cvReferences().at(refIndex).get('organisation').value}}
                                                        </strong></p>
                                                    <p style="color: #dfd5d5;"
                                                        *ngIf="cvReferences().at(refIndex).get('telephone').value.trim()">
                                                        <strong>Tel : </strong>
                                                        {{cvReferences().at(refIndex).get('telephone').value}} </p>
                                                    <p style="color: #dfd5d5;"
                                                        *ngIf="cvReferences().at(refIndex).get('email').value.trim()">
                                                        <strong>Email : </strong>
                                                        {{cvReferences().at(refIndex).get('email').value}} </p>


                                                </div>
                                                <p style="color: #fff; font-size: 3px;">_</p>
                                            </li>


                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="resume_right" id="right_side1">
                                <div class="resume_item resume_about"
                                    style="margin-top: -33px;  ">
                                    <div class="title">
                                        <p *ngIf="cvFormGroup.get('profile').value.trim()" class="bold"> {{'profile' |
                                            translate}}</p>
                                    </div>
                                    <p style="font-size: 12px; word-wrap: break-word; margin-top: -7px;">

                                        {{this.cvFormGroup.get("profile").value}}
                                    </p>
                                </div>

                                <!-- EDUCATION BEGINNER -->
                                <div *ngIf="cvFormGroup.get('educations').value.length && isBeginner" formArrayName="educations"
                                    class="resume_item resume_education">
                                    <div class="title">
                                        <p class="bold">Education</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let education of cvEducations().controls; let educationIndex=index"
                                            [formGroupName]="educationIndex">

                                            <div class="info">
                                                <p class="semi-bold">
                                                    <strong class="date">{{cvEducations().at(educationIndex).get('period').value}}
                                                    </strong>
                                                    <strong style="margin-left: 7px;"> | </strong>
                                                    <strong style="margin-right: 7px;"  [style.color]="firstcolor">
                                                        {{cvEducations().at(educationIndex).get('degree').value}}
                                                    </strong>
                                                </p>
                                                <p> <strong style="text-transform: uppercase; font-size: 15px;">
                                                        {{cvEducations().at(educationIndex).get('institute').value}}</strong><strong style="margin-left: 7px;"> | </strong>
                                                        <strong style="margin-right: 7px;">{{cvEducations().at(educationIndex).get('city').value}}</strong>
                                                </p>
                                            </div>
                                            <p style="color: #fff; font-size: 3px;">_</p>
                                        </li>

                                    </ul>

                                </div>

                                <!-- EXPERIENCE PROFESSIONAL -->
                                <div *ngIf="cvFormGroup.get('experiences').value.length && isProfessional" formArrayName="experiences"
                                    class="resume_item resume_work">
                                    <div class="title">
                                        <p class="bold"> {{'experiences' | translate}}</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let experience of cvExperiences().controls; let experienceIndex=index"
                                            [formGroupName]="experienceIndex">

                                            <div class="info">
                                                <p class="semi-bold">

                                                    <strong style="text-transform: uppercase; font-size: 15px;">
                                                        {{cvExperiences().at(experienceIndex).get('company').value}}</strong>
                                                    <strong style="margin-left: 7px;"> | </strong>
                                                    <strong style="margin-left: 7px;"  [style.color]="firstcolor">
                                                        {{cvExperiences().at(experienceIndex).get('function').value}}
                                                    </strong>
                                                </p>
                                                <p>
                                                    <strong class="date">
                                                        {{cvExperiences().at(experienceIndex).get('period').value}}</strong>
                                                        <strong style="margin-left: 7px;"> | </strong>
                                                    <span style="margin-left: 7px;"> {{cvExperiences().at(experienceIndex).get('city').value}}</span>
                                                </p>
                                                <p style="font-size: 12px; word-wrap: break-word;"
                                                    [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">

                                                </p>
                                            </div>
                                            <p style="color: #fff; font-size: 3px;">_</p>
                                        </li>


                                    </ul>
                                </div>


                                <div *ngIf="cvFormGroup.get('certifications').value.length"
                                    formArrayName="certifications" class="resume_item resume_education">
                                    <div class="title">
                                        <p class="bold"> {{'certificates' | translate}}</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let certif of cvCertifications().controls; let certifIndex=index"
                                            [formGroupName]="certifIndex">
                                            <div class="date">
                                                {{cvCertifications().at(certifIndex).get('date').value}}
                                                <strong style="margin-left: 7px;"> | </strong>
                                                <strong style="margin-right: 7px;"  [style.color]="firstcolor">
                                                    {{cvCertifications().at(certifIndex).get('certificat').value}}</strong>
                                            </div>
                                            <div class="info">

                                                <p> <strong style="text-transform: uppercase; font-size: 15px;">
                                                        {{cvCertifications().at(certifIndex).get('institute').value}}</strong><span></span>
                                                </p>
                                            </div>
                                            <p style="color: #fff; font-size: 3px;">_</p>
                                        </li>

                                    </ul>

                                </div>

                                <div *ngIf="cvFormGroup.get('formations').value.length" formArrayName="formations"
                                    class="resume_item resume_work">
                                    <div class="title">
                                        <p class="bold">Formations</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let formation of cvFormations().controls; let formationIndex=index"
                                            [formGroupName]="formationIndex">
                                            <div class="date">
                                                {{cvFormations().at(formationIndex).get('period').value}}
                                                <strong style="margin-left: 7px;"> | </strong>
                                                <strong style="margin-right: 7px;"  [style.color]="firstcolor">
                                                    {{cvFormations().at(formationIndex).get('title').value}}</strong>

                                            </div>
                                            <div class="info">

                                                <p> <strong style="text-transform: uppercase; font-size: 15px;">
                                                        {{cvFormations().at(formationIndex).get('former').value}}</strong><span>
                                                    </span></p>
                                                <p style="font-size: 12px; word-wrap: break-word;"
                                                    [innerHTML]="cvFormations().at(formationIndex).get('description').value">
                                                </p>
                                            </div>
                                            <p style="color: #fff; font-size: 3px;">_</p>
                                        </li>

                                    </ul>
                                </div>


                                <!-- EDUCATION PROFESSIONAL -->
                                <div *ngIf="cvFormGroup.get('educations').value.length && isProfessional" formArrayName="educations"
                                    class="resume_item resume_education">
                                    <div class="title">
                                        <p class="bold">Education</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let education of cvEducations().controls; let educationIndex=index"
                                        [formGroupName]="educationIndex">

                                        <div class="info">
                                            <p class="semi-bold">
                                                <strong class="date">{{cvEducations().at(educationIndex).get('period').value}}
                                                </strong>
                                                <strong style="margin-left: 7px;"> | </strong>
                                                <strong style="margin-right: 7px;"  [style.color]="firstcolor">
                                                    {{cvEducations().at(educationIndex).get('degree').value}}
                                                </strong>
                                            </p>
                                            <p> <strong style="text-transform: uppercase; font-size: 15px;">
                                                    {{cvEducations().at(educationIndex).get('institute').value}}</strong><strong style="margin-left: 7px;"> | </strong>
                                                    <strong style="margin-right: 7px;">{{cvEducations().at(educationIndex).get('city').value}}</strong>
                                            </p>
                                        </div>
                                        <p style="color: #fff; font-size: 3px;">_</p>
                                    </li>

                                    </ul>

                                </div>

                                <!-- EXPERIENCE BEGINNER -->
                                <div *ngIf="cvFormGroup.get('experiences').value.length && isBeginner" formArrayName="experiences"
                                    class="resume_item resume_work">
                                    <div class="title">
                                        <p class="bold"> {{'experiences' | translate}}</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let experience of cvExperiences().controls; let experienceIndex=index"
                                        [formGroupName]="experienceIndex">

                                        <div class="info">
                                            <p class="semi-bold">

                                                <strong style="text-transform: uppercase; font-size: 15px;">
                                                    {{cvExperiences().at(experienceIndex).get('company').value}}</strong>
                                                <strong style="margin-left: 7px;"> | </strong>
                                                <strong style="margin-left: 7px;" [style.color]="firstcolor" >
                                                    {{cvExperiences().at(experienceIndex).get('function').value}}
                                                </strong>
                                            </p>
                                            <p>
                                                <strong class="date">
                                                    {{cvExperiences().at(experienceIndex).get('period').value}}</strong>
                                                    <strong style="margin-left: 7px;"> | </strong>
                                                <span style="margin-left: 7px;"> {{cvExperiences().at(experienceIndex).get('city').value}}</span>
                                            </p>
                                            <p style="font-size: 12px; word-wrap: break-word;"
                                                [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">

                                            </p>
                                        </div>
                                        <p style="color: #fff; font-size: 3px;">_</p>
                                    </li>


                                    </ul>
                                </div>

                                <!-- RUBRIQUE PERSONNALISEE -->
                                <div *ngIf="cvFormGroup.get('customs').value.length" formArrayName="customs">
                                    <div *ngFor="let custom of cvCustoms().controls; let customIndex=index"
                                        [formGroupName]="customIndex" class="resume_item resume_education">
                                        <div class="title">
                                            <p class="bold">{{cvCustoms().at(customIndex).get('title').value}}</p>
                                        </div>
                                        <ul>
                                            <li>
                                                <div class="info">

                                                    <p style="font-size: 12px; word-wrap: break-word;"
                                                        [innerHTML]="cvCustoms().at(customIndex).get('description').value">
                                                    </p>
                                                </div>
                                                <p style="color: #fff; font-size: 3px;">_</p>
                                            </li>

                                        </ul>

                                    </div>
                                </div>




                            </div>

                        </div>
                        <!--  END PAGE 1-->

                        <!--  START PAGE 2-->


                        <div class="resume" id="content2" #content2>

                            <div id="left_side2" class="resume_left" #left_side2 [style.background]="firstcolor">





                            </div>
                            <div id="right_side2" class="resume_right" #right_side2 style="padding : 0;  "
                                [style.background]="pagecolor">





                            </div>
                        </div>


                        <!--  END PAGE 2-->

                        <!--  START PAGE 3-->


                        <div class="resume" id="content3" #content3>
                            <div id="left_side3" class="resume_left" #left_side3 [style.background]="firstcolor">



                            </div>
                            <div id="right_side3" class="resume_right" #right_side3 style="padding : 0;"
                                [style.background]="pagecolor">

                            </div>
                        </div>


                        <!--  END PAGE 3-->



                    </div>

                </div>
            </form>
        </div>

    </div>
</div>

<!--   FORM ARRAY         -->
