

<div id="cvt" class="container-fluid" style="height: 100%; width: 100%;">
    <div class="row">


        <!--   CV TEMPLATE -->

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">



            <form [formGroup]="cvFormGroup" >
                <div id="cvt2">
                    <div id="cvt10">

                        <!--  START PAGE 1-->



                        <div id="content1" class="resume">

                            <div id="left_side1" class="resume_left">

                                <div *ngIf="imageToShow || imgURL" class="resume_profile">
                                    <img *ngIf="!imageToShow && imgURL" [src]="imgURL" alt="profile_pic">
                                    <img *ngIf="imageToShow" [src]="imageToShow" alt="profile_pic">


                                </div>
                                <div *ngIf="cvFormGroup.get('telephone').value.trim()"
                                    style="background-color: #07223a; font-size: 14px; color: #fff; width: 280px; text-align: left;  padding: 9px;"
                                    [style.background]="firstcolor">
                                    <span style="font-weight: 500;"> {{'phone' | translate}} :</span> <span
                                        *ngIf="this.cvFormGroup.get('telephone').value.includes('U2FsdG')">
                                        {{cvFormGroup.get('telephone').value | decrypted}}</span>
                                    <span *ngIf="!this.cvFormGroup.get('telephone').value.includes('U2FsdG')">
                                        {{cvFormGroup.get('telephone').value | encrypted | decrypted}}</span>
                                </div>
                                <div *ngIf="cvFormGroup.get('email').value.trim()"
                                    style="background-color: #0e2e5e; font-size: 14px; color: #fff; width: 280px; text-align: left;  padding: 9px;"
                                    [style.background]="secondcolor">
                                    <span style="font-weight: 500;"> Email :</span> {{cvFormGroup.get('email').value}}

                                </div>
                                <div *ngIf="cvFormGroup.get('address').value.trim() || cvFormGroup.get('city').value.trim()"
                                    style="background-color: #154d7e; font-size: 14px; color: #fff; width: 280px; text-align: left;  padding: 9px;"
                                    [style.background]="pagecolor">
                                    <span *ngIf="this.cvFormGroup.get('address').value.includes('U2FsdG')">
                                        {{cvFormGroup.get('address').value | decrypted}}</span>
                                    <span *ngIf="!this.cvFormGroup.get('address').value.includes('U2FsdG')">
                                        {{cvFormGroup.get('address').value | encrypted | decrypted}}</span>
                                        {{","+cvFormGroup.get('city').value}}
                                </div>
                                <div
                                    style="background-color: #656868; font-size: 15px; color: #fff; width: 280px; text-align: left;  padding: 9px;">
                                    <p style="color: #fff;" *ngIf="cvFormGroup.get('linkedin').value.trim()">
                                        <strong>Linkedin : </strong><span> {{cvFormGroup.get('linkedin').value}}</span>
                                    </p>
                                    <p style="color: #fff;" *ngIf="cvFormGroup.get('webSite').value.trim()">
                                        <strong>Site web : </strong><span> {{cvFormGroup.get('webSite').value}}</span>
                                    </p>
                                    <p style="color: #fff;" *ngIf="cvFormGroup.get('birth').value.trim()"> <span>Né(e)
                                            le {{cvFormGroup.get('birth').value}} </span> </p>
                                    <p style="color: #fff;" *ngIf="cvFormGroup.get('marital').value.trim()"> <span>
                                            {{cvFormGroup.get('marital').value}} </span> </p>
                                </div>


                                <div class="resume_content">

                                    <div *ngIf="cvFormGroup.get('skills').value.length" formArrayName="skills"
                                        class="resume_item resume_skills">
                                        <div class="title">
                                            <p class="bold">{{'skills' | translate}}</p>
                                        </div>
                                        <ul>
                                            <li *ngFor="let skill of cvSkills().controls; let skillIndex=index"
                                                style="margin-top: -7px;">

                                                <div [formGroupName]="skillIndex" class="skill_name">
                                                    {{cvSkills().at(skillIndex).get('item').value}}
                                                </div>
                                                <div [formGroupName]="skillIndex" class="skill_progress">
                                                    <span [style.width]="cvSkills().at(skillIndex).get('level').value"
                                                        [style.background]="firstcolor"></span>
                                                </div>

                                            </li>



                                        </ul>
                                    </div>
                                    <div *ngIf="cvFormGroup.get('languages').value.length" formArrayName="languages"
                                        class="resume_item resume_skills" style="margin-top: -33px;">
                                        <div class="title">
                                            <p class="bold">{{'languages' | translate}}</p>
                                        </div>
                                        <ul>
                                            <li *ngFor="let lang of cvLanguages().controls; let langIndex=index"
                                                [formGroupName]="langIndex" style="margin-top: -7px;">

                                                <div class="skill_name">
                                                    {{cvLanguages().at(langIndex).get('item').value}}
                                                </div>
                                                <div class="skill_progress">
                                                    <span [style.width]="cvLanguages().at(langIndex).get('level').value"
                                                        [style.background]="firstcolor"></span>
                                                </div>


                                            </li>


                                        </ul>
                                    </div>

                                    <div *ngIf="cvFormGroup.get('quality1').value.trim() || cvFormGroup.get('quality2').value.trim() ||
                                    cvFormGroup.get('quality3').value.trim() || cvFormGroup.get('quality4').value.trim()"
                                        class="resume_item resume_skills" style="margin-top: -33px;">
                                        <div class="title">
                                            <p class="bold">{{'qualities' | translate}}</p>
                                        </div>
                                        <ul>
                                            <li>
                                                <div class="quality">
                                                    {{cvFormGroup.get('quality1').value}}
                                                </div>

                                                <div class="quality">
                                                    {{cvFormGroup.get('quality2').value}}
                                                </div>
                                            </li>
                                            <li>
                                                <div class="quality">
                                                    {{cvFormGroup.get('quality3').value}}
                                                </div>

                                                <div class="quality"> {{cvFormGroup.get('quality4').value}}</div>
                                            </li>

                                        </ul>
                                    </div>
                                    <div *ngIf="cvFormGroup.get('hobby1').value.trim() || cvFormGroup.get('hobby2').value.trim() ||
                                    cvFormGroup.get('hobby3').value.trim() || cvFormGroup.get('hobby4').value.trim()"
                                        class="resume_item resume_skills" style="margin-top: -27px;">
                                        <div class="title">
                                            <p class="bold">{{'hobbies' | translate}}</p>
                                        </div>
                                        <ul>
                                            <li>
                                                <div class="quality">
                                                    {{cvFormGroup.get('hobby1').value}}
                                                </div>

                                                <div class="quality">{{cvFormGroup.get('hobby2').value}}</div>
                                            </li>
                                            <li>
                                                <div class="quality">
                                                    {{cvFormGroup.get('hobby3').value}}
                                                </div>

                                                <div class="quality">{{cvFormGroup.get('hobby4').value}}</div>
                                            </li>

                                        </ul>
                                    </div>
                                    <div *ngIf="cvFormGroup.get('references').value.length" formArrayName="references"
                                        class="resume_item resume_social" style="margin-top: -33px;">
                                        <div class="title">
                                            <p class="bold">{{'references' | translate}}</p>
                                        </div>
                                        <ul style="margin-top: -7px;">
                                            <li *ngFor="let certif of cvReferences().controls; let refIndex=index">

                                                <div [formGroupName]="refIndex" class="data">
                                                    <p style="color: #3a3838;" class="semi-bold">
                                                        {{cvReferences().at(refIndex).get('fullname').value}}</p>
                                                    <p><strong style="color: #3a3838; margin-top: -12px;">
                                                            {{cvReferences().at(refIndex).get('organisation').value}}
                                                        </strong></p>
                                                    <p style="color: #3a3838;"
                                                        *ngIf="cvReferences().at(refIndex).get('telephone').value.trim()">
                                                        <strong>Tel : </strong>
                                                        {{cvReferences().at(refIndex).get('telephone').value}} </p>
                                                    <p style="color: #3a3838;"
                                                        *ngIf="cvReferences().at(refIndex).get('email').value.trim()">
                                                        <strong>Email : </strong>
                                                        {{cvReferences().at(refIndex).get('email').value}} </p>


                                                </div>
                                            </li>


                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="resume_right" id="right_side1">
                                <div class="resume_item resume_about" style="margin-bottom: -27px; margin-top: -12px;">
                                    <div class="">

                                        <span
                                            style="font-size: 33px; color: #0e2e5e; text-transform: uppercase; margin-top: -3px; font-weight: 600;"
                                            [style.color]="secondcolor"> {{this.cvFormGroup.get("firstname").value+"
                                            "+this.cvFormGroup.get("lastname").value}}</span>
                                        <p
                                            style="font-size: 15px; margin-top: 1px; color: #3d4246; text-transform: uppercase;">
                                            {{this.cvFormGroup.get("profession").value}}</p>
                                    </div>
                                    <br>
                                    <p style="font-size: 12px; word-wrap: break-word;">

                                        {{this.cvFormGroup.get("profile").value}}
                                    </p>
                                </div>

                                <!-- EDUCATION BEGINNER -->
                                <div *ngIf="cvFormGroup.get('educations').value.length && isBeginner" formArrayName="educations"
                                    class="resume_item resume_education" style="margin-bottom: -27px;" >
                                    <div class="title">
                                        <p class="bold">Education</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let education of cvEducations().controls; let educationIndex=index"
                                            [formGroupName]="educationIndex">

                                            <div class="info">
                                                <p class="semi-bold">
                                                    <strong class="date">{{cvEducations().at(educationIndex).get('period').value}}
                                                    </strong>
                                                    <strong style="margin-left: 7px;"> | </strong>
                                                    <strong style="margin-right: 7px;" [style.color]="secondcolor">
                                                        {{cvEducations().at(educationIndex).get('degree').value}}
                                                    </strong>
                                                </p>
                                                <p> <strong style="text-transform: uppercase; font-size: 15px;">
                                                        {{cvEducations().at(educationIndex).get('institute').value}}</strong><strong style="margin-left: 7px;"> | </strong>
                                                        <strong style="margin-right: 7px;">{{cvEducations().at(educationIndex).get('city').value}}</strong>
                                                </p>
                                            </div>
                                            <p style="color: #fff; font-size: 3px;">_</p>
                                        </li>

                                    </ul>

                                </div>

                                <!-- EXPERIENCE PROFESSIONAL -->
                                <div *ngIf="cvFormGroup.get('experiences').value.length && isProfessional" formArrayName="experiences"
                                    class="resume_item resume_work" style="margin-bottom: -27px; ">
                                    <div class="title">
                                        <p class="bold"> {{'experiences' | translate}}</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let experience of cvExperiences().controls; let experienceIndex=index"
                                            [formGroupName]="experienceIndex">

                                            <div class="info">
                                                <p class="semi-bold">

                                                    <strong style="text-transform: uppercase; font-size: 15px;">
                                                        {{cvExperiences().at(experienceIndex).get('company').value}}</strong>
                                                    <strong style="margin-left: 7px;"> | </strong>
                                                    <strong style="margin-left: 7px;" [style.color]="secondcolor">
                                                        {{cvExperiences().at(experienceIndex).get('function').value}}
                                                    </strong>
                                                </p>
                                                <p>
                                                    <strong class="date">
                                                        {{cvExperiences().at(experienceIndex).get('period').value}}</strong>
                                                        <strong style="margin-left: 7px;"> | </strong>
                                                    <span style="margin-left: 7px;"> {{cvExperiences().at(experienceIndex).get('city').value}}</span>
                                                </p>
                                                <p style="font-size: 12px; word-wrap: break-word;"
                                                    [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">

                                                </p>
                                            </div>
                                            <p style="color: #fff; font-size: 3px;">_</p>
                                        </li>


                                    </ul>
                                </div>


                                <div *ngIf="cvFormGroup.get('certifications').value.length"
                                    formArrayName="certifications" class="resume_item resume_education" style="margin-bottom: -27px; " >
                                    <div class="title">
                                        <p class="bold"> {{'certificates' | translate}}</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let certif of cvCertifications().controls; let certifIndex=index"
                                            [formGroupName]="certifIndex">
                                            <div class="date">
                                                {{cvCertifications().at(certifIndex).get('date').value}}
                                                <strong style="margin-left: 7px;"> | </strong>
                                                <strong style="margin-left: 7px;" [style.color]="secondcolor">
                                                    {{cvCertifications().at(certifIndex).get('certificat').value}}</strong>
                                            </div>
                                            <div class="info">

                                                <p> <strong style="text-transform: uppercase; font-size: 15px;">
                                                        {{cvCertifications().at(certifIndex).get('institute').value}}</strong><span></span>
                                                </p>
                                            </div>
                                            <p style="color: #fff; font-size: 3px;">_</p>
                                        </li>

                                    </ul>

                                </div>

                                <div *ngIf="cvFormGroup.get('formations').value.length" formArrayName="formations"
                                    class="resume_item resume_work" style="margin-bottom: -27px; ">
                                    <div class="title">
                                        <p class="bold">Formations</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let formation of cvFormations().controls; let formationIndex=index"
                                            [formGroupName]="formationIndex">
                                            <div class="date">
                                                {{cvFormations().at(formationIndex).get('period').value}}
                                                <strong style="margin-left: 7px;"> | </strong>
                                                <strong style="margin-left: 7px;" [style.color]="secondcolor">
                                                    {{cvFormations().at(formationIndex).get('title').value}}</strong>

                                            </div>
                                            <div class="info">

                                                <p> <strong style="text-transform: uppercase; font-size: 15px;">
                                                        {{cvFormations().at(formationIndex).get('former').value}}</strong><span>
                                                    </span></p>
                                                <p style="font-size: 12px; word-wrap: break-word;"
                                                    [innerHTML]="cvFormations().at(formationIndex).get('description').value">
                                                </p>
                                            </div>
                                            <p style="color: #fff; font-size: 3px;">_</p>
                                        </li>

                                    </ul>
                                </div>


                                <!-- EDUCATION PROFESSIONAL -->
                                <div *ngIf="cvFormGroup.get('educations').value.length && isProfessional" formArrayName="educations"
                                    class="resume_item resume_education" style="margin-bottom: -27px; ">
                                    <div class="title">
                                        <p class="bold">Education</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let education of cvEducations().controls; let educationIndex=index"
                                        [formGroupName]="educationIndex">

                                        <div class="info">
                                            <p class="semi-bold">
                                                <strong class="date">{{cvEducations().at(educationIndex).get('period').value}}
                                                </strong>
                                                <strong style="margin-left: 7px;"> | </strong>
                                                <strong style="margin-left: 7px;" [style.color]="secondcolor">
                                                    {{cvEducations().at(educationIndex).get('degree').value}}
                                                </strong>
                                            </p>
                                            <p> <strong style="text-transform: uppercase; font-size: 15px;">
                                                    {{cvEducations().at(educationIndex).get('institute').value}}</strong><strong style="margin-left: 7px;"> | </strong>
                                                    <strong style="margin-right: 7px;">{{cvEducations().at(educationIndex).get('city').value}}</strong>
                                            </p>
                                        </div>
                                        <p style="color: #fff; font-size: 3px;">_</p>
                                    </li>

                                    </ul>

                                </div>

                                <!-- EXPERIENCE BEGINNER -->
                                <div *ngIf="cvFormGroup.get('experiences').value.length && isBeginner" formArrayName="experiences"
                                    class="resume_item resume_work" style="margin-bottom: -27px; ">
                                    <div class="title">
                                        <p class="bold"> {{'experiences' | translate}}</p>
                                    </div>
                                    <ul>
                                        <li *ngFor="let experience of cvExperiences().controls; let experienceIndex=index"
                                        [formGroupName]="experienceIndex">

                                        <div class="info">
                                            <p class="semi-bold">

                                                <strong style="text-transform: uppercase; font-size: 15px;">
                                                    {{cvExperiences().at(experienceIndex).get('company').value}}</strong>
                                                <strong style="margin-left: 7px;"> | </strong>
                                                <strong style="margin-left: 7px;" [style.color]="secondcolor">
                                                    {{cvExperiences().at(experienceIndex).get('function').value}}
                                                </strong>
                                            </p>
                                            <p>
                                                <strong class="date">
                                                    {{cvExperiences().at(experienceIndex).get('period').value}}</strong>
                                                    <strong style="margin-left: 7px;"> | </strong>
                                                <span style="margin-left: 7px;"> {{cvExperiences().at(experienceIndex).get('city').value}}</span>
                                            </p>
                                            <p style="font-size: 12px; word-wrap: break-word;"
                                                [innerHTML]="cvExperiences().at(experienceIndex).get('description').value">

                                            </p>
                                        </div>
                                        <p style="color: #fff; font-size: 3px;">_</p>
                                    </li>


                                    </ul>
                                </div>

                                <!-- RUBRIQUE PERSONNALISEE -->
                                <div *ngIf="cvFormGroup.get('customs').value.length" formArrayName="customs" style="margin-bottom: -27px; ">
                                    <div *ngFor="let custom of cvCustoms().controls; let customIndex=index"
                                        [formGroupName]="customIndex" class="resume_item resume_education">
                                        <div class="title">
                                            <p class="bold">{{cvCustoms().at(customIndex).get('title').value}}</p>
                                        </div>
                                        <ul>
                                            <li>
                                                <div class="info">

                                                    <p style="font-size: 12px; word-wrap: break-word;"
                                                        [innerHTML]="cvCustoms().at(customIndex).get('description').value">
                                                    </p>
                                                </div>
                                                <p style="color: #fff; font-size: 3px;">_</p>
                                            </li>

                                        </ul>

                                    </div>
                                </div>

                            </div>

                        </div>
                        <!--  END PAGE 1-->





                    </div>

                </div>
            </form>
        </div>

    </div>
</div>

<!--   FORM ARRAY         -->
