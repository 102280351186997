import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TokenStorageService } from '../cv-login-page/token-storage.service';
import { CvService } from '../cv-modele/modeles/cv.service';
// import library classes
import { ConfirmBoxEvokeService } from '@costlydeveloper/ngx-awesome-popup';

import { Cv } from './cv';
import { CvDashboardService } from './cv-dashboard.service';
import { Cover } from '../cover-modele/cover';
import { CoverService } from '../cover-modele/cover.service';


@Component({
  selector: 'subscription-dashboard',
  templateUrl: './subscription-dashboard.component.html',
  styleUrls: ['./cv-dashboard.component.scss']
})
export class CvsSubscriptionInfoDashboardComponent implements OnInit {

  constructor(
    private dashboardService: CvDashboardService,
    private tokenStorageService: TokenStorageService,
    private cvService: CvService,
    private coverService: CoverService,
    private fb: FormBuilder,
    private router: Router,
    private confirmBoxEvokeService: ConfirmBoxEvokeService
  ) { }

maxcv : number=6;
user : any;

  ngOnInit(): void {



    this.findAll();
    this.findAll2();
    this.user=this.tokenStorageService.getUser();


    if(this.tokenStorageService.getUser().role==="user"){
        this.maxcv=6;
    }else{
        this.maxcv=120;
    }

    // alert(this.tokenStorageService.getUser().email);
    if (this.tokenStorageService.getToken() === null) {
      this.tokenStorageService.signOut2();
    }

  }

  confirmBox(cv : Cv) {
    this.confirmBoxEvokeService
      .danger('Supprimer le CV', 'Voulez-vous vraiment supprimer ce CV ?', 'Oui', 'Non')
      .subscribe(
        (resp) => resp.success?this.deleteCv(cv):console.log() );
  }

  confirmBoxCover(cover : Cover) {
    this.confirmBoxEvokeService
      .danger('Supprimer le CV', 'Voulez-vous vraiment supprimer ce CV ?', 'Oui', 'Non')
      .subscribe(
        (resp) => resp.success?this.deleteCover(cover):console.log() );
  }

  listCover: Array<Cover> = [];
  coverFormGroup: FormGroup;

  listCV: Array<Cv> = [];



  isSuccess: boolean = false;
  cvFormGroup: FormGroup;
  initCV(cv: Cv): void {
    //alert(this.decrypt.transform(cv.firstname));
    this.cvFormGroup = this.fb.group({
      name: [cv.name],
      photo: [cv.photo],
      firstname: [cv.firstname],
      lastname: [cv.lastname],
      profession: [cv.profession],
      profile: [cv.profile],
      telephone: [cv.telephone],
      email: [cv.email],
      linkedin: [cv.linkedin],
      webSite: [cv.webSite],
      address: [cv.address],
      city: [cv.city],
      country: [cv.country],
      birth: [cv.birth],
      marital: [cv.marital],
      quality1: [cv.quality1],
      quality2: [cv.quality2],
      quality3: [cv.quality3],
      quality4: [cv.quality4],
      hobby1: [cv.hobby1],
      hobby2: [cv.hobby2],
      hobby3: [cv.hobby3],
      hobby4: [cv.hobby4],
      firstcolor: [cv.firstcolor],
      secondcolor: [cv.secondcolor],
      thirdcolor: [cv.thirdcolor],
      pagecolor: [cv.pagecolor],
      fontfamily: [cv.fontfamily],
      theme: [cv.theme],
      idmodele: [cv.idmodele],
      skills: this.fb.array([]),
      experiences: this.fb.array([]),
      educations: this.fb.array([]),
      customs: this.fb.array([]),
      formations: this.fb.array([]),
      certifications: this.fb.array([]),
      references: this.fb.array([]),
      languages: this.fb.array([]),
      flag: [cv.flag],
    });
  }

  initCover(cover : Cover): void {
    //alert(this.decrypt.transform(cover.firstname));
    this.coverFormGroup = this.fb.group({

        name: [cover.name],
        firstname: [cover.firstname],
        lastname: [cover.lastname],

        profession: [cover.profession],

        telephone: [cover.telephone],
        email: [cover.email],

        address: [cover.address],

        city: [cover.city],

        date: [cover.date],

        recipient: [cover.recipient],

        content: [cover.content],

        language: [cover.language],
        linkedin: [cover.linkedin],

        subject: [cover.subject],

        title: [cover.title],

        introduction: [cover.introduction],

        actual: [cover.actual],

        motivation: [cover.motivation],

        end: [cover.end],

        firstcolor: [cover.firstcolor],
        secondcolor: [cover.secondcolor],
        thirdcolor: [cover.thirdcolor],
        pagecolor: [cover.pagecolor],
        fontfamily: [cover.fontfamily],
        fontsize: [cover.fontsize],
        theme: [cover.theme],
        idmodele: [cover.idmodele],

    });
}



  findAll() {
    this.dashboardService.findAll().subscribe(
      (data) => {
        // alert(data);
        this.listCV = data;
        this.isSuccess = true;

      },
      (error) => {

        this.tokenStorageService.signOut2();
      },

      () => {

        this.listCV = this.listCV.reverse();

      }
    );

  }

  findAll2() {
    this.dashboardService.findAll2().subscribe(
      (data) => {

        this.listCover = data;
        this.isSuccess = true;


      },
      (error) => {

        this.tokenStorageService.signOut2();
      },

      () => {

        this.listCover = this.listCover.reverse();

      }
    );

  }


  showCv(cv: Cv): void {

    let link = ["cvs/m" + cv.idmodele + "-edit", cv.id];
    this.router.navigate(link);
  }

  showCover(cover: Cover): void {

    let link = ["cvs/m" + cover.idmodele + "-edit-cover", cover.id];
    this.router.navigate(link);
  }


  deleteCv(cv: Cv): void {

      this.cvService.deleteOne(cv.id).subscribe(
        (data) => {
          // alert(data);


        },
        (error) => {

          this.tokenStorageService.signOut2();
        },

        () => {
          this.ngOnInit();

        }
      );


  }

  deleteCover(cover: Cover): void {

    this.coverService.deleteOne(cover.id).subscribe(
      (data) => {
        // alert(data);


      },
      (error) => {

        this.tokenStorageService.signOut2();
      },

      () => {
        this.ngOnInit();

      }
    );

}


  async duplicateCv(cv: Cv) {
    let lenght= await this.listCV.length;
    if(this.tokenStorageService.getUser().role==="user"){
        if (lenght < 6) {
            this.initCV(cv);
            const data = cv;

            data.skills.forEach((el) => {
              this.cvSkills().push(this.initCvSkill2(el));
            });

            data.experiences.forEach((el) => {
              this.cvExperiences().push(this.initCvExperience2(el));
            });

            data.educations.forEach((el) => {
              this.cvEducations().push(this.initCvEducation2(el));
            });

            data.customs.forEach((el) => {
              this.cvCustoms().push(this.initCvCustom2(el));
            });

            data.formations.forEach((el) => {
              this.cvFormations().push(this.initCvFormation2(el));
            });
            data.certifications.forEach((el) => {
              this.cvCertifications().push(this.initCvCertification2(el));
            });
            data.references.forEach((el) => {
              this.cvReferences().push(this.initCvReference2(el));
            });

            data.languages.forEach((el) => {
              this.cvLanguages().push(this.initCvLanguage2(el));
            });


              this.cvService.save(this.cvFormGroup.value).subscribe(
                (data) => {
                  // alert(data);


                },
                (error) => {

                  this.tokenStorageService.signOut2();
                },

                () => {
                  this.ngOnInit();

                }
              );

          }
          else {

              this.confirmBoxEvokeService
                .info('Info', 'Vous avez déjà atteint le nombre maximal (6) de CV', 'Ok')
                .subscribe(
                  (resp) => console.log("Déjà 6 CV !") );

          }
    }
    else{
        this.initCV(cv);
        const data = cv;

        data.skills.forEach((el) => {
          this.cvSkills().push(this.initCvSkill2(el));
        });

        data.experiences.forEach((el) => {
          this.cvExperiences().push(this.initCvExperience2(el));
        });

        data.educations.forEach((el) => {
          this.cvEducations().push(this.initCvEducation2(el));
        });

        data.customs.forEach((el) => {
          this.cvCustoms().push(this.initCvCustom2(el));
        });

        data.formations.forEach((el) => {
          this.cvFormations().push(this.initCvFormation2(el));
        });
        data.certifications.forEach((el) => {
          this.cvCertifications().push(this.initCvCertification2(el));
        });
        data.references.forEach((el) => {
          this.cvReferences().push(this.initCvReference2(el));
        });

        data.languages.forEach((el) => {
          this.cvLanguages().push(this.initCvLanguage2(el));
        });


          this.cvService.save(this.cvFormGroup.value).subscribe(
            (data) => {
              // alert(data);


            },
            (error) => {

              this.tokenStorageService.signOut2();
            },

            () => {
              this.ngOnInit();

            }
          );
    };


  }

  duplicateCover(cover: Cover): void {
    if(this.tokenStorageService.getUser().role==="user"){
        if (this.listCover.length < 12) {
            this.initCover(cover);
            const data = cover;



              this.coverService.save(this.coverFormGroup.value).subscribe(
                (data) => {
                  // alert(data);


                },
                (error) => {
                  alert(error.error.message);

                  this.tokenStorageService.signOut2();
                },

                () => {
                  this.ngOnInit();

                }
              );

          }
          else {

              this.confirmBoxEvokeService
                .info('Info', 'Vous avez déjà atteint le nombre maximal (6) de lettres', 'Ok')
                .subscribe(
                  (resp) => console.log("Déjà 6 lettres !") );

          }
    }
    else {
        this.initCover(cover);
            const data = cover;

              this.coverService.save(this.coverFormGroup.value).subscribe(
                (data) => {
                  // alert(data);


                },
                (error) => {
                  alert(error.error.message);

                  this.tokenStorageService.signOut2();
                },

                () => {
                  this.ngOnInit();

                }
              );
    }


  }

  cvSkills(): FormArray {
    return this.cvFormGroup.get("skills") as FormArray;
  }

  cvExperiences(): FormArray {
    return this.cvFormGroup.get("experiences") as FormArray;
  }

  cvEducations(): FormArray {
    return this.cvFormGroup.get("educations") as FormArray;
  }

  cvCustoms(): FormArray {
    return this.cvFormGroup.get("customs") as FormArray;
  }

  cvFormations(): FormArray {
    return this.cvFormGroup.get("formations") as FormArray;
  }
  cvCertifications(): FormArray {
    return this.cvFormGroup.get("certifications") as FormArray;
  }
  cvReferences(): FormArray {
    return this.cvFormGroup.get("references") as FormArray;
  }

  cvLanguages(): FormArray {
    return this.cvFormGroup.get("languages") as FormArray;
  }

  initCvSkill2(skill): FormGroup {
    return this.fb.group({
      item: [skill.item],
      level: [skill.level],
    });
  }

  initCvExperience(): FormGroup {
    return this.fb.group({
      index: [0],
      period: [""],
      company: [""],
      city: [""],
      function: [""],
      description: [""],
    });
  }

  initCvExperience2(experience): FormGroup {
    return this.fb.group({
      index: [experience.index],
      period: [experience.period],
      company: [experience.company],
      city: [experience.city],
      function: [experience.function],
      description: [experience.description],
    });
  }

  initCvEducation2(education): FormGroup {
    return this.fb.group({
      index: [education.index],
      period: [education.period],
      institute: [education.institute],
      city: [education.city],
      country: [education.country],
      degree: [education.degree],
    });
  }

  initCvCustom2(custom): FormGroup {
    return this.fb.group({
      index: [custom.index],
      title: [custom.title],
      description: [custom.description],
    });
  }


  initCvFormation2(formation): FormGroup {
    return this.fb.group({
      index: [formation.index],
      period: [formation.period],
      title: [formation.title],
      description: [formation.description],
      former: [formation.former],
    });
  }

  initCvCertification2(certification): FormGroup {
    return this.fb.group({
      index: [certification.index],
      date: [certification.date],
      certificat: [certification.certificat],
      institute: [certification.institute],
    });
  }


  initCvReference2(reference): FormGroup {
    return this.fb.group({
      index: [reference.index],
      fullname: [reference.fullname],
      organisation: [reference.organisation],
      telephone: [reference.telephone],
      description: [reference.description],
      email: [reference.email],
    });
  }


  initCvLanguage2(lang): FormGroup {
    return this.fb.group({
      item: [lang.item],
      level: [lang.level],
    });
  }




}
