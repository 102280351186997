<div class="banner-wrapper" style="margin-top: -127px;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="wrapper-content">
                    <h1>Parcourez notre CVThèque</h1>
                    <p>Vous trouverez des exemples de CV professionels que vous pouvez personnaliser,
                        ou vous pouvez utiliser comme inspiration.
                        vous devrez l'adapter à votre propre parcours professionnel et à l'emploi pour lequel vous postulez.
                         N'hésitez pas à mettre en valeur vos expériences les plus pertinentes et les réalisations les plus importantes.
                    </p>
                    <form>
                        <input type="text" class="input-search input-sm" placeholder="Rechercher par profession"
                        #searchBox
                        (keyup)="search(searchBox.value)"
                        >

                        <div class="collection2" style="background-color: #fff; color: black; padding: 9px;"
                          *ngFor="let cv of listCV$ | async">

                            <a
                                 href="javascript:"
                                (click)="goToDetail(cv)"
                                class="collection-item"
                                style="margin: 7px; padding: 7px ; font-size: 13px; font-weight: 600; "

                            >
                                {{ cv.name }}
                            </a>
                            <br>
                        </div>

                        <h2 style="color: #f6ba66;"></h2>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="banner-courses-category">
                    <ul >
                        <li>
                            <a routerLink="/cvtheque/informatique">
                                <i class='bx bx-desktop'></i>
                               INFORMATIQUE
                            </a>
                        </li>

                        <li>
                            <a routerLink="/cvtheque/environnement">
                                <i class='bx bx-shape-triangle'></i>
                                ENVIRONNEMENT
                            </a>
                        </li>
                        <li>
                            <a routerLink="/cvtheque/bank-finance">
                                <i class='bx bx-dollar'></i>
                                BANQUE-FINANCE
                            </a>
                        </li>

                        <li>
                            <a routerLink="/cvtheque/marketing">
                                <i class='bx bx-target-lock'></i>
                                COMMERCE-MARKETING
                            </a>
                        </li>
                        <li>
                            <a routerLink="/cvtheque/industry">
                                <i class='bx bx-bar-chart-alt-2'></i>
                                INDUSTRIE
                            </a>
                        </li>
                        <li>
                            <a routerLink="/cvtheque/btp">
                                <i class='bx bx-home'></i>
                                BTP-URBANISME
                            </a>
                        </li>
                        <li>
                            <a routerLink="/cvtheque/health" style="text-transform: uppercase;">
                                <i class='bx bx-first-aid'></i>
                                Santé
                            </a>
                        </li>



                        <li>
                            <a routerLink="/cvtheque/human">
                                <i class='bx bx-group'></i>
                              SCIENCES HUMAINES
                            </a>
                        </li>
                        <li>
                            <a routerLink="/cvtheque/others">
                                <i class='bx bxs-book-reader'></i>
                              AUTRES
                            </a>
                        </li>



                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
