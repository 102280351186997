<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Conseils</li>
            </ul>
            <h2>Conseils pratiques</h2>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/about1.jpg" class="shadow" alt="image">
                    <img src="assets/img/about/about2.jpg" class="shadow" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">About Us !</span> -->
                    <h2>Buts et Objects d'un CV</h2>
                    <h6>Le CV (Curriculum Vitae) est un document à part entière de votre recherche d'emploi.</h6>
                    <p>Il a pour but de résumer en une ou quelques pages votre parcours professionel et vos formations, les différents étapes que vous avez franchies, ainsi que vos centres d'intérêt. </p>
                    <p> Mais l'unique grand objectif d'un CV c'est celui de <strong>décrocher un entretien</strong>.</p>
                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>Le CV comme un laissez-passer vers le succès</h5>
                        <p> Un CV est un document que les recruteurs utilisent comme base pour décider qui sera invité à un entretien. Qu'est-ce qui fait la différence entre un CV professionnel qui finit tout de suite à la poubelle et celui qui incite un
                            recruteur à rencontrer un candidat ? </p>
                        <p>Les experts en emploi ont distingué des éléments cruciaux que nous devons garder à l'esprit pour nous assurer que notre CV est un laissez-passer menant à la réussite professionnelle.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Fournir les détails les plus pertinents</h3>
                        <p>Votre CV doit conténir les détails les plus importants de votre parcours: les meilleurs resutats que vous avez obtenus; les défis auxquels vous avez faits face. Si vous êtes agent commercial, de combein vous avez augmenté les ventes.
                            Ces informations sont plus mémorables.</p>
                        <!--
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Activate Listening</li>
                            <li><i class='bx bx-check'></i> Brilliant minds</li>
                            <li><i class='bx bx-check'></i> Better. Best. Wow!</li>
                            <li><i class='bx bx-check'></i> Branding it better!</li>
                        </ul>
                        -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Faire attention à la mise en forme</h3>
                        <p>Les compétences, les réalisations,les expériences..., sont des informations qui doivent apparaître dans un CV sous une forme transparente. Empêchez votre CV d'être encombré afin de ne pas rendre sa lecture difficile pour un employeur.
                            Points et marges. Ils sont presque aussi importants que le contenu lui-même.</p>

                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Utiliser ses propres mots</h3>
                        <p>L'une des erreurs les plus courantes que commettent les candidats lors de la création d'un CV est d'écrire en utilisant le même style que celui utilisé dans les offres d'emploi. Parfois, il arrive même que les candidats recopient
                            mot pour mot certaines expressions. Malheureusement, cela ne semble pas crédible. Il est bien préférable d'utiliser son propre vocabulaire, en adéquation avec les tâches qui faisaient réellement partie de ses fonctions.</p>

                    </div>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Toujours écrire la vérité sur vous</h3>
                        <p>Votre expérience peut être modeste, mais vous avez toujours une chance d'obtenir un entretien d'embauche si votre CV est bien rédigé. Soyez sincère pour éviter toute surprise lors de votre rencontre avec un employeur.</p>
                        <!--
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Activate Listening</li>
                            <li><i class='bx bx-check'></i> Brilliant minds</li>
                            <li><i class='bx bx-check'></i> Better. Best. Wow!</li>
                            <li><i class='bx bx-check'></i> Branding it better!</li>
                        </ul>
                        -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Adapter votre CV à l'offre d'emploi</h3>
                        <p>Adapter les informations au poste tout en étant véridique. Vous devez savoir qui est le destinataire de votre CV. Les informations que vous incluez dans votre CV doivent être adaptées à l'offre d'emploi pour laquelle vous postulez
                            afin de montrer que la candidature est bien pensée et créée avec une offre spécifique à l'esprit.</p>

                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Quel modèle de CV choisir?</h3>
                        <p>Le CV classique fera ses preuves dans les métiers techniques et c'est aussi un bon choix pour les salariés du secteur de la finance et des assurances. Les modèles modernes sont un bon choix pour tous ceux qui veulent montrer qu'ils
                            sont à jour avec les nouvelles tendances. Un CV moderne est un choix idéal pour tous les métiers de l'informatique (programmeurs, administrateurs réseaux). C'est également une bonne option pour les gestionnaires, les commerçants,
                            les analystes.

                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--
<app-our-mission></app-our-mission>


<div class="story-area ptb-100">
    <app-our-story></app-our-story>
</div>

<div class="funfacts-area">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div>

<div class="values-area ptb-100">
    <app-our-values></app-our-values>
</div>

<div class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-two></app-instructors-style-two>
    </div>
</div>

<div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>
-->

<!--
<div class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>
-->
