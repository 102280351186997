import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
    ViewChild,
} from "@angular/core";
import jsPDF from "jspdf";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

import { from, Observable, Observer } from "rxjs";
import { map } from "rxjs/operators";
import {
    DataUrl,
    DOC_ORIENTATION,
    NgxImageCompressService,
    UploadResponse,
} from "ngx-image-compress";
import { Cv } from "../../cv-dashboard/cv";
import * as $ from "jquery";
import html2canvas from "html2canvas";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { CvService } from "../../cv-dashboard/cv.service";
import { ThemeService } from "../../cv-theme/cv-theme.service";
import { LanguageService } from "../../cv-language/language.service";
import { DecryptPipe, EncryptPipe } from "src/app/encrypt-decrypt.pipe";
import { TokenStorageService } from "../../cv-login-page/token-storage.service";
//import * as IconSelect from 'src/assets/lib/control/iconselect.js';

declare function IconSelect(value): void;

@Component({
    selector: "mcv4",
    templateUrl: "./m4-edit.component.html",
    styleUrls: ["./m4-edit.component.scss"],
})
export class MCV4Component implements OnInit, AfterViewInit {
    cvFormGroup: FormGroup;

    isIcon: boolean = false;
    listSpinner: boolean = false;
    isLoading: boolean = false;

    @Input()
    cv: Cv;

    loadScript(url) {
        const body = <HTMLDivElement>document.body;
        const script = document.createElement("script");
        script.innerHTML = "";
        script.src = url;
        script.async = false;
        script.defer = true;
        body.appendChild(script);
    }

    constructor(
        private cvService: CvService,
        private imageCompress: NgxImageCompressService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private elementRef: ElementRef,
        private router: Router,
        private themeService: ThemeService,
        private languageService: LanguageService,
        private encrypt: EncryptPipe,
        private decrypt: DecryptPipe,
        private tokenStorageService: TokenStorageService
    ) { }

    tokenStorage: any;

    ngOnInit(): void {
        this.tokenStorage = this.tokenStorageService;

        this.findOne(this.cv.id);
        this.showImage2("assets/img/professional2.png");
        this.getLanguage();
        // this.loadScript("assets/lib/control/iconselect.js");
        // this.loadScript("assets/lib/iscroll.js");
        // this.loadScript("assets/lib/custom.js");
    }

    ngAfterViewInit(): void {
        //this.fillSecondPage2();
        //  this.fillSecondPage3();
    }

    selectSkill() {
        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 712);
    }

    languages: any;
    userLanguage: any = "fr";
    selectLanguage(event: any) {
        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 712);

        return this.languageService.setLanguage(event.target.value);
    }

    getLanguage() {
        this.userLanguage = this.languageService.userLanguage;
    }

    listLanguages() {
        this.languages = this.languageService.getAvailablelanguage;
    }

    idmodele: number;
    getModele(idCV, idModele): void {

        let link = ["cvs/m" + idModele + "-edit", idCV];
        this.router.navigate(link);

    }

    i2: number = 0;
    onChangeModele() {
        setTimeout(() => {
            this.i2++;
            this.i3 = 0;

            this.idmodele = +this.selectedText2.nativeElement.value;
            if (!isNaN(+this.selectedText2.nativeElement.value)) {
                if (this.i2 === 2) {
                    this.i2 = 0;

                    document.getElementById(
                        "my-icon-select2"
                    ).style.pointerEvents = "none";
                    this.cvFormGroup
                        .get("idmodele")
                        .setValue(+this.selectedText2.nativeElement.value);
                    this.cvFormGroup
                        .get("address")
                        .setValue(
                            this.encrypt.transform(
                                this.cvFormGroup.get("address").value
                            )
                        );
                    this.cvFormGroup
                        .get("telephone")
                        .setValue(
                            this.encrypt.transform(
                                this.cvFormGroup.get("telephone").value
                            )
                        );
                    if (this.idmodele === 1) {
                        this.cvFormGroup.get("firstcolor").setValue("#1a2a3b");
                        this.cvFormGroup.get("secondcolor").setValue("#5891bc");

                        this.cvFormGroup.get("pagecolor").setValue("#fff");
                    }
                    if (this.idmodele === 2) {
                        this.cvFormGroup.get("firstcolor").setValue("#212529");
                        this.cvFormGroup.get("secondcolor").setValue("#5eaae2");

                        this.cvFormGroup.get("pagecolor").setValue("#fff");
                    }
                    if (this.idmodele === 3) {
                        this.cvFormGroup.get("firstcolor").setValue("#740913");
                        this.cvFormGroup.get("secondcolor").setValue("#8b9093");

                        this.cvFormGroup.get("pagecolor").setValue("#fff");
                    }
                    if (this.idmodele === 4) {
                        this.cvFormGroup.get("firstcolor").setValue("#252727");
                        this.cvFormGroup.get("secondcolor").setValue("#5a9aba");

                        this.cvFormGroup.get("pagecolor").setValue("#fff");
                    }
                    if (this.idmodele === 5) {
                        this.cvFormGroup.get("firstcolor").setValue("#2e3133");
                        this.cvFormGroup.get("secondcolor").setValue("#7e0905");

                        this.cvFormGroup.get("pagecolor").setValue("#fff");
                    }

                    if (this.idmodele === 6) {
                        this.cvFormGroup.get("firstcolor").setValue("#182839");
                        this.cvFormGroup.get("secondcolor").setValue("#24601f");

                        this.cvFormGroup.get("pagecolor").setValue("#cbefac");
                    }
                    if (this.idmodele === 7) {
                        this.cvFormGroup.get("firstcolor").setValue("#581b31");
                        this.cvFormGroup.get("secondcolor").setValue("#1a4978");

                        this.cvFormGroup.get("pagecolor").setValue("#fff");
                    }
                    if (this.idmodele === 8) {
                        this.cvFormGroup.get("firstcolor").setValue("#740c1b");
                        this.cvFormGroup.get("secondcolor").setValue("#4c4d4e");

                        this.cvFormGroup.get("pagecolor").setValue("#dce1e4");
                    }
                    if (this.idmodele === 9) {
                        this.cvFormGroup.get("firstcolor").setValue("#003147");
                        this.cvFormGroup.get("secondcolor").setValue("rgba(17, 19, 29, 0.4)");

                        this.cvFormGroup.get("pagecolor").setValue("#fff");
                    }
                    if (this.idmodele === 10) {
                        this.cvFormGroup.get("firstcolor").setValue("#003147");
                        this.cvFormGroup.get("secondcolor").setValue("rgba(17, 19, 29, 0.4)");

                        this.cvFormGroup.get("pagecolor").setValue("#fff");
                    }
                    if (this.idmodele === 11) {
                        this.cvFormGroup.get("firstcolor").setValue("#093556");
                        this.cvFormGroup.get("secondcolor").setValue("#0c5285");

                        this.cvFormGroup.get("pagecolor").setValue("#1970b1");
                    }
                    if (this.idmodele === 12) {
                        this.cvFormGroup.get("firstcolor").setValue("#0e3958");
                        this.cvFormGroup.get("secondcolor").setValue("#0e3341");

                        this.cvFormGroup.get("pagecolor").setValue("#cedbeb");
                    }
                    if (this.idmodele === 13) {
                        this.cvFormGroup.get("firstcolor").setValue("#394043");
                        this.cvFormGroup.get("secondcolor").setValue("#afa40c");

                        this.cvFormGroup.get("pagecolor").setValue("#e9d18f");
                    }
                    if (this.idmodele === 14) {
                        this.cvFormGroup.get("firstcolor").setValue("#212529");
                        this.cvFormGroup.get("secondcolor").setValue("#5e5f62");

                        this.cvFormGroup.get("pagecolor").setValue("#bac0c0");
                    }
                    if (this.idmodele === 15) {
                        this.cvFormGroup.get("firstcolor").setValue("#102927");
                        this.cvFormGroup.get("secondcolor").setValue("#77f5ad");

                        this.cvFormGroup.get("pagecolor").setValue("#c2e7a8");
                    }
                    if (this.idmodele === 16) {
                        this.cvFormGroup.get("firstcolor").setValue("#404549");
                        this.cvFormGroup.get("secondcolor").setValue("#431427");

                        this.cvFormGroup.get("pagecolor").setValue("#fff");
                    }

                    this.cvService
                        .update(this.cvFormGroup.value, this.cv.id)
                        .subscribe(
                            (data) => {
                                this.cv = data;
                            },
                            (error) => {
                                alert("Déconnecté, la page va se recharger!");
                                let link = ["/"];
                                this.router.navigate(link);
                            },

                            () => {
                                //alert(this.selectedText2.nativeElement.value);

                                setTimeout(() => {
                                    this.findOne2(this.cv.id);
                                }, 312);

                                setTimeout(() => {
                                    this.isCrypt = true;

                                    this.getModele(
                                        this.cv.id,
                                        +this.selectedText2.nativeElement.value
                                    );
                                }, 712);
                            }
                        );
                }
            } else {
                alert("veuillez recharger la page svp!");
            }
        }, 500);
    }

    i3: number = 0;
    onChangeTheme() {
        this.i3++;
        this.i2 = 0;
        if (this.i3 === 2) {
            this.i3 = 0;
            setTimeout(() => {
                //alert(this.selectedText.nativeElement.value);
                this.getTheme(+this.selectedText.nativeElement.value);
                this.updatePage2();
                this.updatePage3();
            }, 500);
        }
    }

    getTheme(id) {
        this.themeService.findOne(id).subscribe(
            (data) => {
                this.theme = data;
            },
            (error) => {
                alert("Déconnecté, la page va se recharger!");
                let link = ["/"];
                this.router.navigate(link);
            },

            () => {
                this.firstcolor = this.theme.firstcolor;
                this.secondcolor = this.theme.secondcolor;
                this.thirdcolor = this.theme.thirdcolor;
                this.fontfamily = this.theme.fontfamily;
                this.pagecolor = this.theme.pagecolor;
                // alert(this.theme.id);
            }
        );
    }

    idTheme: any;
    id2: any;
    iter: any = 4;

    @ViewChild("selectedText")
    selectedText: ElementRef;

    @ViewChild("selectedText2")
    selectedText2: ElementRef;

    @ViewChild("selectedText2")
    iconselect2: ElementRef;

    selectTheme() {
        var iconSelect;
        var iconSelect2;
        var selectedText;
        var selectedText2;

        this.id2 = this.cv.theme.id;

        //this.iter=this.iter+1;

        var i = this.cv.theme.id;
        var i2 = this.cv.idmodele;
        var j = 1;
        setTimeout(() => {
            selectedText = document.getElementById("selected-text");
            selectedText2 = document.getElementById("selected-text2");

            document
                .getElementById("my-icon-select")
                .addEventListener("changed", function (e) {
                    j = j + 1;

                    if (j === 2) {
                        //iconSelect.setSelectedValue(i-1);
                        selectedText.value = iconSelect.setSelectedValue(i - 1);
                    } else {
                        iconSelect.getSelectedValue();
                        selectedText.value = iconSelect.getSelectedValue();
                    }
                });

            document
                .getElementById("my-icon-select2")
                .addEventListener("changed", function (e) {
                    j = j + 1;

                    if (j === 2) {
                        //iconSelect.setSelectedValue(i-1);
                        selectedText2.value = iconSelect2.setSelectedValue(
                            i2 - 1
                        );
                    } else {
                        iconSelect.getSelectedValue();
                        selectedText2.value = iconSelect2.getSelectedValue();
                    }
                });

            iconSelect = new IconSelect("my-icon-select");
            iconSelect2 = new IconSelect("my-icon-select2");

            var icons = [];
            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/1.png",
                iconValue: "1",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/2.png",
                iconValue: "2",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/3.png",
                iconValue: "3",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/4.png",
                iconValue: "4",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/5.png",
                iconValue: "5",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/6.png",
                iconValue: "6",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/7.png",
                iconValue: "7",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/8.png",
                iconValue: "8",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/9.png",
                iconValue: "9",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/10.png",
                iconValue: "10",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/11.png",
                iconValue: "11",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/12.png",
                iconValue: "12",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/13.png",
                iconValue: "13",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/14.png",
                iconValue: "14",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/15.png",
                iconValue: "15",
            });
            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/16.png",
                iconValue: "16",
            });
            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/17.png",
                iconValue: "17",
            });
            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/18.png",
                iconValue: "18",
            });
            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/19.png",
                iconValue: "19",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/20.png",
                iconValue: "20",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/21.png",
                iconValue: "21",
            });
            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/22.png",
                iconValue: "22",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/23.png",
                iconValue: "23",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes/m4/24.png",
                iconValue: "24",
            });

            if(i>0 && i<24){
                iconSelect.refresh(icons, i-1);
            }
            if(i>24 && i<49){
                iconSelect.refresh(icons, i-24 - 1);
            }
            if(i>48 && i<73){
                iconSelect.refresh(icons, i-48 - 1);
            }
            if(i>72 && i<97){
                iconSelect.refresh(icons, i-72 - 1);
            }
            if(i>96 && i<121){
                iconSelect.refresh(icons, i-96 - 1);
            }
            if(i>120 && i<145){
                iconSelect.refresh(icons, i-120 - 1);
            }

            var icons2 = [];
            icons2.push({
                iconFilePath: "assets/images/icons-modeles/1.png",
                iconValue: "1",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles/2.png",
                iconValue: "2",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles/3.png",
                iconValue: "3",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles/4.png",
                iconValue: "4",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles/5.png",
                iconValue: "5",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles/6.png",
                iconValue: "6",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles/7.png",
                iconValue: "7",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles/8.png",
                iconValue: "8",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles/9.png",
                iconValue: "9",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles/10.png",
                iconValue: "10",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles/11.png",
                iconValue: "11",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles/12.png",
                iconValue: "12",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles/13.png",
                iconValue: "13",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles/14.png",
                iconValue: "14",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles/15.png",
                iconValue: "15",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles/16.png",
                iconValue: "16",
            });

            iconSelect2.refresh(icons2, i2 - 1);
        }, 0);
    }

    // Config textarea
    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: "8rem",
        minHeight: "7rem",
        placeholder: "Bref description ....",
        translate: "yes",
        defaultParagraphSeparator: "p",
        defaultFontName: "Calibri",
        defaultFontSize: "2",
        toolbarHiddenButtons: [
            ["strikeThrough", "subscript", "superscript"],
            [
                "heading",
                "backgroundColor",
                "customClasses",
                "link",
                "unlink",
                "insertImage",
                "insertVideo",
                "insertHorizontalRule",
                "removeFormat",
                "toggleEditorMode",
            ],
        ],
        customClasses: [
            {
                name: "quote",
                class: "quote",
            },
            {
                name: "redText",
                class: "redText",
            },
            {
                name: "titleText",
                class: "titleText",
                tag: "h1",
            },
        ],
    };

    @ViewChild("rd0") rd0: ElementRef;
    @ViewChild("rd1") rd1: ElementRef;
    @ViewChild("rd2") rd2: ElementRef;
    @ViewChild("rd3") rd3: ElementRef;
    @ViewChild("rd4") rd4: ElementRef;
    @ViewChild("rd5") rd5: ElementRef;
    @ViewChild("rd6") rd6: ElementRef;
    @ViewChild("rd7") rd7: ElementRef;
    @ViewChild("rd8") rd8: ElementRef;
    @ViewChild("rd9") rd9: ElementRef;
    @ViewChild("rd10") rd10: ElementRef;
    @ViewChild("rd11") rd11: ElementRef;
    @ViewChild("rd12") rd12: ElementRef;

    check0(event) {
        if (event.target.checked) {
            this.rd1.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check1(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check2(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check3(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check4(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check5(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check6(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check7(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check8(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check9(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check10(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check11(event) {
        if (event.target.checked) {
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }
    check12(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
        }
    }

    fillSecondPage() {
        setTimeout(() => {
            $(function () {
                var copy = $("#right_side1").clone().attr("id", "onecopy").css({
                    "margin-top": "-841.5pt",
                    height: "1683pt",
                });
                $("#right_side2").append(copy);

                $("#content_1").keyup(function () {
                    var copy2 = $("#right_side1")
                        .clone()
                        .attr("id", "onecopy2")
                        .css({
                            "margin-top": "-841.5pt",
                            height: "1683pt",
                        });

                    $("#right_side2").empty();
                    $("#right_side2").append(copy2);

                    $("#right_side1").css({
                        //"max-height": "841.5pt",
                        "min-height": "841.5pt",
                    });
                    $("#right_side2").css({
                        "max-height": "841.5pt",
                        "main-height": "841.5pt",
                    });

                    $("#left_side1").css({
                        "min-height": "841.5pt",
                        //"max-height": "841.5pt",
                    });
                    $("#left_side2").css({ "min-height": "841.5pt" });
                });
            });
        }, 250);
    }

    fillSecondPage2() {
        setTimeout(() => {
            $(function () {
                var copy = $("#right_side1").clone().attr("id", "onecopy").css({
                    "margin-top": "-841.5pt",
                    height: "1683pt",
                });

                $("#right_side2").append(copy);

                var copy11 = $("#left_side1")
                    .clone()
                    .attr("id", "onecopy11")
                    .css({
                        "margin-top": "-841.5pt",
                        height: "1683pt",
                    });
                $("#left_side2").empty();
                $("#left_side2").append(copy11);

                if (
                    $("#right_side1").prop("scrollHeight") <
                    $("#right_side1").prop("clientHeight") + 30 &&
                    $("#left_side1").prop("scrollHeight") <
                    $("#left_side1").prop("clientHeight") + 30
                ) {
                    $("#content2").css({
                        visibility: "hidden",
                        position: "absolute",
                        height: "2px",
                        "min-height": "2px",
                    });
                    $("#right_side2").css({
                        position: "absolute",
                        height: "2px",
                        "min-height": "2px",
                    });
                    $("#left_side2").css({
                        position: "absolute",
                        height: "2px",
                        "min-height": "2px",
                    });
                }


                $("#content_2").mouseup(function () {
                    if (
                        $("#right_side1").prop("scrollHeight") >
                        $("#right_side1").prop("clientHeight") + 30 ||
                        $("#left_side1").prop("scrollHeight") >
                        $("#left_side1").prop("clientHeight") + 30
                    ) {
                        var copy = $("#right_side1")
                            .clone()
                            .attr("id", "onecopy")
                            .css({
                                "margin-top": "-841.5pt",
                                height: "1683pt",
                            });
                        $("#right_side2").empty();
                        $("#right_side2").append(copy);

                        var copy11 = $("#left_side1")
                            .clone()
                            .attr("id", "onecopy11")
                            .css({
                                "margin-top": "-841.5pt",
                                height: "1683pt",
                            });
                        $("#left_side2").empty();
                        $("#left_side2").append(copy11);
                    }
                });

                $("#content_2").mousedown(function () {
                    if (
                        $("#right_side1").prop("scrollHeight") >
                        $("#right_side1").prop("clientHeight") + 30 ||
                        $("#left_side1").prop("scrollHeight") >
                        $("#left_side1").prop("clientHeight") + 30
                    ) {
                        var copy = $("#right_side1")
                            .clone()
                            .attr("id", "onecopy")
                            .css({
                                "margin-top": "-841.5pt",
                                height: "1683pt",
                            });
                        $("#right_side2").empty();
                        $("#right_side2").append(copy);

                        var copy11 = $("#left_side1")
                            .clone()
                            .attr("id", "onecopy11")
                            .css({
                                "margin-top": "-841.5pt",
                                height: "1683pt",
                            });
                        $("#left_side2").empty();
                        $("#left_side2").append(copy11);
                    }
                });

                $("#content_1").keyup(function () {
                    if (
                        $("#right_side1").prop("scrollHeight") >
                        $("#right_side1").prop("clientHeight") + 30 ||
                        $("#left_side1").prop("scrollHeight") >
                        $("#left_side1").prop("clientHeight") + 30
                    ) {
                        //if 'true', the content overflows the tab: we show the hidden link

                        //alert($('#right_side1').prop('clientHeight'));

                        // $('#content2').css('visibility', 'block');

                        $("#content2").css({
                            visibility: "visible",
                        });

                        setTimeout(() => {
                            var copy2 = $("#right_side1")
                                .clone()
                                .attr("id", "onecopy2")
                                .css({
                                    "margin-top": "-841.5pt",
                                    height: "1683pt",
                                });

                            $("#right_side2").empty();
                            $("#right_side2").append(copy2);

                            var copy22 = $("#left_side1")
                                .clone()
                                .attr("id", "onecopy22")
                                .css({
                                    "margin-top": "-841.5pt",
                                    height: "1683pt",
                                });
                            $("#left_side2").empty();
                            $("#left_side2").append(copy22);

                            $("#right_side1").css({
                                //"max-height": "841.5pt",
                                "min-height": "841.5pt",
                            });
                            $("#right_side2").css({
                                "max-height": "841.5pt",
                                "main-height": "841.5pt",
                            });

                            $("#left_side1").css({
                                "min-height": "841.5pt",
                                //"max-height": "841.5pt",
                            });
                            $("#left_side2").css({ "min-height": "841.5pt" });
                        }, 123);
                    } else {
                        $("#content2").css({ visibility: "hidden" });
                    }

                    setTimeout(() => {
                        //alert( $("#right_side1").prop("scrollHeight") +"___"+($("#right_side1").prop("clientHeight") + 30))
                        if (
                            $("#right_side1").prop("scrollHeight") >
                            $("#right_side1").prop("clientHeight") + 30 ||
                            $("#left_side1").prop("scrollHeight") >
                            $("#left_side1").prop("clientHeight") + 30
                        ) {
                            $("#content2").css({
                                visibility: "visible",
                                position: "relative",
                                height: "100%",
                                "min-height": "841.995pt",
                            });
                            $("#right_side2").css({
                                position: "relative",
                                height: "841.5pt",
                                "min-height": "841.5pt",
                            });
                            $("#left_side2").css({
                                position: "relative",
                                height: "841.5pt",
                                "min-height": "841.5pt",
                            });
                        } else {
                            $("#content2").css({
                                visibility: "hidden",
                                position: "absolute",
                                height: "2px",
                                "min-height": "2px",
                            });
                            $("#right_side2").css({
                                position: "absolute",
                                height: "2px",
                                "min-height": "2px",
                            });
                            $("#left_side2").css({
                                position: "absolute",
                                height: "2px",
                                "min-height": "2px",
                            });
                        }
                    }, 123);
                });
            });
        }, 712);
    }

    fillSecondPage3() {
        setTimeout(() => {
            $(function () {
                var copy = $("#right_side1")
                    .clone()
                    .attr("id", "onecopy2")
                    .css({
                        "margin-top": "-1683pt",
                        height: "2524.5pt",
                    });

                $("#right_side3").append(copy);

                var copy = $("#left_side1").clone().attr("id", "onecopy2").css({
                    "margin-top": "-1683pt",
                    height: "2524.5pt",
                });

                $("#left_side3").append(copy);

                if (
                    $("#right_side1").prop("scrollHeight") < 2287 &&
                    $("#left_side1").prop("scrollHeight") < 2287
                ) {
                    $("#content3").css({
                        visibility: "hidden",
                        position: "absolute",
                        height: "2px",
                        "min-height": "2px",
                    });
                    $("#right_side3").css({
                        position: "absolute",
                        height: "2px",
                        "min-height": "2px",
                    });
                    $("#left_side3").css({
                        position: "absolute",
                        height: "2px",
                        "min-height": "2px",
                    });
                }

                $("#content_2").mouseup(function () {
                    if (
                        $("#right_side1").prop("scrollHeight") > 2287 ||
                        $("#left_side1").prop("scrollHeight") > 2287
                    ) {
                        var copy = $("#right_side1")
                            .clone()
                            .attr("id", "onecopy")
                            .css({
                                "margin-top": "-1683pt",
                                height: "2524.5pt",
                            });
                        $("#right_side3").empty();
                        $("#right_side3").append(copy);

                        var copy11 = $("#left_side1")
                            .clone()
                            .attr("id", "onecopy11")
                            .css({
                                "margin-top": "-1683pt",
                                height: "2524.5pt",
                            });
                        $("#left_side3").empty();
                        $("#left_side3").append(copy11);
                    }
                });
                $("#content_2").mousedown(function () {
                    if (
                        $("#right_side1").prop("scrollHeight") > 2287 ||
                        $("#left_side1").prop("scrollHeight") > 2287
                    ) {
                        var copy = $("#right_side1")
                            .clone()
                            .attr("id", "onecopy")
                            .css({
                                "margin-top": "-1683pt",
                                height: "2524.5pt",
                            });
                        $("#right_side3").empty();
                        $("#right_side3").append(copy);

                        var copy11 = $("#left_side1")
                            .clone()
                            .attr("id", "onecopy11")
                            .css({
                                "margin-top": "-1683pt",
                                height: "2524.5pt",
                            });
                        $("#left_side3").empty();
                        $("#left_side3").append(copy11);
                    }
                });

                $("#content_1").keyup(function () {
                    if (
                        $("#right_side1").prop("scrollHeight") > 2287 ||
                        $("#left_side1").prop("scrollHeight") > 2287
                    ) {
                        //if 'true', the content overflows the tab: we show the hidden link

                        //alert($('#right_side1').prop('clientHeight'));

                        // $('#content2').css('visibility', 'block');
                        $("#content3").css({
                            visibility: "visible",
                        });

                        setTimeout(() => {
                            var copy3 = $("#right_side1")
                                .clone()
                                .attr("id", "onecopy3")
                                .css({
                                    "margin-top": "-1683pt",
                                    height: "2524.5pt",
                                });

                            $("#right_side3").empty();
                            $("#right_side3").append(copy3);

                            var copy33 = $("#left_side1")
                                .clone()
                                .attr("id", "onecopy33")
                                .css({
                                    "margin-top": "-1683pt",
                                    height: "2524.5pt",
                                });

                            $("#left_side3").empty();
                            $("#left_side3").append(copy33);
                            $("#right_side1").css({
                                //"max-height": "841.5pt",
                                "min-height": "841.5pt",
                            });
                            $("#right_side3").css({
                                "max-height": "841.5pt",
                                "main-height": "841.5pt",
                            });

                            $("#left_side1").css({
                                "min-height": "841.5pt",
                                //"max-height": "841.5pt",
                            });
                            $("#left_side3").css({ "min-height": "841.5pt" });
                        }, 127);
                    } else {
                        $("#content3").css({
                            visibility: "hidden",
                            position: "absolute",
                            height: "2px",
                            "min-height": "2px",
                        });
                        $("#right_side3").css({
                            position: "absolute",
                            height: "2px",
                            "min-height": "2px",
                        });
                        $("#left_side3").css({
                            position: "absolute",
                            height: "2px",
                            "min-height": "2px",
                        });
                    }

                    setTimeout(() => {
                        if ($("#right_side1").prop("scrollHeight") > 2287) {
                            $("#content3").css({
                                visibility: "visible",
                                position: "relative",
                                height: "100%",
                                "min-height": "841.995pt",
                            });
                            $("#right_side3").css({
                                position: "relative",
                                height: "841.5pt",
                                "min-height": "841.5pt",
                            });
                            $("#left_side3").css({
                                position: "relative",
                                height: "841.5pt",
                                "min-height": "841.5pt",
                            });
                        } else {
                            $("#content3").css({
                                visibility: "hidden",
                                position: "absolute",
                                height: "2px",
                                "min-height": "2px",
                            });
                            $("#right_side3").css({
                                position: "absolute",
                                height: "2px",
                                "min-height": "2px",
                            });
                            $("#left_side3").css({
                                position: "absolute",
                                height: "2px",
                                "min-height": "2px",
                            });
                        }
                    }, 373);
                });
            });
        }, 712);
    }

    updatePage2() {
        if (
            $("#right_side1").prop("scrollHeight") >
            $("#right_side1").prop("clientHeight") + 30 ||
            $("#left_side1").prop("scrollHeight") >
            $("#left_side1").prop("clientHeight") + 30
        ) {
            //if 'true', the content overflows the tab: we show the hidden link

            //alert($('#right_side1').prop('clientHeight'));

            // $('#content2').css('visibility', 'block');
            $("#content2").css({
                visibility: "visible",
            });

            setTimeout(() => {
                var copy2 = $("#right_side1")
                    .clone()
                    .attr("id", "onecopy2")
                    .css({
                        "margin-top": "-841.5pt",
                        height: "1683pt",
                    });

                $("#right_side2").empty();
                $("#right_side2").append(copy2);

                var copy11 = $("#left_side1")
                    .clone()
                    .attr("id", "onecopy11")
                    .css({
                        "margin-top": "-841.5pt",
                        height: "1683pt",
                    });
                $("#left_side2").empty();
                $("#left_side2").append(copy11);

                $("#right_side1").css({
                    //"max-height": "841.5pt",
                    "min-height": "841.5pt",
                });
                $("#right_side2").css({
                    "max-height": "841.5pt",
                    "main-height": "841.5pt",
                });

                $("#left_side1").css({
                    "min-height": "841.5pt",
                    //"max-height": "841.5pt",
                });
                $("#left_side2").css({ "min-height": "841.5pt" });
            }, 127);
        } else {
            $("#content2").css({
                visibility: "hidden",
                position: "absolute",
                height: "2px",
                "min-height": "2px",
            });
            $("#right_side2").css({
                position: "absolute",
                height: "2px",
                "min-height": "2px",
            });
            $("#left_side2").css({
                position: "absolute",
                height: "2px",
                "min-height": "2px",
            });
        }

        setTimeout(() => {
            if (
                $("#right_side1").prop("scrollHeight") >
                $("#right_side1").prop("clientHeight") + 30
            ) {
                $("#content2").css({
                    visibility: "visible",
                    position: "relative",
                    height: "100%",
                    "min-height": "841.995pt",
                });
                $("#right_side2").css({
                    position: "relative",
                    height: "841.5pt",
                    "min-height": "841.5pt",
                });
                $("#left_side2").css({
                    position: "relative",
                    height: "841.5pt",
                    "min-height": "841.5pt",
                });
            } else if (
                $("#left_side1").prop("scrollHeight") >
                $("#left_side1").prop("clientHeight") + 30
            ) {
                $("#content2").css({
                    visibility: "visible",
                    position: "relative",
                    height: "100%",
                    "min-height": "841.995pt",
                });
                $("#right_side2").css({
                    position: "relative",
                    height: "841.5pt",
                    "min-height": "841.5pt",
                });
                $("#left_side2").css({
                    position: "relative",
                    height: "841.5pt",
                    "min-height": "841.5pt",
                });
            } else {
                $("#content2").css({
                    visibility: "hidden",
                    position: "absolute",
                    height: "2px",
                    "min-height": "2px",
                });
                $("#right_side2").css({
                    position: "absolute",
                    height: "2px",
                    "min-height": "2px",
                });
                $("#left_side2").css({
                    position: "absolute",
                    height: "2px",
                    "min-height": "2px",
                });
            }
        }, 312);
    }

    updatePage3() {
        if ($("#right_side1").prop("scrollHeight") > 2287) {
            //if 'true', the content overflows the tab: we show the hidden link

            //alert($('#right_side1').prop('clientHeight'));

            // $('#content2').css('visibility', 'block');
            $("#content3").css({
                visibility: "visible",
            });

            setTimeout(() => {
                var copy3 = $("#right_side1")
                    .clone()
                    .attr("id", "onecopy3")
                    .css({
                        "margin-top": "-1683pt",
                        height: "2524.5pt",
                    });

                $("#right_side3").empty();
                $("#right_side3").append(copy3);

                var copy33 = $("#left_side1")
                    .clone()
                    .attr("id", "onecopy33")
                    .css({
                        "margin-top": "-1683pt",
                        height: "2524.5pt",
                    });

                $("#left_side3").empty();
                $("#left_side3").append(copy33);
                $("#right_side1").css({
                    //"max-height": "841.5pt",
                    "min-height": "841.5pt",
                });
                $("#right_side3").css({
                    "max-height": "841.5pt",
                    "main-height": "841.5pt",
                });

                $("#left_side1").css({
                    "min-height": "841.5pt",
                    //"max-height": "841.5pt",
                });
                $("#left_side3").css({ "min-height": "841.5pt" });
            }, 127);
        } else {
            $("#content3").css({
                visibility: "hidden",
                position: "absolute",
                height: "2px",
                "min-height": "2px",
            });
            $("#right_side3").css({
                position: "absolute",
                height: "2px",
                "min-height": "2px",
            });
            $("#left_side3").css({
                position: "absolute",
                height: "2px",
                "min-height": "2px",
            });
        }

        setTimeout(() => {
            if ($("#right_side1").prop("scrollHeight") > 2287) {
                $("#content3").css({
                    visibility: "visible",
                    position: "relative",
                    height: "100%",
                    "min-height": "841.995pt",
                });
                $("#right_side3").css({
                    position: "relative",
                    height: "841.5pt",
                    "min-height": "841.5pt",
                });
                $("#left_side3").css({
                    position: "relative",
                    height: "841.5pt",
                    "min-height": "841.5pt",
                });
            } else {
                $("#content3").css({
                    visibility: "hidden",
                    position: "absolute",
                    height: "2px",
                    "min-height": "2px",
                });
                $("#right_side3").css({
                    position: "absolute",
                    height: "2px",
                    "min-height": "2px",
                });
                $("#left_side3").css({
                    position: "absolute",
                    height: "2px",
                    "min-height": "2px",
                });
            }
        }, 373);
    }

    @ViewChild("right_side2") right_side2: ElementRef;
    @ViewChild("right_side1") right_side1: ElementRef;
    @ViewChild("right_side3") right_side3: ElementRef;


    cvs = [];
    skills: Array<any> = [];
    spin1: boolean = false;
    spin2: boolean = false;
    isSave: boolean = true;
    isOpen: boolean = true;

    checkBtn(event) {
        this.isIcon = event.target.checked;
        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 712);
    }

    initCV2(cv: Cv): void {
        //alert(this.decrypt.transform(cv.firstname));
        this.cvFormGroup = this.fb.group({
            id: [cv.id],
            photo: [cv.photo],
            firstname: [cv.firstname],
            lastname: [cv.lastname],
            profession: [cv.profession],
            profile: [cv.profile],
            telephone: [this.decrypt.transform(cv.telephone)],
            email: [cv.email],
            linkedin: [cv.linkedin],
            webSite: [cv.webSite],
            address: [this.decrypt.transform(cv.address)],
            city: [cv.city],
            country: [cv.country],
            birth: [cv.birth],
            marital: [cv.marital],
            quality1: [cv.quality1],
            quality2: [cv.quality2],
            quality3: [cv.quality3],
            quality4: [cv.quality4],
            hobby1: [cv.hobby1],
            hobby2: [cv.hobby2],
            hobby3: [cv.hobby3],
            hobby4: [cv.hobby4],
            firstcolor: [cv.firstcolor],
            secondcolor: [cv.secondcolor],
            thirdcolor: [cv.thirdcolor],
            pagecolor: [cv.pagecolor],
            fontfamily: [cv.fontfamily],
            theme: [cv.theme],
            idmodele: [cv.idmodele],
            skills: this.fb.array([]),
            experiences: this.fb.array([]),
            educations: this.fb.array([]),
            customs: this.fb.array([]),
            formations: this.fb.array([]),
            certifications: this.fb.array([]),
            references: this.fb.array([]),
            languages: this.fb.array([]),
            flag: [cv.flag],
            flag1: [cv.flag1],

        });
    }

    isProfessional:boolean= true;
    isBeginner :boolean=false;


    onRadioClick(value : number){

        this.cvFormGroup.get("flag1").setValue(value);
       // this.cvFormGroup.controls.choose.setValue(value);

        if(value===1){
            this.isProfessional=true;
            this.isBeginner=false;

        }else{
            this.isBeginner=true;
            this.isProfessional=false;
        }

        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 773);
    }

    fileFormGroup: FormGroup;
    initFile(): void {
        this.fileFormGroup = this.fb.group({
            file: [""],
        });
    }

    cvSkills(): FormArray {
        return this.cvFormGroup.get("skills") as FormArray;
    }

    cvExperiences(): FormArray {
        return this.cvFormGroup.get("experiences") as FormArray;
    }

    cvEducations(): FormArray {
        return this.cvFormGroup.get("educations") as FormArray;
    }

    cvCustoms(): FormArray {
        return this.cvFormGroup.get("customs") as FormArray;
    }

    cvFormations(): FormArray {
        return this.cvFormGroup.get("formations") as FormArray;
    }
    cvCertifications(): FormArray {
        return this.cvFormGroup.get("certifications") as FormArray;
    }
    cvReferences(): FormArray {
        return this.cvFormGroup.get("references") as FormArray;
    }

    cvLanguages(): FormArray {
        return this.cvFormGroup.get("languages") as FormArray;
    }

    initCvSkill(): FormGroup {
        return this.fb.group({
            id: [null],
            item: [""],
            level: ["50%"],
        });
    }

    initCvSkill2(skill): FormGroup {
        return this.fb.group({
            id: [skill.id],
            item: [skill.item],
            level: [skill.level],
        });
    }

    initCvExperience(): FormGroup {
        return this.fb.group({
            id: [null],
            index: [0],
            period: [""],
            company: [""],
            city: [""],
            function: [""],
            description: [""],
        });
    }

    initCvExperience2(experience): FormGroup {
        return this.fb.group({
            id: [experience.id],
            index: [experience.index],
            period: [experience.period],
            company: [experience.company],
            city: [experience.city],
            function: [experience.function],
            description: [experience.description],
        });
    }

    initCvEducation(): FormGroup {
        return this.fb.group({
            id: [null],
            index: [0],
            period: [""],
            institute: [""],
            city: [""],
            country: [""],
            degree: [""],
        });
    }
    initCvEducation2(education): FormGroup {
        return this.fb.group({
            id: [education.id],
            index: [education.index],
            period: [education.period],
            institute: [education.institute],
            city: [education.city],
            country: [education.country],
            degree: [education.degree],
        });
    }

    initCvCustom(): FormGroup {
        return this.fb.group({
            id: [null],
            index: [0],
            title: [""],
            description: [""],
        });
    }

    initCvCustom2(custom): FormGroup {
        return this.fb.group({
            id: [custom.id],
            index: [custom.index],
            title: [custom.title],
            description: [custom.description],
        });
    }

    initCvFormation(): FormGroup {
        return this.fb.group({
            id: [null],
            index: [0],
            period: [""],
            title: [""],
            description: [""],
            former: [""],
        });
    }
    initCvFormation2(formation): FormGroup {
        return this.fb.group({
            id: [formation.id],
            index: [formation.index],
            period: [formation.period],
            title: [formation.title],
            description: [formation.description],
            former: [formation.former],
        });
    }
    initCvCertification(): FormGroup {
        return this.fb.group({
            id: [null],
            index: [0],
            date: [""],
            certificat: [""],
            institute: [""],
        });
    }

    initCvCertification2(certification): FormGroup {
        return this.fb.group({
            id: [certification.id],
            index: [certification.index],
            date: [certification.date],
            certificat: [certification.certificat],
            institute: [certification.institute],
        });
    }

    initCvReference(): FormGroup {
        return this.fb.group({
            id: [null],
            index: [0],
            fullname: [""],
            organisation: [""],
            telephone: [""],
            description: [""],
            email: [""],
        });
    }

    initCvReference2(reference): FormGroup {
        return this.fb.group({
            id: [reference.id],
            index: [reference.index],
            fullname: [reference.fullname],
            organisation: [reference.organisation],
            telephone: [reference.telephone],
            description: [reference.description],
            email: [reference.email],
        });
    }

    initCvLanguage(): FormGroup {
        return this.fb.group({
            id: [null],
            item: [""],
            level: ["50%"],
        });
    }

    initCvLanguage2(lang): FormGroup {
        return this.fb.group({
            id: [lang.id],
            item: [lang.item],
            level: [lang.level],
        });
    }

    addCvSkill() {
        this.cvSkills().push(this.initCvSkill());
        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 712);
    }

    addCvExperience() {
        this.cvExperiences().push(this.initCvExperience());
        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 712);
    }

    addCvEducation() {
        this.cvEducations().push(this.initCvEducation());
        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 712);
    }

    addCvCustom() {
        this.cvCustoms().push(this.initCvCustom());
        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 712);
    }

    addCvFormation() {
        this.cvFormations().push(this.initCvFormation());
        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 712);
    }

    addCvCertification() {
        this.cvCertifications().push(this.initCvCertification());
        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 712);
    }

    addCvReference() {
        this.cvReferences().push(this.initCvReference());
        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 712);
    }

    addCvLanguage() {
        this.cvLanguages().push(this.initCvLanguage());
        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 712);
    }

    removeCvSkill(index: number) {
        if (
            confirm(
                "Voulez-vous confirmer la suppression de cette compétence ?"
            )
        ) {
            this.cvSkills().removeAt(index);
            setTimeout(() => {
                this.updatePage2();
                this.updatePage3();
            }, 712);
        }
    }

    removeCvExperience(experienceIndex: number) {
        if (
            confirm(
                "Voulez-vous confirmer la suppression de l' expéreince " +
                (experienceIndex + 1) +
                " ?"
            )
        ) {
            this.cvExperiences().removeAt(experienceIndex);
            setTimeout(() => {
                this.updatePage2();
                this.updatePage3();
            }, 712);
        }
    }

    removeCvEducation(index: number) {
        if (
            confirm(
                "Voulez-vous confirmer la suppression de l' éducation " +
                (index + 1) +
                " ?"
            )
        ) {
            this.cvEducations().removeAt(index);
            setTimeout(() => {
                this.updatePage2();
                this.updatePage3();
            }, 712);
        }
    }

    removeCvCustom(index: number) {
        if (
            confirm(
                "Voulez-vous confirmer la suppression de la rubrique personnalisée " +
                (index + 1) +
                " ?"
            )
        ) {
            this.cvCustoms().removeAt(index);
            setTimeout(() => {
                this.updatePage2();
                this.updatePage3();
            }, 712);
        }
    }

    removeCvFormation(index: number) {
        if (
            confirm(
                "Voulez-vous confirmer la suppression de la formation " +
                (index + 1) +
                " ?"
            )
        ) {
            this.cvFormations().removeAt(index);
            setTimeout(() => {
                this.updatePage2();
                this.updatePage3();
            }, 712);
        }
    }
    removeCvCertification(index: number) {
        if (
            confirm(
                "Voulez-vous confirmer la suppression de la certification " +
                (index + 1) +
                " ?"
            )
        ) {
            this.cvCertifications().removeAt(index);
            setTimeout(() => {
                this.updatePage2();
                this.updatePage3();
            }, 712);
        }
    }
    removeCvReference(index: number) {
        if (
            confirm(
                "Voulez-vous confirmer la suppression de la reference " +
                (index + 1) +
                " ?"
            )
        ) {
            this.cvReferences().removeAt(index);
            setTimeout(() => {
                this.updatePage2();
                this.updatePage3();
            }, 712);
        }
    }

    removeCvlanguage(index: number) {
        if (confirm("Voulez-vous confirmer la suppression de cette langue ?")) {
            this.cvLanguages().removeAt(index);
            setTimeout(() => {
                this.updatePage2();
                this.updatePage3();
            }, 712);
        }
    }

    selectedFiles: FileList;
    currentFileUpload: File;
    files: any = [];
    myFiles: FileList;
    public imagePath;
    imgURL: any;
    url: any;
    @ViewChild("photo", { static: false }) photo!: ElementRef;

    isDeleteFile: boolean = false;
    isSetFile: boolean = false;
    file: any;
    localUrl: any;
    localCompressedURl: any;
    sizeOfOriginalImage: number;
    sizeOFCompressedImage: number;
    mimeType: any;
    imgResultBeforeCompress;
    imgResultAfterCompress;
    src: any;
    selectFile(event) {
        this.initFile();

        var fileName: string;

        this.file = event.target.files.item(0);
        fileName = this.file["name"];

        if (this.file.size / 1000 > 2048) {
            alert(
                "La taille ne doit pas depasser 2 MO! " +
                "\n" +
                "Astuce : Recadrer ou rogner l'image pour reduire la taille"
            );
            event.target.value = "";
        }
        //if (file.type.match("image.*") || file.type === "application/pdf") {
        else if (this.file.type.match("image/*")) {
            this.mimeType = event.target.files[0].type;

            this.imageToShow = null;
            //alert("TYPE : "+mimeType);

            /* Pour afficher l'image avec <img height="200"  *ngIf="imgURL" [src]="imgURL"  />  */
            var reader = new FileReader();

            this.imagePath = this.file;
            reader.readAsDataURL(this.file);
            reader.onload = (event2) => {
                this.imgURL = reader.result;
                this.url = reader.result;
                this.localUrl = event2.target.result;
            };

            this.isSetFile = true;
            this.isDeleteFile = false;

            this.fileFormGroup.get("file").setValue(this.file);
            // const imageBlob = this.dataURItoBlob(this.url);

            console.warn(
                "Size in bytes was:",
                this.imageCompress.byteCount(this.url)
            );
        } else {
            alert("Le fichier doit être en jpg, jpeg ou png!");
            event.target.value = "";
        }
    }

    //

    selectFile2(event: any) {
        var fileName: any;
        this.file = event.target.files[0];
        this.mimeType = event.target.files[0].type;
        if (this.file.type.match("image/*")) {
            fileName = this.file["name"];
            if (event.target.files && event.target.files[0]) {
                var reader = new FileReader();
                reader.onload = (event: any) => {
                    this.localUrl = event.target.result;
                    this.compressFile(this.localUrl, fileName);
                };
                reader.readAsDataURL(event.target.files[0]);
            }
        } else {
            alert("Le fichier doit être en jpg, jpeg ou png!");
            event.target.value = "";
        }
    }

    compressFile(image, fileName) {
        this.initFile();
        var orientation = -1;
        this.sizeOfOriginalImage =
            this.imageCompress.byteCount(image) / (1024 * 1024);
        console.warn("Size in bytes is now:", this.sizeOfOriginalImage);
        this.imageCompress
            .compressFile(image, orientation, 70, 70, 400, 400)
            .then((result) => {
                this.imgResultAfterCompress = result;
                this.localCompressedURl = result;

                this.sizeOFCompressedImage =
                    this.imageCompress.byteCount(result) / (1024 * 1024);
                console.warn(
                    "Size in bytes after compression:",
                    this.sizeOFCompressedImage
                );
                // create file from byte
                const imageName = fileName;
                // call method that creates a blob from dataUri
                const imageBlob = this.dataURItoBlob(
                    this.imgResultAfterCompress.split(",")[1]
                );
                //imageFile created below is the new compressed file which can be send to API in form data
                const imageFile = new File([result], imageName, {
                    type: this.mimeType,
                });

                this.imageToShow = null;
                this.imgURL = result;
                this.url = result;

                this.isSetFile = true;
                this.isDeleteFile = false;

                const file2 = this.blobToFile(imageBlob, imageName);

                this.fileFormGroup.get("file").setValue(imageBlob);
            });
    }

    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: this.mimeType });

        return blob;
    }

    blobToFile(theBlob, fileName) {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    public delete() {
        this.isSetFile = false;
        this.isDeleteFile = true;
        this.imageToShow = null;
        this.url = null;
        this.imgURL = null;
        this.photo.nativeElement.value = "";
    }

    remainingText = 0;
    textAreaValueChange(value) {
        this.remainingText = 500 - value;
    }

    moveUp(array, index: number) {
        if (index > 0) {
            const extrasFormArray = array as FormArray;
            const extras = extrasFormArray.value;
            const newExtras = this.swap(extras, index - 1, index);

            extrasFormArray.setValue(newExtras);
            this.updatePage2();
            this.updatePage3();
        }
    }

    moveDown(array, index: number) {
        const extrasFormArray = array as FormArray;
        const extras = extrasFormArray.value;
        if (index < extras.length - 1) {
            const newExtras = this.swap(extras, index, index + 1);
            extrasFormArray.setValue(newExtras);
        }
        this.updatePage2();
        this.updatePage3();
    }

    swap(arr: any[], index1: number, index2: number): any[] {
        arr = [...arr];
        const temp = arr[index1];
        arr[index1] = arr[index2];
        arr[index2] = temp;
        return arr;
    }

    removeNullFields(formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach((key) => {
            formGroup.controls[key].setErrors(null);
            if (formGroup.controls[key] instanceof FormArray) {
                const array: Array<any> = formGroup.controls[key].value;
                let filtered = [];
                for (const obj of array) {
                    Object.keys(obj).filter((k) => {
                        if (obj[k] === null) {
                            delete obj[k];
                        }
                    });
                    filtered.push(obj);
                }
            }
        });
    }

    sortArrayOfObjects = (arr, key) => {
        return arr.sort((a, b) => {
            return a[key] - b[key];
        });
    };

    isSuccess: boolean = false;

    savePhoto() {
        if (this.isSetFile) {
            this.cvService
                .upload(this.fileFormGroup.get("file").value)
                .subscribe(
                    (data) => { },
                    (error) => {
                        alert("Photo invalide !");
                    },
                    () => { }
                );
        }
    }

    save() {
        this.removeNullFields(this.cvFormGroup);
        if (this.isDeleteFile) {
            this.cvFormGroup.get("photo").setValue("");
        }

        if (this.cvFormGroup.valid) {
            this.cvService.save(this.cvFormGroup.value).subscribe(
                (data) => {
                    this.cv = data;
                    this.isSuccess = true;
                },
                (error) => {
                    alert("Informations invalides");
                },

                () => {
                    this.savePhoto();
                }
            );
            return;
        } else alert("Informations invalides 2");
    }

    sortBy(FieldName: string, myItems) {
        myItems.setValue(
            myItems.value.sort((a, b) => a[FieldName] - b[FieldName])
        );
    }

    firstcolor: string;
    secondcolor: string;
    thirdcolor: string;
    fontfamily: string;
    pagecolor: string;
    theme: any;
    findOne(id) {

        this.cvService.findOne2(id).subscribe(
            (data) => {
                this.cv = data;

                this.initCV2(data);
                data.skills.forEach((el) => {
                    this.cvSkills().push(this.initCvSkill2(el));
                });

                data.experiences.forEach((el) => {
                    this.cvExperiences().push(this.initCvExperience2(el));
                });

                data.educations.forEach((el) => {
                    this.cvEducations().push(this.initCvEducation2(el));
                });

                data.customs.forEach((el) => {
                    this.cvCustoms().push(this.initCvCustom2(el));
                });

                data.formations.forEach((el) => {
                    this.cvFormations().push(this.initCvFormation2(el));
                });
                data.certifications.forEach((el) => {
                    this.cvCertifications().push(this.initCvCertification2(el));
                });
                data.references.forEach((el) => {
                    this.cvReferences().push(this.initCvReference2(el));
                });

                data.languages.forEach((el) => {
                    this.cvLanguages().push(this.initCvLanguage2(el));
                });
            },
            (error) => {
                alert("Informations invalides");
                let link = ["/"];
                this.router.navigate(link);
            },
            () => {
                this.firstcolor = this.cv.firstcolor;
                this.secondcolor = this.cv.secondcolor;
                this.thirdcolor = this.cv.thirdcolor;
                this.pagecolor = this.cv.pagecolor;
                this.theme = this.cv.theme;

                this.sortBy("index", this.cvEducations());
                this.sortBy("index", this.cvCustoms());
                this.sortBy("index", this.cvFormations());
                this.sortBy("index", this.cvExperiences());
                this.sortBy("index", this.cvReferences());
                this.sortBy("index", this.cvCertifications());

                if (this.cv.photo) {
                    this.showImage(this.cv.photo);
                }
                document.getElementById("my-icon-select2").style.pointerEvents =
                    "auto";
                this.fillSecondPage2();
                this.fillSecondPage3();
                this.selectTheme();
            }
        );
    }

    findOne2(id) {
        document.getElementById("my-icon-select2").style.pointerEvents = "none";
        this.cvService.findOne(id).subscribe(
            (data) => {
                this.cv = data;

                this.initCV2(data);
                data.skills.forEach((el) => {
                    this.cvSkills().push(this.initCvSkill2(el));
                });

                data.experiences.forEach((el) => {
                    this.cvExperiences().push(this.initCvExperience2(el));
                });

                data.educations.forEach((el) => {
                    this.cvEducations().push(this.initCvEducation2(el));
                });

                data.customs.forEach((el) => {
                    this.cvCustoms().push(this.initCvCustom2(el));
                });

                data.formations.forEach((el) => {
                    this.cvFormations().push(this.initCvFormation2(el));
                });
                data.certifications.forEach((el) => {
                    this.cvCertifications().push(this.initCvCertification2(el));
                });
                data.references.forEach((el) => {
                    this.cvReferences().push(this.initCvReference2(el));
                });

                data.languages.forEach((el) => {
                    this.cvLanguages().push(this.initCvLanguage2(el));
                });
            },
            (error) => {
                alert("Informations invalides");
                let link = ["/"];
                this.router.navigate(link);
            },
            () => {
                this.firstcolor = this.cv.firstcolor;
                this.secondcolor = this.cv.secondcolor;
                this.thirdcolor = this.cv.thirdcolor;
                this.pagecolor = this.cv.pagecolor;
                this.theme = this.cv.theme;

                this.sortBy("index", this.cvEducations());
                this.sortBy("index", this.cvCustoms());
                this.sortBy("index", this.cvFormations());
                this.sortBy("index", this.cvExperiences());
                this.sortBy("index", this.cvReferences());
                this.sortBy("index", this.cvCertifications());

                if (this.cv.photo) {
                    this.showImage(this.cv.photo);
                }
                document.getElementById("my-icon-select2").style.pointerEvents =
                    "auto";

                this.selectTheme();
            }
        );
    }

    imageToShow: any = null;

    showImage(photoname: string): any {
        this.cvService
            .fetchImageById(photoname)

            .subscribe(
                (image) => {
                    this.createImage(image);
                },
                (err) => {
                    this.handleImageRetrievalError(err);
                },
                () => { }
            );
    }

    private handleImageRetrievalError(err: Error) {
        console.error(err);

        //alert("Problem retrieving profile photo.");
    }

    createImage(image: Blob) {
        if (image && image.size > 0) {
            let reader = new FileReader();
            reader.addEventListener(
                "load",
                () => {
                    this.imageToShow = reader.result;
                    // this.showSpinner = false;
                },
                false
            );

            reader.readAsDataURL(image);
        } else {
            //this.showSpinner = false;
        }
    }

    imageToShow2: any;
    showImage2(photoname: string) {
        this.cvService
            .fetchImageById2(photoname)

            .subscribe(
                (image) => {
                    this.createImage2(image);
                },
                (err) => {
                    this.handleImageRetrievalError(err);
                },
                () => { }
            );
    }

    createImage2(image: Blob) {
        if (image && image.size > 0) {
            let reader = new FileReader();
            reader.addEventListener(
                "load",
                () => {
                    this.imageToShow2 = reader.result;
                    // this.showSpinner = false;
                },
                false
            );

            reader.readAsDataURL(image);
        } else {
            //this.showSpinner = false;
        }
    }

    isCrypt: boolean = true;
    async update() {
        this.isCrypt = false;

        this.isOpen = false;
        this.spin1 = true;
        document.getElementById("my-icon-select2").style.pointerEvents = "none";
        if (this.cvFormGroup.get("educations").value.length > 1) {
            for (
                let i = 1;
                i <= this.cvFormGroup.get("educations").value.length;
                i++
            ) {
                this.cvEducations()
                    .at(i - 1)
                    .get("index")
                    .setValue(i - 1);
            }
        }
        if (this.cvFormGroup.get("customs").value.length > 1) {
            for (
                let i = 1;
                i <= this.cvFormGroup.get("customs").value.length;
                i++
            ) {
                this.cvCustoms()
                    .at(i - 1)
                    .get("index")
                    .setValue(i - 1);
            }
        }
        if (this.cvFormGroup.get("formations").value.length > 1) {
            for (
                let i = 1;
                i <= this.cvFormGroup.get("formations").value.length;
                i++
            ) {
                this.cvFormations()
                    .at(i - 1)
                    .get("index")
                    .setValue(i - 1);
            }
        }

        if (this.cvFormGroup.get("certifications").value.length > 1) {
            for (
                let i = 1;
                i <= this.cvFormGroup.get("certifications").value.length;
                i++
            ) {
                this.cvCertifications()
                    .at(i - 1)
                    .get("index")
                    .setValue(i - 1);
            }
        }

        if (this.cvFormGroup.get("references").value.length > 1) {
            for (
                let i = 1;
                i <= this.cvFormGroup.get("references").value.length;
                i++
            ) {
                this.cvReferences()
                    .at(i - 1)
                    .get("index")
                    .setValue(i - 1);
            }
        }
        if (this.cvFormGroup.get("experiences").value.length > 1) {
            for (
                let i = 1;
                i <= this.cvFormGroup.get("experiences").value.length;
                i++
            ) {
                this.cvExperiences()
                    .at(i - 1)
                    .get("index")
                    .setValue(i - 1);
            }
        }

        this.removeNullFields(this.cvFormGroup);
        if (this.isDeleteFile) {
            this.cvFormGroup.get("photo").setValue("");
        }

        if (this.cvFormGroup.valid) {
            this.cvFormGroup.get("theme").setValue(this.theme);
            this.cvFormGroup.get("idmodele").setValue(this.idmodele);
            this.cvFormGroup.get("firstcolor").setValue(this.firstcolor);
            this.cvFormGroup.get("secondcolor").setValue(this.secondcolor);
            this.cvFormGroup.get("thirdcolor").setValue(this.thirdcolor);
            this.cvFormGroup.get("pagecolor").setValue(this.pagecolor);
            this.cvFormGroup.get("fontfamily").setValue(this.fontfamily);
            this.cvFormGroup
                .get("address")
                .setValue(
                    this.encrypt.transform(
                        this.cvFormGroup.get("address").value
                    )
                );
            this.cvFormGroup
                .get("telephone")
                .setValue(
                    this.encrypt.transform(
                        this.cvFormGroup.get("telephone").value
                    )
                );

            this.cvService.update(this.cvFormGroup.value, this.cv.id).subscribe(
                (data) => {
                    this.cv = data;
                    //alert(JSON.stringify(data))
                },
                (error) => {
                    alert("Déconnecté, la page va se recharger!");
                    let link = ["/"];
                    this.router.navigate(link);
                },

                () => {
                    this.savePhoto();
                    document.getElementById(
                        "my-icon-select2"
                    ).style.pointerEvents = "auto";
                    /*
                        this.sortBy("index", this.cvEducations());
                         this.sortBy("index", this.cvCustoms());
                        this.sortBy("index", this.cvFormations());
                        this.sortBy("index", this.cvExperiences());
                        this.sortBy("index", this.cvReferences());
                        this.sortBy("index", this.cvCertifications());
                        */
                    setTimeout(() => {
                        this.isCrypt = true;
                        this.isOpen = true;
                        this.spin1 = false;
                    }, 1000);

                    setTimeout(() => {
                        this.findOne2(this.cv.id);
                    }, 777);
                }
            );
            return;
        } else alert("Veuilez recharger la page stp!");
    }

    async update2() {
        if (this.isDeleteFile) {
            this.cvFormGroup.get("photo").setValue("");
        }

        if (this.cvFormGroup.get("educations").value.length > 1) {
            for (
                let i = 1;
                i <= this.cvFormGroup.get("educations").value.length;
                i++
            ) {
                this.cvEducations()
                    .at(i - 1)
                    .get("index")
                    .setValue(i - 1);
            }
        }
        if (this.cvFormGroup.get("customs").value.length > 1) {
            for (
                let i = 1;
                i <= this.cvFormGroup.get("customs").value.length;
                i++
            ) {
                this.cvCustoms()
                    .at(i - 1)
                    .get("index")
                    .setValue(i - 1);
            }
        }
        if (this.cvFormGroup.get("formations").value.length > 1) {
            for (
                let i = 1;
                i <= this.cvFormGroup.get("formations").value.length;
                i++
            ) {
                this.cvFormations()
                    .at(i - 1)
                    .get("index")
                    .setValue(i - 1);
            }
        }

        if (this.cvFormGroup.get("certifications").value.length > 1) {
            for (
                let i = 1;
                i <= this.cvFormGroup.get("certifications").value.length;
                i++
            ) {
                this.cvCertifications()
                    .at(i - 1)
                    .get("index")
                    .setValue(i - 1);
            }
        }

        if (this.cvFormGroup.get("references").value.length > 1) {
            for (
                let i = 1;
                i <= this.cvFormGroup.get("references").value.length;
                i++
            ) {
                this.cvReferences()
                    .at(i - 1)
                    .get("index")
                    .setValue(i - 1);
            }
        }
        if (this.cvFormGroup.get("experiences").value.length > 1) {
            for (
                let i = 1;
                i <= this.cvFormGroup.get("experiences").value.length;
                i++
            ) {
                this.cvExperiences()
                    .at(i - 1)
                    .get("index")
                    .setValue(i - 1);
            }
        }
        this.removeNullFields(this.cvFormGroup);
        if (this.cvFormGroup.valid) {
            this.cvFormGroup.get("theme").setValue(this.theme);
            this.cvFormGroup.get("firstcolor").setValue(this.firstcolor);
            this.cvFormGroup.get("secondcolor").setValue(this.secondcolor);
            this.cvFormGroup.get("thirdcolor").setValue(this.thirdcolor);
            this.cvFormGroup.get("pagecolor").setValue(this.pagecolor);
            this.cvFormGroup.get("fontfamily").setValue(this.fontfamily);
            this.cvFormGroup.get("idmodele").setValue(this.idmodele);
            this.cvFormGroup
                .get("address")
                .setValue(
                    this.encrypt.transform(
                        this.cvFormGroup.get("address").value
                    )
                );
            this.cvFormGroup
                .get("telephone")
                .setValue(
                    this.encrypt.transform(
                        this.cvFormGroup.get("telephone").value
                    )
                );

            this.cvService.update(this.cvFormGroup.value, this.cv.id).subscribe(
                (data) => {
                    this.cv = data;
                },
                (error) => {
                    alert("Déconnecté, la page va se recharger!");
                    let link = ["/"];
                    this.router.navigate(link);
                },

                () => {
                    this.savePhoto();
                    this.sortBy("index", this.cvEducations());
                    this.sortBy("index", this.cvCustoms());
                    this.sortBy("index", this.cvFormations());
                    this.sortBy("index", this.cvExperiences());
                    this.sortBy("index", this.cvReferences());
                    this.sortBy("index", this.cvCertifications());
                    setTimeout(() => {
                        this.findOne(this.cv.id);
                    }, 777);
                }
            );
            return;
        } else alert("Veuilez recharger la page stp!");
    }

    async openPDF() {
        var totalPDFPages = 1;
        await setTimeout(() => {
            this.isLoading = true;
            this.isOpen = false;
            this.spin1 = true;
            //  this.update2();
        }, 0);

        setTimeout(() => {
            var x1 = $("#right_side1").prop("scrollHeight");
            var y1 = $("#right_side1").prop("clientHeight") + 30;

            var x2 = $("#left_side1").prop("scrollHeight");
            var y2 = $("#left_side1").prop("clientHeight") + 30;

            if (x1 > 2287 || x2 > 2287) {
                totalPDFPages = 3;
            } else if (x1 > y1 || x2 > y2) {
                totalPDFPages = 2;
            } else {
                totalPDFPages = 1;
            }
        }, 1500);

        setTimeout(() => {
            for (let i = 1; i <= totalPDFPages; i++) {
                $("#content" + i).css({
                    position: "relative",
                    height: "100%",
                    "min-height": "841.995pt",
                    "min-width": "595.28pt",
                });
                $("#right_side" + i).css({
                    position: "relative",
                    height: "841.5pt",
                    "min-height": "841.5pt",
                    "min-width": "396.18pt",
                });
                $("#left_side" + i).css({
                    position: "relative",
                    height: "841.995pt",
                    "min-height": "841.995pt",
                    "min-width": "198.45pt",
                });
            }
        }, 2100);

        setTimeout(() => {
            var pdf = new jsPDF("p", "pt", "a4");
            for (let i = 1; i <= totalPDFPages; i++) {
                html2canvas($("#content" + i)[0], {
                    allowTaint: true,
                    scale: 3,
                }).then((canvas) => {
                    //canvas.getContext('2d');

                    var imgData = canvas.toDataURL("image/jpeg", 0.7);

                    pdf.addImage(imgData, "JPEG", 0, 0, 595.35, 841.995);

                    if (i === totalPDFPages) {
                        setTimeout(async () => {
                            pdf.save("CV_" + this.cvFormGroup.get("firstname").
                                value + "_" + this.cvFormGroup.get("lastname").value + ".pdf");

                            this.isOpen = true;
                            this.spin1 = false;
                            this.isLoading = false;
                        }, 2900);
                    } else {
                        pdf.addPage("a4", "p");
                    }
                });
            }
        }, 2900);
    }
}
/*




*/
