<div class="hero-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-content">
                            <span class="sub-title">Réussissez votre candidature</span>
                            <h1>Créez un CV moderne et professionel </h1>
                            <p>Faites-vous remarquer et augmentez votre chance sur le marché du travail en utilisant nos modèles professionnels et modernes.</p>
                            <div class="btn-box">
                                <a routerLink="/modeles" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Modèles CV</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                <a *ngIf="!this.tokenStorage.getToken()" routerLink="/se-connecter" class="optional-btn">Créer mon CV</a>
                                <a *ngIf="this.tokenStorage.getToken()" routerLink="/cvs" class="optional-btn">Mon Tableau de bord</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-image text-center">
                            <img src="assets/img/banner-img1.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
