<div class="feedback-slides">
    <owl-carousel-o [options]="feedbackSlides">
        <ng-template carouselSlide>
            <div class="single-feedback-item">
                <p>“Pour avoir été parmi les premiers testeurs, j'avoue être bluffé par la rapidité à laquelle j'ai pu créer un magnifique CV.”

                </p>
                <div class="info">
                    <h3>Yann M.</h3>
                    <span>Brazaville, Congo</span>
                    <img src="assets/img/user.png" class="shadow rounded-circle" alt="image">
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-feedback-item">
                <p>“Facile et simple à utiliser avec plusieurs possibilités de customization. Ceci est un magnifique service !”</p>
                <div class="info">
                    <h3>Farel B.</h3>
                    <span>Pointe-Noire, Congo</span>
                    <img src="assets/img/user.png" class="shadow rounded-circle" alt="image">
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-feedback-item">
                <p>“Great job, great site! je suis très impressonnée par la qualité des CV”</p>
                <div class="info">
                    <h3>Taliane M. </h3>
                    <span>Brazzaville, Congo</span>
                    <img src="assets/img/user.png" class="shadow rounded-circle" alt="image">
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
