<section class="main-banner-wrapper">
    <div class="container">
        <div class="banner-wrapper-content">
            <h1>Comment créer son CV ?  </h1>  
              
        </div>
        <br>
       
            <br>
        <h3><span class="orange">1</span><strong> S'enregistrer</strong></h3>
        <p>Avant de pouvoir créer votre Cv vous devez vous connecter, et pour vous connecter, vous devez vous enregistrer.

        </p>
    </div>
   

    <div class="container">
        <br>
        <h3><span class="orange">2</span><strong> Se connecter</strong></h3>
        <p>Insérer son email et son mot de passe puis cliquer sur "Se connecter". </p>
    </div>
   

    <div class="container">
        <br>
        <h3><span class="orange">3</span><strong> Créer son CV</strong></h3>
       
    </div>
   
    <div  class="col-lg-12">
        
        <div class="row">
           
            <div  class=" col-lg-612col-md-12 banner-wrapper-image text-center">
               
                <video width="100%" height="100%" loop autoplay muted>
                    <source src="assets/img/create1.mp4" type="video/mp4">
                   
                   Votre navigateur ne supporte pas la vidéo!
                  </video>
              
            </div>
           
        </div>
       
    </div>
    <div class="container">
        <br>
        <h3><span class="orange">3</span><strong> Gérer les coupures de mots</strong></h3>
        <h5>Au cas où votre CV fait plus d'une page et que vous avez des mots qui se coupent, vous pouvez insérer les espaces dans rubriques précédentes. </h5>
    </div>
   
    <div class="col-lg-12">
        
        <div class="row">
           
            <div class=" col-lg-612col-md-12 banner-wrapper-image text-center">
               
                <video width="100%" height="100%" loop autoplay muted>
                    <source src="assets/img/create2.mp4" type="video/mp4">
                   
                   Votre navigateur ne supporte pas la vidéo!
                  </video>
              
            </div>
           
        </div>
       
    </div>
</section>
<!-- 

<ngx-tabset>
    <ngx-tab tabTitle="Créer son CV">
        
    </ngx-tab>
    <ngx-tab tabTitle="Additional information">
       
    </ngx-tab>
    <ngx-tab tabTitle="Reviews">
       
    </ngx-tab>
</ngx-tabset>
-->
