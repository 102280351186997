<div class="become-instructor-partner-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content bg-color">
                    <h2>Utilisez un exemple de lettres de motivations</h2>
                    <p>Faites un choix parmi nos exemples de lettres de motivations modifiables idéales pour accompagner vos CV et vos candidatures, que ce soit pour une réponse
                        à une annonce; pour une candidature spontanée ou un stage.</p>

                    <a routerLink="/cover-letter/examples" class="default-btn"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Choisir un exemple</span><i class="bx bx-plus-circle icon-arrow after"></i></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content">
                    <h2>Créer une lettre de motivation à partir de zéro</h2>
                    <p>Créer une lettre de motivation à partir de zéro et télécharger en PDF</p>
                    <a  routerLink="/cover-modeles" class="default-btn"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Créer maintenant</span><i class="bx bx-plus-circle icon-arrow after"></i></a>
                </div>
            </div>
            <!--
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image1">
                    <img src="assets/img/become-partner.jpg"  alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image2">
                    <img src="assets/img/become-instructor.jpg" alt="image">
                </div>
            </div>
            -->
        </div>
    </div>
</div>
