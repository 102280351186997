import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmBoxEvokeService } from '@costlydeveloper/ngx-awesome-popup';
import { TokenStorageService } from '../../cv-login-page/token-storage.service';
import { Cover } from '../cover';

import { ModeleService } from './cover-modele.service';
import { Modele } from './modele';


@Component({
  selector: 'cover-modele',
  templateUrl: './cover-modele.component.html',
  styleUrls: ['./cover-modele.component.scss']
})
export class CoverModeleComponent implements OnInit {

    listModele: Array<Modele>=[];
    modele : Modele;

  constructor(private modeleService: ModeleService,
    private router : Router,

    private tokenStorageService: TokenStorageService,
    private confirmBoxEvokeService: ConfirmBoxEvokeService
) { }

tokenStorage: any;

ngOnInit(): void {
    this.tokenStorage = this.tokenStorageService;
      //this.findAll();
      //this.findAllcover();

  }

  listcover: Array<Cover> = [];


  listModele2: Array<any>=[

    {id:1,"name":"modele1","firstcolor":"","secondcolor":"","thirdcolor":"","pagecolor":"","fontfamily":"","flag":"1"},
    {id:2,"name":"modele2","firstcolor":"","secondcolor":"","thirdcolor":"","pagecolor":"","fontfamily":"","flag":"1"},
    {id:3,"name":"modele3","firstcolor":"","secondcolor":"","thirdcolor":"","pagecolor":"","fontfamily":"","flag":"1"},
    {id:4,"name":"modele4","firstcolor":"","secondcolor":"","thirdcolor":"","pagecolor":"","fontfamily":"","flag":"1"},
    {id:5,"name":"modele5","firstcolor":"","secondcolor":"","thirdcolor":"","pagecolor":"","fontfamily":"","flag":"1"},
    {id:6,"name":"modele6","firstcolor":"","secondcolor":"","thirdcolor":"","pagecolor":"","fontfamily":"","flag":"1"},
    {id:7,"name":"modele7","firstcolor":"","secondcolor":"","thirdcolor":"","pagecolor":"","fontfamily":"","flag":"1"},
    {id:8,"name":"modele8","firstcolor":"","secondcolor":"","thirdcolor":"","pagecolor":"","fontfamily":"","flag":"1"},


    ];

  findAll(){
    this.modeleService.findAll().subscribe(
        (data) => {

            this.listModele = data;

        },
        (error) => {
          let link = ["/se-connecter"];
          this.confirmBoxEvokeService
          .danger('Se connecter', 'Veuillez-vous connecter !', 'Ok')
          .subscribe(
            (resp) => this.router.navigate(link) );
        },
        () => {

        }
    );

  }

  findOne(id : number){
    this.modeleService.findOne(id).subscribe(
        (data) => {

            this.modele = data;

        },
        (error) => {
          let link = [""];
          this.confirmBoxEvokeService
          .danger('Erreur', 'Retour à la page d\'accueil !', 'Ok')
          .subscribe(
            (resp) => this.router.navigate(link) );
        },
        () => {

        }
    );

  }

  addCover(idModele): void {

   if(this.tokenStorageService.getToken()===null){

    let link = ["se-connecter"];
    this.router.navigate(link);
   }else {
    if (this.listcover.length < 120) {
    let link = ["cover-modeles/m"+idModele+"-add-cover"];
    this.router.navigate(link);
    }
    else {
      let link = ["cvs"];
      this.confirmBoxEvokeService
          .info('Info', 'Vous avez déjà atteint le nombre maximal (12) de lettres de motivation', 'Ok')
          .subscribe(
            (resp) => this.router.navigate(link) );



    }


   }

  }


}
