<div class="login-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="assets/img/login-bg.jpg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/black-logo.png" alt="image"></a>
                            </div>
                            <h3>Bienvenue! </h3>
                            <p>Nouveau sur iCV?
                                <a routerLink="/register"> S'enregistrer</a>
                            </p>
                            <p>{{loginMessage}}</p>
                            <form [formGroup]="loginFormGroup" (ngSubmit)="login()">
                                <div class="form-group">
                                    <input class="form-control" type="email" placeholder="Email" formControlName="email">
                                    <div class="alert alert-danger" *ngIf="isSubmitted && formControls.email.errors &&
                                    formControls.email.errors.required">L'email est requis</div>
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Mot de passe" formControlName="password">
                                    <div class="alert alert-danger" *ngIf="isSubmitted && formControls.password.errors
                                     && formControls.password.errors.required">
                                        Le mot de passe est requis</div>
                                </div>
                                <button type="button" class="vvv" (click)="login()">Se connecter</button>


                                <div class="forgot-password">
                                    <a routerLink="/">Mot de passe oublié?</a>
                                </div>
                                <div class="connect-with-social">
                                    <button type="submit" style="margin-bottom: 12px;" class="google"><i class='bx bxl-google'></i>Se connecter avec Google</button>
                                    <button type="submit" class="facebook"><i class='bx bxl-linkedin'></i> Se connecter avec Linkedin</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
