<loading *ngIf="isLoading">
    {{ isLoading }}</loading>

<div id="cvt" class="container-fluid" style="height: 100%; width: 100%;" >
    <div class="row">


        <div id="cvt1" class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="height: 100%;  " >


            <div class="checkout-area ptb-100" style="margin-left : 14px; margin-right: 33px ">
                <div class="container">
                    <div class="user-actions" style="margin-bottom: 0; ">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-6 ">

                                <span *ngIf="isOpen">
                                    <a *ngIf="!this.tokenStorage.getToken()" style=" font-size: 15px; font-weight: 600;"
                                        routerLink="/se-connecter">
                                        <i class="bx bx-arrow-back"></i>
                                        {{'login' | translate}}
                                    </a>
                                    <a *ngIf="this.tokenStorage.getToken()" style=" font-size: 15px;font-weight: 600;"
                                        routerLink="/cvs/cover_letters">
                                        <i class="bx bx-arrow-back"></i>
                                        {{'dashboard' | translate}}
                                    </a>
                                </span>
                            </div>


                            <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 ">
                                <div class="form-group">
                                    <button *ngIf="isOpen" type="button" class="form-control btn btn-sm btn-secondary"
                                        style="padding: 3px;padding-bottom: 29px; font-size: 12px;  color: #fff; background: #003147; height: 30px;width: 110px;"
                                        (click)="update()">


                                        <i style="color: #fff; font-size: 18px;" class="bx bx-save"></i>
                                        {{'save' | translate}}
                                    </button>
                                    <button *ngIf="spin1" type="button" class=" form-control btn "
                                        style="padding: 7px; font-size: 12px;  height: 30px;width: 30px;">
                                        <span role="status" style="color: #003147;"
                                            class="spinner-border spinner-danger spinner-border-sm"></span>
                                    </button>


                                    <button *ngIf="isOpen" type="button" class=" form-control btn btn-sm btn-secondary"
                                        (click)="openPDF()"
                                        style="padding: 3px;padding-bottom: 29px; font-size: 12px; color: #fff; background: #003147; height: 10px;width: 110px;">
                                        <i style="color: #fff; font-size: 18px;" class="bx bx-download"></i>
                                        {{'download' | translate}}
                                    </button>



                                </div>


                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div class=" input-group">
                                    <label class="input-group-text" style="padding-bottom: 7px; padding-left: 3px;" for>
                                        <i class="bx bx-globe"></i>
                                    </label>
                                    <select class="form-select" (click)="selectLanguage($event)" [value]="userLanguage"
                                        style="padding: 7px; font-size: 13px;  height: 33px;width: auto; font-weight: 600;"
                                        name id>


                                        <option value="fr">  {{'french' | translate}}</option>
                                        <option value="en">  {{'english' | translate}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                              
                            </div>
                        </div>


                    </div>
                    <form [formGroup]="coverFormGroup">
                        <div id="content_1" class="row" style="height: 1000pt; overflow: scroll;">


                            <div class="col-lg-12 col-md-12" style="margin-bottom: -14px;">
                                <div class="billing-details">


                                    <div class="row">





                                        <div class="col-lg-12 col-md-12">
                                            <div class="row">


                                                <div class="col-lg-12 col-md-12" style="visibility: hidden;">
                                                    <div class="form-group">


                                                        <input type="text" class="form-control">
                                                    </div>
                                                </div>


                                                <div class="col-lg-12 col-md-12" style="visibility: hidden;">
                                                    <div class="form-group">


                                                        <input type="text" class="form-control">
                                                    </div>
                                                </div>


                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">


                                                        <input type="text" placeholder="{{'firstname' | translate}}"
                                                            formControlName="firstname"
                                                            [value]="coverFormGroup.get('firstname').value"
                                                            class="form-control">




                                                    </div>
                                                </div>


                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">


                                                        <input type="text" formControlName="lastname"
                                                            [value]="coverFormGroup.get('lastname').value"
                                                            placeholder="{{'lastname' | translate}}"
                                                            class="form-control">


                                                    </div>
                                                </div>


                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">


                                                        <input type="text" formControlName="profession"
                                                            [value]="coverFormGroup.get('profession').value"
                                                            title="{{'profession' | translate}}"
                                                            placeholder="{{'profession' | translate}}"
                                                            class="form-control">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6" *ngIf="isAdmin" >
                                                    <div class="form-group">


                                                        <input type="text" formControlName="name"

                                                            placeholder="{{'cv_name' | translate}}"
                                                            class="form-control">


                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6" *ngIf="isAdmin">
                                                    <div class="form-group">


                                                        <input type="text" placeholder="{{'flag' | translate}}"
                                                            formControlName="flag2"

                                                            class="form-control">




                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div class="col-12" style="margin-right: 14px;">


                                            <div class="tabs">





                                                <div class="tab">


                                                    <input class="input2" #rd1 type="checkbox" id="rd1"
                                                        (change)="check1($event)">
                                                    <label class="tab-label" for="rd1"
                                                        style="font-size: 14px; text-transform: uppercase;">
                                                        {{'header' | translate}}
                                                    </label>
                                                    <div class="tab-content">


                                                        <div class="row">

                                                            <div class="col-lg-12 col-md-12">
                                                                <div class="form-group">


                                                                    <input
                                                                        *ngIf="isCrypt && !this.coverFormGroup.get('address').value.includes('U2FsdG')"
                                                                        type="text" formControlName="address"
                                                                        [value]="this.coverFormGroup.get('address').value"
                                                                        title="{{'address' | translate}}"
                                                                        placeholder="{{'address' | translate}}"
                                                                        class="form-control" >

                                                                    <input
                                                                        *ngIf="!isCrypt || this.coverFormGroup.get('address').value.includes('U2FsdG')"
                                                                        type="text" placeholder="{{'address' | translate}}"
                                                                        class="form-control">


                                                                </div>
                                                            </div>


                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">


                                                                    <input
                                                                        *ngIf="isCrypt && !this.coverFormGroup.get('telephone').value.includes('U2FsdG')"
                                                                        type="text" formControlName="telephone"
                                                                        [value]="this.coverFormGroup.get('telephone').value"
                                                                        title="{{'phone' | translate}}"
                                                                        placeholder="{{'phone' | translate}}"
                                                                        class="form-control" >

                                                                    <input
                                                                        *ngIf="!isCrypt || this.coverFormGroup.get('telephone').value.includes('U2FsdG')"
                                                                        type="text"
                                                                        placeholder="{{'phone' | translate}}"
                                                                        class="form-control">


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">


                                                                    <input type="text" formControlName="linkedin"
                                                                        placeholder="Linkedin" class="form-control">
                                                                </div>
                                                            </div>



                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">


                                                                    <input type="email" formControlName="email"
                                                                        placeholder="Email" class="form-control">
                                                                </div>
                                                            </div>





                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <input type="text" formControlName="date"
                                                                        placeholder="Date" class="form-control">
                                                                </div>
                                                            </div>



                                                            <div class="col-lg-12 col-md-12">
                                                                <div class="form-group">
                                                                    <label class="input-group-text" style="font-size:18px ; text-align: left; padding:7px ;"  for>
                                                                      {{ 'recipient' | translate}}
                                                                     </label>
                                                                    <div class="textarea-wrapper" >



                                                                        <ckeditor [editor]="Editor" formControlName="recipient"  ></ckeditor>


                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div class="col-lg-12 col-md-12">
                                                                <div class="form-group"></div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="tab">
                                                    <input class="input2" type="checkbox" id="rd2" #rd2
                                                        (change)="check2($event)">
                                                    <label class="tab-label" for="rd2"
                                                        style="font-size: 14px; text-transform: uppercase;">
                                                        {{'content' | translate }}

                                                    </label>


                                                    <div class="tab-content">
                                                        <div class="row">

                                                            <div class="col-lg-12 col-md-12">
                                                                <div class="form-group">

                                                                    <input type="text" formControlName="subject"
                                                                        title="{{'subject' | translate}}"
                                                                        placeholder="{{'subject' | translate}}"
                                                                        class="form-control">
                                                                </div>
                                                            </div>


                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">

                                                                    <input type="text" formControlName="title"
                                                                        title="{{'title' | translate}}"
                                                                        placeholder="{{'dear' | translate}}"
                                                                        class="form-control">
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 col-md-12">
                                                                <div class="form-group">
                                                                    <label class="input-group-text" style="font-size:18px ; text-align: left; padding:7px ;"  for>
                                                                       Introduction
                                                                     </label>
                                                                    <div class="textarea-wrapper">


                                                                        <ckeditor id="introduction" [editor]="Editor" formControlName="introduction"  ></ckeditor>




                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div class="col-lg-12 col-md-12">
                                                                <div class="form-group">
                                                                    <label class="input-group-text" style="font-size:18px ; text-align: left; padding:7px ;"  for>
                                                                      Situation actuelle
                                                                     </label>
                                                                    <div class="textarea-wrapper">


                                                                        <ckeditor id="actual" [editor]="Editor" formControlName="actual"  ></ckeditor>




                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 col-md-12">
                                                                <div class="form-group">
                                                                    <label class="input-group-text" style="font-size:18px ; text-align: left; padding:7px ;"  for>
                                                                       Motivations
                                                                    </label>
                                                                    <div class="textarea-wrapper">



                                                                        <ckeditor id="motivation" [editor]="Editor" formControlName="motivation"></ckeditor>


                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 col-md-12">
                                                                <div class="form-group">
                                                                    <label class="input-group-text" style="font-size:18px ; text-align: left; padding:7px ;"  for>
                                                                       Fin : Formule de politesse
                                                                     </label>
                                                                    <div class="textarea-wrapper">



                                                                        <ckeditor [editor]="Editor" formControlName="end"  ></ckeditor>


                                                                    </div>
                                                                </div>
                                                            </div>




                                                        </div>
                                                    </div>













                                                </div>







                                            </div>

                                            <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 ">
                                                <div class="form-group">
                                                    <button *ngIf="isOpen" type="button" class="form-control btn btn-sm btn-secondary"
                                                        style="padding: 3px;padding-bottom: 29px; font-size: 12px;  color: #fff; background: #003147; height: 30px;width: 110px;"
                                                        (click)="update()">


                                                        <i style="color: #fff; font-size: 18px;" class="bx bx-save"></i>
                                                        {{'save' | translate}}
                                                    </button>
                                                    <button *ngIf="spin1" type="button" class=" form-control btn "
                                                        style="padding: 7px; font-size: 12px;  height: 30px;width: 30px;">
                                                        <span role="status" style="color: #003147;"
                                                            class="spinner-border spinner-danger spinner-border-sm"></span>
                                                    </button>


                                                    <button *ngIf="isOpen" type="button" class=" form-control btn btn-sm btn-secondary"
                                                        (click)="openPDF()"
                                                        style="padding: 3px;padding-bottom: 29px; font-size: 12px; color: #fff; background: #003147; height: 10px;width: 110px;">
                                                        <i style="color: #fff; font-size: 18px;" class="bx bx-download"></i>
                                                        {{'download' | translate}}
                                                    </button>



                                                </div>


                                            </div>
                                        </div>






                                    </div>
                                </div>






                            </div>


                        </div>
                    </form>




                </div>


            </div>


        </div>


        <!--   CV TEMPLATE -->

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"  >

            <div class="container">
                <div class="user-actions" style="background: #47494b; padding-top : 7px; padding-bottom: 3px;">


                    <div id="content_2" class="row">

                        <div class="col-lg-2 col-md-2 col-sm-6 icon1" style="z-index : 40">
                            <div #iconselect2 (click)="onChangeModele()" id="my-icon-select2" style="margin: 0;"></div>
                            <label style="margin-left:3px ; color: #fff; font-weight: 400;">{{'modeles' |
                                translate}}</label>
                            <input #selectedText2 type="text" id="selected-text2" name="selected-text2"
                                style="width:65px; height:2px; visibility: hidden;">
                        </div>

                        <div class="col-lg-2 col-md-2 col-sm-6 icon1" style="z-index : 30;">
                            <div #iconselect (click)="onChangeTheme()" id="my-icon-select" style="margin: 0;"></div>
                            <label style="margin-left:3px ; color: #fff; font-weight: 400;">{{'themes' |
                                translate}}</label>
                            <input #selectedText type="text" id="selected-text" name="selected-text"
                                style="width:65px; height: 3px; visibility: hidden;">
                        </div>

                        <div class="col-lg-1 col-md-2 col-sm-2">
                            <input [(colorPicker)]="firstcolor" class="color1" [style.background]="firstcolor"
                                [cpPosition]="'right'" [cpOKButton]="true"
                                [cpPresetColors]="[ '#871919','#0c3d87', '#333536','#525214','#1b3f23','#3d3a30']"
                                [style.width]="'33px'" [cpOutputFormat]="'hex'"
                                >
                            <label style="margin-left:3px ; color: #fff; font-weight: 400;"></label>
                        </div>


                        <div class="col-lg-1 col-md-2 col-sm-2">
                            <input [(colorPicker)]="pagecolor" class="color1" [style.background]="pagecolor"
                                [cpPosition]="'right'" [style.width]="'33px'"
                            [cpPresetColors]="[ '#ffffff','#d4e2f7', '#d7e1e7','#f1e4e4','#e2efe9','#e0dbcf']"
                            [cpOKButton]="true" [cpOutputFormat]="'hex'"
                                >
                            <label style="margin-left:3px ; color: #fff; font-weight: 400;"></label>
                        </div>

                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                            <div class=" input-group">
                                <label class="input-group-text" style="padding-bottom: 7px; padding-left: 3px;" for>
                                    <i class="bx bx-font-family"></i>
                                </label>
                                <select class="form-select" (click)="selectFontFamily($event)" [value]="family1"
                                    style="padding: 7px; font-size: 11px;  height: 33px;width: auto; font-weight: 600;"
                                    >
                                    <option style="font-family: Arial, Helvetica, sans-serif;" value="Arial, Helvetica, sans-serif"> Arial</option>
                                    <option style="font-family: 'Arial Black', Times, serif;" value="'Arial Black', Times, serif"> Arial Black</option>
                                    <option style="font-family: 'Bahnschrift', sans-serif" value="'Bahnschrift', sans-serif"> Bahnschrift </option>
                                    <option style="font-family: calibri;" value="calibri"> Calibri</option>
                                    <option style="font-family:'Constantia', Times, serif;" value="'Constantia', Times, serif"> Canstania</option>
                                    <option style="font-family: 'Century Gothic'" value="'Century Gothic'"> Century G.</option>
                                    <option style="font-family: 'Comic Sans MS', 'Comic Sans', cursive" value="'Comic Sans Serif'"> Comic S. </option>
                                    <option style="font-family: 'Courier', Times, serif;" value="'Courier', Times, serif"> Courier</option>
                                    <option style="font-family: 'Ebrima', Times, serif;" value="'Ebrima', Times, serif"> Ebrima</option>
                                    <option style="font-family: Georgia,serif" value="Georgia,serif"> Georgia</option>
                                    <option style="font-family:Lucida Sans Unicode,Lucida Grande,sans-seri" value="Lucida Sans Unicode,Lucida Grande,sans-seri">Lucida  </option>
                                    <option style="font-family: 'Poppins', sans-serif" value="'Poppins', sans-serif"> Poppins</option>
                                    <option style="font-family: 'Tahoma', Times, serif;" value="'Tahoma', Times, serif"> Tahoma</option>
                                    <option style="font-family: 'Times New Roman' ;" value="'Times New Roman'"> Times N </option>
                                    <option style="font-family: Verdana ;" value="Verdana"> Verdana</option>


                                </select>
                            </div>
                        </div>
                        <div class="col-1"></div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                            <div class=" input-group">
                                <label class="input-group-text" style="padding-bottom: 7px; padding-left: 3px;" for>
                                    <i class="bx bx-font-size"></i>
                                </label>
                                <select class="form-select" (click)="selectFontSize($event)" [value]="fontsize"
                                    style="padding: 7px; font-size: 11px;  height: 33px;width: auto; font-weight: 600;"
                                    >
                                    <option value="12px"> XS</option>
                                    <option value="14px"> S</option>

                                    <option value="15px"> M </option>
                                    <option value="17px"> L</option>
                                    <option value="20px"> XL </option>



                                </select>
                            </div>
                        </div>
                        <div class="col-1"></div>

                    </div>

                </div>

            </div>

            <!-- CV RESUME-->

            <form [formGroup]="coverFormGroup" >

                <div id="cvt2" class="div_body">

                    <div class="marge cvt20"  >


                        <div id="content1"
                            class="resume-wrapper-inner mx-auto text-left bg-white shadow-lg text-justify"
                            style="width: 595.35pt ; min-width: 595.35pt; height: 841.995pt;min-height:841.995pt;max-width :595.35pt ">
                            <header class="resume-header "
                                style="width: 595.35pt;min-width: 595.35pt; height: 109pt ;max-width: 595.35pt; position: relative;"
                                [style.background]="pagecolor">
                                <div class="media "
                                    style="width: 595.35pt;min-width: 595.35pt; max-width: 595.35pt; height: 197pt ;">



                                    <div class="media-body p-4"
                                        style="width: 395.35pt;min-width: 395.35pt; height: 197pt ; margin: 33px;">
                                        <div class="primary-info">
                                            <div class="row">
                                                <h1 class="mt-0 mb-1 "
                                                style="font-size: 34px; color: #000;  "
                                                [style.color]="firstcolor"  [style]="{'font-family': family1}" >{{this.coverFormGroup.get("firstname").value}}
                                                <strong  [style]="{'font-family': family1}" style="text-transform: uppercase ;">{{this.coverFormGroup.get("lastname").value}}</strong></h1>


                                            <div class="title " style="font-size: 18px; margin-top: 0.3px; margin-bottom: 3px;"> <span [style.color]="firstcolor"
                                                 style="font-weight: 600;"  [style]="{'font-family': family1}">
                                                {{this.coverFormGroup.get("profession").value}} </span></div>

                                                <div class="progress resume-progress" style="margin-bottom: 15px;">
                                                    <div class="progress-bar theme-progress-bar-dark"
                                                        role="progressbar"
                                                        [style.width]="'100%'"
                                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"
                                                        [style.background]="firstcolor"></div>
                                                </div>



                                        <div class="col-lg-7"  style="margin-top: 25px;">






                                            <ul class="list-unstyled" >

                                                <li *ngIf="coverFormGroup.get('address').value" class="liTitle">
                                                    <a href style="color: #000;">
                                                        <img src="assets/img/map2.png" width="18" height="18">
                                                    </a>


                                                    <span  [style]="{'font-family': family1,'font-size':fontsize}" *ngIf="this.coverFormGroup.get('address').value.includes('U2FsdG')"
                                                        class="liText">
                                                        <span  [style]="{'font-family': family1,'font-size':fontsize}">{{coverFormGroup.get('address').value | decrypted}}</span>


                                                    </span>
                                                    <span  [style]="{'font-family': family1,'font-size':fontsize}" *ngIf="!this.coverFormGroup.get('address').value.includes('U2FsdG')"
                                                        class="liText">
                                                        <span  [style]="{'font-family': family1,'font-size':fontsize}"> {{coverFormGroup.get('address').value | encrypted | decrypted}}</span>


                                                    </span>
                                                </li>


                                                <li *ngIf="coverFormGroup.get('telephone').value" class="liTitle">
                                                    <a href style="color: #000;">
                                                        <img src="assets/img/phone8.png" width="18" height="18">
                                                    </a>


                                                    <span  [style]="{'font-family': family1,'font-size':fontsize}" *ngIf="this.coverFormGroup.get('telephone').value.includes('U2FsdG')"
                                                        class="liText">
                                                        <span  [style]="{'font-family': family1,'font-size':fontsize}">{{coverFormGroup.get('telephone').value | decrypted}}</span>


                                                    </span>
                                                    <span  [style]="{'font-family': family1,'font-size':fontsize}" *ngIf="!this.coverFormGroup.get('telephone').value.includes('U2FsdG')"
                                                        class="liText">
                                                        <span  [style]="{'font-family': family1,'font-size':fontsize}"> {{coverFormGroup.get('telephone').value | encrypted | decrypted}}</span>


                                                    </span>
                                                </li>



                                                    <li id="ic4" *ngIf="coverFormGroup.get('linkedin').value.trim()" class="liTitle">
                                                        <img src="assets/img/linkedin4.png" width="18" height="18">
                                                        <span class="liText">
                                                            <span [style]="{'font-family': family1,'font-size':fontsize}" > {{coverFormGroup.get('linkedin').value}}</span>

                                                        </span>
                                                    </li>



                                                        <li *ngIf="coverFormGroup.get('email').value" class="liTitle">
                                                            <img id="ic1" src="assets/img/env2.png" width="18" height="18">
                                                            <strong [style]="{'font-family': family1,'font-size':fontsize}" class="liText">
                                                                <span [style]="{'font-family': family1,'font-size':fontsize}" style="color: #39393b;"> {{coverFormGroup.get('email').value}}</span>
                                                            </strong>
                                               </li>





                                            </ul>

                                        </div>
                                        <div class="col-lg-5"  style="margin-top: 25px;">
                                            <div class="col-lg-12"  >

                                                <ul style="margin-top: -3px; margin-right: 7px;">
                                                    <li class="liTitle">

                                                        <span
                                                            class="liText2" [style]="{'font-family': family1,'font-size':fontsize}"  >

                                                            {{coverFormGroup.get('date').value }}

                                                        </span>

                                                    </li>
                                                </ul>
                                            </div>
                                            <div style="height:33px ; ">

                                            </div>
                                            <ul style="margin-top: -15px; float: right">
                                                <li class="liTitle" >


                                                    <p  [style]="{'font-family': family1,'font-size':fontsize}" [innerHTML]="coverFormGroup.get('recipient').value"> </p>


                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                        </div>
                                        <!--//primary-info-->

                                        <!--//secondary-info-->

                                    </div>


                                    <!--//media-body-->
                                </div>
                                <!--//media-->
                            </header>

                            <div style="height: 133px;"></div>
                            <div class="resume-body p-5" style="min-width: 595.35pt; width:595.35pt ; ">

                                <!--//summary-section-->
                                <div class="row"   >

                                    <div class="col-12" id="left_side1"
                                        style="padding-left: 15px; padding-right: 15px; "  >

                                        <section
                                            class="resume-section summary-section mb-3"  >

                                            <div class="resume-section-content text-justify" style="color: #000; font-size: 14px; text-align: justify;">

                                                <p [style]="{'font-family': family1,'font-size':fontsize}"  *ngIf="coverFormGroup.get('subject').value" style="font-size: 14px; word-wrap: break-word; text-align: left; ">

                                                  <strong  [style]="{'font-family': family1,'font-size':fontsize}">  {{'subject' |
                                                    translate}} :</strong>  {{this.coverFormGroup.get("subject").value}}
                                                </p>
                                                <br>
                                                <p [style]="{'font-family': family1,'font-size':fontsize}"  style="font-size: 14px; word-wrap: break-word; text-align: justify; margin-bottom: -1px; ">

                                                    {{this.coverFormGroup.get("title").value}}
                                                </p>
                                                <br>
                                                <p [style]="{'font-family': family1,'font-size':fontsize}"  [innerHTML]="coverFormGroup.get('introduction').value" >
                                                     </p>

                                                <p [style]="{'font-family': family1,'font-size':fontsize}"  [innerHTML]="coverFormGroup.get('actual').value"> </p>

                                                <p [style]="{'font-family': family1,'font-size':fontsize}" [innerHTML]="coverFormGroup.get('motivation').value"> </p>

                                                <p [style]="{'font-family': family1,'font-size':fontsize}" [innerHTML]="coverFormGroup.get('end').value"> </p>



                                                <p [style]="{'font-family': family1}"  style="font-size: 16px; word-wrap: break-word; float: left; text-align: left; margin-top: 7px;">

                                                    {{this.coverFormGroup.get("firstname").value}}
                                                   <span  [style]="{'font-family': family1}" style="text-transform: uppercase ;">{{this.coverFormGroup.get("lastname").value}}</span>
                                                </p>
                                            </div>
                                        </section>



                                    </div>

                                </div>
                            </div>
                        </div>



                        <!--  END PAGE 1-->

                        <!--  START PAGE 2-->

                        <div id="content2"
                            class="resume-wrapper-inner mx-auto text-left bg-white shadow-lg text-justify"
                            style="width: 595.35pt ; min-width: 595.35pt; height: 841.995pt;min-height:841.995pt;max-width :595.35pt ">




                        </div>


                        <!--  END PAGE 2-->

                        <!--  START PAGE 3-->

                        <div id="content3"
                            class="resume-wrapper-inner mx-auto text-left bg-white shadow-lg text-justify"
                            style="width: 595.35pt ; min-width: 595.35pt; height: 841.995pt;min-height:841.995pt;max-width :595.35pt ">




                        </div>

                        <!--  END PAGE 3-->




                    </div>

                </div>
            </form>
        </div>

    </div>
</div>

<!--   FORM ARRAY         -->
