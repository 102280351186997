

<div id="cvt" class="container-fluid" style="height: 100%; width: 100%;" >
    <div class="row">




        <!--   CV TEMPLATE -->
        <div id="cvt" class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  >

            <!-- CV RESUME-->

            <form [formGroup]="coverFormGroup" >

                <div id="cvt2" class="">
                    <div id="cvt10" class="">


                        <div id="content1"
                            class="resume-wrapper-inner mx-auto text-left bg-white shadow-lg text-justify"
                            style="width: 595.35pt ; min-width: 595.35pt; height: 841.995pt;min-height:841.995pt;max-width :595.35pt ">
                            <header class="resume-header "
                                style="width: 595.35pt;min-width: 595.35pt; height: 84pt ;max-width: 595.35pt; position: relative;"
                                [style.background]="firstcolor">
                                <div class="media "
                                    style="width: 595.35pt;min-width: 595.35pt; max-width: 595.35pt; height: 84pt ;">



                                    <div class="media-body p-4"
                                        style="width: 395.35pt;min-width: 395.35pt; height: 195pt ; margin-left: 33px; margin-right: 33px;
                                        margin-top: -3px;">
                                        <div class="primary-info">
                                            <div class="row">
                                            <div class="col-lg-12">




                                            <h1 class="mt-0 mb-1 "
                                                style="font-size: 43px; color: rgb(248, 237, 237); font-weight: 700;
                                                text-align: right;"
                                                [style.color]="pagecolor"  [style]="{'font-family': family1}" >{{this.coverFormGroup.get("firstname").value}}
                                                <span  [style]="{'font-family': family1}" style="text-transform: uppercase ;">{{this.coverFormGroup.get("lastname").value}}</span></h1>



                                                <div class="title mb-3" style="font-size: 21px; margin-top : 0;
                                                text-align: right;"> <strong [style.color]="pagecolor"  [style]="{'font-family': family1}">
                                                    {{this.coverFormGroup.get("profession").value}} </strong></div>
                                                    <br>

                                                    <div class="primary-info">
                                                        <div class="row">




                                                    <div class="col-lg-7"  >




                                                        <ul class="list-unstyled" style="margin-top: 12px;" >

                                                            <li *ngIf="coverFormGroup.get('address').value" class="liTitle">
                                                                <a href style="color: #000;">
                                                                    <img src="assets/img/map2.png" width="18" height="18">
                                                                </a>


                                                                <span  [style]="{'font-family': family1,'font-size':fontsize}" *ngIf="this.coverFormGroup.get('address').value.includes('U2FsdG')"
                                                                    class="liText">
                                                                    <span  [style]="{'font-family': family1,'font-size':fontsize}">{{coverFormGroup.get('address').value | decrypted}}</span>


                                                                </span>
                                                                <span  [style]="{'font-family': family1,'font-size':fontsize}" *ngIf="!this.coverFormGroup.get('address').value.includes('U2FsdG')"
                                                                    class="liText">
                                                                    <span  [style]="{'font-family': family1,'font-size':fontsize}"> {{coverFormGroup.get('address').value | encrypted | decrypted}}</span>


                                                                </span>
                                                            </li>


                                                            <li *ngIf="coverFormGroup.get('telephone').value" class="liTitle">
                                                                <a href style="color: #000;">
                                                                    <img src="assets/img/phone8.png" width="18" height="18">
                                                                </a>


                                                                <span  [style]="{'font-family': family1,'font-size':fontsize}" *ngIf="this.coverFormGroup.get('telephone').value.includes('U2FsdG')"
                                                                    class="liText">
                                                                    <span  [style]="{'font-family': family1,'font-size':fontsize}">{{coverFormGroup.get('telephone').value | decrypted}}</span>


                                                                </span>
                                                                <span  [style]="{'font-family': family1,'font-size':fontsize}" *ngIf="!this.coverFormGroup.get('telephone').value.includes('U2FsdG')"
                                                                    class="liText">
                                                                    <span  [style]="{'font-family': family1,'font-size':fontsize}"> {{coverFormGroup.get('telephone').value | encrypted | decrypted}}</span>


                                                                </span>
                                                            </li>



                                                                <li id="ic4" *ngIf="coverFormGroup.get('linkedin').value.trim()" class="liTitle">
                                                                    <img src="assets/img/linkedin4.png" width="18" height="18">
                                                                    <span class="liText">
                                                                        <span [style]="{'font-family': family1,'font-size':fontsize}" > {{coverFormGroup.get('linkedin').value}}</span>

                                                                    </span>
                                                                </li>



                                                                    <li *ngIf="coverFormGroup.get('email').value" class="liTitle">
                                                                        <img id="ic1" src="assets/img/env2.png" width="18" height="18">
                                                                        <strong [style]="{'font-family': family1,'font-size':fontsize}" class="liText">
                                                                            <span [style]="{'font-family': family1,'font-size':fontsize}" style="color: #39393b;"> {{coverFormGroup.get('email').value}}</span>
                                                                        </strong>
                                                           </li>





                                                        </ul>

                                                    </div>
                                                    <div class="col-lg-5" style="margin-top: 12px;">
                                                        <div class="col-lg-12"  >

                                                            <ul style="margin-top: -3px; margin-right: 7px;">
                                                                <li class="liTitle">

                                                                    <span
                                                                        class="liText2" [style]="{'font-family': family1,'font-size':fontsize}"  >

                                                                        {{coverFormGroup.get('date').value }}

                                                                    </span>

                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div style="height:33px ; ">

                                                        </div>
                                                        <ul style="margin-top: -15px; float: right">
                                                            <li class="liTitle" >


                                                                <p  [style]="{'font-family': family1,'font-size':fontsize}" [innerHTML]="coverFormGroup.get('recipient').value"> </p>


                                                            </li>
                                                        </ul>

                                                    </div>

                                                    </div>
                                                    <!--//primary-info-->

                                                    <!--//secondary-info-->

                                                </div>


                                        </div>

                                    </div>
                                        </div>
                                        <!--//primary-info-->

                                        <!--//secondary-info-->

                                    </div>


                                    <!--//media-body-->
                                </div>
                                <!--//media-->
                            </header>

                            <div class="resume-body p-5" style="min-width: 595.35pt; width:595.35pt ; ">

                                <!--//summary-section-->
                                <div style="height: 133px;"></div>
                                <div class="row" >

                                    <div class="col-12" id="left_side1"
                                        style="padding-left: 12px; padding-right: 12px; "  >

                                        <section
                                            class="resume-section summary-section mb-3"  >

                                            <div class="resume-section-content text-justify" style="color: #000; font-size: 14px; text-align: justify;">
                                                <br>
                                                <br>
                                                <p [style]="{'font-family': family1,'font-size':fontsize}"  *ngIf="coverFormGroup.get('subject').value" style="font-size: 14px; word-wrap: break-word; text-align: left; ">

                                                  <strong  [style]="{'font-family': family1,'font-size':fontsize}">  {{'subject' |
                                                    translate}} :</strong>  {{this.coverFormGroup.get("subject").value}}
                                                </p>
                                                <br>
                                                <p [style]="{'font-family': family1,'font-size':fontsize}"  style="font-size: 14px; word-wrap: break-word; text-align: justify; margin-bottom: -1px; ">

                                                    {{this.coverFormGroup.get("title").value}}
                                                </p>
                                                <br>
                                                <p [style]="{'font-family': family1,'font-size':fontsize}"  [innerHTML]="coverFormGroup.get('introduction').value" >
                                                     </p>

                                                <p [style]="{'font-family': family1,'font-size':fontsize}"  [innerHTML]="coverFormGroup.get('actual').value"> </p>

                                                <p [style]="{'font-family': family1,'font-size':fontsize}" [innerHTML]="coverFormGroup.get('motivation').value"> </p>

                                                <p [style]="{'font-family': family1,'font-size':fontsize}" [innerHTML]="coverFormGroup.get('end').value"> </p>




                                                <p [style]="{'font-family': family1}"  style="font-size: 16px; word-wrap: break-word; float: left; text-align: left; margin-top: 7px;">

                                                    {{this.coverFormGroup.get("firstname").value}}
                                                   <span  [style]="{'font-family': family1}" style="text-transform: uppercase ;">{{this.coverFormGroup.get("lastname").value}}</span>
                                                </p>
                                            </div>
                                        </section>



                                    </div>

                                </div>
                            </div>
                        </div>

                        <!--  END PAGE 1-->


                    </div>

                </div>
            </form>
        </div>

    </div>
</div>

<!--   FORM ARRAY         -->
