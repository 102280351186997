<loading *ngIf="isLoading">
    {{ isLoading }}</loading>

<div id="cvt" class="container-fluid" style="height: 100%; width: 100%;">
    <div class="row">


        <div id="cvt1" class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="height: 100%;  ">


          <cover-notice2></cover-notice2>

        </div>

        <!--   CV TEMPLATE -->

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

            <div class="container">
                <div class="user-actions"
                    style="background: #47494b; padding-top : 7px; padding-bottom: 7px; height : 50px">


                    <div id="content_2" class="row">


                        <div style="visibility: hidden; max-height: 3px;" class="col-lg-1 col-md-1 col-sm-1 icon1">
                            <div #iconselect2 id="my-icon-select2" style="margin: 0;"></div>

                            <input #selectedText2 type="text" id="selected-text2" name="selected-text2"
                                style="width:65px; height:2px; visibility: hidden;">
                        </div>


                        <div style="visibility: hidden; max-height: 3px;" class="col-lg-1 col-md-1 col-sm-1 icon1">
                            <div #iconselect (click)="onChangeTheme()" id="my-icon-select" style="margin: 0;"></div>
                            <label style="margin-left:3px ; color: #fff; font-weight: 400;">
                                {{'themes' |
                                translate}}
                            </label>
                            <input #selectedText type="text" id="selected-text" name="selected-text"
                                style="width:65px; height: 3px; visibility: hidden;">
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2"></div>


                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <button *ngIf="isOpen" type="button" class=" form-control btn btn-sm btn-secondary"
                                (click)="useExample(cover)"
                                style="padding: 7px; font-size: 14px; color: #fff; background: #003147; height: 33px;">
                                <i style="color: #fff; font-size: 15px;" class="bx bxs-duplicate"></i>
                                {{'usethiscv' | translate}}
                            </button>
                        </div>

                        <div class="col-lg-2 col-md-2 col-sm-2"></div>

                        <div class="col-lg-2 col-md-2 col-sm-2"></div>


                    </div>


                </div>


            </div>

            <!-- CV RESUME-->

            <form [formGroup]="coverFormGroup">

                <div id="cvt2" class="div_body">

                    <div class="marge cvt20">


                        <div id="content1"
                            class="resume-wrapper-inner mx-auto text-left bg-white shadow-lg text-justify"
                            style="width: 595.35pt ; min-width: 595.35pt; height: 841.995pt;min-height:841.995pt;max-width :595.35pt ">
                            <header class="resume-header "
                                style="width: 595.35pt;min-width: 595.35pt; height: 195pt ;max-width: 595.35pt; position: relative;"
                                [style.background]="pagecolor">
                                <div class="media "
                                    style="width: 595.35pt;min-width: 595.35pt; max-width: 595.35pt; height: 195pt ;">



                                    <div class="media-body p-4"
                                        style="width: 395.35pt;min-width: 395.35pt; height: 195pt ; margin: 33px;">
                                        <div class="primary-info">
                                            <div class="row">
                                                <div class="col-lg-7">



                                                    <h1 class="mt-0 mb-1 "
                                                        style="font-size: 18px; color: #000; font-weight: 700;"
                                                        [style.color]="firstcolor" [style]="{'font-family': family1}">
                                                        {{this.coverFormGroup.get("firstname").value}}
                                                        <span [style]="{'font-family': family1}"
                                                            style="text-transform: uppercase ;">{{this.coverFormGroup.get("lastname").value}}</span>
                                                    </h1>
                                                    <div class="title mb-3" style="font-size: 14px; margin-top : 0;">
                                                        <strong [style]="{'font-family': family1}">
                                                            {{this.coverFormGroup.get("profession").value}} </strong>
                                                    </div>
                                                    <ul class="list-unstyled">

                                                        <li *ngIf="coverFormGroup.get('address').value.trim() || coverFormGroup.get('city').value.trim()"
                                                            style="font-size: 14px;"
                                                            [style]="{'font-family': family1,'font-size':fontsize}">
                                                            <span
                                                                [style]="{'font-family': family1,'font-size':fontsize}"
                                                                style="font-weight: 700;">{{'address' | translate}}
                                                                :</span>
                                                            <span
                                                                [style]="{'font-family': family1,'font-size':fontsize}"
                                                                *ngIf="this.coverFormGroup.get('address').value.includes('U2FsdG')">
                                                                {{coverFormGroup.get('address').value | decrypted}}
                                                            </span>
                                                            <span
                                                                [style]="{'font-family': family1,'font-size':fontsize}"
                                                                *ngIf="!this.coverFormGroup.get('address').value.includes('U2FsdG')">
                                                                {{coverFormGroup.get('address').value | encrypted |
                                                                decrypted}}
                                                            </span>
                                                        </li>
                                                        <li style="font-size: 14px;"
                                                            *ngIf="coverFormGroup.get('telephone').value.trim()">
                                                            <strong
                                                                [style]="{'font-family': family1,'font-size':fontsize}">{{'phone'
                                                                | translate}} :</strong> <span
                                                                [style]="{'font-family': family1,'font-size':fontsize}"
                                                                *ngIf="!this.coverFormGroup.get('telephone').value.includes('U2FsdG')">
                                                                {{coverFormGroup.get('telephone').value | encrypted |
                                                                decrypted}}
                                                            </span>
                                                            <span
                                                                [style]="{'font-family': family1,'font-size':fontsize}"
                                                                *ngIf="this.coverFormGroup.get('telephone').value.includes('U2FsdG')">
                                                                {{coverFormGroup.get('telephone').value | decrypted}}
                                                            </span>
                                                        </li>

                                                        <li style="font-size: 14px;"
                                                            [style]="{'font-family': family1,'font-size':fontsize}"
                                                            *ngIf="coverFormGroup.get('linkedin').value.trim()"><strong
                                                                [style]="{'font-family': family1,'font-size':fontsize}">Linkedin
                                                                :</strong> {{coverFormGroup.get('linkedin').value}}</li>

                                                        <li style="font-size: 14px;"
                                                            [style]="{'font-family': family1,'font-size':fontsize}"
                                                            *ngIf="coverFormGroup.get('email').value.trim()"><strong
                                                                [style]="{'font-family': family1,'font-size':fontsize}">Email
                                                                :</strong> {{coverFormGroup.get('email').value}}</li>





                                                    </ul>

                                                </div>
                                                <div class="col-lg-5">
                                                    <div class="col-lg-12">

                                                        <ul style="margin-top: 12px; margin-right: 7px;">
                                                            <li class="liTitle">

                                                                <span class="liText2"
                                                                    [style]="{'font-family': family1,'font-size':fontsize}">

                                                                    {{coverFormGroup.get('date').value }}

                                                                </span>

                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div style="height:33px ; ">

                                                    </div>
                                                    <ul style="margin-top: -15px; float: right">
                                                        <li class="liTitle">


                                                            <p [style]="{'font-family': family1,'font-size':fontsize}"
                                                                [innerHTML]="coverFormGroup.get('recipient').value">
                                                            </p>


                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                        <!--//primary-info-->

                                        <!--//secondary-info-->

                                    </div>


                                    <!--//media-body-->
                                </div>
                                <!--//media-->
                            </header>
                            <div style="height: 15px;"></div>
                            <div class="resume-body p-5" style="min-width: 595.35pt; width:595.35pt ; ">

                                <!--//summary-section-->
                                <div class="row">

                                    <div class="col-12" id="left_side1"
                                        style="padding-left: 15px; padding-right: 15px; ">

                                        <section class="resume-section summary-section mb-3">

                                            <div class="resume-section-content text-justify"
                                                style="color: #000; font-size: 14px; text-align: justify;">

                                                <p [style]="{'font-family': family1,'font-size':fontsize}"
                                                    *ngIf="coverFormGroup.get('subject').value"
                                                    style="font-size: 14px; word-wrap: break-word; text-align: left; ">

                                                    <strong [style]="{'font-family': family1,'font-size':fontsize}">
                                                        {{'subject' |
                                                        translate}} :</strong>
                                                    {{this.coverFormGroup.get("subject").value}}
                                                </p>
                                                <br>
                                                <p [style]="{'font-family': family1,'font-size':fontsize}"
                                                    style="font-size: 14px; word-wrap: break-word; text-align: justify; margin-bottom: -1px; ">

                                                    {{this.coverFormGroup.get("title").value}}
                                                </p>
                                                <br>
                                                <p [style]="{'font-family': family1,'font-size':fontsize}"
                                                    [innerHTML]="coverFormGroup.get('introduction').value">
                                                </p>

                                                <p [style]="{'font-family': family1,'font-size':fontsize}"
                                                    [innerHTML]="coverFormGroup.get('actual').value"> </p>

                                                <p [style]="{'font-family': family1,'font-size':fontsize}"
                                                    [innerHTML]="coverFormGroup.get('motivation').value"> </p>

                                                <p [style]="{'font-family': family1,'font-size':fontsize}"
                                                    [innerHTML]="coverFormGroup.get('end').value"> </p>


                                                    <p [style]="{'font-family': family1}"  style="font-size: 16px; word-wrap: break-word; float: left; text-align: left; margin-top: 7px;">

                                                        {{this.coverFormGroup.get("firstname").value}}
                                                       <span  [style]="{'font-family': family1}" style="text-transform: uppercase ;">{{this.coverFormGroup.get("lastname").value}}</span>
                                                    </p>
                                            </div>
                                        </section>



                                    </div>

                                </div>
                            </div>
                        </div>



                        <!--  END PAGE 1-->

                        <!--  START PAGE 2-->

                        <div id="content2"
                            class="resume-wrapper-inner mx-auto text-left bg-white shadow-lg text-justify"
                            style="width: 595.35pt ; min-width: 595.35pt; height: 841.995pt;min-height:841.995pt;max-width :595.35pt ">




                        </div>


                        <!--  END PAGE 2-->

                        <!--  START PAGE 3-->

                        <div id="content3"
                            class="resume-wrapper-inner mx-auto text-left bg-white shadow-lg text-justify"
                            style="width: 595.35pt ; min-width: 595.35pt; height: 841.995pt;min-height:841.995pt;max-width :595.35pt ">




                        </div>

                        <!--  END PAGE 3-->




                    </div>

                </div>
            </form>
        </div>

    </div>
</div>

<!--   FORM ARRAY         -->
