<div class="feedback-form-inner">
    <div class="row">
        <div class="col-lg-5 col-md-12">
            <div class="feedback-form-image">
                <img src="assets/img/note-pad.png" alt="image">
            </div>
        </div>
        <div class="col-lg-7 col-md-12">
            <div class="feedback-form">
                <h3>Contactez-nous</h3>
                <form [formGroup]="contactFormGroup">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input type="text" formControlName="name" class="form-control" placeholder="Nom ou Pseudo *">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input type="email" formControlName="email" class="form-control" placeholder="Email *">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input type="text" formControlName="localisation" class="form-control" placeholder="Ville, Pays *">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input type="text" formControlName="subject" class="form-control" placeholder="Objet *">
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <textarea cols="30" formControlName="message" rows="4" class="form-control" placeholder="Message *"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <p *ngIf="isSuccess" class="text-success">{{message}}</p>
                            <button *ngIf="!spin1" type="submit" [disabled]="!contactFormGroup.valid"
                            (click)="sendmessage()" class="default-btn btn-disable"><i class='bx bx-send icon-arrow before'>

                            </i><span class="label">Envoyer</span><i class="bx bx-send icon-arrow after"></i>
                        </button>
                        <button *ngIf="spin1" type="button" class="default-btn" >

                        <span role="status" style="color: #ffffff;"
                            class="spinner-border spinner-primary spinner-border-sm"></span>
                       </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
