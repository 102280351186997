import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth-signin.service';
import { TokenStorageService } from '../token-storage.service';
import { User_ } from '../user';


@Component({
  selector: 'reset-password',
  templateUrl: './cv-reset-password.component.html',
  styleUrls: ['./cv-reset-password.component.scss']
})
export class CVResetPasswordComponent implements OnInit {

    resetFormGroup: FormGroup;
    user: User_;
    isSubmitted  =  false;

 isLoggedIn = false;
  isLoginFailed = false;
  errorMessage: string = '';
  loginMessage: string = '';
  roles: string[] = [];
  mode: number=0;

    constructor(private fb :FormBuilder,
        private tokenStorage: TokenStorageService,
        private authService : AuthService,
        private router: Router) { }

  ngOnInit(): void {
    this.initRegisterForm();

}

initRegisterForm() : void{
  this.resetFormGroup  =  this.fb.group({
      code: ['', Validators.required],
      password: ['', Validators.required]
  });
}
get formControls() {
    return this.resetFormGroup.controls;
 }

 isLoading = false;
  user2: any;

   reset() {
        this.loginMessage = 'Réinitialisation en cours ...';

       this.authService.resetPassword(this.resetFormGroup.value).subscribe(
          (resp) => {

           // this.tokenStorage.saveToken(data.accessToken);

         this.user2=resp;

            this.isLoginFailed = false;
            this.isLoggedIn = true;
            this.loginMessage = 'Vous êtes connecté(e)';

          },
          err => {
            this.mode=1;
            this.isLoginFailed = true;
            this.errorMessage = err.error.message;
            this.loginMessage=this.errorMessage


          },
          ()=>{

            this.isLoading = true;

            setTimeout(() => {
             // alert(this.tokenStorage.getUser().id)
             this.isLoading = false
                 this.login();

              }, 3000);
          }
        );
      }

      login() {
        this.loginMessage = 'Tentative de connexion en cours ...';
        let userLogin ={
            email :this.user2.email,
            password : this.resetFormGroup.get("password").value
        }

       this.authService.login(userLogin).subscribe(
          (resp) => {

           // this.tokenStorage.saveToken(data.accessToken);
            let jwtToken=  resp.body.access_token;

           this.tokenStorage.saveToken("Bearer "+jwtToken);


            this.isLoginFailed = false;
            this.isLoggedIn = true;
            this.loginMessage = 'Vous êtes connecté(e)';

              // Récupère l'URL de redirection depuis le service d'authentification
              // Si aucune redirection n'a été définis, redirige l'utilisateur vers la liste des patients.
             // let redirect = this.redirectUrl ? this.redirectUrl : '/patient/list';

              // Redirige l'utilisateur


           // this.reloadPage();
          },
          err => {

           alert(err.status);

            this.isLoginFailed = true;
            this.errorMessage = err.error.message;

            this.mode=1;
          },
          ()=>{

           this.loadUser();

            setTimeout(() => {
             // alert(this.tokenStorage.getUser().id)

              if(this.tokenStorage.getUser()===null){

              }
              else {

                  let redirect ='/cvs';

                  this.router.navigate([redirect]);

              }
              }, 1000);


          }
        );
      }


      loadUser(): void {

        this.authService.findOne()
          .subscribe(
            (data) => {

              this.user=data;
              this.tokenStorage.saveUser(data);

            },
            (error) => {


              this.tokenStorage.signOut();


            },
            () => {


            }
          );
      }





}
