import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cv-notice2',
  templateUrl: './cv-notice2.component.html'

})
export class CvNotice2Component implements OnInit {

  constructor() { }



  ngOnInit(): void {



  }




}
