<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>A propos</li>
            </ul>
            <h2>A propos</h2>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
           
            <div class="col-lg-10 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">About Us !</span> -->
                    <h2 style="font-size: 25px;"> iCV est un outils efficace pour booster votre carrière .</h2>

                    <div class="features-text">
                        <h4 style="font-size: 18px">
                         Nous vous offrons un créateur de CV efficace pour faire la diiference lors de vos processus d'emploi, vous bénéficiez également
                         des conseils professionels gratuits et efficaces pour la gestion de votre carrière.
                    
                        </h4>
                       <br>
                       
                        </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="about-image">
                                    <img src="assets/img/about/about12.jpg" class="shadow" alt="image">
                
                                </div>
                            </div>
                           
                </div>
                <p style="font-size: 17px;">Ce site a commencé avec un outils de création de CV. Mais le but ultime de ce site est de dévénir un outils incontournable
                    de gestion de carrière. Dans les mises à jour à venir, il y'aura des outils de création de lettre de motivation, de proposition des
                    offres d'emploi, de suivi de carrière. 
                </p>
                <p style="font-size: 17px;">
                    Un outils incontournable pour les départements de ressources humaines (RH), pour la gestion des offres d'emploi
                , la gestion des candidatures et bien d'autres, la gestion des entretiens et bien d'autres fonctionnalités que nous dévélopperons
                au fur et à mésure.
                </p>
            </div>
        </div>

    </div>
</div>

<!--
<app-our-mission></app-our-mission>


<div class="story-area ptb-100">
    <app-our-story></app-our-story>
</div>

<div class="funfacts-area">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div>

<div class="values-area ptb-100">
    <app-our-values></app-our-values>
</div>

<div class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-two></app-instructors-style-two>
    </div>
</div>

<div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>
-->

<!--
<div class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>
-->
