<loading *ngIf="isLoading">
    {{ isLoading }}</loading>
<div class="register-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-image">
                <img src="assets/img/register-bg.jpg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="register-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/black-logo.png" alt="image"></a>
                            </div>
                            <h3>Enregistrez-vous sur iCV</h3>
                            <p>Déjà enregistré(e)? <a routerLink="/login">Se connecter</a></p>
                            <form [formGroup]="registerFormGroup">

                                <div class="form-group">
                                    <input class="form-control" type="email" placeholder="Email" formControlName="email">
                                    <div class="alert alert-danger" *ngIf="isSubmitted && formControls.email.errors &&
                                        formControls.email.errors.required">L'email est requis</div>

                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Créer un mot de passe" formControlName="password">
                                    <div class="alert alert-danger" *ngIf="isSubmitted && formControls.password.errors
                                     && formControls.password.errors.required">
                                        Le mot de passe est requis</div>
                                </div>
                                <button type="submit" (click)="register()">S'enregistrer</button>
                                <div class="connect-with-social">
                                    <span>Ou</span>
                                    <button type="submit" class="google"><i class='bx bxl-google' style="margin-bottom: 12px;"></i>Se connecter avec Google</button>
                                    <button type="submit" class="twitter"><i class='bx bxl-linkedin'></i>Se connecter avec Linkedin</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
