import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { LanguageService } from './components/pages/cv-language/language.service';
import {
    ButtonMaker,
    DialogLayoutDisplay,
    ToastNotificationInitializer,
    ToastPositionEnum,
    ButtonLayoutDisplay,
    AppearanceAnimation,
    DisappearanceAnimation
  } from '@costlydeveloper/ngx-awesome-popup';
import { ColorTypes } from '@costlydeveloper/ngx-awesome-popup/ngx-awesome-popup/core/global-classes';
import { TokenStorageService } from './components/pages/cv-login-page/token-storage.service';

declare let $: any;
// Davy BAKALA né le 03 Juin 1987
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    location: any;
    routerSubscription: any;

    constructor(private router: Router,
      private languageService: LanguageService,
      private tokenStorageService: TokenStorageService) {
    }

    ngOnInit(){
        this.recallJsFuntions();
      if(this.tokenStorageService.getToken()===null){
      // this.cookieBanner();
      }

    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    route(): void {

      let link = ["/privacy"];
      this.router.navigate(link);

    }
    // COOKIES

     // Create the method
  cookieBanner() {
    const newCookieBanner = new ToastNotificationInitializer();

    newCookieBanner.setMessage(
    'Nous utilisons les cookies pour améliorer votre3 expérience,Voulez-vous acceptez ?'+
    '<a style="font-weight : 600" [routerLink]="["/privacy"]" (click)="route()"  > Politique de confidentialité'+'</a>'
    );

    // Choose layout color type
    newCookieBanner.setConfig({
      buttonPosition: 'right', // optional
      layoutType: DialogLayoutDisplay.NONE, // SUCCESS | INFO | NONE | DANGER | WARNING
      toastPosition: ToastPositionEnum.BOTTOM_RIGHT, // TOP_LEFT | TOP_CENTER | TOP_RIGHT | TOP_FULL_WIDTH | BOTTOM_LEFT | BOTTOM_CENTER | BOTTOM_RIGHT | BOTTOM_FULL_WIDTH
      animationIn: AppearanceAnimation.SLIDE_IN_UP,
      animationOut: DisappearanceAnimation.SLIDE_OUT_DOWN,
      customStyles :{

       }
    });

    newCookieBanner.setButtons([
      new ButtonMaker('Politique de confidentialité', 'privacy', ButtonLayoutDisplay.DARK),

        new ButtonMaker('J\'accepte', 'okay', ButtonLayoutDisplay.INFO)

    ]);


    // Simply open the popup and observe button click
    newCookieBanner
      .openToastNotification$()
      .subscribe(resp => {
        if (resp.clickedButtonID==="okay") {

          console.log('Button clicked: ', resp.clickedButtonID);
        }else if (resp.clickedButtonID==="privacy") {

          this.route();
          this.cookieBanner();
        }else{
            this.deleteAllCookies()
        }
      });
  }

  deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

  delete_cookie( name, path, domain ) {
    if( this.get_cookie( name ) ) {
      document.cookie = name + "=" +
        ((path) ? ";path="+path:"")+
        ((domain)?";domain="+domain:"") +
       ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
   }
  }

  get_cookie(a) {
      var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
      return b ? b.pop() : '';
  }



}
