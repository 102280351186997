import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    Renderer2,
    ViewChild,
} from "@angular/core";
import jsPDF from "jspdf";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

import { from, Observable, Observer } from "rxjs";
import { map } from "rxjs/operators";
import {
    DataUrl,
    DOC_ORIENTATION,
    NgxImageCompressService,
    UploadResponse,
} from "ngx-image-compress";
import { Cover } from "../../cover";
import * as $ from "jquery";
import html2canvas from "html2canvas";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { CoverService } from "../../cover.service";

import { LanguageService } from "../../../cv-language/language.service";
import { DecryptPipe, EncryptPipe } from "src/app/encrypt-decrypt.pipe";
import { TokenStorageService } from "../../../cv-login-page/token-storage.service";

import { ConfirmBoxEvokeService } from "@costlydeveloper/ngx-awesome-popup";
import { ThemeService } from "../../../cv-theme/cv-theme.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CoverDashboardService } from "../../../cover-modele/cover-dashboard.service";


//import * as IconSelect from 'src/assets/lib/control/iconselect.js';

declare function IconSelect(value): void;

@Component({
    selector: "m7-edit-cover2",
    templateUrl: "./m7-edit-cover.component.html",
    styleUrls: ["./m7-edit-cover.component.scss"],
})
export class M7EditCover2Component implements OnInit, AfterViewInit {
    coverFormGroup: FormGroup;

    isIcon: boolean = false;
    listSpinner: boolean = false;
    isLoading: boolean = false;
    test1: string = "test";
    public Editor = ClassicEditor;
    family1 = "Arial, Helvetica, sans-serif"; //"Impact, Haettenschweiler, 'Franklin Gothic Bold'";
    family2 = "'Comic Sans MS',  sans-serif";

    loadScript(url) {
        const body = <HTMLDivElement>document.body;
        const script = document.createElement("script");
        script.innerHTML = "";
        script.src = url;
        script.async = false;
        script.defer = true;
        body.appendChild(script);
    }

    constructor(
        private coverService: CoverService,
        private imageCompress: NgxImageCompressService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private elementRef: ElementRef,
        private router: Router,
        private themeService: ThemeService,
        private languageService: LanguageService,
        private dashboardService: CoverDashboardService,
        private encrypt: EncryptPipe,
        private decrypt: DecryptPipe,
        private tokenStorageService: TokenStorageService,
        private confirmBoxEvokeService: ConfirmBoxEvokeService
    ) {
        ClassicEditor.defaultConfig = {

            toolbar: {
                items:[
                       'heading',
                       '|',
                       'bold',
                       'italic',
                       'underline',
                       '|',
                       'bulletedList',
                       'numberedList',
                       '|',
                       //'insertTable',
                       '|',
                       'undo',
                       'redo',
                       //'imageUpload',
                       'classicEditor',
                       //'blockQuote',
                       'list',
                      // 'mediaEmbed',
                       //'pasteFromOffice',
                       //'fontFamily',
                       'todoList',
                       //'youtube'
              ]
            },

            image: {
                toolbar: [
                    "imageStyle:full",
                    "imageStyle:side",
                    "|",
                    "imageTextAlternative",
                ],
            },

            table: {
                contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
            },
            language: "en",
        };


    }

    tokenStorage: any;

    ngOnInit(): void {
        this.tokenStorage = this.tokenStorageService;
        this.listSpinner = true;


        let id = this.route.snapshot.params["id"];
        this.isIcon = false;
        this.findOne(id);

       // this.getLanguage();



        // if (this.tokenStorageService.getToken() !== null) {
        //this.findAllCover();
        // }

        // if (this.tokenStorageService.getToken() !== null) {
        //this.findAllCover();
        // }



    }

    ngAfterViewInit(): void {
        this.fillSecondPage2();
        this.fillSecondPage3();
    }

    initModele() {
        this.firstcolor = "#1a2a3b";
        this.secondcolor = "#5891bc";
        this.pagecolor = "#fff";
    }

    selectSkill() {
        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 712);
    }

    languages: any;
    userLanguage: any = "fr";
    selectLanguage(event: any) {
        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 712);

        this.coverFormGroup
        .get("language")
        .setValue(event.target.value);


        return this.languageService.setLanguage(event.target.value);
    }

    selectFontFamily(event: any) {
        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 712);
        this.coverFormGroup
        .get("fontfamily")
        .setValue(event.target.value);
        this.fontfamily=event.target.value;

        return this.family1=event.target.value;

    }

    fontsize="14px";
    selectFontSize(event: any) {
        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 712);
        this.coverFormGroup
        .get("fontsize")
        .setValue(event.target.value);
        this.fontsize=event.target.value;

        return this.fontsize;

    }

    getLanguage() {
        this.userLanguage = this.languageService.userLanguage;
    }

    getFontFamily() {
       // this.family1 = this.languageService.userLanguage;
    }

    listLanguages() {
        this.languages = this.languageService.getAvailablelanguage;
    }

    idmodele: number ;
    getModele(idCover, idModele): void {

        let link = ["cvs/m" + idModele + "-edit-cover", idCover];
        this.router.navigate(link);
    }

    i2: number = 0;
    onChangeModele() {
        this.getTheme(1);
        this.removeNullFields(this.coverFormGroup);
        if (this.isDeleteFile) {
            this.coverFormGroup.get("photo").setValue("");
        }
        setTimeout(() => {
            this.i2++;
            this.i3 = 0;

            this.idmodele = +this.selectedText2.nativeElement.value;
            if (!isNaN(+this.selectedText2.nativeElement.value)) {
                if (this.i2 === 2) {
                    this.i2 = 0;
                    document.getElementById(
                        "my-icon-select2"
                    ).style.pointerEvents = "none";
                    this.coverFormGroup
                        .get("idmodele")
                        .setValue(+this.selectedText2.nativeElement.value);
                    this.coverFormGroup
                        .get("address")
                        .setValue(
                            this.encrypt.transform(
                                this.coverFormGroup.get("address").value
                            )
                        );
                    this.coverFormGroup
                        .get("telephone")
                        .setValue(
                            this.encrypt.transform(
                                this.coverFormGroup.get("telephone").value
                            )
                        );
                    if (this.idmodele === 1) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#1a2a3b");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#5891bc");

                        this.coverFormGroup.get("pagecolor").setValue("#fff");
                    }
                    if (this.idmodele === 2) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#212529");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#5eaae2");

                        this.coverFormGroup.get("pagecolor").setValue("#fff");
                    }
                    if (this.idmodele === 3) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#740913");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#8b9093");

                        this.coverFormGroup.get("pagecolor").setValue("#fff");
                    }
                    if (this.idmodele === 4) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#252727");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#5a9aba");

                        this.coverFormGroup.get("pagecolor").setValue("#fff");
                    }

                    if (this.idmodele === 5) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#2e3133");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#7e0905");

                        this.coverFormGroup.get("pagecolor").setValue("#fff");
                    }

                    if (this.idmodele === 6) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#182839");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#24601f");

                        this.coverFormGroup
                            .get("pagecolor")
                            .setValue("#cbefac");
                        this.idTheme = 26;
                        this.getTheme(26);
                    }

                    if (this.idmodele === 7) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#581b31");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#1a4978");

                        this.coverFormGroup.get("pagecolor").setValue("#fff");
                    }

                    if (this.idmodele === 8) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#454141");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#4c4d4e");

                        this.coverFormGroup
                            .get("pagecolor")
                            .setValue("#dce1e4");
                    }

                    this.coverFormGroup
                        .get("language")
                        .setValue(this.userLanguage);

                        this.coverFormGroup
                        .get("fontfamily")
                        .setValue(
                           this.family1

                        );
                        this.coverFormGroup
                        .get("fontsize")
                        .setValue(
                           this.fontsize

                        );
                        this.coverFormGroup
                        .get("language")
                        .setValue(this.userLanguage);
                    this.coverFormGroup.get("pagecolor").setValue("#fff");

                    this.coverFormGroup.get("theme").setValue(this.theme);
                    this.coverService.update(this.coverFormGroup.value, this.cover.id).subscribe(
                        (data) => {
                            this.cover = data;
                            this.isSuccess = true;
                        },
                        (error) => {
                            alert(error.error.message);
                            let link = ["/"];
                            this.router.navigate(link);
                        },

                        () => {
                            //alert(this.selectedText2.nativeElement.value);

                            setTimeout(() => {
                                this.findOne2(this.cover.id);
                            }, 312);

                            setTimeout(() => {
                                this.isCrypt = true;

                                this.getModele(
                                    this.cover.id,
                                    +this.selectedText2.nativeElement.value
                                );
                            }, 712);

                            this.savePhoto();
                            setTimeout(() => {
                                this.showCover2(this.cover.id, this.idmodele);
                                this.isOpen = true;
                                this.spin1 = false;
                                this.isLoading = false;
                            }, 2000);
                        }
                    );
                }
            } else {
                alert("veuillez recharger2 la page svp!");
            }
        }, 500);
    }

    i3: number = 0;
    idTheme: number = 1;
    onChangeTheme() {
        this.i3++;
        this.i2 = 0;
        if (this.i3 === 2) {
            this.i3 = 0;
            setTimeout(() => {
                //alert(this.selectedText.nativeElement.value);
                this.idTheme = +this.selectedText.nativeElement.value;
                this.getTheme(+this.selectedText.nativeElement.value);
                this.updatePage2();
                this.updatePage3();
            }, 500);
        }
    }

    getTheme(id) {
        this.themeService.findOne(id).subscribe(
            (data) => {
                this.theme = data;
            },
            (error) => {
                alert("Déconnecté, la page va se recharger!");
                let link = ["/"];
                this.router.navigate(link);
            },

            () => {
                this.firstcolor = this.theme.firstcolor;
                this.secondcolor = this.theme.secondcolor;
                this.thirdcolor = this.theme.thirdcolor;
                this.fontfamily = this.theme.fontfamily;
                this.pagecolor = this.theme.pagecolor;
                this.updatePage2();
                this.updatePage3();
                // alert(this.theme.id);
            }
        );
    }

    id2: any;
    iter: any = 4;

    @ViewChild("selectedText")
    selectedText: ElementRef;

    @ViewChild("selectedText2")
    selectedText2: ElementRef;

    @ViewChild("selectedText2")
    iconselect2: ElementRef;

    selectTheme() {
        var iconSelect;
        var iconSelect2;
        var selectedText;
        var selectedText2;

        this.id2 = 1;

        //this.iter=this.iter+1;

        var i = this.cover.theme.id;
        var i2 = this.cover.idmodele;
        var j = 1;
        setTimeout(() => {
            selectedText = document.getElementById("selected-text");
            selectedText2 = document.getElementById("selected-text2");

            document
                .getElementById("my-icon-select")
                .addEventListener("changed", function (e) {
                    j = j + 1;

                    if (j === 2) {
                        //iconSelect.setSelectedValue(i-1);
                        selectedText.value = iconSelect.setSelectedValue(i - 1);
                    } else {
                        iconSelect.getSelectedValue();
                        selectedText.value = iconSelect.getSelectedValue();
                    }
                });

            document
                .getElementById("my-icon-select2")
                .addEventListener("changed", function (e) {
                    j = j + 1;

                    if (j === 2) {
                        //iconSelect.setSelectedValue(i-1);
                        selectedText2.value = iconSelect2.setSelectedValue(
                            i2 - 1
                        );
                    } else {
                        iconSelect.getSelectedValue();
                        selectedText2.value = iconSelect2.getSelectedValue();
                    }
                });

            iconSelect = new IconSelect("my-icon-select");
            iconSelect2 = new IconSelect("my-icon-select2");

            var icons = [];
            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/1.png",
                iconValue: "1",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/2.png",
                iconValue: "2",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/3.png",
                iconValue: "3",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/4.png",
                iconValue: "4",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/5.png",
                iconValue: "5",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/6.png",
                iconValue: "6",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/7.png",
                iconValue: "7",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/8.png",
                iconValue: "8",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/9.png",
                iconValue: "9",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/10.png",
                iconValue: "10",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/11.png",
                iconValue: "11",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/12.png",
                iconValue: "12",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/13.png",
                iconValue: "13",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/14.png",
                iconValue: "14",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/15.png",
                iconValue: "15",
            });
            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/16.png",
                iconValue: "16",
            });
            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/17.png",
                iconValue: "17",
            });
            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/18.png",
                iconValue: "18",
            });
            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/19.png",
                iconValue: "19",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/20.png",
                iconValue: "20",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/21.png",
                iconValue: "21",
            });
            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/22.png",
                iconValue: "22",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/23.png",
                iconValue: "23",
            });

            icons.push({
                iconFilePath: "assets/images/icons-themes-cl/m5/24.png",
                iconValue: "24",
            });

            if (i > 0 && i < 24) {
                iconSelect.refresh(icons, i - 1);
            }
            if (i > 24 && i < 49) {
                iconSelect.refresh(icons, i - 24 - 1);
            }
            if (i > 48 && i < 73) {
                iconSelect.refresh(icons, i - 48 - 1);
            }
            if (i > 72 && i < 97) {
                iconSelect.refresh(icons, i - 72 - 1);
            }
            if (i > 96 && i < 121) {
                iconSelect.refresh(icons, i - 96 - 1);
            }
            if (i > 120 && i < 145) {
                iconSelect.refresh(icons, i - 120 - 1);
            }

            var icons2 = [];
            icons2.push({
                iconFilePath: "assets/images/icons-modeles-cl/1.png",
                iconValue: "1",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles-cl/2.png",
                iconValue: "2",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles-cl/3.png",
                iconValue: "3",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles-cl/4.png",
                iconValue: "4",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles-cl/5.png",
                iconValue: "5",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles-cl/6.png",
                iconValue: "6",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles-cl/7.png",
                iconValue: "7",
            });
            icons2.push({
                iconFilePath: "assets/images/icons-modeles-cl/8.png",
                iconValue: "8",
            });


            iconSelect2.refresh(icons2, i2 - 1);
        }, 0);
    }

    // Config textarea
    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: "8rem",
        minHeight: "7rem",
        placeholder: "Bref description ....",
        translate: "yes",
        defaultParagraphSeparator: "p",
        defaultFontName: "Calibri",
        defaultFontSize: "2",
        toolbarHiddenButtons: [
            ["strikeThrough", "subscript", "superscript"],
            [
                "heading",
                "backgroundColor",
                "customClasses",
                "link",
                "unlink",
                "insertImage",
                "insertVideo",
                "insertHorizontalRule",
                "removeFormat",
                "toggleEditorMode",
            ],
        ],
        customClasses: [
            {
                name: "quote",
                class: "quote",
            },
            {
                name: "redText",
                class: "redText",
            },
            {
                name: "titleText",
                class: "titleText",
                tag: "h1",
            },
        ],
    };

    @ViewChild("rd0") rd0: ElementRef;
    @ViewChild("rd1") rd1: ElementRef;
    @ViewChild("rd2") rd2: ElementRef;
    @ViewChild("rd3") rd3: ElementRef;
    @ViewChild("rd4") rd4: ElementRef;
    @ViewChild("rd5") rd5: ElementRef;
    @ViewChild("rd6") rd6: ElementRef;
    @ViewChild("rd7") rd7: ElementRef;
    @ViewChild("rd8") rd8: ElementRef;
    @ViewChild("rd9") rd9: ElementRef;
    @ViewChild("rd10") rd10: ElementRef;
    @ViewChild("rd11") rd11: ElementRef;
    @ViewChild("rd12") rd12: ElementRef;



    check1(event) {
        if (event.target.checked) {

            this.rd2.nativeElement.checked = false;

        }
    }

    check2(event) {
        if (event.target.checked) {

            this.rd1.nativeElement.checked = false;

        }
    }

    check3(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check4(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check5(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check6(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check7(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check8(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check9(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check10(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }

    check11(event) {
        if (event.target.checked) {
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd12.nativeElement.checked = false;
        }
    }
    check12(event) {
        if (event.target.checked) {
            this.rd0.nativeElement.checked = false;
            this.rd1.nativeElement.checked = false;
            this.rd3.nativeElement.checked = false;
            this.rd4.nativeElement.checked = false;
            this.rd6.nativeElement.checked = false;
            this.rd7.nativeElement.checked = false;
            this.rd8.nativeElement.checked = false;
            this.rd2.nativeElement.checked = false;
            this.rd5.nativeElement.checked = false;
            this.rd9.nativeElement.checked = false;
            this.rd10.nativeElement.checked = false;
            this.rd11.nativeElement.checked = false;
        }
    }

    fillSecondPage() {
        setTimeout(() => {
            $(function () {
                var copy = $("#content1").clone().attr("id", "onecopy").css({
                    "margin-top": "-841.5pt",
                    height: "1683pt",
                });
                $("#content2").append(copy);

                $("#content1").keyup(function () {
                    var copy2 = $("#content1")
                        .clone()
                        .attr("id", "onecopy2")
                        .css({
                            "margin-top": "-841.5pt",
                            height: "1683pt",
                        });

                    $("#content2").empty();
                    $("#content2").append(copy2);

                    $("#content1").css({
                        //"max-height": "841.5pt",
                        "min-height": "841.5pt",
                    });
                    $("#content2").css({
                        "max-height": "841.5pt",
                        "main-height": "841.5pt",
                    });

                    $("#content1").css({
                        "min-height": "841.5pt",
                        //"max-height": "841.5pt",
                    });
                    $("#content2").css({ "min-height": "841.5pt" });
                });
            });
        }, 250);
    }

    fillSecondPage2() {
        setTimeout(() => {
            $(function () {
                var copy = $("#content1").clone().attr("id", "onecopy").css({
                    "margin-top": "-841.5pt",
                    height: "1683pt",
                });

                $("#content2").append(copy);

                var copy11 = $("#content1")
                    .clone()
                    .attr("id", "onecopy11")
                    .css({
                        "margin-top": "-841.5pt",
                        height: "1683pt",
                    });
                $("#content2").empty();
                $("#content2").append(copy11);

                if (
                    $("#content1").prop("scrollHeight") <
                        $("#content1").prop("clientHeight") + 30 &&
                    $("#content1").prop("scrollHeight") <
                        $("#content1").prop("clientHeight") + 30
                ) {
                    $("#content2").css({
                        visibility: "hidden",
                        position: "absolute",
                        height: "2px",
                        "min-height": "2px",
                    });
                    $("#content2").css({
                        position: "absolute",
                        height: "2px",
                        "min-height": "2px",
                    });
                    $("#content2").css({
                        position: "absolute",
                        height: "2px",
                        "min-height": "2px",
                    });
                }

                $("#content_2").mouseup(function () {
                    if (
                        $("#content1").prop("scrollHeight") >
                            $("#content1").prop("clientHeight") + 30 ||
                        $("#content1").prop("scrollHeight") >
                            $("#content1").prop("clientHeight") + 30
                    ) {
                        var copy = $("#content1")
                            .clone()
                            .attr("id", "onecopy")
                            .css({
                                "margin-top": "-841.5pt",
                                height: "1683pt",
                            });
                        $("#content2").empty();
                        $("#content2").append(copy);

                        var copy11 = $("#content1")
                            .clone()
                            .attr("id", "onecopy11")
                            .css({
                                "margin-top": "-841.5pt",
                                height: "1683pt",
                            });
                        $("#content2").empty();
                        $("#content2").append(copy11);
                    }
                });

                $("#content_2").mousedown(function () {
                    if (
                        $("#content1").prop("scrollHeight") >
                            $("#content1").prop("clientHeight") + 30 ||
                        $("#content1").prop("scrollHeight") >
                            $("#content1").prop("clientHeight") + 30
                    ) {
                        var copy = $("#content1")
                            .clone()
                            .attr("id", "onecopy")
                            .css({
                                "margin-top": "-841.5pt",
                                height: "1683pt",
                            });
                        $("#content2").empty();
                        $("#content2").append(copy);

                        var copy11 = $("#content1")
                            .clone()
                            .attr("id", "onecopy11")
                            .css({
                                "margin-top": "-841.5pt",
                                height: "1683pt",
                            });
                        $("#content2").empty();
                        $("#content2").append(copy11);
                    }
                });

                $("#content_1").keyup(function () {
                    if (
                        $("#content1").prop("scrollHeight") >
                            $("#content1").prop("clientHeight") + 30 ||
                        $("#content1").prop("scrollHeight") >
                            $("#content1").prop("clientHeight") + 30
                    ) {
                        //if 'true', the content overflows the tab: we show the hidden link

                        //alert($('#content1').prop('clientHeight'));

                        // $('#content2').css('visibility', 'block');

                        $("#content2").css({
                            visibility: "visible",
                        });

                        setTimeout(() => {
                            var copy2 = $("#content1")
                                .clone()
                                .attr("id", "onecopy2")
                                .css({
                                    "margin-top": "-841.5pt",
                                    height: "1683pt",
                                });

                            $("#content2").empty();
                            $("#content2").append(copy2);

                            var copy22 = $("#content1")
                                .clone()
                                .attr("id", "onecopy22")
                                .css({
                                    "margin-top": "-841.5pt",
                                    height: "1683pt",
                                });
                            $("#content2").empty();
                            $("#content2").append(copy22);

                            $("#content1").css({
                                //"max-height": "841.5pt",
                                "min-height": "841.5pt",
                            });
                            $("#content2").css({
                                "max-height": "841.5pt",
                                "main-height": "841.5pt",
                            });

                            $("#content1").css({
                                "min-height": "841.5pt",
                                //"max-height": "841.5pt",
                            });
                            $("#content2").css({ "min-height": "841.5pt" });
                        }, 123);
                    } else {
                        $("#content2").css({ visibility: "hidden" });
                    }

                    setTimeout(() => {
                        //alert( $("#content1").prop("scrollHeight") +"___"+($("#content1").prop("clientHeight") + 30))
                        if (
                            $("#content1").prop("scrollHeight") >
                                $("#content1").prop("clientHeight") + 30 ||
                            $("#content1").prop("scrollHeight") >
                                $("#content1").prop("clientHeight") + 30
                        ) {
                            $("#content2").css({
                                visibility: "visible",
                                position: "relative",
                                height: "100%",
                                "min-height": "841.995pt",
                            });
                            $("#content2").css({
                                position: "relative",
                                height: "841.5pt",
                                "min-height": "841.5pt",
                            });
                            $("#content2").css({
                                position: "relative",
                                height: "841.5pt",
                                "min-height": "841.5pt",
                            });
                        } else {
                            $("#content2").css({
                                visibility: "hidden",
                                position: "absolute",
                                height: "2px",
                                "min-height": "2px",
                            });
                            $("#content2").css({
                                position: "absolute",
                                height: "2px",
                                "min-height": "2px",
                            });
                            $("#content2").css({
                                position: "absolute",
                                height: "2px",
                                "min-height": "2px",
                            });
                        }
                    }, 123);
                });
            });
        }, 712);
    }

    fillSecondPage3() {
        setTimeout(() => {
            $(function () {
                var copy = $("#content1")
                    .clone()
                    .attr("id", "onecopy2")
                    .css({
                        "margin-top": "-1683pt",
                        height: "2524.5pt",
                    });

                $("#content3").append(copy);

                var copy = $("#content1").clone().attr("id", "onecopy2").css({
                    "margin-top": "-1683pt",
                    height: "2524.5pt",
                });

                $("#content3").append(copy);

                if (
                    $("#content1").prop("scrollHeight") < 2287 &&
                    $("#content1").prop("scrollHeight") < 2287
                ) {
                    $("#content3").css({
                        visibility: "hidden",
                        position: "absolute",
                        height: "2px",
                        "min-height": "2px",
                    });
                    $("#content3").css({
                        position: "absolute",
                        height: "2px",
                        "min-height": "2px",
                    });
                    $("#content3").css({
                        position: "absolute",
                        height: "2px",
                        "min-height": "2px",
                    });
                }

                $("#content_2").mouseup(function () {
                    if (
                        $("#content1").prop("scrollHeight") > 2287 ||
                        $("#content1").prop("scrollHeight") > 2287
                    ) {
                        var copy = $("#content1")
                            .clone()
                            .attr("id", "onecopy")
                            .css({
                                "margin-top": "-1683pt",
                                height: "2524.5pt",
                            });
                        $("#content3").empty();
                        $("#content3").append(copy);

                        var copy11 = $("#content1")
                            .clone()
                            .attr("id", "onecopy11")
                            .css({
                                "margin-top": "-1683pt",
                                height: "2524.5pt",
                            });
                        $("#content3").empty();
                        $("#content3").append(copy11);
                    }
                });
                $("#content_2").mousedown(function () {
                    if (
                        $("#content1").prop("scrollHeight") > 2287 ||
                        $("#content1").prop("scrollHeight") > 2287
                    ) {
                        var copy = $("#content1")
                            .clone()
                            .attr("id", "onecopy")
                            .css({
                                "margin-top": "-1683pt",
                                height: "2524.5pt",
                            });
                        $("#content3").empty();
                        $("#content3").append(copy);

                        var copy11 = $("#content1")
                            .clone()
                            .attr("id", "onecopy11")
                            .css({
                                "margin-top": "-1683pt",
                                height: "2524.5pt",
                            });
                        $("#content3").empty();
                        $("#content3").append(copy11);
                    }
                });

                $("#content_1").keyup(function () {
                    if (
                        $("#content1").prop("scrollHeight") > 2287 ||
                        $("#content1").prop("scrollHeight") > 2287
                    ) {
                        //if 'true', the content overflows the tab: we show the hidden link

                        //alert($('#content1').prop('clientHeight'));

                        // $('#content2').css('visibility', 'block');
                        $("#content3").css({
                            visibility: "visible",
                        });

                        setTimeout(() => {
                            var copy3 = $("#content1")
                                .clone()
                                .attr("id", "onecopy3")
                                .css({
                                    "margin-top": "-1683pt",
                                    height: "2524.5pt",
                                });

                            $("#content3").empty();
                            $("#content3").append(copy3);

                            var copy33 = $("#content1")
                                .clone()
                                .attr("id", "onecopy33")
                                .css({
                                    "margin-top": "-1683pt",
                                    height: "2524.5pt",
                                });

                            $("#content3").empty();
                            $("#content3").append(copy33);
                            $("#content1").css({
                                //"max-height": "841.5pt",
                                "min-height": "841.5pt",
                            });
                            $("#content3").css({
                                "max-height": "841.5pt",
                                "main-height": "841.5pt",
                            });

                            $("#content1").css({
                                "min-height": "841.5pt",
                                //"max-height": "841.5pt",
                            });
                            $("#content3").css({ "min-height": "841.5pt" });
                        }, 127);
                    } else {
                        $("#content3").css({
                            visibility: "hidden",
                            position: "absolute",
                            height: "2px",
                            "min-height": "2px",
                        });
                        $("#content3").css({
                            position: "absolute",
                            height: "2px",
                            "min-height": "2px",
                        });
                        $("#content3").css({
                            position: "absolute",
                            height: "2px",
                            "min-height": "2px",
                        });
                    }

                    setTimeout(() => {
                        if ($("#content1").prop("scrollHeight") > 2287) {
                            $("#content3").css({
                                visibility: "visible",
                                position: "relative",
                                height: "100%",
                                "min-height": "841.995pt",
                            });
                            $("#content3").css({
                                position: "relative",
                                height: "841.5pt",
                                "min-height": "841.5pt",
                            });
                            $("#content3").css({
                                position: "relative",
                                height: "841.5pt",
                                "min-height": "841.5pt",
                            });
                        } else {
                            $("#content3").css({
                                visibility: "hidden",
                                position: "absolute",
                                height: "2px",
                                "min-height": "2px",
                            });
                            $("#content3").css({
                                position: "absolute",
                                height: "2px",
                                "min-height": "2px",
                            });
                            $("#content3").css({
                                position: "absolute",
                                height: "2px",
                                "min-height": "2px",
                            });
                        }
                    }, 373);
                });
            });
        }, 712);
    }

    updatePage2() {
        if (
            $("#content1").prop("scrollHeight") >
                $("#content1").prop("clientHeight") + 30 ||
            $("#content1").prop("scrollHeight") >
                $("#content1").prop("clientHeight") + 30
        ) {
            //if 'true', the content overflows the tab: we show the hidden link

            //alert($('#content1').prop('clientHeight'));

            // $('#content2').css('visibility', 'block');
            $("#content2").css({
                visibility: "visible",
            });

            setTimeout(() => {
                var copy2 = $("#content1")
                    .clone()
                    .attr("id", "onecopy2")
                    .css({
                        "margin-top": "-841.5pt",
                        height: "1683pt",
                    });

                $("#content2").empty();
                $("#content2").append(copy2);

                var copy11 = $("#content1")
                    .clone()
                    .attr("id", "onecopy11")
                    .css({
                        "margin-top": "-841.5pt",
                        height: "1683pt",
                    });
                $("#content2").empty();
                $("#content2").append(copy11);

                $("#content1").css({
                    //"max-height": "841.5pt",
                    "min-height": "841.5pt",
                });
                $("#content2").css({
                    "max-height": "841.5pt",
                    "main-height": "841.5pt",
                });

                $("#content1").css({
                    "min-height": "841.5pt",
                    //"max-height": "841.5pt",
                });
                $("#content2").css({ "min-height": "841.5pt" });
            }, 127);
        } else {
            $("#content2").css({
                visibility: "hidden",
                position: "absolute",
                height: "2px",
                "min-height": "2px",
            });
            $("#content2").css({
                position: "absolute",
                height: "2px",
                "min-height": "2px",
            });
            $("#content2").css({
                position: "absolute",
                height: "2px",
                "min-height": "2px",
            });
        }

        setTimeout(() => {
            if (
                $("#content1").prop("scrollHeight") >
                $("#content1").prop("clientHeight") + 30
            ) {
                $("#content2").css({
                    visibility: "visible",
                    position: "relative",
                    height: "100%",
                    "min-height": "841.995pt",
                });
                $("#content2").css({
                    position: "relative",
                    height: "841.5pt",
                    "min-height": "841.5pt",
                });
                $("#content2").css({
                    position: "relative",
                    height: "841.5pt",
                    "min-height": "841.5pt",
                });
            } else if (
                $("#content1").prop("scrollHeight") >
                $("#content1").prop("clientHeight") + 30
            ) {
                $("#content2").css({
                    visibility: "visible",
                    position: "relative",
                    height: "100%",
                    "min-height": "841.995pt",
                });
                $("#content2").css({
                    position: "relative",
                    height: "841.5pt",
                    "min-height": "841.5pt",
                });
                $("#content2").css({
                    position: "relative",
                    height: "841.5pt",
                    "min-height": "841.5pt",
                });
            } else {
                $("#content2").css({
                    visibility: "hidden",
                    position: "absolute",
                    height: "2px",
                    "min-height": "2px",
                });
                $("#content2").css({
                    position: "absolute",
                    height: "2px",
                    "min-height": "2px",
                });
                $("#content2").css({
                    position: "absolute",
                    height: "2px",
                    "min-height": "2px",
                });
            }
        }, 312);
    }

    updatePage3() {
        if ($("#content1").prop("scrollHeight") > 2287) {
            //if 'true', the content overflows the tab: we show the hidden link

            //alert($('#content1').prop('clientHeight'));

            // $('#content2').css('visibility', 'block');
            $("#content3").css({
                visibility: "visible",
            });

            setTimeout(() => {
                var copy3 = $("#content1")
                    .clone()
                    .attr("id", "onecopy3")
                    .css({
                        "margin-top": "-1683pt",
                        height: "2524.5pt",
                    });

                $("#content3").empty();
                $("#content3").append(copy3);

                var copy33 = $("#content1")
                    .clone()
                    .attr("id", "onecopy33")
                    .css({
                        "margin-top": "-1683pt",
                        height: "2524.5pt",
                    });

                $("#content3").empty();
                $("#content3").append(copy33);
                $("#content1").css({
                    //"max-height": "841.5pt",
                    "min-height": "841.5pt",
                });
                $("#content3").css({
                    "max-height": "841.5pt",
                    "main-height": "841.5pt",
                });

                $("#content1").css({
                    "min-height": "841.5pt",
                    //"max-height": "841.5pt",
                });
                $("#content3").css({ "min-height": "841.5pt" });
            }, 127);
        } else {
            $("#content3").css({
                visibility: "hidden",
                position: "absolute",
                height: "2px",
                "min-height": "2px",
            });
            $("#content3").css({
                position: "absolute",
                height: "2px",
                "min-height": "2px",
            });
            $("#content3").css({
                position: "absolute",
                height: "2px",
                "min-height": "2px",
            });
        }

        setTimeout(() => {
            if ($("#content1").prop("scrollHeight") > 2287) {
                $("#content3").css({
                    visibility: "visible",
                    position: "relative",
                    height: "100%",
                    "min-height": "841.995pt",
                });
                $("#content3").css({
                    position: "relative",
                    height: "841.5pt",
                    "min-height": "841.5pt",
                });
                $("#content3").css({
                    position: "relative",
                    height: "841.5pt",
                    "min-height": "841.5pt",
                });
            } else {
                $("#content3").css({
                    visibility: "hidden",
                    position: "absolute",
                    height: "2px",
                    "min-height": "2px",
                });
                $("#content3").css({
                    position: "absolute",
                    height: "2px",
                    "min-height": "2px",
                });
                $("#content3").css({
                    position: "absolute",
                    height: "2px",
                    "min-height": "2px",
                });
            }
        }, 373);
    }

    @ViewChild("content2") content2: ElementRef;
    @ViewChild("content1") content1: ElementRef;
    @ViewChild("content3") content3: ElementRef;

    cover: Cover;
    covers = [];
    // skills: Array<any> = [];
    spin1: boolean = false;
    spin2: boolean = false;
    isSave: boolean = true;
    isOpen: boolean = true;

    checkBtn(event) {
        this.isIcon = event.target.checked;
        setTimeout(() => {
            this.updatePage2();
            this.updatePage3();
        }, 712);
    }



    initCover(): void {
        //alert(this.decrypt.transform(cover.firstname));
        this.coverFormGroup = this.fb.group({
            name: [""],

            firstname: [""],

            lastname: [""],

            profession: [""],


            telephone: [""],

            email: [""],

            address: [""],

            language: [""],

            city: [""],

            date: [""],

            recipient: [""],

            content: [""],

            subject: [""],

            title: [""],

            introduction: [""],

            actual: [""],

            motivation: [""],

            end: [""],

            firstcolor: ["#1a2a3b"],
            secondcolor: ["#5891bc"],
            thirdcolor: [""],
            pagecolor: ["#fff"],
            fontfamily: [""],
            fontsize: [""],
            theme: [null],
            idmodele: [1],

            flag: [1],
        });
    }

    initCover2(cover : Cover): void {
        //alert(this.decrypt.transform(cover.firstname));
        this.coverFormGroup = this.fb.group({

            id: [cover.id],
            name: [cover.name],
            firstname: [cover.firstname],
            lastname: [cover.lastname],

            profession: [cover.profession],

            telephone: [this.decrypt.transform(cover.telephone)],
            email: [cover.email],

            address: [this.decrypt.transform(cover.address)],

            city: [cover.city],

            date: [cover.date],

            recipient: [cover.recipient],

            content: [cover.content],

            language: [cover.language],
            linkedin: [cover.linkedin],

            subject: [cover.subject],

            title: [cover.title],

            introduction: [cover.introduction],

            actual: [cover.actual],

            motivation: [cover.motivation],

            end: [cover.end],

            firstcolor: [cover.firstcolor],
            secondcolor: [cover.secondcolor],
            thirdcolor: [cover.thirdcolor],
            pagecolor: [cover.pagecolor],
            fontfamily: [cover.fontfamily],
            fontsize: [cover.fontsize],
            theme: [cover.theme],
            idmodele: [cover.idmodele],


        });
    }

    fileFormGroup: FormGroup;
    initFile(): void {
        this.fileFormGroup = this.fb.group({
            file: [""],
        });
    }

    selectedFiles: FileList;
    currentFileUpload: File;
    files: any = [];
    myFiles: FileList;
    public imagePath;
    imgURL: any;
    url: any;
    @ViewChild("photo", { static: false }) photo!: ElementRef;

    isDeleteFile: boolean = false;
    isSetFile: boolean = false;
    file: any;
    localUrl: any;
    localCompressedURl: any;
    sizeOfOriginalImage: number;
    sizeOFCompressedImage: number;
    mimeType: any;
    imgResultBeforeCompress;
    imgResultAfterCompress;
    src: any;
    selectFile(event) {
        this.initFile();

        var fileName: string;

        this.file = event.target.files.item(0);
        fileName = this.file["name"];

        if (this.file.size / 1000 > 2048) {
            alert(
                "La taille ne doit pas depasser 2 MO! " +
                    "\n" +
                    "Astuce : Recadrer ou rogner l'image pour reduire la taille"
            );
            event.target.value = "";
        }
        //if (file.type.match("image.*") || file.type === "application/pdf") {
        else if (this.file.type.match("image/*")) {
            this.mimeType = event.target.files[0].type;

            this.imageToShow = null;
            //alert("TYPE : "+mimeType);

            /* Pour afficher l'image avec <img height="200"  *ngIf="imgURL" [src]="imgURL"  />  */
            var reader = new FileReader();

            this.imagePath = this.file;
            reader.readAsDataURL(this.file);
            reader.onload = (event2) => {
                this.imgURL = reader.result;
                this.url = reader.result;
                this.localUrl = event2.target.result;
            };

            this.isSetFile = true;
            this.isDeleteFile = false;

            this.fileFormGroup.get("file").setValue(this.file);
            // const imageBlob = this.dataURItoBlob(this.url);

            console.warn(
                "Size in bytes was:",
                this.imageCompress.byteCount(this.url)
            );
        } else {
            alert("Le fichier doit être en jpg, jpeg ou png!");
            event.target.value = "";
        }
    }

    //

    selectFile2(event: any) {
        var fileName: any;
        this.file = event.target.files[0];
        this.mimeType = event.target.files[0].type;
        if (this.file.type.match("image/*")) {
            fileName = this.file["name"];
            if (event.target.files && event.target.files[0]) {
                var reader = new FileReader();
                reader.onload = (event: any) => {
                    this.localUrl = event.target.result;
                    this.compressFile(this.localUrl, fileName);
                };
                reader.readAsDataURL(event.target.files[0]);
            }
        } else {
            alert("Le fichier doit être en jpg, jpeg ou png!");
            event.target.value = "";
        }
    }

    compressFile(image, fileName) {
        this.initFile();
        var orientation = -1;
        this.sizeOfOriginalImage =
            this.imageCompress.byteCount(image) / (1024 * 1024);
        console.warn("Size in bytes is now:", this.sizeOfOriginalImage);
        this.imageCompress
            .compressFile(image, orientation, 70, 70, 400, 400)
            .then((result) => {
                this.imgResultAfterCompress = result;
                this.localCompressedURl = result;

                this.sizeOFCompressedImage =
                    this.imageCompress.byteCount(result) / (1024 * 1024);
                console.warn(
                    "Size in bytes after compression:",
                    this.sizeOFCompressedImage
                );
                // create file from byte
                const imageName = fileName;
                // call method that creates a blob from dataUri
                const imageBlob = this.dataURItoBlob(
                    this.imgResultAfterCompress.split(",")[1]
                );
                //imageFile created below is the new compressed file which can be send to API in form data
                const imageFile = new File([result], imageName, {
                    type: this.mimeType,
                });

                this.imageToShow = null;
                this.imgURL = result;
                this.url = result;

                this.isSetFile = true;
                this.isDeleteFile = false;

                const file2 = this.blobToFile(imageBlob, imageName);

                this.fileFormGroup.get("file").setValue(imageBlob);
            });
    }

    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: this.mimeType });

        return blob;
    }

    blobToFile(theBlob, fileName) {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    public delete() {
        this.isSetFile = false;
        this.isDeleteFile = true;
        this.imageToShow = null;
        this.url = null;
        this.imgURL = null;
        this.photo.nativeElement.value = "";
    }

    remainingText = 0;
    textAreaValueChange(value) {
        this.remainingText = 500 - value;
    }

    moveUp(array, index: number) {
        if (index > 0) {
            const extrasFormArray = array as FormArray;
            const extras = extrasFormArray.value;
            const newExtras = this.swap(extras, index - 1, index);

            extrasFormArray.setValue(newExtras);
            this.updatePage2();
            this.updatePage3();
        }
    }

    moveDown(array, index: number) {
        const extrasFormArray = array as FormArray;
        const extras = extrasFormArray.value;
        if (index < extras.length - 1) {
            const newExtras = this.swap(extras, index, index + 1);
            extrasFormArray.setValue(newExtras);
        }
        this.updatePage2();
        this.updatePage3();
    }

    swap(arr: any[], index1: number, index2: number): any[] {
        arr = [...arr];
        const temp = arr[index1];
        arr[index1] = arr[index2];
        arr[index2] = temp;
        return arr;
    }

    removeNullFields(formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach((key) => {
            formGroup.controls[key].setErrors(null);
            if (formGroup.controls[key] instanceof FormArray) {
                const array: Array<any> = formGroup.controls[key].value;
                let filtered = [];
                for (const obj of array) {
                    Object.keys(obj).filter((k) => {
                        if (obj[k] === null) {
                            delete obj[k];
                        }
                    });
                    filtered.push(obj);
                }
            }
        });
    }

    sortArrayOfObjects = (arr, key) => {
        return arr.sort((a, b) => {
            return a[key] - b[key];
        });
    };

    isSuccess: boolean = false;

    savePhoto() {
        if (this.isSetFile) {
            this.coverService
                .upload(this.fileFormGroup.get("file").value)
                .subscribe(
                    (data) => {},
                    (error) => {
                        alert("Photo invalide !");
                    },
                    () => {}
                );
        }
    }

    listCover: Array<Cover> = [];
    findAllCover() {
        this.dashboardService.findAll().subscribe(
            (data) => {
                this.listCover = data;
            },
            (error) => {
                let link = [""];
                this.confirmBoxEvokeService
                    .info("Erreur", "La page va se recharger", "Ok")
                    .subscribe((resp) => this.router.navigate(link));
            },

            () => {}
        );
    }

    showCover(cover: Cover): void {
        let link = ["my-dashboard/cover", cover.id];
        this.router.navigate(link);
    }

    showCover2(idCover, idModele): void {
        let link = ["cvs/m" + idModele + "-edit-cover", idCover];
        this.router.navigate(link);
    }

    save() {
        this.isOpen = false;
        this.spin1 = true;

        this.getTheme(this.idTheme);

        this.removeNullFields(this.coverFormGroup);
        if (this.isDeleteFile) {
            this.coverFormGroup.get("photo").setValue("");
        }
        setTimeout(() => {
            if (this.coverFormGroup.valid) {
                if (this.listCover.length < 6) {
                    this.coverFormGroup
                        .get("address")
                        .setValue(
                            this.encrypt.transform(
                                this.coverFormGroup.get("address").value
                            )
                        );
                    this.coverFormGroup
                        .get("telephone")
                        .setValue(
                            this.encrypt.transform(
                                this.coverFormGroup.get("telephone").value
                            )
                        );

                    document.getElementById(
                        "my-icon-select2"
                    ).style.pointerEvents = "none";
                    this.coverFormGroup.get("idmodele").setValue(this.idmodele);
                    this.coverFormGroup
                        .get("address")
                        .setValue(
                            this.encrypt.transform(
                                this.coverFormGroup.get("address").value
                            )
                        );
                    this.coverFormGroup
                        .get("telephone")
                        .setValue(
                            this.encrypt.transform(
                                this.coverFormGroup.get("telephone").value
                            )
                        );
                    if (this.idmodele === 1) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#1a2a3b");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#5891bc");

                        this.coverFormGroup.get("pagecolor").setValue("#fff");
                    }
                    if (this.idmodele === 2) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#212529");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#5eaae2");

                        this.coverFormGroup.get("pagecolor").setValue("#fff");
                    }
                    if (this.idmodele === 3) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#740913");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#8b9093");

                        this.coverFormGroup.get("pagecolor").setValue("#fff");
                    }
                    if (this.idmodele === 4) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#252727");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#5a9aba");

                        this.coverFormGroup.get("pagecolor").setValue("#fff");
                    }

                    if (this.idmodele === 5) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#2e3133");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#7e0905");

                        this.coverFormGroup.get("pagecolor").setValue("#fff");
                    }

                    if (this.idmodele === 6) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#182839");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#24601f");

                        this.coverFormGroup
                            .get("pagecolor")
                            .setValue("#cbefac");
                    }

                    if (this.idmodele === 7) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#581b31");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#1a4978");

                        this.coverFormGroup.get("pagecolor").setValue("#fff");
                    }

                    if (this.idmodele === 8) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#454141");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#4c4d4e");

                        this.coverFormGroup
                            .get("pagecolor")
                            .setValue("#dce1e4");
                    }

                    if (this.idmodele === 9) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#003147");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("rgba(17, 19, 29, 0.4)");

                        this.coverFormGroup.get("pagecolor").setValue("#fff");
                    }

                    if (this.idmodele === 10) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#003147");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("rgba(17, 19, 29, 0.4)");

                        this.coverFormGroup.get("pagecolor").setValue("#fff");
                    }

                    if (this.idmodele === 11) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#093556");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#0c5285");

                        this.coverFormGroup
                            .get("pagecolor")
                            .setValue("#1970b1");
                    }

                    if (this.idmodele === 12) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#0e3958");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#0e3341");

                        this.coverFormGroup
                            .get("pagecolor")
                            .setValue("#cedbeb");
                    }

                    if (this.idmodele === 13) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#394043");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#afa40c");

                        this.coverFormGroup
                            .get("pagecolor")
                            .setValue("#e9d18f");
                    }

                    if (this.idmodele === 14) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#212529");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#5e5f62");

                        this.coverFormGroup
                            .get("pagecolor")
                            .setValue("#bac0c0");
                    }

                    if (this.idmodele === 15) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#102927");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#77f5ad");

                        this.coverFormGroup
                            .get("pagecolor")
                            .setValue("#c2e7a8");
                    }

                    if (this.idmodele === 16) {
                        this.coverFormGroup
                            .get("firstcolor")
                            .setValue("#404549");
                        this.coverFormGroup
                            .get("secondcolor")
                            .setValue("#431427");

                        this.coverFormGroup.get("pagecolor").setValue("#fff");
                        alert(this.theme.id);
                    }

                    this.coverFormGroup.get("theme").setValue(this.theme);
                    this.coverService.save(this.coverFormGroup.value).subscribe(
                        (data) => {
                            this.cover = data;
                            this.isSuccess = true;
                        },
                        (error) => {
                            if (error.error.message === undefined) {
                                alert("Serveur inaccessible");
                            } else {
                                alert(error.error.message);
                            }
                            this.isOpen = true;
                            this.spin1 = false;
                        },

                        () => {
                            this.savePhoto();
                            setTimeout(() => {
                                this.showCover2(this.cover.id, this.idmodele);
                                this.isOpen = true;
                                this.spin1 = false;
                                this.isLoading = false;
                            }, 2000);
                        }
                    );
                    return;
                } else {
                    alert("Vous avez déjà six (6) Covers ");
                    this.isOpen = true;
                    this.spin1 = false;
                }
            } else {
                alert("Informations invalides ");
                this.isOpen = true;
                this.spin1 = false;
            }
        }, 1000);
    }

    firstcolor: string;
    secondcolor: string;
    thirdcolor: string;
    fontfamily: string;
    pagecolor: string;
    theme: any;
    findOne(id) {

        this.coverService.findOne2(id).subscribe(
            (data) => {
                this.cover = data;
                this.initCover2(data);
            },
            (error) => {
                alert("Informations invalides");
                let link = ["/"];
                this.router.navigate(link);
            },
            () => {
                this.firstcolor = this.cover.firstcolor;
                this.secondcolor = this.cover.secondcolor;
                this.thirdcolor = this.cover.thirdcolor;
                this.pagecolor = this.cover.pagecolor;
                this.theme = this.cover.theme;
                this.family1=this.cover.fontfamily;
                this.fontsize=this.cover.fontsize;
                this.userLanguage=this.cover.language;
                this.languageService.setLanguage(this.cover.language);
                if(!this.cover.language){
                    this.userLanguage="fr";
                }

                if (this.cover.photo) {
                    this.showImage(this.cover.photo);
                }

                this.fillSecondPage2();
                this.fillSecondPage3();
                this.selectTheme();
            }
        );
    }

    findOne2(id) {
        document.getElementById("my-icon-select2").style.pointerEvents = "none";
        // select Theme

        this.coverService.findOne(id).subscribe(
            (data) => {
                this.cover = data;
                this.initCover2(data);
            },
            (error) => {
                alert("Informations invalides");
                let link = ["/"];
                this.router.navigate(link);
            },
            () => {
                this.firstcolor = this.cover.firstcolor;
                this.secondcolor = this.cover.secondcolor;
                this.thirdcolor = this.cover.thirdcolor;
                this.pagecolor = this.cover.pagecolor;
                this.theme = this.cover.theme;

                if (this.cover.photo) {
                    this.showImage(this.cover.photo);
                }
                document.getElementById("my-icon-select2").style.pointerEvents =
                    "auto";

                this.selectTheme();
            }
        );
    }

    imageToShow: any = null;

    showImage(photoname: string): any {
        this.coverService
            .fetchImageById(photoname)

            .subscribe(
                (image) => {
                    this.createImage(image);
                },
                (err) => {
                    this.handleImageRetrievalError(err);
                },
                () => {}
            );
    }

    private handleImageRetrievalError(err: Error) {
        console.error(err);

        //alert("Problem retrieving profile photo.");
    }

    createImage(image: Blob) {
        if (image && image.size > 0) {
            let reader = new FileReader();
            reader.addEventListener(
                "load",
                () => {
                    this.imageToShow = reader.result;
                    // this.showSpinner = false;
                },
                false
            );

            reader.readAsDataURL(image);
        } else {
            //this.showSpinner = false;
        }
    }

    imageToShow2: any;
    showImage2(photoname: string) {
        this.coverService
            .fetchImageById2(photoname)

            .subscribe(
                (image) => {
                    this.createImage2(image);
                },
                (err) => {
                    this.handleImageRetrievalError(err);
                },
                () => {}
            );
    }

    createImage2(image: Blob) {
        if (image && image.size > 0) {
            let reader = new FileReader();
            reader.addEventListener(
                "load",
                () => {
                    this.imageToShow2 = reader.result;
                    // this.showSpinner = false;
                },
                false
            );

            reader.readAsDataURL(image);
        } else {
            //this.showSpinner = false;
        }
    }

    isCrypt: boolean = true;
    async update() {
        this.isCrypt = false;

        this.isOpen = false;
        this.spin1 = true;
        document.getElementById("my-icon-select2").style.pointerEvents = "none";




        if (this.isDeleteFile) {
            this.coverFormGroup.get("photo").setValue("");
        }

        if (this.coverFormGroup.valid) {
            this.coverFormGroup.get("theme").setValue(this.theme);
            this.coverFormGroup.get("idmodele").setValue(this.idmodele);
            this.coverFormGroup.get("firstcolor").setValue(this.firstcolor);
            this.coverFormGroup.get("secondcolor").setValue(this.secondcolor);
            this.coverFormGroup.get("thirdcolor").setValue(this.thirdcolor);
            this.coverFormGroup.get("pagecolor").setValue(this.pagecolor);
            this.coverFormGroup.get("fontfamily").setValue(this.fontfamily);
            this.coverFormGroup.get("fontsize").setValue(this.fontsize);
            this.coverFormGroup
                .get("address")
                .setValue(
                    this.encrypt.transform(
                        this.coverFormGroup.get("address").value
                    )
                );
            this.coverFormGroup
                .get("telephone")
                .setValue(
                    this.encrypt.transform(
                        this.coverFormGroup.get("telephone").value
                    )
                );

            this.coverService
                .update(this.coverFormGroup.value, this.cover.id)
                .subscribe(
                    (data) => {
                        this.cover = data;
                        //alert(JSON.stringify(data))
                    },
                    (error) => {
                        alert(error.error.message);
                        alert("Erreur, la page va se recharger 3!");
                        let link = ["/"];
                        this.router.navigate(link);
                    },

                    () => {
                        this.savePhoto();
                        document.getElementById(
                            "my-icon-select2"
                        ).style.pointerEvents = "auto";
                        /*
                        this.sortBy("index", this.coverEducations());
                         this.sortBy("index", this.coverCustoms());
                        this.sortBy("index", this.coverFormations());
                        this.sortBy("index", this.coverExperiences());
                        this.sortBy("index", this.coverReferences());
                        this.sortBy("index", this.coverCertifications());
                        */
                        setTimeout(() => {
                            this.isCrypt = true;
                            this.isOpen = true;
                            this.spin1 = false;
                        }, 1000);

                        setTimeout(() => {
                            this.findOne2(this.cover.id);
                        }, 777);
                    }
                );
            return;
        } else alert("Veuilez recharger la page !");
    }

    async openPDF() {
        var totalPDFPages = 1;
        await setTimeout(() => {
            this.isLoading = true;
            this.isOpen = false;
            this.spin1 = true;
            //  this.update2();
        }, 0);

        setTimeout(() => {
            var x1 = $("#content1").prop("scrollHeight");
            var y1 = $("#content1").prop("clientHeight") + 30;

            var x2 = $("#content1").prop("scrollHeight");
            var y2 = $("#content1").prop("clientHeight") + 30;

            if (x1 > 2287 || x2 > 2287) {
                totalPDFPages = 3;
            } else if (x1 > y1 || x2 > y2) {
                totalPDFPages = 2;
            } else {
                totalPDFPages = 1;
            }
        }, 1500);

        setTimeout(() => {
            for (let i = 1; i <= totalPDFPages; i++) {
                $("#content" + i).css({
                    height: "841.995pt",

                    "min-height": "841.995pt",
                    "min-width": "595.35pt",
                });
                $("#content" + i).css({
                    height: "841.5pt",
                    "min-height": "841.5pt",
                    width: "595.35pt",
                    "min-width": "595.35pt",
                });

            }
        }, 2100);

        setTimeout(() => {
            var pdf = new jsPDF("p", "pt", "a4");
            for (let i = 1; i <= totalPDFPages; i++) {
                html2canvas($("#content" + i)[0], {
                    allowTaint: true,
                    scale: 3,
                }).then((canvas) => {
                    //canvas.getContext('2d');

                    var imgData = canvas.toDataURL("image/jpeg", 0.7);

                    pdf.addImage(imgData, "JPEG", 0, 0, 595.35, 841.995);

                    if (i === totalPDFPages) {
                        setTimeout(async () => {
                            pdf.save(
                                "Cover_" +
                                    this.coverFormGroup.get("firstname").value +
                                    "_" +
                                    this.coverFormGroup.get("lastname").value +
                                    ".pdf"
                            );

                            this.isOpen = true;
                            this.spin1 = false;
                            this.isLoading = false;
                        }, 2900);
                    } else {
                        pdf.addPage("a4", "p");
                    }
                });
            }
        }, 2900);
    }



    initCover3(cover : Cover): void {
        //alert(this.decrypt.transform(cover.firstname));
        this.coverFormGroup = this.fb.group({


            name: [cover.name],
            firstname: [cover.firstname],
            lastname: [cover.lastname],

            profession: [cover.profession],

            telephone: [this.cover.telephone],
            email: [cover.email],

            address: [this.cover.address],

            city: [cover.city],

            date: [cover.date],

            recipient: [cover.recipient],

            content: [cover.content],

            language: [cover.language],
            linkedin: [cover.linkedin],

            subject: [cover.subject],

            title: [cover.title],

            introduction: [cover.introduction],

            actual: [cover.actual],

            motivation: [cover.motivation],

            end: [cover.end],

            firstcolor: [cover.firstcolor],
            secondcolor: [cover.secondcolor],
            thirdcolor: [cover.thirdcolor],
            pagecolor: [cover.pagecolor],
            fontfamily: [cover.fontfamily],
            fontsize: [cover.fontsize],
            theme: [cover.theme],
            idmodele: [cover.idmodele],

        });
    }


    cover2 : Cover;
    useExample(cover: Cover): void {
        this.isOpen=false;
        if(this.tokenStorageService.getUser().role==="user"){

                this.initCover3(cover);
                const data = cover;



                  this.coverService.save(this.coverFormGroup.value).subscribe(
                    (data) => {
                      // alert(data);
                      this.cover2=data


                    },
                    (error) => {
                      alert(error.error.message);

                      this.tokenStorageService.signOut2();
                    },

                    () => {
                        setTimeout(() => {


                            this.getModele(
                                this.cover2.id,
                                this.cover.idmodele
                            );
                        }, 312);

                    }
                  );


        }
        else {
            this.initCover3(cover);
                const data = cover;

                  this.coverService.save(this.coverFormGroup.value).subscribe(
                    (data) => {
                      // alert(data);
                      this.cover2=data

                    },
                    (error) => {
                      alert(error.error.message);

                      this.tokenStorageService.signOut2();
                    },

                    () => {

                        setTimeout(() => {


                            this.getModele(
                                this.cover2.id,
                                this.cover.idmodele
                            );
                        }, 312);

                    }
                  );
        }


      }


}
/*




*/
