<section class="courses-area ptb-100">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Top 5</span>
            <h2>Les modèles les plus utilisés</h2>
            <a routerLink="/modeles" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">Tous les modèles</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <div class="courses-slides">
            <owl-carousel-o [options]="coursesSlides">
                <ng-template carouselSlide *ngFor=" let modele of listModele">
                    <div>
                        <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <a *ngIf="modele.id===1" style="border: 1px solid #cccc;" (click)="showThemes(modele.id)" class="d-block"><img src="assets/img/modeles/m1.png" alt="image"></a>
                                <a *ngIf="modele.id===2" style="border: 1px solid #cccc;" (click)="showThemes(modele.id)" class="d-block"><img src="assets/img/modeles/m2.png" alt="image"></a>


                                <div class="courses-tag">
                                    <a *ngIf="modele.id!==3" routerLink="/themes/+{{modele.id}}" class="d-block">Cliquez pour choisir</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </ng-template>





            </owl-carousel-o>
        </div>
    </div>
</section>
