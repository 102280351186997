import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ThemeService } from "./cv-theme.service";

@Component({
    selector: "cv-theme",
    templateUrl: "./cv-theme.component.html",
    styleUrls: ["./cv-theme.component.scss"],
})
export class CvThemeComponent implements OnInit {
    constructor(
        private themeService: ThemeService,
        private route: ActivatedRoute
    ) {}
    idModele: number;

    // theme1 : name : darkblue ; 1color : #19323d; 2color : #03a9f4; 3color: #fff ; pagecolor: #fff

    // theme2 : name : darkblue2 ; 1color :#003147; 2color : #03a9f4; 3color: #fff; pageColor :#d4e2f7

    // theme3 : name : red ; 1color : #871919; 2color : #d57474; 3color: #fff; pagecolor: #fff

    // theme4 : name : red2 ; 1color : #871919; 2color : #03a9f4; 3color: #fff; pageColor: #e9d2d2

    // theme5 : name : gray ; 1color : #333536; 2color : #42a4d5; 3color: #fff; pageColor: #fff

    // theme6 : name : gray2 ; 1color : #333536; 2color : #42a4d5; 3color: #fff; pageColor: #d6d9db

    // theme7 : name : green ; 1color : #0c3b0f; 2color :#70ab90; 3color: #fff; pageColor: #fff

    // theme8 : name : green2 ; 1color : #0c3b0f; 2color :#70ab90; 3color: #fff; pageColor: #d1e9de

    // theme9 : name : yellow ; 1color :  #6e550a; 2color :#ffc107; 3color: #fff; pageColor: #fff

    // theme10 : name : yellow2 ; 1color :  #6e550a; 2color :#ffc107; 3color: #fff; pageColor: #f3e8c7

    // theme11 : name : cyan ; 1color :  #063b3a; 2color :#71c1d1; 3color: #fff; pageColor: #fff

    // theme12 : name : cyan2 ; 1color :  #1f4a52; 2color :#71c1d1; 3color: #fff; pageColor: #cbdbdb

    // theme13 : name : orange ; 1color : #bb3712; 2color :#df907a; 3color: #fff; pageColor: #fff
    // theme14 : name : orange2 ; 1color : #bb3712; 2color :#df907a; 3color: #fff; pageColor:#e9d8d3

    // theme15 : name : red3 ; 1color : #9d0b0b; 2color :#94979b; 3color: #fff; pageColor:#fff

    // theme16 : name : red4 ; 1color : #9d0b0b; 2color :#94979b; 3color: #fff; pageColor:#d4cac9

    // theme17 : name : black ; 1color : #212529; 2color :#94979b; 3color: #fff; pageColor:#fff

    // theme18 : name : black2 ; 1color : #212529; 2color :#94979b; 3color: #fff; pageColor:#dadbdc

     // theme19 : name : black3 ; 1color : #212529; 2color :#e05858; 3color: #fff; pageColor:#fff

    // theme20 : name : black4 ; 1color : #212529; 2color :#5eaae2; 3color: #fff; pageColor:#fff

    // theme21 : name : purple ; 1color : #4c0356; 2color :#c39ede; 3color: #fff; pageColor:#fff

    // theme22 : name : purple2 ; 1color : #292b3b; 2color :#7c82a7; 3color: #fff; pageColor:#d2d9e9

    // theme23 : name : gray3 ; 1color : #343f52; 2color :#4dbad6; 3color: #fff; pageColor:#fff

      // theme23 : name : gray4 ; 1color : #343f52; 2color :#4dbad6; 3color: #fff; pageColor:#d6e7ed


    ngOnInit(): void {
        let id = +this.route.snapshot.params["idModele"];
        this.idModele = id;

    }


}
