<div class="partner-slides">
    <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partner/partner1.png" alt="image">
                </a>
            </div>
        </ng-template>
        <!--
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partner/pp.png" alt="image">
                </a>
            </div>
        </ng-template>
        -->

        <!--
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partner/partner3.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partner/partner4.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partner/partner5.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partner/partner6.png" alt="image">
                </a>
            </div>
        </ng-template>
        -->
    </owl-carousel-o>
</div>