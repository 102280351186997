<app-header-style-one></app-header-style-one>

<div class="page-title-area page-title-style-three item-bg4">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Accueil</a></li>
                <li>Thèmes</li>
            </ul>
            <h2>Choisir un thème pour commencer</h2>
        </div>
    </div>
</div>

<div class="courses-area ptb-100">
    <div class="container">
        <div class="courses-topbar">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="topbar-result-count">
                        <p>Showing 1 – 6 of 54</p>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="topbar-ordering-and-search">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-5 offset-lg-4 offset-md-1 col-sm-6">
                                <div class="topbar-ordering">
                                    <select class="form-control">
                                        <option>Sort by popularity</option>
                                        <option>Sort by latest</option>
                                        <option>Default sorting</option>
                                        <option>Sort by rating</option>
                                        <option>Sort by new</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 col-sm-6">
                                <div class="topbar-search">
                                    <form>
                                        <label><i class="bx bx-search"></i></label>
                                        <input type="text" class="input-search" placeholder="Search here...">
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">

            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a routerLink="/cv1/ajout" class="d-block"><img src="assets/img/courses/cvr1.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/cv1/ajout" class="d-block">Cliquez pour choisir</a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a routerLink="/cv1/ajout" class="d-block"><img src="assets/img/courses/cvr1.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/cv1/ajout" class="d-block">Cliquez pour choisir</a>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a routerLink="/cv1/ajout" class="d-block"><img src="assets/img/courses/cvr1.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/cv1/ajout" class="d-block">Cliquez pour choisir</a>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a routerLink="/cv1/ajout" class="d-block"><img src="assets/img/courses/cvb1.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/cv1/ajout" class="d-block">Cliquez pour choisir</a>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a routerLink="/cv1/ajout" class="d-block"><img src="assets/img/courses/cvb1.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/cv1/ajout" class="d-block">Cliquez pour choisir</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a routerLink="/cv1/ajout" class="d-block"><img src="assets/img/courses/cvb1.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/cv1/ajout" class="d-block">Cliquez pour choisir</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a routerLink="/cv1/ajout" class="d-block"><img src="assets/img/courses/cvb1.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/cv1/ajout" class="d-block">Cliquez pour choisir</a>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a routerLink="/cv1/ajout" class="d-block"><img src="assets/img/courses/cvb1.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/cv1/ajout" class="d-block">Cliquez pour choisir</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a routerLink="/cv1/ajout" class="d-block"><img src="assets/img/courses/cvb1.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/cv1/ajout" class="d-block">Cliquez pour choisir</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a routerLink="/cv1/ajout" class="d-block"><img src="assets/img/courses/cvb1.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/cv1/ajout" class="d-block">Cliquez pour choisir</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a routerLink="/cv1/ajout" class="d-block"><img src="assets/img/courses/cvb1.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/cv1/ajout" class="d-block">Cliquez pour choisir</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a routerLink="/cv1/ajout" class="d-block"><img src="assets/img/courses/cvb1.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/cv1/ajout" class="d-block">Cliquez pour choisir</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/courses-4-columns-style-3" class="page-numbers">2</a>
                    <a routerLink="/courses-4-columns-style-3" class="page-numbers">3</a>
                    <a routerLink="/courses-4-columns-style-3" class="page-numbers">4</a>
                    <a routerLink="/courses-4-columns-style-3" class="page-numbers">5</a>
                    <a routerLink="/courses-4-columns-style-3" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
