<div class="row">
    <div class="col-lg-12 col-md-12 col-12">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bxs-cog'></i>
            </div>
            <h3><span >Fonctionnalités</span></h3>
            <p>Cliquez sur l'une des fonctionnalités ci-dessous et commencez à créer votre CV ou votre lettre de motivation.</p>

        </div>
    </div>

</div>
